import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function ContentWrapper() {
  return (
    <CardSkeleton margin="30px auto" justifyContent="space-between" height="10px" display="flex" width="100%">
    <TextWrapperSkeleton width="150px" />
    <TextWrapperSkeleton  width="70px"/>
  </CardSkeleton>
  )
}

function ForeCloseEmiLoader(){
    return(
        <>
          <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        padding="10px"
        br="0px"
      >
        <TextWrapperSkeleton margin="0px 50px" height='12px' width='100px' />
        <TextWrapperSkeleton height='12px' width='50px' />
      </CardSkeleton>
      <CardSkeleton padding="12px" width="90%" margin="20px auto" height="180px">
        <ContentWrapper/>
        <ContentWrapper/>
        <ContentWrapper/>
      </CardSkeleton>
      <CardSkeleton padding="12px" width="90%" margin="20px auto auto auto" height="60px">
        <CardSkeleton justifyContent="space-between" height="10px" display="flex" width="100%">
        <TextWrapperSkeleton width="150PX"/>
        <TextWrapperSkeleton width="70px"/>
        </CardSkeleton>
        <TextWrapperSkeleton margin="20px 0px" width="200px"/>
      </CardSkeleton>
      <CardSkeleton display="flex" justifyContent="center" alignItems="center" height="40px" margin="24px auto" width="80%">
        <TextWrapperSkeleton width="200px"/>
      </CardSkeleton>
      </>
    )
}

export default ForeCloseEmiLoader;