import { Text, View } from "native-base"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Paths from "../../../routes/Paths"
import { clearCommonState } from "../../../store/actions/Common"
import { ProgramTypes } from "../../../utils/constants"
import { PwaVersions } from "../../../utils/enums"
import CardInHand from "../../svg/cardInHand"
import styles from "./styles"

const getProgramType = (user)=>{
  if(user.programType === ProgramTypes.CREDIT_CARD)
    return " credit "
  else if(user.programType === ProgramTypes.PAY_LATER)
    return " pay later "
  else
    return " "
}
const ActivatePhysicalCardStrip = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [theme, user, config, featureFlags] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
    state.featureFlags,
  ])

  const onClickNavigation = () => {
    if (props?.to) {
      return props.to
    } else if (
      config.version === PwaVersions.V1 ||
      (config.version === PwaVersions.V2 &&
        featureFlags.isQrScanRequiredForActivation)
    ) {
      return "/ActivateCard/Scanner"
    } else {
      return Paths.HOME_WITH_ACTIVATE
    }
  }

  const getBgColor = () =>{
    if(config?.version === PwaVersions.V2){
      return theme.widgetBackgroundColor;
    }
    else if(user.programType === ProgramTypes.PREPAID){
      return `${theme.color1}:alpha.10`;
    }
    else{
      return theme.color3
    } 
  }

  return (
    <View w={props.width}>
      <View
        onClick={() => {
          navigate("/SessionExpired", { replace: true })
          // home component needs to be remounted if path is HOME_WITH_ACTIVATE

          // clear home component state to mimic a remount
          dispatch(clearCommonState())
          navigate( onClickNavigation(), { replace: true })
        }}
      >
        {
          <View
            {...styles.activateCardContainer}
            bgColor = {getBgColor() }
            borderWidth={"0px"}
            shadow={config?.version === PwaVersions.V2 ? 2 : 0}
            borderRadius={"12px"}
          >
            {/* Activate Physical Card Section */}
            <View>
              <CardInHand />
            </View>
            <View w='80%'>
              <View>
                <Text
                  {...styles.activateCardHeading}
                  fontFamily={theme.fontFamily}
                  fontSize={"14px"}
                  fontWeight ="600"
                  color={
                    config?.version === PwaVersions.V2
                      ? theme.appTextColor
                      : null
                  }
                >
                  {props.text ? props.text : "Activate Physical Card"}
                </Text>
              </View>
              <View>
                {props.subText ? (
                  <Text
                    {...styles.activateCardSubHeading}
                    fontFamily={theme.fontFamily}
                    color={
                      config?.version === PwaVersions.V2
                        ? theme.appTextColor
                        : null
                    }
                  >
                    {props.subText}
                  </Text>
                ) : (
                  <Text
                    {...styles.activateCardSubHeading}
                    fontFamily={theme.fontFamily}
                    fontSize="12px"
                    color={
                      config?.version === PwaVersions.V2
                        ? theme.appTextColor
                        : null
                    }
                  >
                    Enable offline usage of your
                    {getProgramType(user)}
                    card
                  </Text>
                )}
              </View>
            </View>
          </View>
        }
      </View>
    </View>
  )
}

export default ActivatePhysicalCardStrip
