import Service from "./Service"
import { getApiConfig } from "../utils/functions"

const AccountService = {
  getSummary: (apiToken, accountId, version) =>
    Service.get(`/accounts/${accountId}/summary`, {
      params: {
        version: version,
      },
      ...getApiConfig(apiToken),
    }),

  getOffersList: (accountId, programId) =>
    Service.get(`/accounts/get-offers-list`, {
      params: {
        programId: programId,
        accountId: accountId,
      },
    }),

  getTransactions: (accountId, data) =>
    Service.post(`/accounts/${accountId}/transactions`, data),

  getRewards: (accountId, data) =>
    Service.post(`/accounts/${accountId}/rewards`, data),

  getCashbacks: (accountId, data) =>
    Service.post(`/accounts/${accountId}/cashbacks`, data),

  getUnbilledTransactions: (accountId, data) =>
    Service.post(`/accounts/${accountId}/unbilled`, data),

  getStatements: (accountId, data) =>
    Service.post(`/accounts/${accountId}/statements`, data),

  downloadStatement: (accountId, statementId, fileName) =>
    Service.get(`/accounts/${accountId}/download-statement/${statementId}`, {
      responseType: "blob",
    }),

  getEMIConfig: (apiToken, accountId, amount, txnRefId) =>
    Service.get(`/accounts/${accountId}/emi`, {
      params: { amount: amount, txnRefId: txnRefId },
      ...getApiConfig(apiToken),
    }),

  createEMI: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/emi/create`,
      data,
      getApiConfig(apiToken),
    ),

  listEmi: (apiToken, accountId) =>
    Service.get(`/accounts/${accountId}/list-emi`, getApiConfig(apiToken)),

  getForeclosureDetails: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/get-foreclosure-details`,
      data,
      getApiConfig(apiToken),
    ),

  forecloseEmi: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/foreclose-emi`,
      data,
      getApiConfig(apiToken),
    ),
}

export default AccountService
