import moment from "moment"
import { ChevronLeftIcon, Text, useToast, View } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import AccountService from "../../../../services/AccountService"
import { DisplayConfig } from "../../../../utils/constants"
import { PwaVersions } from "../../../../utils/enums"
import { Header } from "../../../core"
import Toast from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import "../../styles.css"
import DateSelect from "../DateSelect/DateSelect"
import StatementAccordionsNew from "../StatementAccordions/StatementAccordionsNew"
import styles from "../styles"
import StatementLoader from "../../../core/SkeletonLoader/statementLoader"

const initToDate = moment().format("YYYY-MM-DD")
const initFromDate = moment().subtract(12, "months").format("YYYY-MM-DD")
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const StatementNew = () => {
  const [theme, user, config] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
  ])
  const windowDim = useWindowDimensions()
  const toast = useToast()

  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(0) // to count back to back api errors
  const [statementsByYear, setStatementsByYear] = useState({})

  const [currYear, setCurrYear] = useState(moment().year())
  const [currMonth, setCurrMonth] = useState(
    months[moment(user?.account?.currentCycleStartDate).month() - 1],
  )

  let cycleStartDate = moment(user?.account?.currentCycleStartDate).date()

  useEffect(() => {
    ;(async () => {
      if (apiError < 3) {
        await getStatements()
        setLoading(false)
      } else {
        setLoading(false)
      }
    })()
  }, [])

  async function onViewStatementsClick() {
    if (apiError < 3) {
      await getStatements()
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const getStatements = async () => {
    try {
      let fromDate = cycleStartDate + "/" + currMonth + "/" + currYear
      let toDate = cycleStartDate + currMonth + "/" + currYear

      let range
      range = {
        from: moment(fromDate).add(1, "month").format("YYYY-MM-DD"),
        to: moment(toDate).add(2, "month").format("YYYY-MM-DD"),
      }

      // get Statements for the 3 months provided above
      const response = await AccountService.getStatements(
        user.account.id,
        range,
      )
      const result = response.data

      // if api call successful
      if (result?.success) {
        // setting the statements for the current years accordian
        let statements = result.data.statements
        const statementMonth =
          statements &&
          statements.length === 1 &&
          new Date(statements[0].fromDate).getMonth()
        const selectedMonth = months.indexOf(currMonth)
        if (statementMonth != selectedMonth && statements.length === 1) {
          setStatementsByYear([])
        } else {
          setStatementsByYear(result.data.statements)
        }
        // reset api error count
        setApiError(0)
      } else {
        if (apiError === 2) {
          // if api call failed for 3rd consecutive time
          toast.show({
            render: () => {
              return (
                <Toast>
                  <View ml='8px'>
                    <Text
                      color={theme.toastColor}
                      fontFamily={theme.fontFamily}
                      {...styles.toast}
                    >
                      Error fetching Statements
                    </Text>
                  </View>
                </Toast>
              )
            },
          })
        }

        // incrememnt api error count
        setApiError(apiError + 1)
      }
    } catch (error) {
      if (apiError === 2) {
        toast.show({
          render: () => {
            return (
              <Toast>
                <View ml='8px'>
                  <Text
                    color={theme.toastColor}
                    fontFamily={theme.fontFamily}
                    {...styles.toast}
                  >
                    Error fetching Statements
                  </Text>
                </View>
              </Toast>
            )
          },
        })
      }
      setApiError(apiError + 1)
    }
  }

  return loading ? (
    config.version === PwaVersions.V1 ?
    <View
      height={windowDim.height}
      alignItems='center'
      justifyContent='center'
      style={{
        backgroundColor: `${
          config.version === PwaVersions.V2 ? theme.backgroundColor : "white"
        }`,
      }}
    >
      {/* <Loader color={theme.color1} width={120} height={120} /> */}
    </View> : (
      <View h={windowDim.height} bgColor={theme.backgroundColor}>
        <StatementLoader/>
      </View>
    )
  ) : apiError ? (
    <>
      <ErrorScreen />
    </>
  ) : (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View
          marginTop={config?.version === PwaVersions.V2 ? "0" : "10"}
          mx={config?.version === PwaVersions.V2 ? "0" : "5"}
          minH={windowDim.height}
          bgColor={
            config?.version === PwaVersions.V2 ? theme.backgroundColor : null
          }
        >
          {config?.version === "v2" ? (
            <Header
              text='Statements'
              onBack={async () => {
                window.history.go(-1)
              }}
            />
          ) : (
            <View
              {...styles.header}
              fontFamily={theme.fontFamily}
              py={config?.version === PwaVersions.V2 ? "12px" : "0"}
              px={config?.version === PwaVersions.V2 ? "5" : "0"}
              borderRadius={config?.version === PwaVersions.V2 ? "0" : "5"}
              bgColor={
                config?.version === PwaVersions.V2
                  ? theme.headerBackgroundColor
                  : null
              }
            >
              <Link to={-1}>
                <ChevronLeftIcon
                  size='6'
                  color={
                    config?.version === PwaVersions.V2
                      ? theme.titleColor
                      : "#000000"
                  }
                />
              </Link>
              <Text
                {...styles.statementText}
                fontSize='16'
                fontFamily={theme.fontFamily}
                fontWeight={600}
                color={
                  config?.version === PwaVersions.V2
                    ? theme.titleColor
                    : "#000000"
                }
              >
                Statements
              </Text>
            </View>
          )}

          <DateSelect
            onViewStatementsClick={onViewStatementsClick}
            setCurrMonth={setCurrMonth}
            setCurrYear={setCurrYear}
          />
          <View marginTop='5' zIndex={-1} width='100%'>
            <StatementAccordionsNew allStatements={statementsByYear} />
          </View>
        </View>
      </View>
    </View>
  )
}
export default StatementNew
