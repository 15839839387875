import React from "react"
import { Text, Button } from "native-base"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"

const btnStyles = {
  alignItems: "center",
  justifyContent: "center",
  borderWidth: "1",
  width: "90%",
  py: "14px",
  borderRadius: "6px",
}

const ColorButton = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  return (
    <Button
      borderColor={props.primaryColor || theme.color1}
      {...props}
      backgroundColor={props.primaryColor || theme.color1}
      {...btnStyles}
    >
      <Text
        color={
          config.version === PwaVersions.V2
            ? props.secondaryColor || theme.color2
            : "#fff"
        }
        fontFamily={theme.fontFamily}
        fontSize='16px'
        fontWeight='600'
      >
        {props.text}
      </Text>
    </Button>
  )
}

const WhiteButton = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  return (
    <Button
      onPress={() => props.onPress()}
      mt='8px'
      bgColor={config.version === PwaVersions.V2 ? theme.color2 : "#fff"}
      {...btnStyles}
      borderColor={theme.color1}
    >
      <Text
        color={theme.color1}
        fontFamily={theme.fontFamily}
        fontSize='16px'
        fontWeight='600'
      >
        {props.text}
      </Text>
    </Button>
  )
}

export { ColorButton, WhiteButton }
