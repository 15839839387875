import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const ResetPin = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 32 32' size='32px'>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.00008 5.33325C2.61341 5.33325 0.666748 7.27992 0.666748 9.66658V20.9999C0.666748 23.3866 2.61341 25.3333 5.00008 25.3333H17.6667C17.5001 24.7199 17.3929 24.0533 17.3529 23.3333H5.00008C3.71341 23.3333 2.66675 22.2866 2.66675 20.9999V13.3333H26.6667V15.233C27.2934 15.8597 28.0401 16.2063 28.6667 16.3997V9.66658C28.6667 7.27992 26.7201 5.33325 24.3334 5.33325H5.00008ZM5.00008 7.33325H24.3334C25.6201 7.33325 26.6667 8.37992 26.6667 9.66658V10.6666H2.66675V9.66658C2.66675 8.37992 3.71341 7.33325 5.00008 7.33325ZM24.6667 15.3333C24.4629 15.3333 24.2586 15.4179 24.1329 15.5859C22.3656 17.9419 19.3614 17.9856 19.3334 17.9856C18.9654 17.9856 18.6667 18.2843 18.6667 18.6523V22.6666C18.6667 29.1126 24.4473 30.6324 24.5053 30.6471C24.5586 30.6604 24.6121 30.6666 24.6667 30.6666C24.7214 30.6666 24.7749 30.6604 24.8282 30.6471C24.8862 30.6324 30.6667 29.1126 30.6667 22.6666V18.6523C30.6667 18.2856 30.3694 17.9876 30.0027 17.9856C29.9727 17.9856 26.9679 17.9419 25.2006 15.5859C25.0749 15.4179 24.8706 15.3333 24.6667 15.3333Z'
          fill={theme.appTextColor}
        />
      </svg>
    </Icon>
  )
}

export default ResetPin
