import { Image, Text, View, useToast } from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import CustomerSupportV2 from "../../../../assets/images/CustomerSupportV2.svg"
import { setSetMpinState } from "../../../../store/actions/SetMpin"
import { ProgramTypes } from "../../../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import { Header } from "../../../core"
import CustomLoader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import AboutProgram from "../../../svg/aboutProgram"
import BlockReplace from "../../../svg/blockReplace"
import CardControl from "../../../svg/cardControl"
import ManageEmi from "../../../svg/manageEmi"
import MpinReset from "../../../svg/mpinReset"
import ResetPin from "../../../svg/resetPin"
import ViewStatement from "../../../svg/viewStatement"
import styles from "../../styles"
import { BlockOptions } from "../BlockCard/constants"

const Home_v2 = props => {
  const [screen, theme, session, config, user] = useSelector(state => [
    state.screen,
    state.theme,
    state.session,
    state.config,
    state.user,
  ])
  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const nativeToast = useToast()

  const isPhysicalCardActivated = user?.cards[0]?.isPhysicalCardActivated
  const isVirtualCardActivated = user?.cards[0]?.isVirtualCardActivated
  const notPhysicalAndVirtual =
    !isVirtualCardActivated && !isPhysicalCardActivated

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onChangeMpinClick = async () => {
    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {
          toast("Your MPIN has been changed successfully!")
          // navigate back
          navigate("/ManageCard", { replace: true })
        },

        onSetMpinFailure: async () => {
          toast("Failed to set MPIN")
          navigate("/", { replace: true })
        },

        onSetMpinCancel: async () => {
          navigate("/", { replace: true })
        },
      }),
    )
    // navigate to set mpin screen
    navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin: true } })
  }

  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }
  const getBlockMsg = ()=>{
    if(replaceOrBlock() === BlockOptions.ONLY_REPLACE)
      return "Replace Card"
    else if(replaceOrBlock() === BlockOptions.ONLY_BLOCK)
      return "Block Card"
    else
      return "Block/Replace Card"
  }
  return (
    <View p={0} minH={windowDimensions.height} bgColor={theme.backgroundColor}>
      <Header
        text='Manage Card'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <View px='5'>
        <Link
          style={{
            textDecoration: "None",
          }}
          to={
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.UNBLOCK_CARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session?.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.MANAGE_LIMIT_DISABLE,
            ) ||
            !props.isAccountActive ||
            !props.card ||
            props.card.isHotlisted ||
            notPhysicalAndVirtual
              ? "#"
              : "/ManageCard/CardSettings"
          }
        >
          <View
            {...styles.cardControls}
            {...styles.commonStyles}
            opacity={
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
              ) ||
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.UNBLOCK_CARD_DISABLE,
              ) ||
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.CARD_DASHBOARD_DISABLE,
              ) ||
              session?.disabledFunctionsByBlockCode?.includes(
                AU_BlockCodes.MANAGE_LIMIT_DISABLE,
              ) ||
              !props.isAccountActive ||
              !props.card ||
              props.card.isHotlisted ||
              notPhysicalAndVirtual
                ? "0.6"
                : "1"
            }
            bgColor={theme.widgetBackgroundColor}
          >
            <View p='10px'>
              <CardControl />
            </View>
            <Text
              {...styles.fontText}
              fontFamily={props.theme.fontFamily}
              color={theme.appTextColor}
            >
              Card Controls & Limits
            </Text>
          </View>
        </Link>
        {props.user.programType !== props.ProgramTypes.PREPAID ? (
          props.isAccountActive &&
          props.user.enableManageEMI && (
            <Link
              style={{
                textDecoration: "None",
              }}
              to={
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                ) ? (
                  <></>
                ) : (
                  "/ManageCard/ListEMI"
                )
              }
            >
              <View
                {...styles.cardControls}
                {...styles.commonStyles}
                bgColor={theme.widgetBackgroundColor}
                opacity={
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                  )
                    ? "0.6"
                    : "1"
                }
              >
                <View p='10px'>
                  <ManageEmi />
                </View>
                <Text
                  {...styles.fontText}
                  fontFamily={props.theme.fontFamily}
                  color={theme.appTextColor}
                >
                  My Active EMIs
                </Text>
              </View>
            </Link>
          )
        ) : (
          <View
            onClick={() => {
              window.location.href = props.session.repaymentUrl
            }}
          >
            <View
              {...styles.cardControls}
              {...styles.commonStyles}
              bgColor={theme.widgetBackgroundColor}
            >
              <View p='10px'>
                <ManageEmi />
              </View>
              <Text
                {...styles.fontText}
                fontFamily={props.theme.fontFamily}
                color={theme.appTextColor}
              >
                Manage Balance
              </Text>
            </View>
          </View>
        )}
        <View
          {...styles.resetPin}
          {...styles.commonStyles}
          bgColor={theme.widgetBackgroundColor}
          opacity={
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.CC_PIN_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session?.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.RESET_PIN_DISABLE,
            ) ||
            !props.isAccountActive ||
            !props.card ||
            props.card.isHotlisted ||
            props.card.isLocked ||
            (props.user.programType === ProgramTypes.PREPAID
              ? props.card?.cardType === "Virtual"
                ? (props.card?.isVirtualCardActivated)
                : !(props.card?.isPhysicalCardActivated)
              : !props.card?.isPhysicalCardActivated)
              ? "0.6"
              : "1"
          }
          onClick={() =>
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.CC_PIN_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session?.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.RESET_PIN_DISABLE,
            ) ? (
              <></>
            ) : !props.isAccountActive ||
              !props.card ||
              props.card.isHotlisted ||
              props.card.isLocked ||
              (props.user.programType === ProgramTypes.PREPAID
                ? props.card?.cardType === "Virtual"
                  ? (props.card?.isVirtualCardActivated)
                  : !(props.card?.isPhysicalCardActivated)
                : !props.card?.isPhysicalCardActivated) ? (
              props.alertToast()
            ) : (
              props.handleResetPinClick()
            )
          }
        >
          <View
            px={props.isResetPinLoading ? "0px" : "11.9px"}
            py={props.isResetPinLoading ? "0px" : "10.475px"}
          >
            {props.isResetPinLoading ? (
              <View
                {...styles.loader}
                style={{
                  backgroundColor: `${
                    config.version === PwaVersions.V2
                      ? theme.backgroundColor
                      : "white"
                  }`,
                }}
              >
                <CustomLoader
                  color={props.theme.color1}
                  width={40}
                  height={40}
                />
              </View>
            ) : (
              <ResetPin />
            )}
          </View>
          <Text
            {...styles.fontText}
            fontFamily={props.theme.fontFamily}
            color={theme.appTextColor}
          >
            Reset Pin
          </Text>
        </View>
        <Link
          style={{
            textDecoration: "None",
            opacity:
              replaceOrBlock() === BlockOptions.GRAY_OUT ||
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.CARD_DASHBOARD_DISABLE,
              ) ||
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.REPLACE_CARD_DISABLE,
              )
                ? "0.6"
                : !props.isAccountActive || !props.card
                ? "0.6"
                : "1",
          }}
          to={
            replaceOrBlock() === BlockOptions.GRAY_OUT ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.REPLACE_CARD_DISABLE,
            ) ||
            !props.isAccountActive ||
            !props.card
              ? ""
              : (!props.card.isHotlisted ||
                  replaceOrBlock() === BlockOptions.ONLY_BLOCK) &&
                replaceOrBlock() !== BlockOptions.ONLY_REPLACE
              ? "/ManageCard/BlockCard"
              : "/ManageCard/NewCardRequest"
          }
        >
          <View
            {...styles.cardControls}
            {...styles.commonStyles}
            bgColor={theme.widgetBackgroundColor}
          >
            <View p='10px'>
              <BlockReplace />
            </View>
            <Text
              {...styles.fontText}
              fontFamily={props.theme.fontFamily}
              color={theme.appTextColor}
            >
              {getBlockMsg()}
            </Text>
          </View>
        </Link>

        {props.user.programType !== props.ProgramTypes.PREPAID &&
          theme?.transactionViewType !== "UNBILLED" && (
            <Link
              style={{
                textDecoration: "None",
              }}
              to={
                session.disabledFunctionsByBlockCode.includes(
                  Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
                ) ||
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                ) ||
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.VIEW_STATEMENT_DISABLE,
                ) ||
                !props.isAccountActive ||
                !props.card ||
                props.card.isHotlisted
                  ? "#"
                  : "/Statement"
              }
              bgColor={theme.widgetBackgroundColor}
            >
              <View
                {...styles.cardControls}
                {...styles.commonStyles}
                opacity={
                  session.disabledFunctionsByBlockCode.includes(
                    Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.VIEW_STATEMENT_DISABLE,
                  ) ||
                  !props.isAccountActive ||
                  !props.card ||
                  props.card.isHotlisted
                    ? "0.6"
                    : "1"
                }
                bgColor={theme.widgetBackgroundColor}
              >
                <View p='10px'>
                  <ViewStatement />
                </View>
                <Text
                  cursor='pointer'
                  {...styles.fontText}
                  fontFamily={props.theme.fontFamily}
                  color={theme.appTextColor}
                >
                  View Statement
                </Text>
              </View>
            </Link>
          )}
        {screen?.mpin?.resetMpin?.isEnabled && (
          <View
            {...styles.cardControls}
            {...styles.commonStyles}
            onClick={
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.RESET_MPIN_DISABLE,
              )
                ? () => {}
                : onChangeMpinClick
            }
            bgColor={theme.widgetBackgroundColor}
            opacity={
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.RESET_MPIN_DISABLE,
              )
                ? "0.6"
                : "1"
            }
          >
            <View p='10px'>
              <MpinReset />
            </View>
            <Text
              {...styles.fontText}
              fontFamily={props.theme.fontFamily}
              color={theme.appTextColor}
            >
              Change MPIN
            </Text>
          </View>
        )}
        {screen?.aboutProgram?.isEnabled && (
          <Link
            style={{
              textDecoration: "None",
            }}
            to={"/ManageCard/AboutProgram"}
          >
            <View
              {...styles.cardControls}
              {...styles.commonStyles}
              bgColor={theme.widgetBackgroundColor}
            >
              <View p='10px'>
                <AboutProgram />
              </View>
              <Text
                {...styles.fontText}
                fontFamily={props.theme.fontFamily}
                color={theme.appTextColor}
              >
                About Card Program
              </Text>
            </View>
          </Link>
        )}
        {screen?.customerCare?.isEnabled && (
          <Link
            style={{
              textDecoration: "None",
            }}
            to={"/ManageCard/CustomerCare"}
          >
            <View
              {...styles.cardControls}
              {...styles.commonStyles}
              bgColor={theme.widgetBackgroundColor}
            >
              <View p='10px'>
                <Image
                  source={{ uri: CustomerSupportV2 }}
                  alt='Card Control Icon'
                  width='32px'
                  height='32px'
                />
              </View>
              <Text
                {...styles.fontText}
                fontFamily={props.theme.fontFamily}
                color={theme.appTextColor}
              >
                Customer Care
              </Text>
            </View>
          </Link>
        )}
      </View>
    </View>
  )
}

export default Home_v2
