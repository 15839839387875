export const Envs = Object.freeze({
  DEV: "dev",
  DEV_MOBILE: "dev-mobile",
  UAT: "uat",
  SANDBOX: "sandbox",
  PROD: "prod",
  AU_SANDBOX: "au-sandbox",
})

export const SetMpinTncActions = Object.freeze({
  NONE: "NONE",
  ENABLE_DOMESTIC_ONLINE: "ENABLE_DOMESTIC_ONLINE",
  ACTIVATE_CARD: "ACTIVATE_CARD",
})

export const PwaVersions = Object.freeze({
  V1: "v1",
  V2: "v2",
})

export const AU_BlockCodes = Object.freeze({
  ENTIRE_PWA_ACCOUNT_DISABLE: "AU_ENTIRE_PWA_ACCOUNT_DISABLE",
  ENTIRE_PWA_ACCOUNT_CARD_DISABLE: "AU_ENTIRE_PWA_ACCOUNT_CARD_DISABLE",
  REGISTRATION_DISABLE: "AU_REGISTRATION_DISABLE",
  LOGIN_DISABLE: "AU_LOGIN_DISABLE",
  CARD_DASHBOARD_DISABLE: "AU_CARD_DASHBOARD_DISABLE",
  TEMP_CARD_DISABLE: "AU_TEMP_CARD_DISABLE",
  PERMANENT_CARD_DISABLE: "AU_PERMANENT_CARD_DISABLE",
  UNBLOCK_CARD_DISABLE: "AU_UNBLOCK_CARD_DISABLE",
  REPLACE_CARD_DISABLE: "AU_REPLACE_CARD_DISABLE",
  RESET_PIN_DISABLE: "AU_RESET_PIN_DISABLE",
  VIEW_STATEMENT_DISABLE: "AU_VIEW_STATEMENT_DISABLE",
  PAY_BILL_DISABLE: "AU_PAY_BILL_DISABLE",
  MANAGE_LIMIT_DISABLE: "AU_MANAGE_LIMIT_DISABLE",
  REWARDS_SSO_DISABLE: "AU_REWARDS_SSO_DISABLE",
  EMI_CONVERSION_DISABLE: "AU_EMI_CONVERSION_DISABLE",
  VIRTUAL_CARD_DISABLE: "AU_VIRTUAL_CARD_DISABLE",
  CARD_LOCKED: "AU_CARD_LOCKED",
})
export const LogLevels = Object.freeze({
  INFO: "INFO",
  ERROR: "ERROR",
})

export const Issuers = Object.freeze({
  AU_BANK: "AU Bank",
  TRANSCORPP: "Transcorpp",
  TRANSCORP: "Transcorp ",
  AMERICAN_EXPRESS: "American Express",
  PUNJAB_BANK: "Punjab Bank",
  FEDERAL_BANK: "Federal Bank",
  BANK_OF_MAHARASHTRA: "Bank of Maharashtra",
  ROCKETERY_BANK: "Rocketery Bank",
  HYPERFACE_BANK: "Hyperface Bank",
})

export const KycStatus = Object.freeze({
  SMALL_KYC: "smallKYC",
  FULL_KYC: "COMPLETED",
})

export const CardPrimaryPeriodType = Object.freeze({
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  TRANSACTIONAL: "TRANSACTIONAL",
})

export const Federal_BlockCodes = Object.freeze({
  ENTIRE_PWA_ACCOUNT_DISABLE: "FED_ENTIRE_PWA_ACCOUNT_DISABLE",
  ENTIRE_PWA_ACCOUNT_CARD_DISABLE: "FED_ENTIRE_PWA_ACCOUNT_CARD_DISABLE",
  TEMP_CARD_DISABLE: "FED_TEMP_CARD_DISABLE",
  PERMANENT_CARD_DISABLE: "FED_PERMANENT_CARD_DISABLE",
  UNBLOCK_CARD_DISABLE: "FED_UNBLOCK_CARD_DISABLE",
  REPLACE_CARD_DISABLE: "FED_REPLACE_CARD_DISABLE",
  RESET_MPIN_DISABLE: "FED_RESET_MPIN_DISABLE",
  VIEW_STATEMENT_DISABLE: "FED_VIEW_STATEMENT_DISABLE",
  PAY_BILL_DISABLE: "FED_PAY_BILL_DISABLE",
  REWARDS_SSO_DISABLE: "FED_REWARDS_SSO_DISABLE",
  EMI_CONVERSION_DISABLE: "FED_EMI_CONVERSION_DISABLE",
  VIRTUAL_CARD_DISABLE: "FED_VIRTUAL_CARD_DISABLE",
  CARD_BLOCKED: "FED_CARD_BLOCKED",
  CC_PIN_DISABLE: "FED_CC_PIN_DISABLE",
  CARD_CONTROL_DISABLE: "FED_CARD_CONTROL_DISABLE",
})

export const BlockReplaceTncActions = Object.freeze({
  NONE: "NONE",
  ENABLE_DOMESTIC_ONLINE: "ENABLE_DOMESTIC_ONLINE",
  ACTIVATE_CARD: "ACTIVATE_CARD",
})
