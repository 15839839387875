import { Image, Text, View } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import logo from "../../../assets/images/default-offers-logo.png"
import AccountService from "../../../services/AccountService"
import { setCurrentOffer } from "../../../store/actions/Session"
import { DisplayConfig } from "../../../utils/constants"
import { consoleError, truncateString } from "../../../utils/functions"
import { Header } from "../../core"
import RewardIcon from "../../svg/rewards"
import RewardLoader from "../../core/SkeletonLoader/rewardLoader"

const OfferList_v2 = () => {
  const windowDimensions = useWindowDimensions()
  const [offers, setOffers] = useState()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [theme, user] = useSelector(
    state => [
      state.theme,
      state.user,
    ],
  )
  useEffect(() => {
    (async () => {
      try {
        const response = await AccountService.getOffersList(
          user.account.id,
          user.account.programId,
        )
        if (response.status === 200) {
          let temp = response.data.data
          temp = temp.sort((a, b) => {
            return a.displayOrder - b.displayOrder
          })
          setOffers(temp)
        } else {
          consoleError(response.data.message)
          navigate("/")
        }
      } catch (err) {
        consoleError(err)
        navigate("/")
      }
      setLoading(false)
    })()
  }, [])
  return loading ? (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View
          _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          w='100%'
          minH={windowDimensions.height}
          bgColor={theme.backgroundColor}
        >
          <RewardLoader/>
        </View>
      </View>
    </View>
  ) : (
    <>
      <View alignItems='center' justifyContent='center'>
        <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
          <View
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            w='100%'
            minH={windowDimensions.height}
            bgColor={theme.backgroundColor}
          >
            <Header
              text='My Card - Offers and Promotions'
              onBack={async () => {
                window.history.go(-1)
              }}
            />

            <View alignItems={"center"} justifyContent='center'>
              {offers.map((item, index) => (
                <View
                  w='90%'
                  mt='12px'
                  bgColor={
                    JSON.parse(item.offerDetails)?.usePrimaryColor
                      ? item.displayColor
                      : theme.widgetBackgroundColor
                  }
                  shadow={2}
                  borderRadius={"12px"}
                  flexDir='row'
                  cursor={"pointer"}
                  onClick={() => {
                    navigate("/Offers/Details", { state: { id: index } })
                    dispatch(setCurrentOffer({ currentOffer: item }))
                  }}
                >
                  <View
                    w='30%'
                    borderColor={`${theme.appTextColor}:alpha.20`}
                    borderRightWidth={1}
                    p='18px'
                    alignItems={"center"}
                    justifyContent='center'
                  >
                    {item.benefitType === "MILESTONE" ? (
                      <RewardIcon
                        percent={1 - item.currentValue / item.threshold}
                        color={item?.displayColor || "#B9E9FC"}
                        size='70px'
                      />
                    ) : (
                      <Image
                        src={item.illustrationUrl || logo}
                        height='70px'
                        width={"70px"}
                        alt=''
                      />
                    )}
                  </View>
                  <View w='70%'>
                    {JSON.parse(item.offerDetails).tagLogo && (
                      <View w='100%' alignItems={"flex-end"}>
                        <View
                          w='35%'
                          p='4px'
                          bgColor={JSON.parse(item.offerDetails).tagColor}
                          mr='12px'
                          borderBottomRadius={"4px"}
                          flexDir='row'
                        >
                          <View>
                            <img
                              src={JSON.parse(item.offerDetails).tagLogo}
                              height='14px'
                              width={"14px"}
                              alt=''
                            />
                          </View>
                          <View ml='4px'>
                            <Text
                              fontWeight='600'
                              fontSize='10px'
                              fontFamily={theme.fontFamily}
                              color='#FFFFFF'
                            >
                              {JSON.parse(item.offerDetails).tagTitle}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    <View
                      mb='24px'
                      px='18px'
                      height={"100%"}
                      // alignItems={"center"}
                      justifyContent={
                        JSON.parse(item.offerDetails)?.tagTitle
                          ? "start"
                          : "center"
                      }
                      mt={
                        JSON.parse(item.offerDetails)?.tagTitle ? "12px" : "0"
                      }
                    >
                      <Text
                        fontWeight='400'
                        fontSize='16px'
                        color={theme.appTextColor}
                      >
                        {truncateString(item.description, 90)}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default OfferList_v2
