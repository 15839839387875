import {
  Image,
  ScrollView,
  Text,
  View,
  useToast
} from "native-base"
import React, { useEffect } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import cardImgHorizontal from "../../../../../assets/images/Card_Illustration_horizontal.png"
import cardImgVertical from "../../../../../assets/images/Card_Illustration_vertical.png"
import { CardOrientation, DisplayConfig } from "../../../../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../../utils/enums"
import { Header } from "../../../../core"
import { ColorButton, WhiteButton } from "../../../../core/buttons"
import { showToast1 } from "../../../../core/toast"
import styles from "../styles"

import { BlockOptions } from "../constants"

const BlockCard_v2 = () => {
  const navigate = useNavigate()
  const nativeToast = useToast()
  const windowDim = useWindowDimensions()

  const [theme, user, session, config] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
    state.config,
  ])
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      replaceOrBlock() === BlockOptions.ONLY_REPLACE ||
      replaceOrBlock() === BlockOptions.GRAY_OUT
    ) {
      toast("Access has been blocked to block card page")
      navigate("/")
      return false
    }

    if (
      replaceOrBlock() === BlockOptions.ONLY_BLOCK &&
      user.cards[0].isHotlisted
    ) {
      toast("Card already Blocked")
      navigate("/")
      return
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
    ) {
      toast("Access has been blocked")
      navigate("/")
      return
    }

    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards])

  const proceedBlockCard = () => {
    navigate("/ManageCard/BlockCard/Reason")
  }
  const cancel = () => {
    navigate("/ManageCard")
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <ScrollView>
          <View
            {...styles.mainContainer}
            height={windowDim.height}
            minH={windowDim.height}
            bgColor={theme.backgroundColor}
          >
            <View>
              {/* body content start */}

              <Header
                text='Block Card'
                onBack={async () => {
                  window.history.go(-1)
                }}
              />

              {/* body title end */}

              {/* content box start */}
              <View
                {...styles.heroSection}
                backgroundColor={theme.widgetBackgroundColor}
                borderRadius='12px'
                borderWidth='2px'
                shadow='2'
                borderColor='#F8F8F8'
              >
                {theme.cardOrientation === CardOrientation.HORIZONTAL ? (
                  <View alignItems='center'>
                    <Image
                      source={{ uri: cardImgHorizontal }}
                      width='196px'
                      height='120px'
                      alt='Alternate Text '
                      borderRadius={"4px"}
                    />
                  </View>
                ) : (
                  <View alignItems='center'>
                    <Image
                      source={{ uri: cardImgVertical }}
                      width='120px'
                      height='196px'
                      alt='Alternate Text '
                      borderRadius={"4px"}
                    />
                  </View>
                )}
                {/* content box Image End */}
                <View  mt="20px"  {...styles.heroTextSection}>
                  <View  {...styles.textPara}>
                    <Text
                      {...styles.text}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                      fontSize="16px"
                    >
                      Blocking your card will stop all future purchase
                      transactions permanently.
                    </Text>
                  </View>
                  <View {...styles.textPara}>
                    <Text
                      {...styles.text}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                      fontSize="16px"
                    >
                      You can request an entirely new card post blocking, which
                      will be immediately issued to you
                    </Text>
                  </View>
                </View>
                {/* content box Text end */}
              </View>
              {/* content box start */}

              {/* body content end */}
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              <ColorButton
                text='Proceed to Block Card'
                isDisabled={false}
                onPress={proceedBlockCard}
              />
              {!config?.version == PwaVersions.V2 ? (
                <WhiteButton text='Cancel' onPress={cancel} />
              ) : null}
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default BlockCard_v2
