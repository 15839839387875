const styles = {
  baseText: {
    textAlign: "center",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#4F4F4F",
  },
  centering: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
}

export default styles
