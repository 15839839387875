
const styles = {
  image: {
    width: "100px",
    height: "100px",
  },
  text1: {
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "24px",
    color: "#4F4F4F",
    textAlign: "center",
  },
  text2: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#7B7B7B",
    textAlign: "center",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  section1: {
    alignItems: "center",
    justifyContent: "center",
    mt: "40%",
  },
  innerView: {
    w: "100%",
    minHeight: "650px",
  },
  mainView: {
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    p: "28px",
    borderRadius: "100px",
  },
  text1Container1: {
    w: "80%",
  },
  text1Container2: {
    mt: "36px",
  },
  text2Container: {
    mt: "12px",
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8F8F8",
    padding: 3,
    w: "90%",
    mb: "20px",
  },
  messageText: {
    fontSize: 12,
    color: "#7B7B7B",
  },
  infoSvgContainer: {
    width: "20px",
    mt: "3px",
  },
  expand: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
  textSubTitle: {
    fontSize: "12px",
    // lineHeight: "20px",
    color: "black",
    textAlign: "center",
    marginTop: "10px",
  },
  subsubHeading: {
    textAlign: "center",
    marginTop: "10px",
    fontSize: "12px",
  },
}

export default styles
