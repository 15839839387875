import moment from "moment"
import {
  Image, ScrollView, Text, View
} from "native-base"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import downFilledArrow from "../../../../assets/images/downFilledArrow.svg"
import { ProgramTypes } from "../../../../utils/constants"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
let currMonth = months[moment().month()]
const currYear = moment().year()

let years = []
let setYear = currYear
for (let ind = 0; ind < 4; ind++) {
  years[ind] = setYear-- + ""
}

const DateSelect = ({ setCurrYear, setCurrMonth, onViewStatementsClick }) => {
  const [theme,user] = useSelector(state => [
    state.theme,
    state.user,
  ])
  let currMonthIndex = moment(user?.account?.currentCycleStartDate).month() - 1
  currMonth = months[moment(user?.account?.currentCycleStartDate).month() - 1]

  const [selectedYear, setSelectedYear] = useState(currYear)
  const [selectedMonth, setSelectedMonth] = useState(currMonth)
  const [yearScrollOpen, setYearScrollOpen] = useState(false)
  const [monthScrollOpen, setMonthScrollOpen] = useState(false)

  return (
    <View
      backgroundColor={theme.widgetBackgroundColor}
      margin='16px'
      borderRadius={"8px"}
    >
      <View m='5px'>
        <View display={"flex"} flexDirection={"row"}>
          <View flex='1'>
            <View
              flexDirection={"row"}
              m='10px'
              borderRadius={"8px"}
              borderColor={`gray:alpha.30`}
              borderWidth={"2px"}
              borderStyle='groove'
              cursor={"pointer"}
              onClick={() => {
                setYearScrollOpen(!yearScrollOpen)
              }}
            >
              <Text color={theme.appTextColor} p='3'>{selectedYear}</Text>
              <View
                flex='1'
                justifyContent={"center"}
                alignItems='end'
                mr='15px'
              >
                <Image
                  src={downFilledArrow}
                  // class="zoom-vertical"
                  height='8px'
                  width='16px'
                  alt='Alternate Text '
                />
              </View>
            </View>
            <View
              shadow={2}
              position={"absolute"}
              width='88%'
              height={"150px"}
              backgroundColor={theme.widgetBackgroundColor}
              left={"12px"}
              top='58px'
              borderRadius={"8px"}
              display={yearScrollOpen ? "flex" : "none"}
            >
              <ScrollView showsVerticalScrollIndicator={false}>
                {years.map((year, key) => {
                  return (
                    <>
                      <View
                        mt='5px'
                        width={"100%"}
                        textAlign='center'
                        p='5px'
                        cursor={"pointer"}
                        backgroundColor={
                          year == selectedYear ? `${theme.color1}` : {}
                        }
                        onClick={() => {
                          setSelectedYear(year)
                          setCurrYear(year)
                          setYearScrollOpen(false)
                        }}
                      >
                        <Text
                          color={
                            selectedYear == year
                              ? theme.color2
                              : theme.appTextColor
                          }
                          key={key}
                        >
                          {year}
                        </Text>
                      </View>
                    </>
                  )
                })}
              </ScrollView>
            </View>
          </View>

          <View flex='1'>
            <View
              flexDirection={"row"}
              m='10px'
              borderRadius={"8px"}
              borderColor={`gray:alpha.30`}
              borderWidth={"2px"}
              borderStyle='groove'
              cursor={"pointer"}
              onClick={() => {
                setMonthScrollOpen(!monthScrollOpen)
              }}
            >
              <Text  color={theme.appTextColor}  p='3'>{selectedMonth}</Text>
              <View
                flex='1'
                justifyContent={"center"}
                alignItems='end'
                mr='15px'
              >
                <Image
                  src={downFilledArrow}
                  // class="zoom-vertical"
                  height='8px'
                  width='16px'
                  alt='Alternate Text '
                />
              </View>
            </View>
            <View
              shadow={2}
              position={"absolute"}
              width='88%'
              height={
                selectedYear == currYear
                  ? 35 + (currMonthIndex + 1) * 25 + "px"
                  : "340px"
              }
              backgroundColor={theme.widgetBackgroundColor}
              left={"12px"}
              top='58px'
              borderRadius={"8px"}
              display={monthScrollOpen ? "flex" : "none"}
            >
              <ScrollView showsVerticalScrollIndicator={false}>
                {months.map((month, key) => {
                  if (selectedYear == currYear) {
                    if (key <= currMonthIndex) {
                      return (
                        <>
                          <View
                            mt='5px'
                            width={"100%"}
                            textAlign='center'
                            p='5px'
                            cursor={"pointer"}
                            backgroundColor={
                              month === selectedMonth ? `${theme.color1}` : {}
                            }
                            onClick={() => {
                              setSelectedMonth(month)
                              setCurrMonth(month)
                              setMonthScrollOpen(false)
                            }}
                          >
                            <Text
                              color={
                                month == selectedMonth
                                  ? theme.color2
                                  : theme.appTextColor
                              }
                              key={key}
                            >
                              {month}
                            </Text>
                          </View>
                        </>
                      )
                    }
                  } else {
                    return (
                      <>
                        <View
                          mt='5px'
                          width={"100%"}
                          textAlign='center'
                          p='5px'
                          cursor={"pointer"}
                          backgroundColor={
                            month === selectedMonth ? `${theme.color1}` : {}
                          }
                          onClick={() => {
                            setSelectedMonth(month)
                            setCurrMonth(month)
                            setMonthScrollOpen(false)
                          }}
                        >
                          <Text
                            color={
                              month == selectedMonth
                                ? theme.color2
                                : theme.appTextColor
                            }
                            key={key}
                          >
                            {month}
                          </Text>
                        </View>
                      </>
                    )
                  }
                })}
              </ScrollView>
            </View>
          </View>
        </View>

        <View
          zIndex={-1}
          flex='row'
          cursor='pointer'
          alignItems='center'
          my='12px'
          backgroundColor={theme.color1}
          borderRadius='8px'
          mx='10px'
          onClick={() => onViewStatementsClick()}
        >
          <Text
            p='10px'
            color={theme.color2}
            fontSize='16px'
            fontFamily={theme.fontFamily}
            fontWeight='600'
          >
            {user.programType === ProgramTypes.PREPAID
              ? "View All Transactions"
              : "View Statement Summary"}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default DateSelect
