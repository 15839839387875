import { Text, View } from "native-base"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ProgramTypes } from "../../../../utils/constants"
import { AU_BlockCodes, Federal_BlockCodes } from "../../../../utils/enums"
import ActivatePhysicalCardStrip from "../../../core/ActivatePhysicalCardStrip/ActivatePhysicalCardStrip"
import CardInHand from "../../../svg/cardInHand"
import styles from "./styles"

const ActivitySection_v2 = () => {
  const [theme, user, session] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
  ])

  const account = user.account
  const card = user.cards?.[0]

  const isAccountActive = account.status === "ACTIVE"
  return (
    <>
      {isAccountActive &&
        card &&
        !card.isHotlisted &&
        (user.programType === ProgramTypes.PREPAID
          ? card?.cardType === "Virtual"
            ? !(card?.isVirtualCardActivated)
            : !(card?.isPhysicalCardActivated)
          : !card?.isPhysicalCardActivated) && (
          <View
            w='100%'
            alignItems={"center"}
            justify
            Content='center'
            mb="20px"
          >
            <ActivatePhysicalCardStrip width='90%' />
          </View>
        )}
      {/* Activate Physical Card Section Ends above*/}
      {isAccountActive && card && card.isHotlisted && (
        <Link
          to={
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.REPLACE_CARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.REPLACE_CARD_DISABLE,
            )
              ? ""
              : "/ManageCard/NewCardRequest"
          }
          style={{
            textDecoration: "None",
            width: "90%",
          }}
        >
          <View
            {...styles.activateCardContainer}
            bgColor={theme.widgetBackgroundColor}
            borderWidth={"0px"}
            shadow={2}
            borderRadius={"12px"}
            mb="20px"
            opacity={
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.REPLACE_CARD_DISABLE,
              ) ||
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.REPLACE_CARD_DISABLE,
              )
                ? "0.4"
                : "1"
            }
          >
            {/* Activate Physical Pay Later Card Section */}

            <View>
              <CardInHand />
            </View>
            <View w='80%'>
              <View>
                <Text
                  {...styles.activateCardHeading}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                  fontWeight="600"
                  fontSize="14px"
                >
                  Request New Card
                </Text>
              </View>
              <View>
                <Text
                  {...styles.activateCardSubHeading}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                  fontSize="12px"
                >
                  Your Card has been blocked, get a new card
                </Text>
              </View>
            </View>
          </View>
        </Link>
      )}
    </>
  )
}

export default ActivitySection_v2
