import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { setCards } from "../../../../../store/actions/User"
import styles from "../styles"
import { View, Text, ScrollView, useToast } from "native-base"
import { useNavigate } from "react-router-dom"
import { ColorButton, WhiteButton } from "../../../../core/buttons"
import { consoleError } from "../../../../../utils/functions"
import CardService from "../../../../../services/CardService"
import ErrorScreen from "../../../../messages/errorScreen"
import { showToast1 } from "../../../../core/toast"
import ApiIds from "../../../../../auth/ApiIds"
import { handleApiAuth } from "../../../../../utils/auth"
import { DisplayConfig } from "../../../../../utils/constants"

const NewCardRequest_v1 = () => {
  const window = useWindowDimensions()
  const nativeToast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [theme, user] = useSelector(state => [state.theme, state.user])

  const [address, setAddress] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (
        user.account.status !== "ACTIVE" ||
        !user.cards[0] ||
        !user.cards[0].isHotlisted
      ) {
        navigate("/")
        return
      }

      const currentAddress = user.customer?.currentAddress

      if (currentAddress) {
        const line1 = currentAddress.line1 ? currentAddress.line1 : ""
        const line2 = currentAddress.line2 ? ", " + currentAddress.line2 : ""
        const line3 = currentAddress.line3 ? ", " + currentAddress.line3 : ""
        const city = currentAddress.city ? ", " + currentAddress.city : ""
        const pincode = currentAddress.pincode
          ? " - " + currentAddress.pincode
          : ""
        const state = currentAddress.state ? ", " + currentAddress.state : ""

        setAddress(`${line1}${line2}${line3}${city}${pincode}${state}`)
      } else {
        setError(true)
      }
    })()
  }, [navigate, user.account.status, user.cards, user.customer.currentAddress])

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const handleSubmitClick = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.REPLACE_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to request a new card",
      mpinReason: "to request a new card",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.replace(apiToken, user.cards[0]?.id, {
        cardExpiry: user.cards[0]?.cardExpiry?.replace(/\//g, ""), // remove forward slash from expiry
      })
      const result = response?.data
      if (result?.success) {
        const tempCard = { ...result.data }
        dispatch(setCards({ cards: [tempCard] }))
        toast("The blocked card has been replaced")
        navigate("/", { replace: true })
      } else {
        consoleError(result.errors)
        toast("New card request failed")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return error ? (
    <View
      width='100%'
      height={window.height}
      alignItems='center'
      justifyContent='center'
    >
      <ErrorScreen />
    </View>
  ) : (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <ScrollView>
          <View
            {...styles.mainContainer}
            height={window.height}
            minHeight='480px'
          >
            <View my='24px' mx='16px'>
              {/* body content start */}
              <View justifyContent='center'>
                <Text {...styles.reasonsHeader} fontFamily={theme.fontFamily}>
                  Request a New Card
                </Text>
                <Text
                  {...styles.reasonsSubHeader}
                  fontFamily={theme.fontFamily}
                >
                  Your card will be delivered to your current billing address as
                  below
                </Text>
              </View>
              {/* body title end */}

              {/* content box start */}
              <View {...styles.addressContainer} bgColor={theme.color3}>
                <Text {...styles.addressText} fontFamily={theme.fontFamily}>
                  {address}
                </Text>
              </View>
              {/* body content end */}
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              <ColorButton
                text='Confirm'
                isDisabled={false}
                onPress={handleSubmitClick}
                isLoading={submitLoading}
              />
              <WhiteButton
                text='Return to Home Screen'
                onPress={() => navigate("/", { replace: true })}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default NewCardRequest_v1
