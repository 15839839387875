import Service from "./Service"

const PublicService = {
  getInfoFromActivationCode: params =>
    Service.get(`/public/getInfoFromActivationCode`, { params }),

  getResetPinWidget: data => Service.post(`/public/getResetPinWidget`, data),

  getProgramConfig: programId =>
    Service.get(`/public/programConfig`, {
      params: { programId: programId },
    }),
  login: (accountId, secretKey) =>
    Service.get(`/public/getSessionToken`, {
      params: { accountId: accountId, secretKey: secretKey },
    }),
  onboardingAcknowledgement: data =>
    Service.put(`/public/onboardingAcknowledgement`, data),
  activateCard: data => Service.post(`/public/activateCard`, data),
}

export default PublicService
