import React from "react"
import { View, Text } from "native-base"
import { useSelector } from "react-redux"
import ToastTick from "../svg/toastTick"
import ToastExclamation from "../svg/toastExclamation"
import { PwaVersions } from "../../utils/enums"

const Toast = props => {
  const [theme] = useSelector(state => [state.theme])
  return (
    <View
      bgColor={theme.toastBgColor}
      flexDirection='row'
      px='24px'
      py='12px'
      alignItems='center'
      justifyContent='center'
      borderRadius='6px'
    >
      {props.children}
    </View>
  )
}

export const showToast1 = ({
  nativeToast,
  theme,
  config,
  message,
  hasTick = false,
  hasExclamationMark = false,
}) => {
  nativeToast.show({
    duration: 3000,
    render: () => {
      return (
        <Toast>
          {hasTick && (
            <View w='24px' h='24px' display='flex'>
              <ToastTick />
            </View>
          )}
          {hasExclamationMark && (
            <View w='24px' h='24px' display='flex'>
              <ToastExclamation />
            </View>
          )}
          <View ml='8px'>
            <Text
              color={theme.toastColor}
              fontFamily={theme.fontFamily}
              fontWeight='600'
              fontSize='16px'
              lineHeight='19px'
              letterSpacing='0.01em'
            >
              {message}
            </Text>
          </View>
        </Toast>
      )
    },
    placement: config?.version === PwaVersions.V2 ? "top" : "bottom",
  })
}

export default Toast
