import moment from "moment"
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  Modal,
  Text,
  View,
  useToast,
} from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import { consoleError, formatAmount } from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import Loader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"

import { DisplayConfig } from "../../../../utils/constants"
import { PwaVersions } from "../../../../utils/enums"
import ForeCloseEmiLoader from "../../../core/SkeletonLoader/foreCloseEmiLoader"

const ForeClose = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [error /*, setError */] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [theme, session, common, user, config] = useSelector(state => [
    state.theme,
    state.session,
    state.common,
    state.user,
    state.config,
  ])
  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }

  const { isForecloseEmiSet, forecloseDetailsData } = common

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    ;(async () => {
      if (!isForecloseEmiSet) {
        dispatch(clearCommonState())

        await handleApiAuth({
          apiId: ApiIds.FORECLOSURE_DETAILS,
          onAuthSuccess: onForecloseDetailsAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get emi foreclosure details",
          mpinReason: "to get emi foreclosure details",
          toast,
          navigate,
        })
      } else {
        setData(forecloseDetailsData)
      }

      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForecloseEmiSet])

  const onForecloseDetailsAuthSuccess = async apiToken => {
    try {
      const response = await AccountService.getForeclosureDetails(
        apiToken,
        user.account.id,
        {
          emiRefId: session.currentSelectedEmi.emiRefId,
        },
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isForecloseEmiSet: true,
            forecloseDetailsData: result.data,
          }),
        )

        navigate("/ManageCard/ForecloseEmi", { replace: true })
      } else {
        consoleError(
          "An error occurred while fetching emi foreclosure details. Error:" +
            result?.errors,
        )
        toast("Sorry an error occurred")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(
        "An exception occurred while fetching emi foreclosure details. Error:" +
          error,
      )
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onForecloseEmiAuthSuccess = async apiToken => {
    try {
      const response = await AccountService.forecloseEmi(
        apiToken,
        user.account.id,
        {
          emiRefId: session.currentSelectedEmi.emiRefId,
        },
      )
      const result = response?.data

      if (result?.success && result.data.status === "SUCCESS") {
        toast("Successfully foreclosed EMI")
        navigate("/", { replace: true })
      } else {
        consoleError(
          "An error occurred while foreclosing emi. Error:" + result?.errors,
        )
        toast("Sorry an error occurred while foreclosing EMI")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(
        "An exception occurred while foreclosing emi. Error:" + error,
      )
      toast("Sorry an error occurred while foreclosing EMI")
      navigate("/", { replace: true })
    }
  }

  const handleForecloseEmi = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.FORECLOSE_EMI,
      onAuthSuccess: onForecloseEmiAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to foreclose emi",
      mpinReason: "to foreclose emi",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  return loading ? (
    config.version === PwaVersions.V1 ?
    <View
      height={windowDimensions.height}
      alignItems='center'
      justifyContent='center'
      style={{
        backgroundColor: `${
          config.version === PwaVersions.V2 ? theme.backgroundColor : "white"
        }`,
      }}
    >
      <Loader color={theme.color1} width={120} height={120} />
    </View>:(
      <View h={windowDimensions.height} bgColor={theme.backgroundColor}>
        <ForeCloseEmiLoader/>
      </View>
    )
  ) : error ? (
    <ErrorScreen />
  ) : session.currentSelectedEmi ? (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View
          {...styles.mainView}
          minHeight={windowDimensions.height}
          bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
        >
          <View
            {...(getVersionedDisplay() ? null : styles.header)}
            justifyContent={getVersionedDisplay() ? null : "space-between"}
            bgColor={getVersionedDisplay() ? theme.headerBackgroundColor : null}
          >
            {getVersionedDisplay() ? (
              <Header
                marginBottom='0px'
                text='Convert to EMI'
                rightProp='EMI Table'
                modalOpenFuntion={setModalOpen}
                onBack={async () => {
                  window.history.go(-1)
                }}
              />
            ) : (
              <>
                <View flexDirection='row' alignItems='center'>
                  <View onClick={() => navigate(-1)}>
                    <ChevronLeftIcon
                      size='24px'
                      color={getVersionedDisplay() ? theme.titleColor : null}
                    />
                  </View>
                  <Text
                    {...styles.manageCard}
                    color={getVersionedDisplay() ? theme.titleColor : null}
                  >
                    {session.currentSelectedEmi.emiType === "BALANCE"
                      ? `${moment().format("MMM 'YY")} EMIs`
                      : session.currentSelectedEmi.title}
                  </Text>
                </View>
                <View onClick={() => setModalOpen(true)}>
                  <Text
                    color={theme.color1}
                    fontSize='14px'
                    lineHeight='20px'
                    fontWeight='500'
                  >
                    EMI Table
                  </Text>
                </View>
              </>
            )}

            <Modal
              isOpen={modalOpen}
              onClose={() => {
                setModalOpen(false)
              }}
            >
              <Modal.Content maxWidth='380px' w='100%'>
                <Modal.CloseButton />

                <Modal.Body bgColor='#FFFFFF' w='100%' p='0px'>
                  <View w='100%'>
                    <View mt='8px' py='16px' px='16px' w='100%'>
                      <Text {...styles.precloselargeText}>EMI Table</Text>
                    </View>
                    <View
                      p='16px'
                      justifyContent='space-between'
                      flexDirection={"row"}
                      w='100%'
                      bgColor={"#F8F8F8"}
                    >
                      <View w='35%'>
                        <Text
                          {...styles.precloseDescriptionText}
                          fontWeight='600'
                        >
                          Billing Date
                        </Text>
                      </View>
                      <View w='40%'>
                        <Text
                          {...styles.precloseDescriptionText}
                          fontWeight='600'
                        >
                          EMI
                        </Text>
                        <Text {...styles.precloseDescriptionText}>
                          Principal + Interest
                        </Text>
                      </View>
                      <View w='25%'>
                        <Text
                          {...styles.precloseDescriptionText}
                          fontWeight='600'
                        >
                          Remaining Principal
                        </Text>
                      </View>
                    </View>
                    <View>
                      {session.currentSelectedEmi.schedules.map(item => (
                        <View
                          p='16px'
                          justifyContent='space-between'
                          flexDirection={"row"}
                          w='100%'
                          bgColor={"#FFFFFF"}
                        >
                          <View w='35%'>
                            <Text {...styles.precloseDescriptionText}>
                              {moment(item.paymentDueDate).format("DD MMM 'YY")}
                            </Text>
                            {item.interest === 0 && item.principal === 0 ? (
                              <View {...styles.paid}>
                                <Text
                                  color={"#00A271"}
                                  fontSize='9px'
                                  lineHeight='12px'
                                  fontWeight='500'
                                >
                                  Paid
                                </Text>
                              </View>
                            ) : (
                              <Text
                                {...styles.precloseDescriptionText}
                                fontSize='11px'
                                color={"#FFE5A6"}
                              >
                                Unpaid
                              </Text>
                            )}
                          </View>
                          <View w='40%'>
                            <Text {...styles.precloseDescriptionText}>
                              {`${formatAmount(
                                session.currentSelectedEmi.monthlyEmi,
                              )}`}
                            </Text>
                            <Text
                              {...styles.precloseDescriptionText}
                              fontSize='11px'
                            >
                              {`${formatAmount(
                                item.principal,
                              )} + ${formatAmount(item.interest)}`}
                            </Text>
                          </View>
                          <View w='25%'>
                            <Text
                              {...styles.precloseDescriptionText}
                              textAlign='right'
                            >
                              {`${formatAmount(item.remainingBalance)}`}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>
                </Modal.Body>
              </Modal.Content>
            </Modal>
          </View>
          <View {...styles.precloseBody}>
            <View {...styles.precloseBodyContainer}>
              <View>
                <Text
                  {...styles.precloseBodyText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Principal Amount Pending
                </Text>
              </View>
              <View>
                <Text
                  {...styles.precloseBodyText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {formatAmount(data.remainingPrincipalAmount)}
                </Text>
              </View>
            </View>
            <View {...styles.precloseBodyContainer}>
              <View>
                <Text
                  {...styles.precloseBodyText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Interest Component
                </Text>
              </View>
              <View>
                <Text
                  {...styles.precloseBodyText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {formatAmount(data.residualInterest)}
                </Text>
              </View>
            </View>
            <View {...styles.precloseBodyContainer}>
              <View flexDirection={"row"} alignItems='center' mr='8px'>
                <Text
                  {...styles.precloseBodyText}
                  mr='8px'
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Taxes & Charges
                </Text>
                {!open ? (
                  <ChevronDownIcon
                    size='18px'
                    color={theme.color1}
                    onClick={() => setOpen(!open)}
                  />
                ) : (
                  <ChevronUpIcon
                    size='18px'
                    color={theme.color1}
                    onClick={() => setOpen(!open)}
                  />
                )}
              </View>
              <View>
                <Text
                  {...styles.precloseBodyText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {formatAmount(
                    data.foreclosureFeesTax +
                      data.interestTax +
                      data.foreclosureFees,
                  )}
                </Text>
              </View>
            </View>
            {open ? (
              <View
                {...styles.precloseDropdownContainer}
                bgColor={
                  getVersionedDisplay() ? theme.widgetBackgroundColor : null
                }
              >
                <View {...styles.precloseDropContainer} w='100%'>
                  <View>
                    <Text
                      {...styles.precloseDescriptionText}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      Preclosure Fees
                    </Text>
                  </View>
                  <View>
                    <Text
                      {...styles.precloseDescriptionText}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {formatAmount(data.foreclosureFees)}
                    </Text>
                  </View>
                </View>
                <View
                  {...styles.precloseDropContainer}
                  w='100%'
                  borderTopColor={"#ECECEC"}
                >
                  <View>
                    <Text
                      {...styles.precloseDescriptionText}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      Taxes
                    </Text>
                  </View>
                  <View>
                    <Text
                      {...styles.precloseDescriptionText}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {formatAmount(data.foreclosureFeesTax + data.interestTax)}
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
          <View py='20px' px='16px'>
            <View {...styles.precloseBodyContainer}>
              <View>
                <Text
                  {...styles.precloselargeText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Total Payable
                </Text>
              </View>
              <View>
                <Text
                  {...styles.precloselargeText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {formatAmount(
                    Number(data.interestTax) +
                      Number(data.remainingPrincipalAmount) +
                      Number(data.residualInterest),
                  )}
                </Text>
              </View>
            </View>
            <View
              p='16px'
              bgColor={"#FAFFFB"}
              borderRadius='8px'
              flexDirection={"row"}
              alignItems='center'
              justifyContent={"center"}
            >
              <Text {...styles.precloseDescriptionText} w='90%'>
                <Text
                  {...styles.precloseDescriptionText}
                  fontWeight='600'
                  mr='4px'
                >
                  {formatAmount(
                    Number(data.interestTax) +
                      Number(data.remainingPrincipalAmount) +
                      Number(data.residualInterest),
                  )}
                </Text>
                will be added to your upcoming statement as dues.
              </Text>
            </View>
          </View>
          <View alignItems='center' justifyContent='center' w='100%'>
            <View
              {...styles.CTAsection}
              _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            >
              <ColorButton
                text='Preclose EMI Plan'
                isDisabled={false}
                isLoading={submitLoading}
                onPress={() => handleForecloseEmi()}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  ) : (
    <ErrorScreen />
  )
}

export default ForeClose
