import { Text, View } from "native-base"
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setCurrentOffer } from "../../../../../store/actions/Session"
import { truncateString } from "../../../../../utils/functions"
import RewardIcon from "../../../../svg/rewards"

const OffersCard_v2 = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <View
      backgroundColor={props.data.displayColor}
      borderRadius={"12px"}
      shadow={4}
    >
      <View
        alignItems={"center"}
        justifyContent='space-between'
        flexDir={"row"}
        w={"100%"}
        px={"8px"}
        py='12px'
      >
        <View w='20%' borderRadius={"16px"}>
          {props.data.benefitType === "MILESTONE" ? (
            <RewardIcon
              percent={1 - props.data.currentValue / props.data.threshold}
              color={props?.data?.displayColor || "#B9E9FC"}
              size='50px'
            />
          ) : (
            <img
              src={props.data.illustrationUrl}
              height='50px'
              width={"50px"}
              alt=''
            />
          )}
        </View>
        <View
          w='75%'
          onClick={() => {
            navigate("/Offers/Details", { state: { id: props.index } })
            dispatch(setCurrentOffer({ currentOffer: props.data }))
          }}
        >
          <Text
            fontWeight='500'
            fontSize='12px'
            lineHeight='16px'
            color={"#272727"}
          >
            {truncateString(props.data.description, 60)}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default OffersCard_v2
