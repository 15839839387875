import moment from "moment"
import { ChevronLeftIcon, Text, useToast, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import AccountService from "../../../../services/AccountService"
import { DisplayConfig } from "../../../../utils/constants"
import { PwaVersions } from "../../../../utils/enums"
import { Header } from "../../../core"
import Loader from "../../../core/loader"
import Toast from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import EmptyStatement from "../../emptyStatement"
import "../../styles.css"
import styles from "../styles"
import YearAccordion from "../YearAccordian/index"

const initToDate = moment().format("YYYY-MM-DD")
const initFromDate = moment().subtract(12, "months").format("YYYY-MM-DD")

const Statement = () => {
  const [theme, user, config] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
  ])
  const windowDim = useWindowDimensions()
  const toast = useToast()

  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(0) // to count back to back api errors
  const [statementsByYear, setStatementsByYear] = useState({})

  const fromDateRef = useRef(initFromDate)
  const currentDateRef = useRef(initToDate) // a pointer that goes in reverse from toDate
  const currentYearRef = useRef(moment(initToDate).format("YYYY")) // a pointer to the current year

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  useEffect(() => {
    ;(async () => {
      if (
        moment(currentDateRef.current).format("YYYY-MM-DD") >
          fromDateRef.current &&
        apiError < 3
      ) {
        await getStatements()
      } else {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statementsByYear, apiError])

  const getStatements = async () => {
    try {
      let range
      let hasCurrentYearEnded = false
      const currentYearStartDate = moment(currentDateRef.current)
        .startOf("year")
        .format("YYYY-MM-DD")

      // if 3 months less is not going to previous year
      if (
        moment(currentDateRef.current)
          .subtract(3, "month")
          .format("YYYY-MM-DD") > currentYearStartDate
      ) {
        // fetch for 3 months
        range = {
          from: moment(currentDateRef.current)
            .subtract(3, "month")
            .format("YYYY-MM-DD"),
          to: currentDateRef.current,
        }
      } else {
        // fetch uptil year start
        range = {
          from: currentYearStartDate,
          to: currentDateRef.current,
        }
        hasCurrentYearEnded = true
      }

      // get Statements for the 3 months provided above
      const response = await AccountService.getStatements(
        user.account.id,
        range,
      )
      const result = response.data

      // if api call successful
      if (result?.success) {
        // setting the statements for the current years accordian

        setStatementsByYear(statements => {
          // append statements to object with the year as key

          let newStatements = result.data.statements

          //iterating over all the statements of current range
          newStatements.map(item => {
            //getting from date year
            let dateToAppend = item.fromDate.slice(0, 4)

            //checking if already some items are present in the statements,
            //corresponding to the from year, accordingly pushing items.
            if (statements[dateToAppend]) {
              statements[dateToAppend].push(item)
            } else {
              statements[dateToAppend] = []
              statements[dateToAppend].push(item)
            }
          })

          return { ...statements }
        })

        if (hasCurrentYearEnded) {
          // if current year ended

          // set currentDate to last day of previous year
          currentDateRef.current = moment(currentDateRef.current)
            .subtract(1, "year")
            .endOf("year")
            .format("YYYY-MM-DD")

          // reduce currentYear by one
          currentYearRef.current = (
            parseInt(currentYearRef.current) - 1
          ).toString()
        } else {
          // reduce currentDate by 3 months
          currentDateRef.current = moment(currentDateRef.current)
            .subtract(3, "month")
            .format("YYYY-MM-DD")
        }

        // reset api error count
        setApiError(0)
      } else {
        if (apiError === 2) {
          // if api call failed for 3rd consecutive time
          toast.show({
            render: () => {
              return (
                <Toast>
                  <View ml='8px'>
                    <Text
                      color={theme.toastColor}
                      fontFamily={theme.fontFamily}
                      {...styles.toast}
                    >
                      Error fetching Statements
                    </Text>
                  </View>
                </Toast>
              )
            },
          })
        }

        // incrememnt api error count
        setApiError(apiError + 1)
      }
    } catch (error) {
      if (apiError === 2) {
        toast.show({
          render: () => {
            return (
              <Toast>
                <View ml='8px'>
                  <Text
                    color={theme.toastColor}
                    fontFamily={theme.fontFamily}
                    {...styles.toast}
                  >
                    Error fetching Statements
                  </Text>
                </View>
              </Toast>
            )
          },
        })
      }
      setApiError(apiError + 1)
    }
  }

  return loading ? (
    <View
      height={windowDim.height}
      alignItems='center'
      justifyContent='center'
      style={{
        backgroundColor: `${
          config.version === PwaVersions.V2 ? theme.backgroundColor : "white"
        }`,
      }}
    >
      <Loader color={theme.color1} width={120} height={120} />
    </View>
  ) : apiError ? (
    <>
      <ErrorScreen />
    </>
  ) : (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View
          marginTop={config?.version === PwaVersions.V2 ? "0" : "10"}
          mx={config?.version === PwaVersions.V2 ? "0" : "5"}
          minH={windowDim.height}
          bgColor={
            config?.version === PwaVersions.V2 ? theme.backgroundColor : null
          }
        >
          {config?.version === "v2" ? (
            <Header
              text='Statements'
              onBack={async () => {
                window.history.go(-1)
              }}
            />
          ) : (
            <View
              {...styles.header}
              fontFamily={theme.fontFamily}
              py={config?.version === PwaVersions.V2 ? "12px" : "0"}
              px={config?.version === PwaVersions.V2 ? "5" : "0"}
              borderRadius={config?.version === PwaVersions.V2 ? "0" : "5"}
              bgColor={
                config?.version === PwaVersions.V2
                  ? theme.headerBackgroundColor
                  : null
              }
            >
              <Link to={-1}>
                <ChevronLeftIcon
                  size='6'
                  color={
                    config?.version === PwaVersions.V2
                      ? theme.titleColor
                      : "#000000"
                  }
                />
              </Link>
              <Text
                {...styles.statementText}
                fontSize='16'
                fontFamily={theme.fontFamily}
                fontWeight={600}
                color={
                  config?.version === PwaVersions.V2
                    ? theme.titleColor
                    : "#000000"
                }
              >
                Statements
              </Text>
            </View>
          )}
          <View marginTop='5' width='100%'>
            {!isEmpty(statementsByYear) ? (
              Object.keys(statementsByYear)
                .sort()
                .reverse()
                .filter(
                  key =>
                    statementsByYear[key] && statementsByYear[key].length > 0,
                )
                .map((key, index) => (
                  <View key={key}>
                    <YearAccordion
                      yearIndex={index}
                      year={key}
                      statements={statementsByYear[key]}
                    />
                  </View>
                ))
            ) : (
              <EmptyStatement />
            )}
          </View>
        </View>
      </View>
    </View>
  )
}
export default Statement
