import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import OfferList_v2 from "./v2/OfferList_v2"

const OfferList = () => {
  const [config] = useSelector(state => [state.config])
  return config.version === PwaVersions.V2 ? <OfferList_v2 /> : <></>
}

export default OfferList
