import moment from "moment"
import { FlatList, Text, View, useToast } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import AccountService from "../../../services/AccountService"
import { DisplayConfig, ProgramTypes } from "../../../utils/constants"
import { consoleError } from "../../../utils/functions"
import { Header } from "../../core"
import BilledTransactionLoader from "../../core/SkeletonLoader/BilledTransaction"
import Toast from "../../core/toast"
import Empty from "./empty"
import styles from "./styles"
import Transactions from "./transactions"


const initStartDate = moment().endOf("month").format("YYYY-MM-DD")
const initCurrentDate = moment().startOf("month").format("YYYY-MM-DD")
const initTransactions = []

const initOffset = 0
const transactionCount = 10

const ShowTransactions_v2 = props => {
  const [theme, user] = useSelector(state => [state.theme, state.user])

  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const isTransactionsLoadingRef = useRef(false)
  const [apiError, setApiError] = useState(0)

  const preferences = useSelector(state => state.preferences)
  const toast = useToast()
  const windowDim = useWindowDimensions()
  // transactions array contains -
  // 1. Title
  // 2. transactions
  // 3. Dates
  const [transactions, setTransactions] = useState(initTransactions)
  const offset = useRef(initOffset)
  const [hasMore, setHasMore] = useState(0)
  // date till which txns should be loaded
  const endDate = useRef(moment(props.to).format("YYYY-MM-DD"))
  // pointer to start of the statement date being fetched
  const startDate = useRef(moment(props.from).format("YYYY-MM-DD"))
  useEffect(() => {
    if (apiError >= 3) {
      toast.show({
        placement: "bottom",
        render: () => {
          return (
            <Toast>
              <View ml='8px'>
                <Text
                  color={theme.toastColor}
                  fontFamily={theme.fontFamily}
                  {...styles.toast}
                >
                  Error fetching Transactions
                </Text>
              </View>
            </Toast>
          )
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    ;(async () => {
      try {
        if (apiError < 3) {
          // use ref to track loading as setState can be async
          isTransactionsLoadingRef.current = true
          // show loader in UI
          setIsTransactionsLoading(true)

          // get txns
          await getTransactions()

          isTransactionsLoadingRef.current = false
          setIsTransactionsLoading(false)
        } else if (transactions.length === 0) {
          setTransactions(transactions => [...transactions, { type: "EMPTY" }])
        }
      } catch (err) {
        consoleError(err)
      }
    })()
  }, [hasMore])

  const getTransactions = async () => {
    try {
      // get txns for current month with the set offset
      const response = await AccountService.getTransactions(user.account.id, {
        count: transactionCount,
        offset: offset.current,
        from: startDate.current,
        to: endDate.current,
      })

      const result = response.data

      // if api call successful
      if (result?.success) {
        if (result.data.totalCount !== 0) {
          setTransactions(transactions => [
            ...transactions,
            ...result.data.transactions,
          ])
        }
        // if current month has more txns pending, stay in this month
        if (result.data.hasMore) {
          setHasMore(hasMore + 1)
          // increment offset, next call happens for current month with this offset
          offset.current += transactionCount
        }
      } else {
        setApiError(apiError + 1)
      }
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

  //     isTransactionsLoadingRef.current = false;
  //     setIsTransactionsLoading(false);
  //   } else if (transactions.length === 0) {
  //     setTransactions((transactions) => [...transactions, { type: "EMPTY" }]);
  //   }
  // };

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <View > 
        <BilledTransactionLoader />
      </View>
    ) : null
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "DATE":
        return (
          <View marginTop={30} marginLeft={6}>
            <Text {...styles.dateText} color={theme.appTextColor}>
              {moment(item.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? "Today"
                : moment(item.date).format("Do MMM YY")}
            </Text>
          </View>
        )

      case "EMPTY":
        return <Empty />

      default:
        return (
          <View>
            <Transactions
              transaction={item}
              fontFamily={theme.fontFamily}
              dateFormat={preferences.dateFormat}
              timeFormat={preferences.timeFormat}
              isLast={index === transactions.length - 1}
            />
          </View>
        )
    }
  }

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
    startDate.current = initStartDate
    endDate.current = initCurrentDate
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        minH={windowDim.height}
        bgColor={theme.backgroundColor}
      >
        <>
          <Header
            marginBottom='4px'
            text={
              user.programType === ProgramTypes.PREPAID
                ? `Transactions`
                : `Billed Transactions`
            }
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          {/* <View
            {...styles.header}
            bgColor={theme.headerBackgroundColor}
            onClick={() => navigate(-1)}
          >
            <View>
              <ChevronLeftIcon size="5" c color={theme.titleColor} />
            </View>
            <View>
              <Text
                fontFamily={theme.fontFamily}
                {...styles.heading}
                color={theme.titleColor}
              >
                {user.programType === ProgramTypes.PREPAID
                  ? `Transactions`
                  : `Billed Transactions`}
              </Text>
            </View>
            <View></View>
          </View> */}
        </>
        <View
          mt='32px'
          backgroundColor={"#FFF"}
          mx='16px'
          borderRadius={"12px"}
          borderWidth={"1px"}
          bgColor={theme.widgetBackgroundColor}
          borderColor='#ECECEC'
          shadow={"3"}
          mb='12px'
          p='24px'
        >
          <View>
            <View>
              <Text
                fontWeight='600'
                fontSize='14px'
                lineHeight='22px'
                color={theme.appTextColor}
                fontFamily={theme.fontFamily}
              >
                Transactions between
              </Text>
            </View>
            <View mt='2px'>
              <Text
                fontWeight='400'
                fontSize='18px'
                lineHeight='22px'
                color={theme.appTextColor}
                fontFamily={theme.fontFamily}
              >
                {moment(startDate.current).format(
                  user.programType === ProgramTypes.PREPAID
                    ? "Do MMM 'YY"
                    : "Do MMM",
                )}{" "}
                -{" "}
                {moment(endDate.current).format(
                  user.programType === ProgramTypes.PREPAID
                    ? "Do MMM 'YY"
                    : "Do MMM",
                )}
              </Text>
            </View>
          </View>
          <View>
            {
              <FlatList
                data={transactions}
                renderItem={renderItem}
                keyExtractor={(_transaction, index) => index}
                ListFooterComponent={renderLoader}
                onEndReachedThreshold={0.2}
                refreshing={false}
                onRefresh={handleRefresh}
              />
            }
          </View>
        </View>
      </View>
    </View>
  )
}

export default ShowTransactions_v2
