import moment from "moment"
import { Text, View } from "native-base"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import logo from "../../../assets/images/default-offers-logo.png"
import { DisplayConfig } from "../../../utils/constants"
import { Header } from "../../core"
import RewardIcon from "../../svg/rewards"
import OfferDetailAccordian_v2 from "./OfferDetailAccordian_v2"

const OfferDetails_v2 = () => {
  const windowDimensions = useWindowDimensions()
  const [theme, session] = useSelector(state => [
    state.theme,
    state.session,
  ])
  const [isMilestoneDetailOpen, setIsMilestoneDetailOpen] = useState(false)
  return (
    <>
      <View alignItems='center' justifyContent='center'>
        <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
          <View
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            w='100%'
            minH={windowDimensions.height}
            bgColor={theme.backgroundColor}
          >
            <Header
              text='My Card - Offer Description'
              onBack={async () => {
                window.history.go(-1)
              }}
            />

            <View w='100%' alignItems={"center"} justifyContent='center'>
              <View
                w='90%'
                mt='12px'
                bgColor={theme.widgetBackgroundColor}
                shadow={2}
                borderRadius={"12px"}
              >
                <View
                  alignItems={"center"}
                  p='16px'
                  bgColor={
                    JSON.parse(session.currentOffer.offerDetails)
                      ?.usePrimaryColor
                      ? session.currentOffer.displayColor
                      : theme.widgetBackgroundColor
                  }
                >
                  {session.currentOffer.benefitType === "MILESTONE" ? (
                    <View>
                      <View
                        onClick={() =>
                          setIsMilestoneDetailOpen(!isMilestoneDetailOpen)
                        }
                      >
                        <RewardIcon
                          percent={
                            1 -
                            session.currentOffer.currentValue /
                              session.currentOffer.threshold
                          }
                          color={
                            session.currentOffer?.displayColor || "#B9E9FC"
                          }
                          size='160px'
                        />
                      </View>

                      {isMilestoneDetailOpen && (
                        <View
                          position={"absolute"}
                          borderRadius={"4px"}
                          p='4px'
                          borderWidth={"1px"}
                          backgroundColor={theme.widgetBackgroundColor}
                          top='20%'
                          right={"20%"}
                          borderColor='#ECECEC'
                          shadow={2}
                        >
                          <Text
                            fontWeight='600'
                            fontSize='12px'
                            fontFamily={theme.fontFamily}
                            color={theme.appTextColor}
                          >
                            {`${session.currentOffer.currentValue} out of ${session.currentOffer.threshold} completed`}
                          </Text>
                        </View>
                      )}
                    </View>
                  ) : (
                    <img
                      src={session.currentOffer.illustrationUrl || logo}
                      height='160px'
                      width={"160px"}
                      alt=''
                    />
                  )}
                </View>
                <View
                  w='100%'
                  alignItems={"center"}
                  justifyContent='center'
                  bgColor={
                    JSON.parse(session.currentOffer.offerDetails)
                      ?.usePrimaryColor
                      ? session.currentOffer.displayColor
                      : theme.widgetBackgroundColor
                  }
                >
                  <View w='85%' p='20px'>
                    <View>
                      <Text
                        fontWeight='700'
                        fontSize='18px'
                        fontFamily={theme.fontFamily}
                        color={theme.appTextColor}
                      >
                        {session.currentOffer.name}
                      </Text>
                    </View>
                    {moment(session?.currentOffer?.benefitExpiry).format(
                      "DD MMM YYYY",
                    ) < moment().add(100, "years").format("DD MMM YYYY") && (
                      <View alignItems={"center"} flexDir='row' mt='8px'>
                        <Text
                          fontWeight='600'
                          fontSize='12px'
                          fontFamily={theme.fontFamily}
                          color={theme.appTextColor}
                        >
                          Expires on:
                        </Text>
                        <Text
                          fontWeight='400'
                          fontSize='12px'
                          fontFamily={theme.fontFamily}
                          color={theme.appTextColor}
                          ml='8px'
                        >
                          {moment(session?.currentOffer?.benefitExpiry).format(
                            "DD MMM YYYY",
                          )}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
                {JSON.parse(session.currentOffer.offerDetails)?.content &&
                  JSON.parse(session.currentOffer.offerDetails)?.content.map(
                    (content, index) => (
                      <View
                        p='24px'
                        borderTopWidth={1}
                        borderColor={`${theme.appTextColor}:alpha.20`}
                      >
                        <OfferDetailAccordian_v2 data={content} index={index} />
                      </View>
                    ),
                  )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default OfferDetails_v2
