import {
  ChevronRightIcon, Image, Input, Text, useToast, View
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import CommonService from "../../../services/CommonService"
import {
  restoreStateBeforeSetMpin,
  saveStateBeforeSetMpin,
  setDeviceId,
  setIsMpinSet,
  setVerifiedChallenges
} from "../../../store/actions/Auth"
import { setSetMpinState } from "../../../store/actions/SetMpin"
import * as analytics from "../../../utils/analytics"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton
} from "../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  MpinErrors
} from "../../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions
} from "../../../utils/enums"
import {
  getAnalyticsProgramType,
  goToRedirectUrl
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import MpinBlocked from "./MpinBlocked"
import styles from "./styles"

const Mpin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const [theme, authStore, user, config, screen, session] = useSelector(
    state => [
      state.theme,
      state.auth,
      state.user,
      state.config,
      state.screen,
      state.session,
    ],
  )

  useEffect(() => {
    if (config?.version === PwaVersions.V2) {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.LOGIN_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REGISTRATION_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
        )
      ) {
        navigate("/blocked")
      }

      if (
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
        )
      ) {
        navigate("/blocked")
      }
    }
  }, [])

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showMpinBlocked, setShowMpinBlocked] = useState(false)
  const [pin, setPin] = useState(["", "", "", "", "", "", ""])

  const mpin0 = useRef(null)
  const mpin1 = useRef(null)
  const mpin2 = useRef(null)
  const mpin3 = useRef(null)
  const mpin4 = useRef(null)
  const mpin5 = useRef(null)
  const mpin6 = useRef(null)
  const windowDimensions = useWindowDimensions()

  const showHeader = config?.version === PwaVersions.V2
  const headerText = getHeaderText(AuthChallenges.MPIN)
  const showCancelBtn = !(
    authStore.apiId === ApiIds.ACCOUNT_SUMMARY ||
    (!authStore.afaDetails.config.isMpinSet.result &&
      authStore.apiId === ApiIds.SET_MPIN)
  )

  const mpinSize =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE

  const toast = (message, hasExclamationMark = false) => {
    showToast1({ nativeToast, theme, message, hasExclamationMark })
  }

  const verifyMpin = async () => {
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - Click on Verify MPIN`,
    )

    if (pin.join("").length < mpinSize) {
      setError(true)
      toast("Incorrect MPIN", true)
      return
    }

    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeMpin({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        customerId: user.customer.id,
        programId: user.account.programId,
        mpin: pin.join(""),
      })
      const result = response.data

      if (result?.success) {
        setError(false)

        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          mpinRefId: result.data.mpinRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        await onFactorCompletion(navigate)
      } else {
        if (result?.errors?.status === MpinErrors.INCORRECT_MPIN) {
          setError(true)
          const attempts =
            result?.errors?.attemptsLeft === 2
              ? ". Two attempts left."
              : result?.errors?.attemptsLeft === 1
              ? ". Last attempt left."
              : ""
          toast(`Incorrect MPIN${attempts}`, true)
        } else if (result?.errors?.status === MpinErrors.BLOCKED) {
          // update isMpinSet flag
          dispatch(setIsMpinSet({ isMpinSet: { result: false } }))

          // show mpin blocked screen
          setShowMpinBlocked(true)
        } else {
          await authStore.onAuthFailure(
            result?.errors?.reason,
            "An error occurred while verifying MPIN. Please try again later.",
          )
        }
      }
    } catch (error) {
      await authStore.onAuthFailure(
        error,
        "An error occurred. Please try again later.",
      )
    }

    setSubmitLoading(false)
  }

  const handleForgotMpin = () => {
    dispatch(saveStateBeforeSetMpin())

    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {
          dispatch(restoreStateBeforeSetMpin())
          navigate("/Auth/Mpin", { replace: true })
        },

        onSetMpinFailure: async (error, message) => {
          dispatch(restoreStateBeforeSetMpin())
          await authStore.onAuthFailure(
            "Failed to set mpin",
            "An error occurred. Please try again later.",
          )
        },

        onSetMpinCancel: async message => {
          dispatch(restoreStateBeforeSetMpin())
          // on cancel navigate back here
          navigate("/Auth/Mpin", { replace: true })
        },
      }),
    )

    navigate("/Auth/SetMpin", { replace: true })
  }

  return showMpinBlocked ? (
    <MpinBlocked onSubmit={handleForgotMpin}></MpinBlocked>
  ) : (
    <View
      alignItems='center'
      minH={windowDimensions.height}
      bgColor={
        config?.version === PwaVersions.V2 ? theme.backgroundColor : null
      }
    >
      <View w='100%'>
        {showHeader ? (
          <Header
            text={headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          w='90%'
          borderRadius='12px'
          bgColor={theme.widgetBackgroundColor}
          mt='20px'
          mx='auto'
          py='20px'
          px='5'
          style={{
            boxShadow: "0px 1px 4px rgba(48, 48, 48, 0.16)",
          }}
        >
          <View>
            <Text
              {...styles.mpinVerificationText}
              fontFamily={theme.fontFamily}
              color={theme.appTextColor}
            >
              Enter MPIN
            </Text>
            <Text {...styles.saveSettingText} color={theme.appTextColor}>
              Please enter your MPIN to proceed
            </Text>
          </View>
          <View
            {...styles.pinMainView}
            px={0}
            // backgroundColor={theme.color3}
          >
            <View {...styles.pinMainView1}>
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin0}
                value={pin[0]}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([text, pin[1], pin[2], pin[3], pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    mpin1.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin1}
                value={pin[1]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[1] === "") {
                    mpin0.current.focus()
                    setPin(["", "", pin[2], pin[3], pin[4], pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([pin[0], text, pin[2], pin[3], pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    mpin2.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin2}
                value={pin[2]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[2] === "") {
                    mpin1.current.focus()
                    setPin([pin[0], "", "", pin[3], pin[4], pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([pin[0], pin[1], text, pin[3], pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    mpin3.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin3}
                value={pin[3]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[3] === "") {
                    mpin2.current.focus()
                    setPin([pin[0], pin[1], "", "", pin[4], pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([pin[0], pin[1], pin[2], text, pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    mpinSize === 4
                      ? mpin3.current.blur()
                      : mpin4.current.focus()
                  }
                }}
              />
              {mpinSize > 4 && (
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin4}
                  value={pin[4]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[4] === "") {
                      mpin3.current.focus()
                      setPin([pin[0], pin[1], pin[2], "", "", pin[5], pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      pin[3],
                      text,
                      pin[5],
                      pin[6],
                    ])
                    if (text !== "") {
                      mpinSize === 5
                        ? mpin4.current.blur()
                        : mpin5.current.focus()
                    }
                  }}
                />
              )}
              {mpinSize > 5 && (
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin5}
                  value={pin[5]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[5] === "") {
                      mpin4.current.focus()
                      setPin([pin[0], pin[1], pin[2], pin[3], "", "", pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      pin[3],
                      pin[4],
                      text,
                      pin[6],
                    ])
                    if (text !== "") {
                      mpinSize === 6
                        ? mpin5.current.blur()
                        : mpin6.current.focus()
                    }
                  }}
                />
              )}
              {mpinSize > 6 && (
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin6}
                  value={pin[6]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[6] === "") {
                      mpin5.current.focus()
                      setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      pin[3],
                      pin[4],
                      pin[5],
                      text,
                    ])
                    if (text !== "") {
                      mpin6.blur()
                    }
                  }}
                />
              )}
            </View>
          </View>
          {/* <View mt="12px" display={error ? "flex" : "None"}>
              <Text
                fontFamily={theme.fontFamily}
                font-weight="700"
                font-size="14px"
                line-height="18px"
                letter-spacing="0.02em"
                color="#C2181B"
              >
                {error}
              </Text>
            </View> */}
          <View
            alignItems='center'
            justifyContent='center'
            width='100%'
            mt='20px'
          >
            <ColorButton
              text={"Verify MPIN"}
              isDisabled={pin.join("").length !== mpinSize}
              onPress={verifyMpin}
              isLoading={submitLoading}
            />
            {showCancelBtn && !config?.version == PwaVersions.V2 && (
              <WhiteButton
                text='Cancel'
                onPress={async () => {
                  analytics.track(
                    `${getAnalyticsProgramType(
                      user.programType,
                    )} - Click on Cancel(MPIN)`,
                  )
                  await authStore.onAuthCancel()
                }}
              />
            )}
            <View {...styles.forgotMpinContainer}>
              <Text {...styles.forgotMpinText} color={theme.appTextColor}>
                Forgot MPIN?
              </Text>
              <Text
                cursor='pointer'
                {...styles.resetNowText}
                onClick={handleForgotMpin}
                color={theme.color1}
              >
                Reset Now
              </Text>
              <ChevronRightIcon mt='1px' color={theme.color1} size={4} />
            </View>
          </View>
        </View>
        {screen?.mpin?.bannerImageUrl && (
          <Image
            source={{ uri: screen?.mpin?.bannerImageUrl }}
            w='150px'
            h='250px'
            mx='auto'
            mt='30px'
          />
        )}
      </View>
    </View>
  )
}

export default Mpin
