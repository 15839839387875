import moment from "moment"
import { ChevronLeftIcon, Radio, Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import Loader from "../../../../components/core/loader"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
} from "../../../../utils/constants"
import {
  consoleError,
  formatAmount,
  getTransactionDate,
} from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import TransactionLogo from "../../../core/transactionLogo"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"

import { PwaVersions } from "../../../../utils/enums"
import ConvertEmiLoader from "../../../core/SkeletonLoader/convertEmiLoader"

const EMI_CONVERTED_ERROR_STRING = /is previously converted to EMI/

function EMIPlans() {
  const windowDim = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const [theme, common, user, config, screen] = useSelector(state => [
    state.theme,
    state.common,
    state.user,
    state.config,
    state.screen,
  ])

  const { isEmiPlansSet, txnState, emiPlansData } = common
  const [value, setValue] = useState()
  const [emiPlans, setEmiPlans] = useState([])
  const [error /*, setError */] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!isEmiPlansSet) {
        dispatch(clearCommonState())
        dispatch(setCommonState({ txnState: location.state }))

        await handleApiAuth({
          apiId: ApiIds.EMI_CONFIG,
          onAuthSuccess: onEmiConfigAuthSuccess(location.state.amount),
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get emi plans",
          mpinReason: "to get emi plans",
          toast,
          navigate,
        })
      } else {
        let emi_plans = emiPlansData.emiPlans
        emi_plans.sort(sortByTenure)
        setEmiPlans(emi_plans)

        setValue(emiPlansData.emiPlans[0]["tenureInMonths"])
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmiPlansSet])

  function sortByTenure(a, b) {
    if (a.tenureInMonths < b.tenureInMonths) {
      return 1
    }
    if (a.tenureInMonths > b.tenureInMonths) {
      return -1
    }
    return 0
  }

  const onEmiConfigAuthSuccess = amount => async apiToken => {
    try {
      const response = await AccountService.getEMIConfig(
        apiToken,
        user.account.id,
        amount,
        location.state.txnId,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isEmiPlansSet: true,
            emiPlansData: result.data,
          }),
        )

        navigate("/ConvertToEMI", { replace: true })
      } else {
        consoleError(result?.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(
            "Sorry!the transaction has already been converted. \n Please contact your bank for more details.",
          )
        } else {
          toast("Sorry an error occurred")
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onCreateEmiAuthSuccess = async apiToken => {
    try {
      const data = {
        tenureInMonths: value,
        txnRefId: txnState.txnId,
      }
      const response = await AccountService.createEMI(
        apiToken,
        user.account.id,
        data,
      )
      const result = response?.data

      if (result?.success) {
        if (txnState?.txnType === "AUTHORIZE") {
          toast("EMI Conversion requested")
        } else {
          toast("Successfully converted to EMI")
        }
        navigate("/", { replace: true })
      } else {
        consoleError(result.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(
            "Sorry!the transaction has already been converted. \n Please contact your bank for more details.",
          )
        } else {
          toast("Sorry an error occurred while converting to EMI")
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred while converting to EMI")
      navigate("/", { replace: true })
    }
  }

  const handleConvertToEMI = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.CREATE_EMI,
      onAuthSuccess: onCreateEmiAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to create emi",
      mpinReason: "to create emi",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }

  return (
    <View
      minH={getVersionedDisplay() ? windowDim.height : null}
      bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
      _web={{ maxW: DisplayConfig.MAX_WIDTH }}
      w='100%'
    >
      {error ? (
        <ErrorScreen />
      ) : loading ? (
        config.version === PwaVersions.V1 ? (
          <View
            height={window.height}
            alignItems='center'
            justifyContent='center'
            style={{
              backgroundColor: `${
                config.version === PwaVersions.V2
                  ? theme.backgroundColor
                  : "white"
              }`,
            }}
          >
            <Loader color={theme.color1} width={120} height={120} />
          </View>
        ) : (
          <View>
            <ConvertEmiLoader />
          </View>
        )
      ) : (
        <View alignItems='center' justifyContent='center'>
          <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
            {getVersionedDisplay() ? (
              <Header
                text='Convert to EMI'
                onBack={async () => {
                  window.history.go(-1)
                }}
              />
            ) : (
              <View
                {...styles.header}
                backgroundColor={
                  getVersionedDisplay() ? theme.headerBackgroundColor : null
                }
                justifyContent='space-between'
              >
                <View>
                  <Link
                    style={{
                      textDecoration: "None",
                    }}
                    to={txnState.prevPath}
                  >
                    <ChevronLeftIcon
                      size='24px'
                      color={
                        getVersionedDisplay() ? theme.titleColor : "#303030"
                      }
                      width='fit-content'
                    />
                  </Link>
                </View>
                <View>
                  <Text
                    {...styles.manageCard}
                    color={getVersionedDisplay() ? theme.titleColor : "#303030"}
                    fontFamily={theme.fontFamily}
                  >
                    Convert to EMI
                  </Text>
                </View>
                <View></View>
              </View>
            )}

            <View {...styles.cardInterface}>
              {txnState.logo ? (
                <img
                  {...styles.image}
                  src={`data:image/jpg;base64,${txnState.logo}`}
                  alt='logo'
                  width={TRANSACTION_LOGO_WIDTH + "px"}
                  height={TRANSACTION_LOGO_HEIGHT + "px"}
                />
              ) : (
                <View
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <TransactionLogo transactionType={txnState.txnType} />
                </View>
              )}
            </View>
            <View alignItems={"center"} mt='4px'>
              <Text
                {...styles.displayTitle}
                color={getVersionedDisplay() ? theme.appTextColor : null}
                fontFamily={theme.fontFamily}
              >
                {txnState.description}
              </Text>
            </View>
            <View alignItems={"center"} mt='4px'>
              <Text
                {...styles.displayDate}
                fontFamily={theme.fontFamily}
                color={getVersionedDisplay() ? theme.appTextColor : null}
              >
                {getTransactionDate(
                  txnState.transactionDate,
                  txnState.postingDate,
                )}
              </Text>
            </View>
            <View alignItems={"center"} mt='8px'>
              <Text
                {...styles.displayAmount}
                fontFamily={theme.fontFamily}
                color={getVersionedDisplay() ? theme.appTextColor : null}
              >
                {formatAmount(txnState.amount)}
              </Text>
            </View>
          </View>
          <View {...styles.choosePlan}>
            <View {...styles.choosePlan}>
              <View>
                <Text
                  {...styles.choosePlanTitle}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Choose Plan
                </Text>
              </View>
              {emiPlans &&
                emiPlans.map((emiPlan, index) => (
                  <View
                    key={index}
                    {...styles.plan}
                    borderRadius='8px'
                    style={{
                      margin: "5px",
                      background: getVersionedDisplay()
                        ? theme.widgetBackgroundColor
                        : "#F8F8F8",
                      borderBottom: "1px solid #ECECEC",
                    }}
                  >
                    <View>
                      <View
                        {...styles.monthlyPlanTextBox}
                        backgroundColor={
                          getVersionedDisplay()
                            ? `${theme.backgroundColor}:alpha.60`
                            : "#FFE5A6"
                        }
                      >
                        <Text
                          {...styles.monthlyPlanText}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          {" "}
                          {`${emiPlan.tenureInMonths} months`}
                        </Text>
                      </View>
                      <View>
                        <Text
                          {...styles.amountPerMonth}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          {`₹${emiPlan.emi}`}
                          <span style={{ fontSize: "14px" }}>/month</span>
                        </Text>
                      </View>
                      <View
                        {...styles.monthlyPlanIntrestBox}
                        style={{ gap: "10px" }}
                      >
                        <Text
                          {...styles.monthlyPlanIntrest}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          Interest:{" "}
                          {`${formatAmount(
                            emiPlan.schedules.reduce((total, schedule) => {
                              return schedule.interest + total
                            }, 0),
                          )}`}
                        </Text>
                        <Text
                          {...styles.monthlyPlanIntrest}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          .
                        </Text>
                        <Text
                          {...styles.monthlyPlanIntrest}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          Processing Fees:{" "}
                          {`${formatAmount(emiPlan.processingFees)}`}
                        </Text>
                      </View>
                      <View>
                        <Text
                          {...styles.monthlyPlanDuration}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          Duration:{" "}
                          {`${moment(
                            emiPlan.schedules[0].paymentDueDate,
                          ).format("MMM 'YY")}`}{" "}
                          -{" "}
                          {moment(
                            emiPlan.schedules[emiPlan.schedules.length - 1]
                              .paymentDueDate,
                          ).format("MMM 'YY")}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Radio.Group
                        value={value}
                        onChange={item => {
                          setValue(item)
                        }}
                        color={theme.color1}
                        name='myRadioGroup'
                        accessibilityLabel='pick'
                      >
                        <Radio
                          value={emiPlan.tenureInMonths}
                          colorScheme={theme.color1}
                          my={1}
                        ></Radio>
                      </Radio.Group>
                    </View>
                  </View>
                ))}
            </View>
            <View px='16px'>
              {/* <View style={{ paddingTop: "16px" }}>
              <Text {...styles.monthlyPlanText}>
                By continuing you agree to the{" "}
                <a href="#" style={{ color: "#303030" }}>
                  Terms & Conditions
                </a>
              </Text>
            </View> */}
            </View>
            <View alignItems='center' justifyContent='center' w='100%'>
              <View
                width='100%'
                alignItems='center'
                my='10px'
                pt='20px'
                _web={{ maxW: DisplayConfig.MAX_WIDTH }}
              >
                {getVersionedDisplay() && screen?.emi?.isTncEnabled && (
                  <View
                    mb='4px'
                    w={"90%"}
                    display='flex'
                    alignItems={"flex-start"}
                    justifyContent='flex-start'
                    flexDir={"row"}
                  >
                    <Text
                      fontWeight='400'
                      fontSize='11px'
                      lineHeight='22px'
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                      fontFamily={theme.fontFamily}
                    >
                      By continuing you agree to the
                    </Text>
                    <View
                      ml='4px'
                      onClick={() =>
                        (window.location.href =
                          screen?.emi?.isTncEnabled ||
                          "https://hyperface.co.in")
                      }
                    >
                      <Text
                        fontWeight='400'
                        fontSize='11px'
                        lineHeight='22px'
                        color={
                          getVersionedDisplay() ? theme.appTextColor : null
                        }
                        fontFamily={theme.fontFamily}
                      >
                        Terms & Conditions{" "}
                      </Text>
                    </View>
                  </View>
                )}
                <ColorButton
                  style={{ width: "100%", borderRadius: "6px" }}
                  text='Convert to EMI'
                  isLoading={submitLoading}
                  onPress={() => handleConvertToEMI()}
                />
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  )
}

export default EMIPlans
