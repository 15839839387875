import { Text, View, Image } from "native-base"
import React from "react"
import banner from "../../assets/images/preLoaderTransparent.gif"
import bankDefaultIcon from "../../assets/images/bankDefaultIcon.svg"
import HyperfaceLogo from "../../assets/images/HyperfaceLogo.svg"
import styles from "./styles"
import { useWindowDimensions } from "react-native-web"

const Preloader_v2 = () => {
  const windowDim = useWindowDimensions()

  return (
    <View
      {...styles.centering}
      minH={windowDim.height}
      backgroundColor={
        localStorage.getItem("backgroundColor")
          ? localStorage.getItem("backgroundColor")
          : "#FFFFFF"
      }
    >
      <View {...styles.centering} mt='30%'>
        {localStorage.getItem("preLoaderBanner") ? (
          <Image
            source={{
              uri: localStorage.getItem("preLoaderBanner"),
            }}
            width='300px'
            height='300px'
            alt='Alternate Text '
          />
        ) : (
          <Image
            source={{
              uri: banner,
            }}
            width='200px'
            height='150px'
            alt='Alternate Text '
            my='40%'
          />
        )}
      </View>
      <View mt='32px' {...styles.centering}>
        {localStorage.getItem("issuerImage") !== null && (
          <View {...styles.centering}>
            <View>
              <Text
                {...styles.baseText}
                textAlign={"center"}
              >{`Establishing a secure link with ${
                localStorage.getItem("issuerBankName")
                  ? localStorage.getItem("issuerBankName")
                  : "the bank"
              }`}</Text>
            </View>
            <View mt='12px'>
              {localStorage.getItem("issuerImage") !== null ? (
                <Image
                  source={{
                    uri: localStorage.getItem("issuerImage"),
                  }}
                  width={"100px"}
                  height={"43px"}
                  alt='Alternate Text '
                />
              ) : (
                <Image
                  source={{
                    uri: bankDefaultIcon,
                  }}
                  width={"36px"}
                  height={"36px"}
                  alt='Alternate Text '
                />
              )}
            </View>
          </View>
        )}
        <View mt='16px' {...styles.centering}>
          <View>
            <Text {...styles.baseText} textAlign={"center"}>
              {localStorage.getItem("issuerImage") !== null
                ? `Powered by`
                : `Establishing a secure link Powered by`}
            </Text>
          </View>
          <View mt='12px'>
            <Image
              source={{
                uri: HyperfaceLogo,
              }}
              width={"100px"}
              height={"28px"}
              alt='Alternate Text '
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default Preloader_v2
