import React from "react"
import { useSelector } from "react-redux"
import {
  View,
  Text,
  Image,
  ChevronRightIcon,
  Button,
  useToast,
} from "native-base"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import styles from "./styles"
import { CardOrientation, ProgramTypes } from "../../../../utils/constants"
import { formatAmount } from "../../../../utils/functions"
import VerticalCard from "../../../../assets/images/sampleVerticalCard.svg"
import HorizontalCard from "../../../../assets/images/sampleHorizontalCard.svg"
import block from "../../../../assets/images/block.svg"
import click from "../../../../assets/images/click.svg"
import ActivatePhysicalCardStrip from "../../../core/ActivatePhysicalCardStrip/ActivatePhysicalCardStrip"
import { showToast1 } from "../../../core/toast"


const CardAndBalance_v1 = props => {
  const navigate = useNavigate()
  const nativeToast = useToast()

  const [theme, user, session, preferences] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
    state.preferences,
  ])
  const addExtraSpace = () => {
    if (props.cardDetails.orientation === CardOrientation.HORIZONTAL) {
      return 10
    } else {
      return 5
    }
  }
  
const getStripMsg = (account,card)=>{
  if(account.status === "SUSPENDED")
    return "Account is temporarily suspended"
  else if(account.status === "FORCED_SUSPENDED")
    return "Account is suspended"
  else if(account.status === "CLOSED")
    return "Account is closed"
  else if(!card)
    return "Card is not issued"
  else if(card.isHotlisted)
    return " ".repeat(addExtraSpace()) + "Card Blocked"
  else if(card.isLocked)
    return " ".repeat(addExtraSpace()) + "Card Locked"
  else
    return null
}
  const repayUrlCall = () => {
    window.location.href = session.repaymentUrl
  }

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const seeCardDetails = () => {
    navigate("/CardDetailsWidget")
  }

  const seePrepaidCardDetails = () => {
    if (card?.isLocked) {
      toast(
        "Unlock your card from the settings \n to access virtual card details",
      )
    } else {
      navigate("/CardDetailsWidget")
    }
  }

  const account = user.account
  const card = user.cards?.[0]
  const isAccountActive = account.status === "ACTIVE"

  const stripMsg = getStripMsg(account,card)

  return (
    <View {...styles.horizontalContainer}>
      {/** Card Section */}
      {props.cardDetails.orientation === CardOrientation.HORIZONTAL ? (
        <>
          <View>
            <View
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <Image
                source={{
                  uri: props.cardDetails.image || HorizontalCard,
                }}
                width='343px'
                height='216px'
                alt='Alternate Text '
                borderRadius='8px'
              />
            </View>
            {stripMsg && (
              <View
                width='343px'
                backgroundColor='#fff'
                alignItems='center'
                flexDirection='row'
                px='24px'
                py='12px'
                position='absolute'
                bottom='16px'
              >
                <View>
                  <Image
                    source={{ uri: block }}
                    width='14px'
                    height='14px'
                    alt='Alternate Text '
                    borderRadius='8px'
                  />
                </View>
                <View ml='8px'>
                  <Text
                    fontFamily={theme.fontFamily}
                    fontWeight='700'
                    fontSize='14px'
                    lineHeight='18px'
                    letterSpacing='0.02em'
                    color='#E02929'
                  >
                    {stripMsg}
                  </Text>
                </View>
              </View>
            )}
          </View>
          {/**Card Section End */}
          {user.programType !== ProgramTypes.PREPAID
            ? !stripMsg && (
                <View
                  {...styles.horizontalCardDetailsContainer}
                  borderColor={theme.color1}
                  onClick={() => seeCardDetails()}
                >
                  <View flexDirection='row' cursor='pointer'>
                    <Text
                      {...styles.CardDetailsText}
                      fontFamily={theme.fontFamily}
                      color={theme.color1}
                    >
                      See Card Details
                    </Text>
                  </View>
                  <View>
                    <ChevronRightIcon size='6' color={theme.color1} />
                  </View>
                </View>
              )
            : isAccountActive &&
              card &&
              !card.isHotlisted && (
                <View
                  mt='12px'
                  flexDirection={"row"}
                  alignItems='center'
                  onClick={() => seePrepaidCardDetails()}
                >
                  <View mr='8px'>
                    <Image
                      source={{ uri: click }}
                      width='16px'
                      height='16px'
                      alt='Alternate Text '
                    />
                  </View>
                  <View>
                    <Text>Tap card to see details</Text>
                  </View>
                </View>
              )}
        </>
      ) : (
        <>
          {" "}
          <View>
            <View
              onTouchEnd={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              {" "}
              <Image
                source={{
                  uri: props.cardDetails.image || VerticalCard,
                }}
                width='216px'
                height='343px'
                alt='Alternate Text '
              />
            </View>
            {stripMsg && (
              <View
                width='216px'
                backgroundColor='#fff'
                alignItems='center'
                flexDirection='row'
                px='24px'
                py='12px'
                position='absolute'
                bottom='16px'
              >
                <View>
                  <Image
                    source={{ uri: block }}
                    width='14px'
                    height='14px'
                    alt='Alternate Text '
                    borderRadius='8px'
                  />
                </View>
                <View ml='12px' width='160px'>
                  <View>
                    <Text
                      fontFamily={theme.fontFamily}
                      fontWeight='700'
                      fontSize='14px'
                      lineHeight='18px'
                      letterSpacing='0.02em'
                      color='#E02929'
                    >
                      {stripMsg}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
          {/**Card Section End */}
          {user.programType !== ProgramTypes.PREPAID
            ? !stripMsg && (
                <View
                  {...styles.verticalCardDetailsContainer}
                  borderColor={theme.color1}
                  onClick={() => seeCardDetails()}
                >
                  <View flexDirection='row' cursor='pointer'>
                    <Text
                      {...styles.CardDetailsText}
                      fontFamily={theme.fontFamily}
                      color={theme.color1}
                    >
                      See Card Details
                    </Text>
                  </View>
                  <View>
                    <ChevronRightIcon size='6' color={theme.color1} />
                  </View>
                </View>
              )
            : isAccountActive &&
            card &&
            !card.isHotlisted && (
              <View
                mt='12px'
                flexDirection={"row"}
                alignItems='center'
                onClick={() => seePrepaidCardDetails()}
              >
                <View mr='8px'>
                  <Image
                    source={{ uri: click }}
                    width='16px'
                    height='16px'
                    alt='Alternate Text '
                  />
                </View>
                <View>
                  <Text>Tap card to see details</Text>
                </View>
              </View>
              )}
        </>
      )}

      {/** Balance section start */}
      {isAccountActive &&
        card &&
        !card.isHotlisted &&
        (user.programType === ProgramTypes.PREPAID
          ? card?.cardType === "Virtual"
            ? !(card?.isVirtualCardActivated)
            : !(card?.isPhysicalCardActivated)
          : !card?.isPhysicalCardActivated) && (
          <View
            mt='24px'
            w='100%'
            alignItems={"center"}
            justify
            Content='center'
          >
            <ActivatePhysicalCardStrip width='90%' />
          </View>
        )}

      {user.programType !== ProgramTypes.PREPAID ? (
        <View {...styles.horizontalCreditContainer}>
          {
            <View {...styles.horizontalCreditBalanceContainer}>
              <View>
                <View>
                  <Text
                    {...styles.CreditBalanceHeading}
                    fontFamily={theme.fontFamily}
                  >
                    Available Credit
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.CreditBalanceText}
                    fontFamily={theme.fontFamily}
                  >
                    {formatAmount(props.creditDetails.availableCredit)}
                  </Text>
                </View>
              </View>
              <View width='1px' height='100%' bgColor='#ECECEC' />
              <View>
                <View>
                  <Text
                    {...styles.CreditBalanceHeading}
                    fontFamily={theme.fontFamily}
                  >
                    {user.programType === ProgramTypes.CREDIT_CARD
                      ? "Available Cash"
                      : "Used Credit"}
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.CreditBalanceText}
                    fontFamily={theme.fontFamily}
                  >
                    {formatAmount(
                      user.programType !== ProgramTypes.PAY_LATER
                        ? props.creditDetails.availableCash
                        : Number(props.creditDetails.totalCredit) -
                            Number(props.creditDetails.availableCredit),
                    )}
                  </Text>
                </View>
              </View>
            </View>
          }
          {/**Balance Amount Ends  */}

          {/**Pay Reminder Setio Starts  */}

          {session.repaymentFlow ? (
            <View
              display={props.showReminder.show ? "flex" : "none"}
              {...styles.horizontalPayReminder}
              borderTopRadius={card && !card.isHotlisted ? "0px" : "8px"}
              bgColor={
                moment(props.showReminder.dueDate).format("YYYY-MM-DD") >
                moment().format("YYYY-MM-DD")
                  ? "#FAFFFB"
                  : "#FDF6F6"
              }
              borderTopWidth='2px'
              borderTopColor='#ECECEC'
              flexDirection='column'
            >
              <View>
                <View>
                  <Text
                    {...styles.CreditBalanceHeading}
                    fontFamily={theme.fontFamily}
                  >
                    Total Due
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.repaymentReminderText}
                    fontFamily={theme.fontFamily}
                  >
                    {formatAmount(props.showReminder.amount)}
                  </Text>
                </View>
              </View>
              <View mt='4px'>
                {moment(props.showReminder.dueDate).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") ? (
                  <Text {...styles.repaymentReminderTextLight} color='#036F29'>
                    {`Due on ${moment(props.showReminder.dueDate).format(
                      "DD MMM",
                    )}`}
                  </Text>
                ) : moment(props.showReminder.graceDate).format("YYYY-MM-DD") >=
                  moment().format("YYYY-MM-DD") ? (
                  <Text {...styles.repaymentReminderTextLight} color='#C2181B'>
                    {`Pay by ${moment(props.showReminder.graceDate).format(
                      "DD MMM",
                    )} to avoid Card Suspension `}
                  </Text>
                ) : (
                  <Text {...styles.repaymentReminderTextLight} color='#C2181B'>
                    {user.account.status === "SUSPENDED"
                      ? `Pay your dues to remove suspension on your account `
                      : ""}
                  </Text>
                )}
              </View>

              <View w='100%' alignItems='center'>
                <View
                  {...styles.PayNowContainer}
                  bgColor={theme.color1}
                  onClick={() => repayUrlCall()}
                  cursor='pointer'
                >
                  <Text {...styles.PayNowContainerText}>Pay Now</Text>
                </View>
              </View>
            </View>
          ) : (
            <View
              display={props.showReminder.show ? "flex" : "none"}
              {...styles.horizontalPayReminder}
              borderTopRadius={card && !card.isHotlisted ? "0px" : "8px"}
              bgColor={theme.color1}
              onClick={() => navigate("/Statement")}
            >
              <View flexDirection='row' alignItems='center'>
                <View>
                  <Text
                    {...styles.PayReminderText}
                    fontFamily={theme.fontFamily}
                    mr='4px'
                  >
                    {formatAmount(props.showReminder.amount)}
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.PayReminderTextLight}
                    fontFamily={theme.fontFamily}
                  >
                    due on
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.PayReminderText}
                    fontFamily={theme.fontFamily}
                    ml='4px'
                  >
                    {moment(props.showReminder.dueDate).format(
                      preferences.dateFormat,
                    )}
                  </Text>
                </View>
                <View>
                  <ChevronRightIcon size='6' color='#ffffff' />
                </View>
              </View>
            </View>
          )}

          {/**Pay Reminder Setio ends  */}
        </View>
      ) : (
        <View {...styles.horizontalCreditContainer}>
          <View {...styles.horizontalCreditBalanceContainer}>
            <View>
              <View>
                <Text
                  {...styles.CreditBalanceHeading}
                  fontFamily={theme.fontFamily}
                >
                  CURRENT BALANCE
                </Text>
              </View>
              <View>
                <Text
                  {...styles.CreditBalanceText}
                  fontFamily={theme.fontFamily}
                >
                  {formatAmount(props.creditDetails.currentBalance)}
                </Text>
              </View>
            </View>
            {session.repaymentFlow && (
              <View w='48%'>
                <Button
                  onPress={() => repayUrlCall()}
                  mt='8px'
                  backgroundColor='#ffffff'
                  borderColor={theme.color1}
                  {...styles.btnStyles}
                >
                  <Text
                    color={theme.color1}
                    fontFamily={theme.fontFamily}
                    fontSize='16px'
                    fontWeight='600'
                  >
                    Manage Balance
                  </Text>
                </Button>
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  )
}

export default CardAndBalance_v1
