import {
  Input,
  Text,
  View,
  useToast
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DisplayConfig, ProgramTypes } from "../../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../utils/enums"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import styles from "../styles"

const ResetPin_v2 = props => {
  const [theme, user, session, config] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
    state.config,
  ])
  const windowDim = useWindowDimensions()
  const confirmPin0 = useRef(null)
  const confirmPin1 = useRef(null)
  const confirmPin2 = useRef(null)
  const confirmPin3 = useRef(null)
  const navigate = useNavigate()
  const nativeToast = useToast()
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const [mainContainerHeight, setMainContainerHeight] = useState(0)
  const mainContainerRef = useRef(null)
  const [isFilled, setIsFilled] = useState(false)

  useEffect(() => {
    if (mainContainerRef.current) {
      setMainContainerHeight(mainContainerRef.current.clientHeight)
    }
  }, [mainContainerRef.current])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.CC_PIN_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.RESET_PIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      )
    ) {
      toast("Access has been blocked for Reset PIN page")
      navigate("/")
    }
  })

  const pin0 = useRef(null)
  const pin1 = useRef(null)
  const pin2 = useRef(null)
  const pin3 = useRef(null)

  useEffect(() => {
    setIsFilled(
      [
        pin0?.current?.value,
        pin1?.current?.value,
        pin2?.current?.value,
        pin3?.current?.value,
        confirmPin0?.current?.value,
        confirmPin1?.current?.value,
        confirmPin2?.current?.value,
        confirmPin3?.current?.value,
      ].every(val => val !== ""),
    )
  }, [
    pin0?.current?.value,
    pin1?.current?.value,
    pin2?.current?.value,
    pin3?.current?.value,
    confirmPin0?.current?.value,
    confirmPin1?.current?.value,
    confirmPin2?.current?.value,
    confirmPin3?.current?.value,
  ])

  const getPinMsg = ()=>{
    if(user.programType === ProgramTypes.CREDIT_CARD)
      return "Setup a new Credit Card PIN"
    else if(user.programType === ProgramTypes.PREPAID)
      return "Setup a new Prepaid Card PIN"
    else
      return "Setup a new Card PIN"
  }

  return (
    <View width='100%' minH={windowDim.height} bgColor={theme.backgroundColor}>
      <Header
        marginBottom='0px'
        text='Reset Pin'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <View>
        <View px='16px' mt='12px' ref={mainContainerRef}>
          <View
            mt='16px'
            bgColor={theme.widgetBackgroundColor}
            {...styles.pinMainView}
            shadow='1'
            borderColor={"#ECECEC"}
            borderWidth='1px'
            borderRadius={"16px"}
          >
            <View my='16px' justifyContent='center'>
              <Text {...styles.mainHeading} color={theme.appTextColor}>
                {getPinMsg()}
              </Text>
            </View>
            <View mt='12px'>
              <View mb='12px'>
                <Text {...styles.pinSectionHeading} color={theme.appTextColor} fontSize="14px">
                  Set Pin
                </Text>
              </View>
              <View {...styles.pinMainView1} b>
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin0}
                  value={props.pin[0]}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      text,
                      props.pin[1],
                      props.pin[2],
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin1}
                  value={props.pin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[1] === ""
                    ) {
                      pin0.current.focus()
                      props.setPin(["", "", props.pin[2], props.pin[3]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      props.pin[0],
                      text,
                      props.pin[2],
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin2}
                  value={props.pin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[2] === ""
                    ) {
                      pin1.current.focus()
                      props.setPin([props.pin[0], "", "", props.pin[3]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      props.pin[0],
                      props.pin[1],
                      text,
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin3}
                  value={props.pin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[3] === ""
                    ) {
                      pin2.current.focus()
                      props.setPin([props.pin[0], props.pin[1], "", ""])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      props.pin[0],
                      props.pin[1],
                      props.pin[2],
                      text,
                    ])
                    if (text !== "") {
                      pin3.current.blur()
                    }
                  }}
                />
              </View>
            </View>
            <View mt='40px'>
              <View mb='12px'>
                <Text {...styles.pinSectionHeading} color={theme.appTextColor} fontSize="14px">
                  Confirm Pin
                </Text>
              </View>
              <View {...styles.pinMainView1}>
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin0}
                  value={props.confirmPin[0]}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      text,
                      props.confirmPin[1],
                      props.confirmPin[2],
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin1}
                  value={props.confirmPin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[1] === ""
                    ) {
                      confirmPin0.current.focus()
                      props.setConfirmPin([
                        "",
                        "",
                        props.confirmPin[2],
                        props.confirmPin[3],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      props.confirmPin[0],
                      text,
                      props.confirmPin[2],
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin2}
                  value={props.confirmPin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[2] === ""
                    ) {
                      confirmPin1.current.focus()
                      props.setConfirmPin([
                        props.confirmPin[0],
                        "",
                        "",
                        props.confirmPin[3],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      props.confirmPin[0],
                      props.confirmPin[1],
                      text,
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin3}
                  value={props.confirmPin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[3] === ""
                    ) {
                      confirmPin2.current.focus()
                      props.setConfirmPin([
                        props.confirmPin[0],
                        props.confirmPin[1],
                        "",
                        "",
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      props.confirmPin[0],
                      props.confirmPin[1],
                      props.confirmPin[2],
                      text,
                    ])
                    if (text !== "") {
                      confirmPin3.current.blur()
                    }
                  }}
                />
              </View>
            </View>
          </View>
        </View>
        <View alignItems='center' justifyContent='center' w='100%'>
          <View
            {...styles.buttonSection}
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            position='relative'
            paddingTop={10}
            minH={windowDim.height - mainContainerHeight - 85}
            justifyContent='flex-end'
          >
            <ColorButton
              text='Submit'
              isLoading={props.submitLoading}
              onPress={props.handleSubmit}
              isDisabled={!isFilled}
            />
            {!config?.version == PwaVersions.V2 ? (
              <WhiteButton text='Cancel' onPress={props.Cancel} />
            ) : null}
          </View>
        </View>
      </View>
    </View>
  )
}

export default ResetPin_v2
