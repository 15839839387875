import React from "react"
import { useSelector } from "react-redux"
import { CardOrientation } from "../../../utils/constants.js"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function TransactionWrapper() {
  return (
    <CardSkeleton
      width='100%'
      margin='40px 0px'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <TextWrapperSkeleton width='56px' height='50px' br='50%' />
      <CardSkeleton width='100%'>
        <CardSkeleton width='100%' height='50%' display='flex'>
          <TextWrapperSkeleton
            margin='0px auto 0px 30px'
            display='inline'
            width='50px'
          />
          <TextWrapperSkeleton width='50px' />
        </CardSkeleton>
        <TextWrapperSkeleton margin='5px 30px' width='100px' />
      </CardSkeleton>
    </CardSkeleton>
  )
}

function SkeletonLoader({ orientation }) {
  const [theme] = useSelector(state => [state.theme])

  return (
    <>
      <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        br='0'
      >
        <TextWrapperSkeleton height='12px' width='200px' />
      </CardSkeleton>

      {orientation === CardOrientation.VERTICAL ? (
        <CardSkeleton
          width='100%'
          height='100%'
          bg={theme.backgroundColor}
          display='flex'
        >
          <CardSkeleton margin='24px' width='40%' height='260px' />
          <CardSkeleton
            margin='24px'
            width='40%'
            height='260px'
            display='flex'
            flexDirection='column'
          >
            <CardSkeleton
              margin='10px'
              width='60%'
              height='128px'
              bg='#c7c3c3'
            />
            <CardSkeleton margin='10px' width='80%' height='76px' bg='#c7c3c3'>
              <TextWrapperSkeleton margin='10px' width='80px' />
              <TextWrapperSkeleton margin='10px' width='50px' />
            </CardSkeleton>
          </CardSkeleton>
        </CardSkeleton>
      ) : (
        <CardSkeleton width='100%' height='100%' bg={theme.backgroundColor}>
          <CardSkeleton width='261px' height='135px' margin='24px auto' />
          <CardSkeleton
            margin='24px auto'
            width='261px'
            height='135px'
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <CardSkeleton
              margin='auto 10px'
              width='90px'
              height='40px'
              bg='#c7c3c3'
            />
            <CardSkeleton
              margin='auto 20px'
              width='90px'
              height='110px'
              bg='#c7c3c3'
            ></CardSkeleton>
          </CardSkeleton>
        </CardSkeleton>
      )}

      <CardSkeleton margin='24px' width='90%' padding='15px'>
        <TextWrapperSkeleton width='100px' />
      </CardSkeleton>
      <CardSkeleton margin='24px' width='90%' height='600px' padding='15px'>
        <TextWrapperSkeleton width='150px' display />
        <TransactionWrapper />
        <TransactionWrapper />
        <TransactionWrapper />
        <TransactionWrapper />
        <TransactionWrapper />
      </CardSkeleton>
    </>
  )
}

export default SkeletonLoader
