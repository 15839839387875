import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function CardDetailsLoader({isImagePresent}) {
  return (
    <>
      <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        br='0'
      >
        <TextWrapperSkeleton height='12px' width='200px' />
      </CardSkeleton>
      <CardSkeleton height='270px' margin='20px auto' width='85%' padding='15px'>
        <TextWrapperSkeleton width='60px' />
        <TextWrapperSkeleton margin='10px  0px 60px 0px' width='180px' />
        <TextWrapperSkeleton margin='10px 0px' width='50px' />
        <TextWrapperSkeleton margin='10px  0px 60px 0px' width='100px' />
        <CardSkeleton width='100%'>
          <CardSkeleton height='30px' width='180px' display='flex'>
            <TextWrapperSkeleton margin='0px auto 0px 0px' width='50px' />
            <TextWrapperSkeleton width='50px' />
          </CardSkeleton>
          <CardSkeleton height='10px' width='180px' display='flex'>
            <TextWrapperSkeleton margin='0px auto 0px 0px' width='50px' />
            <TextWrapperSkeleton width='50px' />
          </CardSkeleton>
        </CardSkeleton>
      </CardSkeleton>
      {isImagePresent && <CardSkeleton height='300px' margin='20px auto' width='196px' />}
    </>
  )
}

export default CardDetailsLoader
