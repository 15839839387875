import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const ContactlessIcon = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 30 30' size='24px'>
     <svg width="24" height="24" viewBox="0 0 24 24" fill={theme.appTextColor} xmlns="http://www.w3.org/2000/svg">
<path d="M5.63388 4.83899C5.81085 4.83899 5.98763 4.76374 6.1392 4.63825C7.55434 3.20821 9.47466 2.40524 11.496 2.40524C13.5173 2.40524 15.4126 3.18299 16.8528 4.6132C17.1308 4.8892 17.5855 4.8892 17.8634 4.6132C18.1414 4.33721 18.1414 3.88568 17.8634 3.60969C16.1707 1.92853 13.8965 1.00024 11.4959 1.00024C9.09537 1.00024 6.82134 1.92853 5.12839 3.63474C4.85043 3.91074 4.85043 4.36226 5.12839 4.63825C5.25477 4.76374 5.45693 4.83899 5.6337 4.83899H5.63388Z" fill={theme.appTextColor}/>
<path d="M15.9938 6.46966C16.2717 6.19366 16.2717 5.74214 15.9938 5.46615C13.5176 3.00738 9.47464 3.00738 6.97312 5.46615C6.69516 5.74215 6.69516 6.19367 6.97312 6.46966C7.1247 6.62018 7.30165 6.67041 7.47843 6.67041C7.65539 6.67041 7.83217 6.59515 7.98374 6.46966C9.90406 4.56287 13.0626 4.56287 14.983 6.46966C15.2611 6.74566 15.7158 6.74566 15.9938 6.46966Z" fill={theme.appTextColor}/>
<path d="M8.84323 7.32323C8.56528 7.59923 8.56528 8.05075 8.84323 8.32674C8.99481 8.47725 9.17177 8.52748 9.34855 8.52748C9.52551 8.52748 9.70229 8.45222 9.85386 8.32674C10.7635 7.4235 12.2291 7.4235 13.1133 8.32674C13.3913 8.60274 13.846 8.60274 14.124 8.32674C14.4019 8.05074 14.4019 7.59922 14.124 7.32323C12.684 5.86801 10.3087 5.86801 8.84296 7.32323H8.84323Z" fill={theme.appTextColor}/>
<path d="M2 15.6804V20.8702C2 21.8107 2.74359 22.5757 3.65361 22.5757H19.4751C20.3851 22.5757 21.1287 21.8107 21.1287 20.8702L21.1289 15.6804H2ZM10.3638 20.4549H5.57659C5.35202 20.4549 5.17029 20.2678 5.17029 20.0369C5.17029 19.8061 5.35202 19.6189 5.57659 19.6189H10.3638C10.5884 19.6189 10.7701 19.8061 10.7701 20.0369C10.7701 20.2678 10.5884 20.4549 10.3638 20.4549ZM14.399 18.6356H5.57665C5.35207 18.6356 5.17034 18.4484 5.17034 18.2176C5.17034 17.9868 5.35207 17.7996 5.57665 17.7996H14.399C14.6236 17.7996 14.8053 17.9868 14.8053 18.2176C14.8053 18.4484 14.6236 18.6356 14.399 18.6356Z" fill={theme.appTextColor}/>
<path d="M21.1289 13.4568V11.7442C21.1289 10.8487 20.3853 10.1204 19.4753 10.1204H3.65363C2.7436 10.1204 2 10.8487 2 11.7442V13.4568H21.1289Z" fill={theme.appTextColor}/>
</svg>

    </Icon>
  )
}

export default ContactlessIcon
