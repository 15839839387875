import moment from "moment"
import { Text, View } from "native-base"
import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import { formatAmount, formatNegativeAmount } from "../../../../utils/functions"
import styles from "../../Accordion/styles"
import StatementAccordionBody from "../StatementAccordionBody/StatementAccordionBody"

const StatementAccordionsNew = ({ allStatements }) => {
  const [theme,config] = useSelector(state => [
    state.theme,
    state.config,
  ])

  let statements = []

  if (allStatements && allStatements.length > 0) {
    let size = allStatements.length
    //using only last statement, if more then 1 is coming
    statements.push(allStatements[size - 1])
  } else statements = null

  return config?.version === PwaVersions.V1 ? (
    <View {...styles.mainView}>
      {statements?.length > 0 ? (
        statements.map((statement, index) => (
          <View key={index}>
            <View {...styles.statementHeader}>
              <Text {...styles.periodText} fontFamily={theme.fontFamily}>
                {moment(statement.fromDate).format("Do MMM")} -{" "}
                {moment(statement.toDate).format("Do MMM")}
              </Text>
              <Text {...styles.periodAmount} fontFamily={theme.fontFamily}>
                {formatNegativeAmount(formatAmount(statement.totalAmountDue))}
              </Text>
            </View>

            {index !== statements.length - 1 && (
              <View borderBottomWidth={1} width='100%' borderColor='#ededed' />
            )}
            <StatementAccordionBody
              statement={statement}
              statementIndex={index}
            />
          </View>
        ))
      ) : (
        <View m='8px' alignItems='center' justifyContent='center'>
          <Text
            textAlign='center'
            fontFamily={theme.fontFamily}
            {...styles.emptyStateText}
          >
            No Statements found
          </Text>
        </View>
      )}
    </View>
  ) : (
    <View>
      {statements?.length > 0 ? (
        statements.map((statement, index) => (
          <View
            key={index}
            borderRadius={"12px"}
            borderWidth={"1px"}
            borderColor='#ECECEC'
            bgColor={theme.widgetBackgroundColor}
            shadow={"3"}
            mb='12px'
            mx='16px'
          >
            <View
              display={"flex"}
              alignItems='center'
              flexDir={"row"}
              justifyContent='space-between'
              py='16px'
              px='12px'
            >
              <Text
                fontWeight='400'
                fontSize='16px'
                lineHeight='22px'
                color={theme.appTextColor}
                fontFamily={theme.fontFamily}
              >
                {moment(statement.fromDate).format("Do MMM")} -{" "}
                {moment(statement.toDate).format("Do MMM")}
              </Text>
            </View>

            {index !== statements.length - 1 && (
              <View width='100%' borderColor='#ededed' />
            )}
            <StatementAccordionBody
              statement={statement}
              statementIndex={index}
            />
          </View>
        ))
      ) : (
        <View m='8px' alignItems='center' justifyContent='center'>
          <Text
            textAlign='center'
            fontFamily={theme.fontFamily}
            {...styles.emptyStateText}
            color={theme.appTextColor}
          >
            No Statements found
          </Text>
        </View>
      )}
    </View>
  )
}

export default StatementAccordionsNew
