import { ScrollView, Text, View } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  Issuers,
  PwaVersions
} from "../../utils/enums"
import { goToRedirectUrl, isIosDevice, postMessageToIos } from "../../utils/functions"
import { Header } from "../core"
import { ColorButton } from "../core/buttons"
import AttentionIcon from "../svg/attention"
import styles from "./styles"

const Blocked = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()

  const [session, theme, config, screen, user] = useSelector(state => [
    state.session,
    state.theme,
    state.config,
    state.screen,
    state.user,
  ])
  const [pageTitle, setPageTitle] = useState("")

  useEffect(() => {
    let title = ""
    let accountStatus = user.account.status
    if (accountStatus === "ACTIVE" || accountStatus === "DORMANT") {
      //move ahead
    } else {
      title = "Account Closed"
    }
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      ) ||
      (session.issuer !== Issuers.AU_BANK &&
        session.issuer !== Issuers.FEDERAL_BANK &&
        (accountStatus === "PENDING_CLOSURE" ||
          accountStatus === "CLOSED" ||
          accountStatus === "CHARGE_OFF"))
    ) {
      title = "Account Closed"
    } else if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.REGISTRATION_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.LOGIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      (session.issuer !== Issuers.AU_BANK &&
        session.issuer !== Issuers.FEDERAL_BANK &&
        (accountStatus === "SUSPENDED" || accountStatus === "FORCED_SUSPENDED"))
    ) {
      title = "Account / Card Blocked"
    }
    if (!title) {
      navigate("/")
    }
    setPageTitle(title)
  }, [])

  const description = screen.accountClosed
  const isMobileNumPresent = ()=>{
    if(description?.moblieNo)
      return "Or"
    else
      return null
  }
  const backHome = () => {
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }

  //width will be minimum of (412, windowWidth)
  let windowWidth = 412
  if (windowDimensions.width < windowWidth) {
    windowWidth = windowDimensions.width
  }

  return (
    <View {...styles.mainView}>
      <ScrollView>
        <Header
          marginBottom='0px'
          text='Attention'
          onBack={async () => {
            goToRedirectUrl()
          }}
        />
        <View
          {...styles.innerView}
          h={windowDimensions.height}
          minH={windowDimensions.height}
          bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
          minW={windowWidth}
        >
          <View {...styles.section1}>
            <View mt='30px'>
              <AttentionIcon />
              {/* <Image {...styles.image} source={{ uri: ErrorImage }} /> */}
            </View>
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}>
                <Text
                  {...styles.text1}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {pageTitle}
                </Text>
              </View>
              <View>
                <Text
                  {...styles.textSubTitle}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {description.descText}
                </Text>

                {description?.moblieNo ? (
                  <View>
                    <Text
                      {...styles.subsubHeading}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      You can call us on :
                    </Text>
                    <Text
                      {...styles.subsubHeading}
                      marginTop='0px'
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {description.moblieNo}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}

                {description?.email ? (
                  <View>
                    <Text
                      {...styles.subsubHeading}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {isMobileNumPresent()} Email us:
                    </Text>
                    <Text
                      {...styles.subsubHeading}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                      marginTop='0px'
                    >
                      {description.email}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
          {!config?.version == PwaVersions.V2 ? (
            <View {...styles.button} mt='25%'>
              <ColorButton text='Go Back' onPress={backHome} />
            </View>
          ) : null}
        </View>
      </ScrollView>
    </View>
  )
}

export default Blocked
