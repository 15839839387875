import moment from "moment"
import store from "../store/Store"
import {
  AU_BlockCodesMapping,
  Federal_BlockCodesMapping,
} from "./blockCodesMapping"
import { ProgramTypes, TransactionDateTypes } from "./constants"
import { AU_BlockCodes, Federal_BlockCodes, LogLevels } from "./enums"
import logger from "./logger"

const getLogAsString = (level, args) => {
  const dateObj = new Date()
  const date = dateObj.toLocaleDateString("en-CA")
  const time = dateObj.toLocaleTimeString()

  if (args.length > 0) {
    args[0] =
      typeof args[0] === "string" && args[0].startsWith("\n")
        ? args[0].substring(1)
        : args[0]
    args[0] = `${date} ${time} ${level} PWA ` + args[0]
  }

  return args
    .map(arg => {
      if (typeof arg === "string") return arg

      if (arg && arg.hasOwnProperty("toString")) return arg.toString()

      return JSON.stringify(arg)
    })
    .join(" ")
}

export const consoleLog = (...args) => {
  const log = getLogAsString(LogLevels.INFO, args)
  logger.info(log)
}

export const consoleError = (...args) => {
  const log = getLogAsString(LogLevels.ERROR, args)
  logger.error(log)
}

export const openLink = url => {
  const link = document.createElement("a")
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string

export const getTransactionDate = (transactionDate, postingDate) => {
  const programType = store.getState().user.programType
  const transactionDateType = store.getState().theme.transactionDateType

  // if txn type to show is posting date and posting date is not null then show posting date else txn date
  let date = transactionDate
  if (
    postingDate &&
    transactionDateType === TransactionDateTypes.POSTING_DATE
  ) {
    date = postingDate
  }

  const givenDate = moment(date).format("YYYY-MM-DD")
  const todayDate = moment().format("YYYY-MM-DD")
  const dateFormat =
    programType === ProgramTypes.CREDIT_CARD ? "DD MMM YYYY" : "MMM Do"

  // if not today
  if (givenDate !== todayDate) {
    return moment(date).format(dateFormat)
  }

  // if program is credit card
  if (programType === ProgramTypes.CREDIT_CARD) {
    return moment(date).format(dateFormat)
  } else {
    return "Today, " + moment(date).format("h:mm a")
  }
}

export const formatAmount = amount => {
  const preferences = store.getState().preferences
  return (
    // "₹ " +
    Math.abs(Number(amount)).toLocaleString(
      preferences.locales,
      preferences.localeOptions,
    )
  )
}

export const formatNegativeAmount = amount => {
  let s = amount
  if (s.includes("-")) {
    s = s.replace("-", "(")
    s = s.concat(")")
  }
  return s
}

export const formatCardNumber = num =>
  String(num)
    .match(/.{1,4}/g)
    ?.join(" ")

export const copyToClipboard = text => {
  const textArea = document.createElement("textarea")
  document.body.appendChild(textArea)
  textArea.value = text
  textArea.select()
  document.execCommand("copy")
  document.body.removeChild(textArea)
}

export const getApiConfig = apiToken => {
  const headers = {}

  if (apiToken) headers.apiToken = apiToken

  return { headers }
}

export const getLastDigitsOfMobileNumber = (n = "") => n.slice(-4)
export const getMaskedEmail = (n = "") => {
  return `${n.slice(0, 4)}*******@${n.split("@")[1]}`
}

export const setInputValue = (e, finalValue) => {
  const target = e.target
  const initValue = target.value

  // if the new char was rejected, cursor would have still moved by one so bring it back
  const selectionRange =
    target.selectionStart + finalValue?.length - initValue?.length

  // set value
  target.value = finalValue

  // setting a value different than the actual value moves cursor to end hence reset the cursor
  target.setSelectionRange(selectionRange, selectionRange)
}

// ios integration
export const isIosDevice = () =>
  Boolean(window?.webkit?.messageHandlers?.hyperfaceIosBridge)

export const postMessageToIos = action =>
  window?.webkit?.messageHandlers?.hyperfaceIosBridge?.postMessage(action)
// ios integration

export const getAnalyticsProgramType = programType => {
  if(programType === ProgramTypes.CREDIT_CARD)
    return "CC"
  else if(programType === ProgramTypes.PAY_LATER)
    return "PL"
  else if(programType === ProgramTypes.PREPAID)
    return "PPD"
  else
    return "Undefined Program Type"
}

export const goToRedirectUrl = () => {
  const session = store.getState().session

  if (isIosDevice()) {
    postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
  } else {
    window.location.href = session.redirectUrl
  }
}

export const getEnumsFromAU_BlockCodes = blockcodes => {
  let blockCodesMapping = AU_BlockCodesMapping
  let blockCodesEnums = AU_BlockCodes
  let disabledFeaturesByBlockCodes = []

  for(let currBlockCode of blockcodes){
    let currMappedArray = Object.keys(blockCodesMapping)
    currMappedArray.forEach(item => {
      if (blockCodesMapping[item].includes(currBlockCode)) {
        if(disabledFeaturesByBlockCodes.indexOf(blockCodesEnums[item]) === -1){
            disabledFeaturesByBlockCodes.push(blockCodesEnums[item])
        }
      }
    })
  }
  return disabledFeaturesByBlockCodes
}

export const getUnion = (array1, array2) => {
  let unionArray = array1
  for (let element of array2) {
    if (unionArray.indexOf(element) === -1) {
      unionArray.push(element)
    }
  }
  return unionArray
}
export async function retrieveHTMLFromTxt(url) {
  let headerFromFile = ""
  headerFromFile = fetch(url)
    .then(r => r.text())
    .then(text_content => {
      headerFromFile = text_content
      return headerFromFile
    })
    .catch(err => {
      console.log(err)
    })
  return headerFromFile
}

export const capitalizeFirsLetter = sentence => {
  sentence = sentence.trim()
  const wordsToSkip = ["DOM", "POS", "INTL", "XXXXXX", "GST", "EMI"]
  const words = sentence.split(" ")
  for (let i = 0; i < words.length; i++) {
    if (
      words[i] == "" ||
      wordsToSkip.includes(words[i]) ||
      words[i].includes("XXXXXX")
    ) {
      continue
    }
    words[i] = words[i].toLowerCase()
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  return words.join(" ")
}

export const isCardInactive = (cards, cardId) => {
  let cardInactive = false
  cards.map(card => {
    if (card.id === cardId) {
      if (card.cardStatus === "INACTIVE") {
        cardInactive = true
      }
    }
  })
  return cardInactive
}

export const getEnumsFromFederal_BlockCodes = blockcodes => {
  let blockCodesMapping = Federal_BlockCodesMapping
  let blockCodesEnums = Federal_BlockCodes
  let disabledFeaturesByBlockCodes = []

  for(let currBlockCode of blockcodes){
    let currMappedArray = Object.keys(blockCodesMapping)
    currMappedArray.forEach(item => {
      if (blockCodesMapping[item].includes(currBlockCode)) {
        if(disabledFeaturesByBlockCodes.indexOf(blockCodesEnums[item]) === -1){
          disabledFeaturesByBlockCodes.push(blockCodesEnums[item])
        }
      }
    })
  }
  return disabledFeaturesByBlockCodes
}
