const styles = {
  activateCardContainer: {
    w: "100%",
    px: "24px",
    py: "12px",
    borderRadius: "8px",
    borderWidth: "1px",

    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  activateCardHeading: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#303030",
    mr: "4px",
  },

  activateCardSubHeading: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16px",
    color: "#303030",
    opacity: "0.64",
    mr: "4px",
    mt: "4px",
  },
}

export default styles
