import {
  Box,
  ChevronLeftIcon,
  FormControl,
  Input,
  KeyboardAvoidingView,
  Slider,
  Stack,
  Switch,
  Text,
  View,
  WarningOutlineIcon,
  useToast,
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import {
  default as CurrencyFormat,
  default as NumberFormat,
} from "react-currency-format"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import CashImage from "../../../svg/cash"
import ContactlessImage from "../../../svg/contactless"
import OnlineImage from "../../../svg/online"
import PosImage from "../../../svg/pos"
import ApiIds from "../../../../auth/ApiIds"
import CardService from "../../../../services/CardService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { setdisabledFunctionsByBlockCode } from "../../../../store/actions/Session"
import { setCards } from "../../../../store/actions/User"
import * as analytics from "../../../../utils/analytics"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  INTEGER_REGEX,
  ProgramTypes,
} from "../../../../utils/constants"
import {
  AU_BlockCodes,
  CardPrimaryPeriodType,
  Issuers,
  KycStatus,
  PwaVersions,
} from "../../../../utils/enums"
import {
  consoleError,
  getAnalyticsProgramType,
} from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"
import CardControlsLoader from "../../../core/SkeletonLoader/CardControlsLoader"

const sliderSteps = "100"
const grayColor = "#a8a5a5"

// analytics
const getAnalyticsLocation = location => {
  if(location === "domestic")
    return "Dom"
  else if(location === "international")
    return "Intl"
  else
    return "Undefined Location"
}

const getAnalyticsKey = key => {
  if(key === "online")
    return "Online Transaction"
  else if(key === "offline")
    return "Offline Transaction"
  else if(key === "contactless")
    return "Contactless"
  else if(key === "cash")
    return "Cash Withdrawal"
  else
    return "Undefined Mode"
}

const trackBackClick = programType => {
  analytics.track(
    `${getAnalyticsProgramType(
      programType,
    )} - Click on back button (Card Controls)`,
  )
}

const trackCancelClick = programType => {
  analytics.track(
    `${getAnalyticsProgramType(programType)} - Click on Cancel (Card Controls)`,
  )
}

const trackCardStatus = (programType, cardStatus) => {
  analytics.track(
    `${getAnalyticsProgramType(programType)} - Click on Card Status`,
    {
      status: cardStatus ? "on" : "off",
    },
  )
}

const trackToggle = (programType, location, key, isEnabled, limit) => {
  const name = `${getAnalyticsProgramType(
    programType,
  )} - ${getAnalyticsLocation(location)} - ${getAnalyticsKey(key)}`

  analytics.track(name, {
    status: isEnabled ? "on" : "off",
    limit,
  })
}

const trackLimit = (programType, location, key, limit) => {
  const name = `${getAnalyticsProgramType(
    programType,
  )} - ${getAnalyticsLocation(location)} - limit - ${getAnalyticsKey(key)}`

  analytics.track(name, { limit: Number(limit) })
}

const getCardStatus = cardStatus => {
  return cardStatus ? "on" : "off"
}
const trackSaveClick = (programType, cardStatus, cardSetting) => {
  const isPaylaterOrPrepaidProgram =
    programType === ProgramTypes.PAY_LATER ||
    programType === ProgramTypes.PREPAID

  const name = `${getAnalyticsProgramType(
    programType,
  )} - Click on Save Changes (Card Controls)`

  let props = {
    "Card Status": getCardStatus(cardStatus),

    [getAnalyticsLocation("domestic")]: {
      [getAnalyticsKey("online")]: {
        status: cardSetting.domestic.online.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.online.dailyLimit),
      },
      [getAnalyticsKey("offline")]: {
        status: cardSetting.domestic.offline.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.offline.dailyLimit),
      },
      [getAnalyticsKey("contactless")]: {
        status: cardSetting.domestic.contactless.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.contactless.dailyLimit),
      },
    },
  }

  if (!isPaylaterOrPrepaidProgram) {
    props = {
      ...props,
      [getAnalyticsLocation("domestic")]: {
        ...props[getAnalyticsLocation("domestic")],
        [getAnalyticsKey("cash")]: {
          status: cardSetting.domestic.cash.isEnabled ? "on" : "off",
          limit: Number(cardSetting.domestic.cash.dailyLimit),
        },
      },
      [getAnalyticsLocation("international")]: {
        [getAnalyticsKey("online")]: {
          status: cardSetting.international.online.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.online.dailyLimit),
        },
        [getAnalyticsKey("offline")]: {
          status: cardSetting.international.offline.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.offline.dailyLimit),
        },
        [getAnalyticsKey("contactless")]: {
          status: cardSetting.international.contactless.isEnabled
            ? "on"
            : "off",
          limit: Number(cardSetting.international.contactless.dailyLimit),
        },
        [getAnalyticsKey("cash")]: {
          status: cardSetting.international.cash.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.cash.dailyLimit),
        },
      },
    }
  }

  analytics.track(name, props)
}

const CardSettings_v2 = () => {
  const nativeToast = useToast()
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()

  const dispatch = useDispatch()
  const [theme, user, common, config, session, screen] = useSelector(state => [
    state.theme,
    state.user,
    state.common,
    state.config,
    state.session,
    state.screen,
  ])
  const isPhysicalCardActivated = user?.cards[0]?.isPhysicalCardActivated
  const isVirtualCardActivated = user?.cards[0]?.isVirtualCardActivated
  const cardTypeVirtual = user?.cards[0]?.cardType === "Virtual"

  const onlyVirtual =
    isVirtualCardActivated && (cardTypeVirtual || !isPhysicalCardActivated)
  const notPhysicalAndVirtual =
    !isVirtualCardActivated && !isPhysicalCardActivated

  let primaryPeriodType = screen?.cardControls?.primaryPeriodType

  if (primaryPeriodType === CardPrimaryPeriodType.MONTHLY) {
    primaryPeriodType = "Monthly Transactions"
  } else if (primaryPeriodType === CardPrimaryPeriodType.TRANSACTIONAL) {
    primaryPeriodType = "Per Transaction"
  } else {
    primaryPeriodType = "Daily Transactions"
  }

  const kycStatus = user?.customer?.kycStatus

  const account = user.account
  const card = user.cards?.[0]

  let approvedCashLimit = account.approvedCashLimit
  let approvedCreditLimit = account.approvedCreditLimit

  // common store vars
  const { isCardSettingsSet, fetchCardData, fetchCardControlsData } = common

  const isAccountActive = account.status === "ACTIVE"

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isDomesticSelected, setIsDomesticSelected] = useState(true)

  const [disableAllControls, setDisableAllControls] = useState(false)

  const [cardStatus, setCardStatus] = useState(true)
  const [cardSetting, setCardSetting] = useState()
  let initCardSettings = useRef({})

  const isPaylaterOrPrepaidProgram =
    user.programType === ProgramTypes.PAY_LATER ||
    user.programType === ProgramTypes.PREPAID

  useEffect(() => {
    if (notPhysicalAndVirtual) {
      navigate("/", { replace: true })
      toast("Access has been blocked for Card Settings page")
      return false
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.UNBLOCK_CARD_DISABLE,
      )
    ) {
      toast("Access has been blocked for Card Settings page")
      navigate("/")
      return false
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      setDisableAllControls(true)
    }
  }, [])

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/")
    }
  }, [user.account.status, user.cards, navigate])

  useEffect(() => {
    if (notPhysicalAndVirtual) {
      navigate("/", { replace: true })
      toast("Access has been blocked for Card Settings page")
      return false
    }

    ;(async () => {
      if (!isCardSettingsSet) {
        // if state is not set in store

        // clear common store
        dispatch(clearCommonState())

        // call init apis
        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD,
          onAuthSuccess: onFetchCardAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get card details",
          mpinReason: "to get card details",
          toast,
          navigate,
        })
      } else {
        const isLocked = fetchCardData.isLocked
        const cardControl = fetchCardControlsData

        // get current config
        let initConfig = {
          domesticOnline: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
            )
          })[0],
          domesticOffline: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACT"
            )
          })[0],
          domesticContactless: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
            )
          })[0],
        }

        if (!isPaylaterOrPrepaidProgram) {
          initConfig = {
            ...initConfig,
            domesticCash: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
                item.labelsMap.MCC === "CASH" &&
                item.labelsMap.POS_ENTRY_MODE === undefined
              )
            })[0],
            internationalOnline: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
              )
            })[0],
            internationalOffline: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "CONTACT"
              )
            })[0],
            internationalContactless: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
              )
            })[0],
            internationalCash: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "CASH" &&
                item.labelsMap.POS_ENTRY_MODE === undefined
              )
            })[0],
          }
        }

        // save current config
        initCardSettings.current = {
          cardStatus: !isLocked,

          domestic: {
            online: {
              isEnabled: initConfig?.domesticOnline?.isEnabled,
              dailyLimit: initConfig?.domesticOnline?.dailyTxnLimit?.limit || 0,
            },
            offline: {
              isEnabled: initConfig.domesticOffline.isEnabled,
              dailyLimit: initConfig.domesticOffline.dailyTxnLimit?.limit || 0,
            },
            contactless: {
              isEnabled: initConfig.domesticContactless.isEnabled,
              dailyLimit:
                initConfig.domesticContactless.dailyTxnLimit?.limit || 0,
            },
          },
        }

        if (!isPaylaterOrPrepaidProgram) {
          initCardSettings.current.domestic.cash = {
            isEnabled: initConfig.domesticCash.isEnabled,
            dailyLimit: initConfig.domesticCash.dailyTxnLimit?.limit || 0,
          }

          initCardSettings.current.international = {
            online: {
              isEnabled: initConfig.internationalOnline.isEnabled,
              dailyLimit:
                initConfig.internationalOnline.dailyTxnLimit?.limit || 0,
            },
            offline: {
              isEnabled: initConfig.internationalOffline.isEnabled,
              dailyLimit:
                initConfig.internationalOffline.dailyTxnLimit?.limit || 0,
            },
            contactless: {
              isEnabled: initConfig.internationalContactless.isEnabled,
              dailyLimit:
                initConfig.internationalContactless.dailyTxnLimit?.limit || 0,
            },
            cash: {
              isEnabled: initConfig.internationalCash.isEnabled,
              dailyLimit:
                initConfig.internationalCash.dailyTxnLimit?.limit || 0,
            },
          }
        }

        // set lock in state
        setCardStatus(!fetchCardData.isLocked)

        // set settings in state
        setCardSetting(() => {
          const setting = {
            domestic: {
              online: {
                label: "Online Transaction",
                ...initCardSettings.current.domestic.online,
              },
              offline: {
                label: "Offline Transaction",
                ...initCardSettings.current.domestic.offline,
              },
              contactless: {
                label: "Contactless",
                ...initCardSettings.current.domestic.contactless,
              },
            },
          }

          if (!isPaylaterOrPrepaidProgram) {
            setting.domestic.cash = {
              label: "Cash Withdrawal",
              ...initCardSettings.current.domestic.cash,
            }

            setting.international = {
              online: {
                label: "Online Transaction",
                ...initCardSettings.current.international.online,
              },
              offline: {
                label: "Offline Transaction",
                ...initCardSettings.current.international.offline,
              },
              contactless: {
                label: "Contactless",
                ...initCardSettings.current.international.contactless,
              },
              cash: {
                label: "Cash Withdrawal",
                ...initCardSettings.current.international.cash,
              },
            }
          }

          return setting
        })
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaylaterOrPrepaidProgram, isCardSettingsSet])

  const haveCardControlsChanged = () => {
    return (
      // check domestic
      initCardSettings.current.domestic.online.isEnabled !==
        cardSetting.domestic.online.isEnabled ||
      initCardSettings.current.domestic.online.dailyLimit !==
        Number(cardSetting.domestic.online.dailyLimit) ||
      initCardSettings.current.domestic.offline.isEnabled !==
        cardSetting.domestic.offline.isEnabled ||
      initCardSettings.current.domestic.offline.dailyLimit !==
        Number(cardSetting.domestic.offline.dailyLimit) ||
      initCardSettings.current.domestic.contactless.isEnabled !==
        cardSetting.domestic.contactless.isEnabled ||
      initCardSettings.current.domestic.contactless.dailyLimit !==
        Number(cardSetting.domestic.contactless.dailyLimit) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.domestic.cash.isEnabled !==
          cardSetting.domestic.cash.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.domestic.cash.dailyLimit !==
          Number(cardSetting.domestic.cash.dailyLimit)) ||
      // check international
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.online.isEnabled !==
          cardSetting.international.online.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.online.dailyLimit !==
          Number(cardSetting.international.online.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.offline.isEnabled !==
          cardSetting.international.offline.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.offline.dailyLimit !==
          Number(cardSetting.international.offline.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.contactless.isEnabled !==
          cardSetting.international.contactless.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.contactless.dailyLimit !==
          Number(cardSetting.international.contactless.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.cash.isEnabled !==
          cardSetting.international.cash.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.cash.dailyLimit !==
          Number(cardSetting.international.cash.dailyLimit))
    )
  }

  const hasStateChanged = () => {
    if (
      !Object.keys(initCardSettings.current).length ||
      !Object.keys(cardSetting).length
    )
      return false

    return (
      // check lock
      initCardSettings.current.cardStatus !== cardStatus ||
      haveCardControlsChanged()
    )
  }

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const areControlsValid = () => {
    if (
      user.programType !== ProgramTypes.PREPAID &&
      approvedCreditLimit &&
      approvedCashLimit
    ) {
      if (
        (cardSetting.domestic.online.isEnabled &&
          Number(cardSetting.domestic.online.dailyLimit) >
            approvedCreditLimit) ||
        (cardSetting.domestic.offline.isEnabled &&
          Number(cardSetting.domestic.offline.dailyLimit) > approvedCreditLimit)
      ) {
        toast("Transaction limit cannot exceed " + approvedCreditLimit)
        return false
      }
      if (
        cardSetting.domestic.cash.isEnabled &&
        Number(cardSetting.domestic.cash.dailyLimit) > approvedCashLimit
      ) {
        toast("Cash Transaction limit cannot exceed " + approvedCashLimit)
        return false
      }
      if (
        (cardSetting.international.online.isEnabled &&
          Number(cardSetting.international.online.dailyLimit) >
            approvedCreditLimit) ||
        (cardSetting.international.offline.isEnabled &&
          Number(cardSetting.international.offline.dailyLimit) >
            approvedCreditLimit)
      ) {
        toast("Transaction limit cannot exceed " + approvedCreditLimit)
        return false
      }
      if (
        cardSetting.international.cash.isEnabled &&
        Number(cardSetting.international.cash.dailyLimit) > approvedCashLimit
      ) {
        toast("Cash Transaction limit cannot exceed " + approvedCashLimit)
        return false
      }

      if (
        cardSetting.international.contactless.isEnabled &&
        Number(cardSetting.international.contactless.dailyLimit) >
          approvedCreditLimit
      ) {
        toast("Contactless limit cannot exceeed " + approvedCreditLimit)
        return false
      }

      if (
        cardSetting.domestic.contactless.isEnabled &&
        Number(cardSetting.domestic.contactless.dailyLimit) >
          approvedCreditLimit
      ) {
        toast("Contactless limit cannot exceeed " + approvedCreditLimit)
        return false
      }
    } else if (
      user.programType === ProgramTypes.PREPAID ||
      user.programType === ProgramTypes.PAY_LATER
    ) {
      let transLimit = 10000
      if (kycStatus === KycStatus.FULL_KYC) {
        transLimit = 200000
      }

      if (
        cardSetting.domestic.online.isEnabled &&
        Number(cardSetting.domestic.online.dailyLimit) > transLimit
      ) {
        toast("Transaction limit cannot exceed " + transLimit)
        return false
      }
      if (
        cardSetting.domestic.offline.isEnabled &&
        Number(cardSetting.domestic.offline.dailyLimit) > transLimit
      ) {
        toast("Transaction limit cannot exceed  " + transLimit)
        return false
      }
      if (
        cardSetting.domestic.contactless.isEnabled &&
        Number(cardSetting.domestic.contactless.dailyLimit) > transLimit
      ) {
        toast("Contactless limit cannot exceeed " + transLimit)
        return false
      }
    }

    if (Number(cardSetting.domestic.online.dailyLimit) % 100 !== 0) {
      toast("Limit must be a multiple of 100")
      return false
    }

    if (Number(cardSetting.domestic.offline.dailyLimit) % 100 !== 0) {
      toast("Limit must be a multiple of 100")
      return false
    }

    if (Number(cardSetting.domestic.contactless.dailyLimit) % 100 !== 0) {
      toast("Limit must be a multiple of 100")
      return false
    }

    if (!isPaylaterOrPrepaidProgram) {
      if (Number(cardSetting.domestic.cash.dailyLimit) % 100 !== 0) {
        toast("Limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.online.dailyLimit) % 100 !== 0) {
        toast("Limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.offline.dailyLimit) % 100 !== 0) {
        toast("Limit must be a multiple of 100")
        return false
      }

      if (
        Number(cardSetting.international.contactless.dailyLimit) % 100 !==
        0
      ) {
        toast("Limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.cash.dailyLimit) % 100 !== 0) {
        toast("Limit must be a multiple of 100")
        return false
      }
    }

    // check if card is unlocked but none of the toggles are enabled
    let areAllNonCCControlsDisabled =
      !cardSetting.domestic.online.isEnabled &&
      !cardSetting.domestic.offline.isEnabled &&
      !cardSetting.domestic.contactless.isEnabled
    let areAllCCControlsDisabled = false
    if (!isPaylaterOrPrepaidProgram) {
      areAllCCControlsDisabled =
        !cardSetting.domestic.cash.isEnabled &&
        !cardSetting.international.online.isEnabled &&
        !cardSetting.international.offline.isEnabled &&
        !cardSetting.international.contactless.isEnabled &&
        !cardSetting.international.cash.isEnabled
    }

    if (
      disableAllControls === false &&
      cardStatus &&
      ((isPaylaterOrPrepaidProgram && areAllNonCCControlsDisabled) ||
        (!isPaylaterOrPrepaidProgram &&
          areAllNonCCControlsDisabled &&
          areAllCCControlsDisabled))
    ) {
      toast("Please enable at least one setting")
      return false
    }

    return true
  }

  const handleSubmitClick = async () => {
    if (!areControlsValid()) return

    trackSaveClick(user.programType, cardStatus, cardSetting)
    setSubmitLoading(true)

    if (initCardSettings.current.cardStatus !== cardStatus) {
      // if lock status has changed
      // first call lock api, then its callback will call card controls api
      await handleApiAuth({
        apiId: cardStatus ? ApiIds.UNLOCK_CARD : ApiIds.LOCK_CARD,
        onAuthSuccess: onLockAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: "to change card status",
        mpinReason: "to change card status",
        toast,
        navigate,
      })
    } else {
      // if lock status didn't change and user was shown Save button then card controls must have changed
      // directly update card controls
      await handleApiAuth({
        apiId: ApiIds.UPDATE_CARD_CONTROLS,
        flowId: "3",
        onAuthSuccess: onControlsAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: "to update card controls",
        mpinReason: "to update card controls",
        toast,
        navigate,
      })
    }

    setSubmitLoading(false)
  }

  const onFetchCardAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardDetails(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        dispatch(setCommonState({ fetchCardData: result.data }))

        // call api for fetching card controls
        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD_CONTROLS,
          onAuthSuccess: onFetchCardControlsAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get card controls",
          mpinReason: "to get card controls",
          toast,
          navigate,
        })
      } else {
        toast("Error occurred while loading the page, Try again")
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      toast("Error occurred while loading the page, Try again")
      consoleError(error)
      setError(true)
    }
  }

  const onFetchCardControlsAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardControls(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        // set flag and result in store
        dispatch(
          setCommonState({
            isCardSettingsSet: true,
            fetchCardControlsData: result.data,
          }),
        )

        // navigate back to this screen
        // if already on this screen (in case auth wasn't required) then navigate won't have any effect
        //only if allowed by block codes
        if (
          (session.disabledFunctionsByBlockCode.includes(
            AU_BlockCodes.CARD_DASHBOARD_DISABLE,
          ) ||
            session.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.UNBLOCK_CARD_DISABLE,
            )) === false
        )
          navigate("/ManageCard/CardSettings", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onLockAuthSuccess = async apiToken => {
    try {
      const response = cardStatus
        ? await CardService.unlockCard(apiToken, user.cards[0]?.id)
        : await CardService.lockCard(apiToken, user.cards[0]?.id)
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...card }
        tempCard.isLocked = !cardStatus

        let disabledFunctions = session?.disabledFunctionsByBlockCode
        if (tempCard?.isLocked) {
          if (session?.issuer === Issuers.AU_BANK) {
            let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
            if (index === -1) {
              disabledFunctions.push(AU_BlockCodes.CARD_LOCKED)
            }
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )
          }
        } else {
          if (session?.issuer === Issuers.AU_BANK) {
            let index = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
            if (index > -1) {
              disabledFunctions.splice(index, 1)
            }
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )
          }
        }

        dispatch(setCards({ cards: [tempCard] }))

        if (haveCardControlsChanged()) {
          // call api for updating card controls
          await handleApiAuth({
            apiId: ApiIds.UPDATE_CARD_CONTROLS,
            flowId: "3",
            onAuthSuccess: onControlsAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to update card controls",
            mpinReason: "to update card controls",
            toast,
            navigate,
          })
        } else {
          toast("Card settings updated successfully", true)
          navigate("/", { replace: true })
        }
      } else {
        consoleError(result.errors)
        toast("Unable to update Card Controls. Please retry after some time")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry, an error occurred while updating card status")
      navigate("/", { replace: true })
    }
  }

  const onControlsAuthSuccess = async apiToken => {
    const controls = []

    if (
      initCardSettings.current.domestic.online.isEnabled !==
        cardSetting.domestic.online.isEnabled ||
      initCardSettings.current.domestic.online.dailyLimit !==
        Number(cardSetting.domestic.online.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "ECOMMERCE",
        },
        isEnabled:
          Number(cardSetting.domestic.online.dailyLimit) > 0 &&
          cardSetting.domestic.online.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.online.dailyLimit) > 0 &&
          cardSetting.domestic.online.isEnabled
            ? Number(cardSetting.domestic.online.dailyLimit)
            : null,
      })
    }

    if (
      initCardSettings.current.domestic.offline.isEnabled !==
        cardSetting.domestic.offline.isEnabled ||
      initCardSettings.current.domestic.offline.dailyLimit !==
        Number(cardSetting.domestic.offline.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACT",
        },
        isEnabled:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline.isEnabled
            ? Number(cardSetting.domestic.offline.dailyLimit)
            : null,
      })

      // need to set same config for magnetic
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "MAGNETIC",
        },
        isEnabled:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.offline.dailyLimit) > 0 &&
          cardSetting.domestic.offline.isEnabled
            ? Number(cardSetting.domestic.offline.dailyLimit)
            : null,
      })
    }

    if (
      initCardSettings.current.domestic.contactless.isEnabled !==
        cardSetting.domestic.contactless.isEnabled ||
      initCardSettings.current.domestic.contactless.dailyLimit !==
        Number(cardSetting.domestic.contactless.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACTLESS",
        },
        isEnabled:
          Number(cardSetting.domestic.contactless.dailyLimit) > 0 &&
          cardSetting.domestic.contactless.isEnabled,
        dailyTxnLimit:
          Number(cardSetting.domestic.contactless.dailyLimit) > 0 &&
          cardSetting.domestic.contactless.isEnabled
            ? Number(cardSetting.domestic.contactless.dailyLimit)
            : null,
      })
    }

    if (!isPaylaterOrPrepaidProgram) {
      if (
        initCardSettings.current.domestic.cash.isEnabled !==
          cardSetting.domestic.cash.isEnabled ||
        initCardSettings.current.domestic.cash.dailyLimit !==
          Number(cardSetting.domestic.cash.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "DOMESTIC",
            txnType: "CASH",
          },
          isEnabled:
            Number(cardSetting.domestic.cash.dailyLimit) > 0 &&
            cardSetting.domestic.cash.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.domestic.cash.dailyLimit) > 0 &&
            cardSetting.domestic.cash.isEnabled
              ? Number(cardSetting.domestic.cash.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.online.isEnabled !==
          cardSetting.international.online.isEnabled ||
        initCardSettings.current.international.online.dailyLimit !==
          Number(cardSetting.international.online.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE",
          },
          isEnabled:
            Number(cardSetting.international.online.dailyLimit) > 0 &&
            cardSetting.international.online.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.online.dailyLimit) > 0 &&
            cardSetting.international.online.isEnabled
              ? Number(cardSetting.international.online.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.offline.isEnabled !==
          cardSetting.international.offline.isEnabled ||
        initCardSettings.current.international.offline.dailyLimit !==
          Number(cardSetting.international.offline.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "CONTACT",
          },
          isEnabled:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline.isEnabled
              ? Number(cardSetting.international.offline.dailyLimit)
              : null,
        })

        // need to set same config for magnetic
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "MAGNETIC",
          },
          isEnabled:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.offline.dailyLimit) > 0 &&
            cardSetting.international.offline.isEnabled
              ? Number(cardSetting.international.offline.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.contactless.isEnabled !==
          cardSetting.international.contactless.isEnabled ||
        initCardSettings.current.international.contactless.dailyLimit !==
          Number(cardSetting.international.contactless.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "CONTACTLESS",
          },
          isEnabled:
            Number(cardSetting.international.contactless.dailyLimit) > 0 &&
            cardSetting.international.contactless.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.contactless.dailyLimit) > 0 &&
            cardSetting.international.contactless.isEnabled
              ? Number(cardSetting.international.contactless.dailyLimit)
              : null,
        })
      }

      if (
        initCardSettings.current.international.cash.isEnabled !==
          cardSetting.international.cash.isEnabled ||
        initCardSettings.current.international.cash.dailyLimit !==
          Number(cardSetting.international.cash.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "CASH",
          },
          isEnabled:
            Number(cardSetting.international.cash.dailyLimit) > 0 &&
            cardSetting.international.cash.isEnabled,
          dailyTxnLimit:
            Number(cardSetting.international.cash.dailyLimit) > 0 &&
            cardSetting.international.cash.isEnabled
              ? Number(cardSetting.international.cash.dailyLimit)
              : null,
        })
      }
    }

    const response = await CardService.updateCardControls(
      apiToken,
      user.cards[0]?.id,
      {
        updateCardControls: controls,
      },
    )
    const result = response?.data

    if (result?.success) {
      dispatch(setCards({ cards: [result.data] }))

      toast("Card settings updated successfully", true)
      navigate("/", { replace: true })
    } else {
      consoleError(result.errors)

      const limitExceedErrors =
        result.errors?.filter(error =>
          error.reason.includes("dailyTxnLimit cannot exceed"),
        ) || []

      if (limitExceedErrors.length > 0) {
        const errorMsg = limitExceedErrors[0].reason.replace(
          "dailyTxnLimit",
          "Transaction limit",
        )
        toast(errorMsg)
      } else {
        toast("An error occurred while updating card settings")
      }

      navigate("/ManageCard/CardSettings", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    toast(message ? message : "Oops an internal error occurred!!")
    navigate("/ManageCard", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/ManageCard", { replace: true })
  }

  const getImageIcon = control => {
    if (control.label === "Online Transaction") {
      return <OnlineImage />
    } else if (control.label === "Offline Transaction") {
      return <PosImage />
    } else if (control.label === "Contactless") {
      return <ContactlessImage />
    } else if (control.label === "Cash Withdrawal") {
      return <CashImage />
    }
  }
  const getMaxLimit = (control)=>{
    if( isPaylaterOrPrepaidProgram){
      if(kycStatus === KycStatus.SMALL_KYC){
        return 10000
      }
      else
        return 200000
    }
    else{
      if(control.label === "Cash Withdrawal")
        return approvedCashLimit
      else
        return approvedCreditLimit
    }
  }
  const getSettings = location => {
    // location can be domestic or international
    return (
      <View>
        {Object.entries(cardSetting[location]).map(([key, control], index) => {
          // key can be online, cash etc.
          return (
            <View
              shadow={2}
              key={key}
              borderBottomColor='#DAD3D3'
              borderBottomWidth={1}
              {...styles.cardSettingContainer}
              borderRadius='0px'
              borderBottomLeftRadius={
                index + 1 == Object.keys(cardSetting[location]).length
                  ? "8px"
                  : "0px"
              }
              borderBottomRightRadius={
                index + 1 == Object.keys(cardSetting[location]).length
                  ? "8px"
                  : "0px"
              }
              mb='0'
              backgroundColor={theme.widgetBackgroundColor}
            >
              <View
                {...styles.onlineTransaction}
                {...styles.commonStyles}
                paddingTop='4px'
              >
                <View flex='1'>{getImageIcon(control)}</View>

                <Text
                  flex='10'
                  {...styles.cardStatusText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                  fontFamily={theme.fontFamily}
                >
                  {control.label}
                </Text>
                <Switch
                  {...styles.cardControlSwitch}
                  offTrackColor={grayColor}
                  isDisabled={
                    (disableAllControls &&
                      (cardStatus || disableAllControls)) ||
                    ((user.programType === ProgramTypes.PREPAID ||
                      getVersionedDisplay()) &&
                      isAccountActive &&
                      card &&
                      !card.isHotlisted &&
                      onlyVirtual &&
                      control.label !== "Online Transaction")
                  }
                  isChecked={
                    cardStatus &&
                    control.isEnabled &&
                    !(onlyVirtual && control.label !== "Online Transaction")
                  }
                  onTrackColor={
                    getVersionedDisplay() ? theme.color1 : theme.color2
                  }
                  size='md'
                  onToggle={() => {
                    cardStatus &&
                      setCardSetting(currCardSetting => ({
                        ...currCardSetting,
                        [location]: {
                          ...currCardSetting[location],
                          [key]: {
                            ...currCardSetting[location][key],
                            isEnabled:
                              !currCardSetting[location][key].isEnabled,
                          },
                        },
                      }))

                    trackToggle(
                      user.programType,
                      location,
                      key,
                      !cardSetting[location][key].isEnabled, // pass reverse of current toggle state
                      cardSetting[location][key].dailyLimit,
                    )
                  }}
                />
              </View>
              {!disableAllControls && (
                <>
                  <View>
                    <Stack w='40%' maxW='200px' mx='auto'>
                      <CurrencyFormat
                        isAllowed={values =>
                          values?.value <=
                          (getMaxLimit(control))
                        }
                        allowNegative={false}
                        decimalScale={0}
                        size='md'
                        placeholder='NA'
                        textAlign={"center"}
                        thousandSpacing='2s'
                        thousandSeparator={true}
                        prefix={"₹ "}
                        fontWeight='bold'
                        color={
                          !control.isEnabled ||
                          !cardStatus ||
                          (control.label !== "Online Transaction" &&
                            onlyVirtual)
                            ? `${theme.appTextColor}:alpha.70`
                            : theme.appTextColor
                        }
                        fontSize='18px'
                        variant='underlined'
                        customInput={Input}
                        placeholderTextColor={`${theme.appTextColor}`}
                        isReadOnly={
                          !control.isEnabled ||
                          !cardStatus ||
                          (control.label !== "Online Transaction" &&
                            onlyVirtual)
                        }
                        value={
                          !control.isEnabled ||
                          !cardStatus ||
                          (control.label !== "Online Transaction" &&
                            onlyVirtual)
                            ? "NA"
                            : control.dailyLimit
                        }
                        onValueChange={values => {
                          let amount = values?.value

                          //Restricting entering negative amount
                          if ((amount + "").charAt(0) === "-") {
                            amount = amount * -1
                          }

                          if (amount.length > 9) return false
                          if (INTEGER_REGEX.test(amount)) {
                            setCardSetting(currCardSetting => ({
                              ...currCardSetting,
                              [location]: {
                                ...currCardSetting[location],
                                [key]: {
                                  ...currCardSetting[location][key],
                                  dailyLimit: Number(amount),
                                },
                              },
                            }))

                            trackLimit(
                              user.programType,
                              location,
                              key,
                              amount, // pass amount
                            )
                          }
                        }}
                      />
                    </Stack>
                    <Box mt='5px' w='100%'>
                      <View w='76%'>
                        <View flexDirection={"row"} width='100%'>
                          <Text
                            color={
                              !control.isEnabled ||
                              !cardStatus ||
                              (control.label !== "Online Transaction" &&
                                onlyVirtual)
                                ? `${theme.appTextColor}:alpha.70`
                                : theme.appTextColor
                            }
                            mr='10px'
                          >
                            Min.
                          </Text>
                          <FormControl>
                            <Slider
                              isReadOnly={
                                !cardStatus ||
                                (control.label !== "Online Transaction" &&
                                  onlyVirtual)
                              }
                              isDisabled={
                                !control.isEnabled ||
                                !cardStatus ||
                                (control.label !== "Online Transaction" &&
                                  onlyVirtual)
                              }
                              onChange={amount => {
                                // if entered character is valid
                                if (amount.length > 9) return false
                                if (INTEGER_REGEX.test(amount)) {
                                  setCardSetting(currCardSetting => ({
                                    ...currCardSetting,
                                    [location]: {
                                      ...currCardSetting[location],
                                      [key]: {
                                        ...currCardSetting[location][key],
                                        dailyLimit: amount,
                                      },
                                    },
                                  }))

                                  trackLimit(
                                    user.programType,
                                    location,
                                    key,
                                    amount, // pass amount
                                  )
                                }
                              }}
                              minValue={0}
                              maxValue={
                                getMaxLimit(control)
                              }
                              value={control.dailyLimit}
                              defaultValue={control.dailyLimit}
                              step={sliderSteps}
                            >
                              <Slider.Track
                                backgroundColor={
                                    grayColor
                                }
                              >
                                <Slider.FilledTrack
                                  backgroundColor={
                                    !control.isEnabled ||
                                    !cardStatus ||
                                    (control.label !== "Online Transaction" &&
                                      onlyVirtual)
                                      ? grayColor
                                      : theme.color1
                                  }
                                />
                              </Slider.Track>
                              <Slider.Thumb
                                style={
                                  isPaylaterOrPrepaidProgram
                                    ? control.dailyLimit >
                                      (kycStatus === KycStatus.SMALL_KYC
                                        ? 10000
                                        : 200000)
                                      ? { left: "100%" }
                                      : {}
                                    : control.dailyLimit >
                                      (control.label === "Cash Withdrawal"
                                        ? approvedCashLimit
                                        : approvedCreditLimit)
                                    ? { left: "100%" }
                                    : {}
                                }
                                backgroundColor={"white"}
                                shadow={4}
                                display={
                                  !control.isEnabled ||
                                  !cardStatus ||
                                  (control.label !== "Online Transaction" &&
                                    onlyVirtual)
                                    ? "none"
                                    : ""
                                }
                              />
                            </Slider>
                            <FormControl.ErrorMessage
                              leftIcon={<WarningOutlineIcon size='xs' />}
                            >
                              Something is wrong.
                            </FormControl.ErrorMessage>
                          </FormControl>
                          <Text
                            color={
                              !control.isEnabled ||
                              !cardStatus ||
                              (control.label !== "Online Transaction" &&
                                onlyVirtual)
                                ? `${theme.appTextColor}:alpha.70`
                                : theme.appTextColor
                            }
                            ml='10px'
                          >
                            Max.
                          </Text>
                        </View>
                      </View>
                    </Box>
                    <View flexDirection={"row"} justifyContent='space-between'>
                      <Text
                        color={
                          !control.isEnabled ||
                          !cardStatus ||
                          (control.label !== "Online Transaction" &&
                            onlyVirtual)
                            ? `${theme.appTextColor}:alpha.70`
                            : theme.appTextColor
                        }
                      >
                        <NumberFormat
                          flex='1'
                          value={"0"}
                          displayType={"text"}
                          thousandSpacing='2s'
                          thousandSeparator={true}
                          prefix={"₹"}
                        />
                      </Text>
                      <Text
                        mr='-5px'
                        color={
                          !control.isEnabled ||
                          !cardStatus ||
                          (control.label !== "Online Transaction" &&
                            onlyVirtual)
                            ? `${theme.appTextColor}:alpha.70`
                            : theme.appTextColor
                        }
                      >
                        <NumberFormat
                          flex='1'
                          value={
                            getMaxLimit(control)
                          }
                          displayType={"text"}
                          thousandSpacing='2s'
                          thousandSeparator={true}
                          prefix={"₹"}
                        />
                      </Text>
                    </View>
                    {control.label === "Contactless" &&
                    control.isEnabled &&
                    cardStatus &&
                    !onlyVirtual ? (
                      <View
                        mt='10px'
                        borderRadius='4px'
                        backgroundColor={`${theme.color1}:alpha.10`}
                      >
                        <Text
                          color={theme.appTextColor}
                          p='5px'
                          textAlign={"center"}
                        >
                          Transactions upto ₹5000 will not require PIN
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>
                </>
              )}
            </View>
          )
        })}
      </View>
    )
  }

  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }

  return (
    // not using scrollview as react-native-web adds adds an extra div for it
    <View backgroundColor='#F8F8F8' {...styles.expand} minH={window.height}>
      {loading ? (
        <View
          w='100%'
  
     
          height={windowDim.height}
          style={{
            backgroundColor: `${
              config.version === PwaVersions.V2
                ? theme.backgroundColor
                : "white"
            }`,
          }}
        >
          <CardControlsLoader/>
        </View>
      ) : error ? (
        <ErrorScreen />
      ) : (
        <View
          {...styles.expand}
          minH={windowDim.height}
          _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
          py={!getVersionedDisplay() ? "10" : "0"}
          px={!getVersionedDisplay() ? "18px" : "0"}
        >
          <KeyboardAvoidingView>
            <View {...styles.expand}>
              <View alignItems='center' justifyContent='center'>
                <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
                  {config?.version === PwaVersions.V2 ? (
                    <Header
                      text='Card Controls'
                      onBack={async () => {
                        window.history.go(-1)
                      }}
                    />
                  ) : (
                    <View
                      {...styles.header}
                      w='100%'
                      bgColor={
                        getVersionedDisplay()
                          ? theme.headerBackgroundColor
                          : null
                      }
                      py={getVersionedDisplay() ? "18px" : "0"}
                      px={getVersionedDisplay() ? "18px" : "0"}
                    >
                      <Link
                        to='/ManageCard'
                        onClick={() => {
                          trackBackClick(user.programType)
                        }}
                      >
                        <ChevronLeftIcon
                          color={
                            getVersionedDisplay() ? theme.titleColor : "#303030"
                          }
                          size='24px'
                        />
                      </Link>
                      <Text
                        {...styles.cardCotnrolText}
                        fontFamily={theme.fontFamily}
                        color={
                          getVersionedDisplay() ? theme.titleColor : "#303030"
                        }
                      >
                        Card Controls
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              <View
                alignItems='center'
                justifyContent='center'
                px={getVersionedDisplay() ? "18px" : "0"}
              >
                <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
                  <View
                    {...styles.cardStatus}
                    bgColor={
                      getVersionedDisplay()
                        ? theme.widgetBackgroundColor
                        : "#FFFFFF"
                    }
                    borderWidth={"1px"}
                    borderColor='#ECECEC'
                    shadow={2}
                  >
                    <Text
                      {...styles.cardStatusText}
                      fontFamily={theme.fontFamily}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      Card Status
                    </Text>
                    <Switch
                      {...styles.cardControlSwitch}
                      isChecked={cardStatus}
                      onTrackColor={
                        getVersionedDisplay() ? theme.color1 : theme.color2
                      }
                      size='md'
                      onToggle={() => {
                        setCardStatus(!cardStatus)
                        trackCardStatus(user.programType, !cardStatus)
                      }}
                    />
                  </View>

                  <View
                    borderWidth={"1px"}
                    borderColor='#ECECEC'
                    shadow={2}
                    {...styles.cardStatus}
                    backgroundColor={theme.widgetBackgroundColor}
                    paddingTop='10px'
                    paddingBottom='10px'
                  >
                    <Text
                      fontWeight={500}
                      fontFamily={theme.fontFamily}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      Set your {primaryPeriodType} Limit
                    </Text>
                    {/* <View {...styles.notesStrip}  backgroundColor={theme.color1}>
                      <Text color={theme.color2} px="11px" pb="4px">
                        Note
                      </Text>
                    </View> */}
                  </View>
                </View>
              </View>
              <View>
                <View w='100%' px='18px'>
                  {!isPaylaterOrPrepaidProgram ? (
                    <View
                      borderTopLeftRadius={"6px"}
                      borderTopRightRadius='6px'
                      backgroundColor={"white"}
                      textAlign={"center"}
                      flexDirection={"row"}
                    >
                      <View
                        style={
                          isDomesticSelected ? styles.domIntlSelected : null
                        }
                        p='16px'
                        flex='1'
                        borderTopLeftRadius='6px'
                        borderBottomColor={
                          cardStatus ? theme.color1 : grayColor
                        }
                        backgroundColor={
                          getVersionedDisplay()
                            ? !cardStatus
                              ? grayColor
                              : isDomesticSelected
                              ? `${theme.color1}:alpha.30`
                              : `#FFFFFF`
                            : null
                        }
                        onClick={() => setIsDomesticSelected(true)}
                        cursor='pointer'
                      >
                        <Text
                          fontWeight={"bold"}
                          color={
                            getVersionedDisplay()
                              ? !cardStatus
                                ? theme.appTextColor
                                : theme.color1
                              : null
                          }
                        >
                          Domestic
                        </Text>
                      </View>

                      <View
                        style={
                          !isDomesticSelected ? styles.domIntlSelected : null
                        }
                        flex='1'
                        p='16px'
                        borderTopRightRadius={"6px"}
                        borderBottomColor={
                          cardStatus ? theme.color1 : grayColor
                        }
                        backgroundColor={
                          getVersionedDisplay()
                            ? !cardStatus
                              ? grayColor
                              : isDomesticSelected
                              ? `#FFFFFF`
                              : `${theme.color1}:alpha.30`
                            : null
                        }
                        onClick={() => setIsDomesticSelected(false)}
                        cursor='pointer'
                      >
                        <Text
                          fontWeight={"bold"}
                          color={
                            getVersionedDisplay()
                              ? !cardStatus
                                ? theme.appTextColor
                                : theme.color1
                              : null
                          }
                        >
                          International
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              </View>

              <View p='18px' pt='0px'>
                <View backgroundColor={"gray.200"} borderBottomRadius={"6px"}>
                  {isDomesticSelected
                    ? getSettings("domestic")
                    : getSettings("international")}
                </View>
              </View>

              {/* dummy div to expand and push below div to bottom */}
              <View {...styles.expand}></View>
              {hasStateChanged() && (
                <View
                  alignItems='center'
                  justifyContent='center'
                  w='100%'
                  px={getVersionedDisplay() ? "18px" : "0"}
                >
                  <View
                    {...styles.button}
                    _web={{ maxW: DisplayConfig.MAX_WIDTH }}
                  >
                    <ColorButton
                      text='Save Changes'
                      isLoading={submitLoading}
                      isDisabled={false}
                      onPress={handleSubmitClick}
                    />
                    {!config?.version == PwaVersions.V2 ? (
                      <WhiteButton
                        text='Cancel'
                        onPress={() => {
                          navigate("/")
                          trackCancelClick(user.programType)
                        }}
                      />
                    ) : null}
                  </View>
                </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </View>
      )}
    </View>
  )
}

export default CardSettings_v2
