import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useWindowDimensions } from "react-native"
import { View, Text, Input, Link, useToast } from "native-base"
import { WhiteButton, ColorButton } from "../../core/buttons"
import styles from "./styles"
import CommonService from "../../../services/CommonService"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import Loader from "../../core/loader"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import {
  AuthChallenges,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
  OtpErrors,
} from "../../../utils/constants"
import {
  getLastDigitsOfMobileNumber,
  getAnalyticsProgramType,
  goToRedirectUrl,
} from "../../../utils/functions"
import Info from "../../svg/info"
import Lock from "../../svg/lock"
import { showToast1 } from "../../core/toast"
import * as analytics from "../../../utils/analytics"
import { Header } from "../../core"
import { PwaVersions } from "../../../utils/enums"

const intervalTime = 60

const Otp_v1 = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const [theme, user, authStore, config] = useSelector(state => [
    state.theme,
    state.user,
    state.auth,
    state.config,
  ])

  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [resendCount, setResendCount] = useState(0)
  const [seconds, setSeconds] = useState(intervalTime)
  const [pin, setPin] = useState(["", "", "", ""])

  const interval = useRef(null)
  const otpId = useRef("")
  const otp0 = useRef(null)
  const otp1 = useRef(null)
  const otp2 = useRef(null)
  const otp3 = useRef(null)

  const showHeader = config?.version === PwaVersions.V2
  const headerText = getHeaderText(AuthChallenges.OTP_SMS)

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  useEffect(() => {
    if (seconds < 1) {
      clearInterval(interval.current)
    }
  }, [seconds])

  useEffect(() => {
    ;(async () => {
      try {
        // generate otp for token and scope
        const response = await CommonService.generateChallengeOTP({
          apiToken: authStore.apiToken,
          challengeScope: authStore.currentFactor.scope,

          mobileNumber: user.customer.mobileNumber,
          mobileNumberCountryCode: user.customer.mobileCountryCode,
        })
        const result = response.data

        if (result?.success) {
          otpId.current = result.data.mobileVerificationRefId

          setSeconds(intervalTime)
          interval.current = setInterval(() => {
            setSeconds(seconds => seconds - 1)
          }, 1000)
        } else {
          // if otp generation failed call failure callback
          if (
            result?.errors?.status === OtpErrors.RETRIES_EXCEEDED ||
            result?.errors?.status === OtpErrors.BLOCKED_TEMPORARILY
          ) {
            const retryTime = result?.errors?.failureReason?.match(
              /Please retry after (.*)./,
            )?.[1]

            await authStore.onAuthFailure(
              result?.errors?.status,
              `OTP retries exceeded.\nPlease retry after ${
                retryTime || "some time"
              }.`,
            )
          } else {
            await authStore.onAuthFailure(
              result?.errors?.reason,
              "An error occurred while generating otp.\nPlease try again later.",
            )
          }
        }
      } catch (error) {
        // if exception occurred call failure callback
        await authStore.onAuthFailure(
          error,
          "An error occurred while generating otp.\nPlease try again later.",
        )
      }
    })()

    if (resendCount < 1) setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendCount])

  const verifyOtp = async () => {
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - Click on Verify OTP`,
    )

    if (pin.join("")?.length !== 4) {
      setError(true)
      toast("The OTP entered is incorrect")
      return
    }

    setSubmitLoading(true)

    try {
      // verify otp for token and scope
      const response = await CommonService.verifyChallengeOTP({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        mobileNumber: user.customer.mobileNumber,
        mobileNumberCountryCode: user.customer.mobileCountryCode,
        mobileVerificationRefId: otpId.current,
        otp: pin.join(""),
      })
      const result = response.data

      if (result?.success) {
        setError(false)

        // update otp ref id in verified challenges
        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          otpRefId: otpId.current,
        }

        // set verified challenges
        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        // on successful factor completion
        await onFactorCompletion(navigate)
      } else {
        if (result?.errors?.failureReason === OtpErrors.INVALID_OTP) {
          setError(true)
          toast("The OTP entered is incorrect")
        } else if (result?.errors?.failureReason === OtpErrors.OTP_EXPIRED) {
          setError(true)
          toast("Your OTP has expired")
        } else {
          // if otp verification failed due to unknow error then call failure callback
          await authStore.onAuthFailure(
            result?.errors?.reason,
            "An error occurred while verifying otp. Please try again later.",
          )
        }
      }
    } catch (error) {
      // if exception occurred call failure callback
      await authStore.onAuthFailure(
        error,
        "An error occurred. Please try again later.",
      )
    }

    setSubmitLoading(false)
  }

  return loading ? (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View
          height={windowDimensions.height}
          alignItems='center'
          justifyContent='center'
        >
          <Loader color={theme.color1} width={120} height={120} />
        </View>
      </View>
    </View>
  ) : (
    <View alignItems='center'>
      <View w='100%'>
        {showHeader ? (
          <Header
            text={headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          {...styles.expand}
          px='16px'
          minHeight={
            windowDimensions.height -
            (showHeader ? HEADER_HEIGHT_IN_PX : 0) -
            20
          }
        >
          <View>
            <Text {...styles.otpVerificationText} fontFamily={theme.fontFamily}>
              Enter OTP {/* authStore.otpReason */}
            </Text>
            <Text {...styles.saveSettingText}>OTP verification code</Text>
          </View>
          <View
            {...styles.pinMainView}
            // backgroundColor={theme.color3}
          >
            <View {...styles.pinMainView1}>
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={otp0}
                value={pin[0]}
                onChangeText={text => {
                  setPin([text, pin[1], pin[2], pin[3]])
                  if (text !== "") {
                    otp1.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={otp1}
                value={pin[1]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[1] === "") {
                    otp0.current.focus()
                    setPin(["", "", pin[2], pin[3]])
                  }
                }}
                onChangeText={text => {
                  setPin([pin[0], text, pin[2], pin[3]])
                  if (text !== "") {
                    otp2.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={otp2}
                value={pin[2]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[2] === "") {
                    otp1.current.focus()
                    setPin([pin[0], "", "", pin[3]])
                  }
                }}
                onChangeText={text => {
                  setPin([pin[0], pin[1], text, pin[3]])
                  if (text !== "") {
                    otp3.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={otp3}
                value={pin[3]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[3] === "") {
                    otp2.current.focus()
                    setPin([pin[0], pin[1], "", ""])
                  }
                }}
                onChangeText={text => {
                  setPin([pin[0], pin[1], pin[2], text])
                  if (text !== "") {
                    otp3.current.blur()
                  }
                }}
              />
            </View>
          </View>
          {/* <View mt="12px" display={error ? "flex" : "None"}>
            <Text
              fontFamily={theme.fontFamily}
              font-weight="700"
              font-size="14px"
              line-height="18px"
              letter-spacing="0.02em"
              color="#C2181B"
            >
              {error}
            </Text>
          </View> */}
          <View mt='3'>
            <Text {...styles.resendOtp}>
              Didn't get OTP?{" "}
              <Link
                _text={{
                  color: seconds === 0 ? "blue.400" : "#808080",
                }}
                _web={{
                  mb: 1,
                }}
                isUnderlined={seconds === 0}
                onPress={() => {
                  if (seconds < 1) {
                    setResendCount(count => count + 1)
                  }
                }}
              >
                <Text
                  color={seconds === 0 ? "#3A5FB6" : null}
                  fontWeight={seconds === 0 ? "600" : null}
                >
                  Resend
                </Text>
              </Link>
              <Text display={seconds === 0 ? "none" : "inline"}> in </Text>
              <Text
                color='#000'
                bold
                display={seconds === 0 ? "none" : "inline"}
              >
                00:{seconds >= 10 ? seconds : `0${seconds}`}
              </Text>
            </Text>
          </View>
          <View {...styles.expand}></View>
          <View alignItems='center' justifyContent='center' w='100%' mt='20px'>
            <View {...styles.button} _web={{ maxW: DisplayConfig.MAX_WIDTH }}>
              <View {...styles.messageContainer}>
                <View {...styles.infoSvgContainer}>
                  <Info iconProps={{ viewBox: "0 0 30 30" }} />
                </View>
                <Text {...styles.messageText}>
                  One Time Password (OTP) has been sent to your registered
                  mobile number{" "}
                  {user.customer?.mobileNumber &&
                    "ending with " +
                      getLastDigitsOfMobileNumber(
                        user.customer?.mobileNumber,
                      )}{" "}
                  linked with the bank.
                </Text>
              </View>
              <ColorButton
                text='Verify OTP'
                isDisabled={pin.join("").length !== 4}
                onPress={verifyOtp}
                isLoading={submitLoading}
              />
              {showCancelButton() && (
                <WhiteButton
                  text='Cancel'
                  onPress={async () => {
                    analytics.track(
                      `${getAnalyticsProgramType(
                        user.programType,
                      )} - Click on Cancel(OTP)`,
                    )
                    await authStore.onAuthCancel()
                  }}
                />
              )}
              <View {...styles.secureMessageContainer}>
                <View {...styles.lockSvgContainer}>
                  <Lock iconProps={{ viewBox: "0 0 30 30" }} />
                </View>
                <Text {...styles.secureMessageText}>
                  Information is sent over a secure connection
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Otp_v1
