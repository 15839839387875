import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const ONLINEIcon = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 30 30' size='24px'>
      <svg  width="24" height="24" viewBox="0 0 24 24" fill={theme.appTextColor} xmlns="http://www.w3.org/2000/svg">
<path d="M5.18652 11.7616V16.2118C5.18652 17.0183 5.84249 17.6743 6.64528 17.6743H20.6025C21.4053 17.6743 22.0613 17.0183 22.0613 16.2118L22.0614 11.7616H5.18652ZM12.5648 15.8557H8.34168C8.14356 15.8557 7.98325 15.6952 7.98325 15.4973C7.98325 15.2993 8.14356 15.1388 8.34168 15.1388H12.5648C12.7629 15.1388 12.9232 15.2993 12.9232 15.4973C12.9232 15.6952 12.7629 15.8557 12.5648 15.8557ZM16.1246 14.2956H8.34173C8.14361 14.2956 7.9833 14.1352 7.9833 13.9372C7.9833 13.7393 8.14361 13.5788 8.34173 13.5788H16.1246C16.3227 13.5788 16.483 13.7393 16.483 13.9372C16.483 14.1352 16.3227 14.2956 16.1246 14.2956Z" fill={theme.appTextColor}/>
<path d="M22.0613 9.73287V8.19046C22.0613 7.384 21.4053 6.72803 20.6025 6.72803H6.64528C5.84249 6.72803 5.18652 7.384 5.18652 8.19046V9.73287H22.0613Z" fill={theme.appTextColor}/>
<path d="M15.7852 6.01083V2.43371C15.7852 1.64159 15.1401 1 14.3515 1H2.43371C1.64159 1 1 1.64159 1 2.43371V21.9681C1 22.7602 1.64159 23.4018 2.43371 23.4018H14.3515C15.1401 23.4018 15.7852 22.7602 15.7852 21.9681V18.391H14.3478V19.4591C14.3478 20.0541 13.8675 20.5344 13.2725 20.5344H3.50891C2.91387 20.5344 2.43362 20.0541 2.43362 19.4591V3.509C2.43362 2.91764 2.91387 2.43371 3.50891 2.43371H6.01791L6.73477 3.53772H10.0466L11.1218 2.43371H13.2724C13.8675 2.43371 14.3477 2.91764 14.3477 3.509V6.01083H15.7852ZM8.3908 21.3587C8.7277 21.3587 9.00022 21.6311 9.00022 21.968C9.00022 22.3049 8.72772 22.5737 8.3908 22.5737C8.0574 22.5737 7.78505 22.3049 7.78505 21.968C7.78505 21.6311 8.05755 21.3587 8.3908 21.3587ZM9.00389 2.58425H7.77811C7.58087 2.58425 7.41968 2.42289 7.41968 2.22583C7.41968 2.02859 7.58087 1.8674 7.77811 1.8674H9.00389C9.20463 1.8674 9.36232 2.02859 9.36232 2.22583C9.36232 2.42306 9.20463 2.58425 9.00389 2.58425Z"fill={theme.appTextColor}/>
</svg>

    </Icon>
  )
}

export default ONLINEIcon
