import { Image, Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import Confetti from "react-confetti"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import settings from "../../assets/images/settings.svg"
import ApiIds from "../../auth/ApiIds"
import AccountService from "../../services/AccountService"
import CardService from "../../services/CardService"
import { clearCommonState, setCommonState } from "../../store/actions/Common"
import { setFirstTimeLoaded } from "../../store/actions/Session"
import {
  setAccount,
  setCards,
  setCustomer,
  setSummary,
} from "../../store/actions/User"
import { handleApiAuth } from "../../utils/auth"
import { DisplayConfig, ProgramTypes } from "../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../utils/enums"
import { consoleError, goToRedirectUrl } from "../../utils/functions"
import Preloader_v2 from "../PreLoader/preloader_v2"
import SkeletonLoader from "../core/SkeletonLoader/homeLoader"
import { Header } from "../core/headers"
import { showToast1 } from "../core/toast"
import ErrorScreen from "../messages/errorScreen"
import Settings from "../svg/settings"
import ActivitySection_v1 from "./Components/ActivitySection/ActivitySection_v1"
import ActivitySection_v2 from "./Components/ActivitySection/ActivitySection_v2"
import CardAndBalance_v1 from "./Components/CardAndBalance/CardAndBalance_v1"
import CardAndBalance_v2 from "./Components/CardAndBalance/CardAndBalance_v2"
import TransactionSection_v1 from "./Components/TransactionSection/TransactionSection_v1"
import TransactionSection_v2 from "./Components/TransactionSection/TransactionSection_v2"
import styles from "./styles"
import Loader from "../core/loader"

const Home = () => {
  const [theme, user, common, session, config] = useSelector(state => [
    state.theme,
    state.user,
    state.common,
    state.session,
    state.config,
  ])

  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const location = useLocation()
  const navigate = useNavigate()

  const account = user.account
  const card = user.cards?.[0]

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.LOGIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.REGISTRATION_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
    }
  }, [])
  const { isHomeSet, hasConfetti, summaryData } = common

  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"

  const [creditDetails, setCreditDetails] = useState({})
  // eslint-disable-next-line
  const [cardDetails, setCardDetails] = useState({})
  // eslint-disable-next-line
  const [showReminder, setShowReminder] = useState({})
  // eslint-disable-next-line
  const [offersList, setOffersList] = useState({})
  // eslint-disable-next-line
  const [recentTransactions, setRecentTransactions] = useState([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick: hasTick })
  }

  useEffect(() => {
    ;(async () => {
      if (!isHomeSet) {
        dispatch(clearCommonState())

        // if user coming from activation flow then first call activate api
        if (
          location.pathname.includes("/activated") ||
          location.state?.from === "CardActivated"
        ) {
          // activate card
          await handleApiAuth({
            apiId: ApiIds.ACTIVATE_CARD,
            onAuthSuccess: onActivateAuthSuccess,
            onAuthFailure: onActivateAuthFailure,
            onAuthCancel: onActivateAuthCancel,
            otpReason: "to activate card",
            mpinReason: "to activate card",
            toast,
            navigate,
          })
        } else {
          // else directly call summary api
          await handleApiAuth({
            apiId: ApiIds.ACCOUNT_SUMMARY,
            onAuthSuccess: onSummaryAuthSuccess(false),
            onAuthFailure: onSummaryAuthFailure,
            onAuthCancel: onSummaryAuthCancel,
            otpReason: "to get account summary",
            mpinReason: "to get account summary",
            toast,
            navigate,
          })
        }
      } else {
        dispatch(
          setSummary({
            summary: summaryData,
          }),
        )

        const customer = summaryData.account.customer
        const account = summaryData.account
        const card = summaryData.primaryCard

        // update user store
        dispatch(setCustomer({ customer }))
        dispatch(setAccount({ account }))
        dispatch(setCards({ cards: [card] }))

        setRecentTransactions(summaryData.latestTransactions)

        setCreditDetails({
          availableCredit: account.availableCreditLimit,
          totalCredit: account.approvedCreditLimit,
          availableCash: account.availableCashLimit,
          currentBalance: account.currentBalance,
        })
        setOffersList(summaryData.offers)
        if (summaryData.lastStatement?.dueDate) {
          setShowReminder({
            show: parseFloat(summaryData.lastStatement.balanceAmountDue) > 0,
            amount: summaryData.lastStatement.balanceAmountDue,
            dueDate: summaryData.lastStatement.dueDate,
            graceDate: summaryData.lastStatement.graceDate,
            fromDate: summaryData.lastStatement.fromDate,
            toDate: summaryData.lastStatement.toDate,
          })
        }

        setCardDetails({
          orientation: theme.cardOrientation,
          image: theme.cardDesignUrl,
          name:
            customer?.firstname +
            (customer?.middlename && " " + customer?.middlename) +
            (customer?.lastname && " " + customer?.lastname),
          cardNo: card.maskedCardNumber,
          CVV: "655",
          Expiry: card.cardExpiry,
        })
      }
      dispatch(
        setFirstTimeLoaded({
          isLoadedFirstTime: false,
        }),
      )
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHomeSet])

  useEffect(() => {
    return () => {
      // on leaving summary page clear common state, we don't want summary data to be cached
      dispatch(clearCommonState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onActivateAuthSuccess = async apiToken => {
    let isActivateSuccess = false
    try {
      const activationCode = localStorage.getItem("activationCode")
      localStorage.removeItem("activationCode")

      const response = await CardService.activateCard(
        apiToken,
        user.cards[0]?.id,
        {
          activationCode,
        },
      )
      const result = response.data

      if (result?.success) {
        // card state will be updated by summary api later

        isActivateSuccess = true
      } else {
        consoleError(result?.errors)
        toast("An error occurred while activating your card")
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while activating your card")
    }

    // call summary api
    await handleApiAuth({
      apiId: ApiIds.ACCOUNT_SUMMARY,
      onAuthSuccess: onSummaryAuthSuccess(isActivateSuccess),
      onAuthFailure: onSummaryAuthFailure,
      onAuthCancel: onSummaryAuthCancel,
      otpReason: "to get account summary",
      mpinReason: "to get account summary",
      toast,
      navigate,
    })
  }

  const onSummaryAuthSuccess = isCardActivated => async apiToken => {
    try {
      const response = await AccountService.getSummary(
        apiToken,
        user.account.id,
        config?.version,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            summaryData: result.data,
            isHomeSet: true,
          }),
        )

        // navigate and show confetti
        if (isCardActivated) {
          toast("Physical Card Activated Successfully")
          dispatch(setCommonState({ hasConfetti: true }))
          setTimeout(() => {
            dispatch(setCommonState({ hasConfetti: false }))
          }, 5000)
        }

        navigate("/", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onSummaryAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/Error", { replace: true })
  }

  const onSummaryAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onActivateAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onActivateAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const versioned_home = () => {
    switch (config.version) {
      case PwaVersions.V1:
        return (
          <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}
            <View
              mt='24px'
              mx='16px'
              flexDirection='row'
              justifyContent={"space-between"}
            >
              <View></View>
              <View {...styles.centering}>
                <Text {...styles.heading} fontFamily={theme.fontFamily}>
                  {theme.title}
                </Text>
              </View>
              <View>
                {user.programType === ProgramTypes.PREPAID && (
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Image
                      source={{
                        uri: settings,
                      }}
                      width='24px'
                      height='24px'
                      alt='Alternate Text '
                    />
                  </View>
                )}
              </View>
            </View>
            <View mb='24px'>
              <CardAndBalance_v1
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
              />
            </View>

            <View {...styles.centering}>
              <ActivitySection_v1 />
            </View>
            {recentTransactions.length > 0 && (
              <View {...styles.centering}>
                <TransactionSection_v1
                  recentTransactions={recentTransactions}
                />
              </View>
            )}
          </View>
        )
      case PwaVersions.V2:
        return (
          <View
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            w='100%'
            minH={windowDimensions.height}
            bgColor={theme.backgroundColor}
          >
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}

            <Header
              text={theme.title || "My credit card"}
              isHomePage= {true}
              onBack={async () => {
                goToRedirectUrl()
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />
            {/* <View
              py="12px"
              px="12px"
              flexDirection="row"
              justifyContent={"space-between"}
              backgroundColor={theme.headerBackgroundColor}
            >
              <View
                onClick={() => (window.location.href = session.redirectUrl)}
              >
                <ChevronLeftIcon size="6" color={theme.titleColor} />
              </View>
              <View {...styles.centering}>
                <Text
                  {...styles.heading}
                  fontFamily={theme.fontFamily}
                  color={theme.titleColor}
                >
                  {theme.title || "My Credit Card"}
                </Text>
              </View>
              <View>
                <View
                  onClick={() =>
                    isAccountActive && card ? navigate("/ManageCard") : null
                  }
                >
                  <Settings />
                </View>
              </View>
            </View> */}
            <View {...styles.centering}>
              <ActivitySection_v2 />
            </View>
            <View mb='24px'>
              <CardAndBalance_v2
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
                offersList={offersList}
              />
            </View>

            <View {...styles.centering}>
              <TransactionSection_v2 recentTransactions={recentTransactions} />
            </View>
          </View>
        )
      default:
        return (
          <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}
            <View
              mt='24px'
              mx='16px'
              flexDirection='row'
              justifyContent={"space-between"}
            >
              <View></View>
              <View {...styles.centering}>
                <Text {...styles.heading} fontFamily={theme.fontFamily}>
                  {theme.title}
                </Text>
              </View>
              <View>
                {user.programType === ProgramTypes.PREPAID && (
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Image
                      source={{
                        uri: settings,
                      }}
                      width='24px'
                      height='24px'
                      alt='Alternate Text '
                    />
                  </View>
                )}
              </View>
            </View>
            <View mb='24px'>
              <CardAndBalance_v1
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
              />
            </View>
            <View {...styles.centering}>
              <ActivitySection_v1 />
            </View>
            {recentTransactions.length > 0 && (
              <View {...styles.centering}>
                <TransactionSection_v1
                  recentTransactions={recentTransactions}
                />
              </View>
            )}
          </View>
        )
    }
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View {...styles.mainContainer} minHeight='750px'>
          {error ? (
            <ErrorScreen />
          ) : loading &&
            session.isLoadedFirstTime &&
            config?.version === PwaVersions.V2 ? (
            <View
              height={windowDimensions.height}
              alignItems='center'
              justifyContent='center'
              style={{
                backgroundColor: `${
                  config.version === PwaVersions.V2
                    ? theme.backgroundColor
                    : "white"
                }`,
              }}
            >
              <View mt='70px'>
                <Preloader_v2 />
              </View>
            </View>
          ) : loading ? (
            config.version === PwaVersions.V1 ? (
              <View
                w='100%'
                alignItems='center'
                justifyContent='center'
                height={windowDimensions.height}
                style={{
                  backgroundColor: `${
                    config.version === PwaVersions.V2
                      ? theme.backgroundColor
                      : "white"
                  }`,
                }}
              >
                <Loader color={theme.color1} width={120} height={120} />
              </View>
            ) : (
              <View bgColor={theme.backgroundColor}>
                <SkeletonLoader  orientation =  { theme.cardOrientation} />
              </View>
            )
          ) : (
            <View alignItems='center' justifyContent='center'>
              {versioned_home()}
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default Home
