import { View, Text, ChevronRightIcon} from "native-base"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { setSelectedEmi } from "../../../../../store/actions/Session"
import { formatAmount } from "../../../../../utils/functions"
import EmptyBillEmi from "../../../../svg/emptyBillEmi"
import { PwaVersions } from "../../../../../utils/enums"

const getColor = (getVersionedDisplay,theme)=>{
  return getVersionedDisplay() ? theme.appTextColor : null
}
const BillEmi = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }

  return (
    <View p='16px'>
      {props.data.length > 0 ? (
        props.data.map(item => (
          <View
            borderRadius='8px'
            borderWidth={"1px"}
            borderColor='#ECECEC'
            mt='24px'
            bgColor={
              getVersionedDisplay() ? `${theme.appTextColor}:alpha.10` : null
            }
          >
            <View
              flexDirection={"row"}
              alignItems='center'
              justifyContent={"space-between"}
              px='14px'
              py='12px'
              borderTopRadius='8px'
              bgColor={
                getVersionedDisplay() ? `${theme.appTextColor}:alpha.10` : null
              }
            >
              <View>
                <Text
                  fontSize='12px'
                  lineHeight='16px'
                  fontWeight='600'
                  color={getColor(getVersionedDisplay,theme)}
                >
                  {`${moment(item.createdOn).format("MMM 'YY")} EMIs`}
                </Text>
              </View>
              <View
                borderWidth='1px'
                px='4px'
                py='1px'
                borderRadius='4px'
                borderColor={getColor(getVersionedDisplay,theme)}
              >
                <Text
                  fontSize='11px'
                  lineHeight='16px'
                  fontWeight='500'
                  color={getColor(getVersionedDisplay,theme)}
                >
                  {`${item.currentCycle - 1}/${item.tenureInMonths} Paid`}
                </Text>
              </View>
            </View>
            <View>
              {item.schedules.map((schedule, index) => (
                <View
                  px='12px'
                  pt='12px'
                  bgColor={
                    getVersionedDisplay() ? theme.widgetBackgroundColor : null
                  }
                  alignItems='center'
                >
                  <View
                    pb={item.schedules.length !== index + 1 ? "16px" : "12px"}
                    w='100%'
                    borderBottomWidth={
                      item.schedules.length !== index + 1 ? "1px" : "0px"
                    }
                    borderBottomColor={"#F8F8F8"}
                    alignItems='center'
                    justifyContent={"space-between"}
                    flexDirection='row'
                  >
                    <View>
                      <View>
                        <Text
                          fontSize='16px'
                          lineHeight='20px'
                          fontWeight='400'
                          color={
                            getColor(getVersionedDisplay,theme)
                          }
                        >
                          {` EMI ${index + 1}/${item.schedules.length}`}
                        </Text>
                      </View>
                      <View>
                        <Text
                          fontSize='11px'
                          lineHeight='16px'
                          fontWeight='400'
                          color='#036F29'
                        >
                          {`Due in ${moment(schedule.paymentDueDate).format(
                            "MMM Do 'YY",
                          )}`}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <Text
                        fontSize='16px'
                        lineHeight='20px'
                        fontWeight='400'
                        color={
                          getColor(getVersionedDisplay,theme)
                        }
                      >
                        {formatAmount(item.monthlyEmi)}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
            <View
              p='12px'
              w='100%'
              borderTopWidth={"1px"}
              borderTopColor={"#F8F8F8"}
            >
              <View
                alignItems={"center"}
                flexDir='row'
                onClick={() => {
                  dispatch(setSelectedEmi({ currentSelectedEmi: item }))
                  navigate("/ManageCard/ForecloseEmi")
                }}
              >
                <Text
                  fontSize='16px'
                  lineHeight='20px'
                  fontWeight='500'
                  color={theme.color1}
                >
                  Preclose
                </Text>
                <ChevronRightIcon ml='4px' size='20px' color={theme.color1} />
              </View>
            </View>
          </View>
        ))
      ) : (
        <View w='100%' mt='50%' alignItems='center' justifyContent={"center"}>
          <View>
            <EmptyBillEmi />
          </View>
          <View mt='12px'>
            <Text
              fontSize='16px'
              lineHeight='20px'
              fontWeight='600'
              color={getVersionedDisplay() ? theme.appTextColor : null}
            >
              No Balance EMIs
            </Text>
          </View>
          <View w='80%' mt='16px'>
            <Text
              textAlign={"center"}
              fontSize='12px'
              lineHeight='16px'
              fontWeight='400'
              color={getVersionedDisplay() ? theme.appTextColor : null}
            >
              You can convert your upcoming bill amount to EMI once the
              statement is generated
            </Text>
          </View>
        </View>
      )}
    </View>
  )
}

export default BillEmi
