import { ChevronLeftIcon, ScrollView, Text, useToast, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import QrReader from "react-qr-reader"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import Paths from "../../../routes/Paths"
import CardService from "../../../services/CardService"
import { handleApiAuth } from "../../../utils/auth"
import {
  DisplayConfig, PHYSICAL_CARD_ACTIVATION_PATH, ProgramTypes
} from "../../../utils/constants"
import { PwaVersions } from "../../../utils/enums"
import {
  consoleError,
  isIosDevice,
  postMessageToIos
} from "../../../utils/functions"
import { Header } from "../../core"
import ErrorScreen from "../../messages/errorScreen"
import { showToast1 } from "../toast"
import styles from "./styles"


const QRscanner = () => {
  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()
  const navigate = useNavigate()

  const [theme, user, config, featureFlags] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
    state.featureFlags,
  ])

  const [activationCode, setActivationCode] = useState("")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [iosLinkError, setIosLinkError] = useState(false)

  const qrCode = useRef(null)
  const activationCodeRef = useRef("")
  const cameraGranted = useRef(0)

  const updateQrPageErrorScreen = () => {
    setError(!error)
    setActivationCode("")
  }
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    if (!user.cards[0] || user.cards[0].isPhysicalCardActivated) {
      navigate("/")
    }
    if (isIosDevice()) {
      // if it's an iOS device

      const qrLink = window.hyperfaceIosProps?.cardActivationQrLink
      // process a link only once
      delete window.hyperfaceIosProps?.cardActivationQrLink

      const isQrLinkSet = Boolean(qrLink) && Boolean(qrLink.trim())
      const openQrAction = JSON.stringify({ cmd: "CARD_ACTIVATION_QR_SCAN" })

      if (!isQrLinkSet) {
        // if no qr link was set, send message to open qr scanner
        postMessageToIos(openQrAction)
      } else if (isQrLinkSet) {
        // if qr link was set, use the link
        if (isQrLinkValid(qrLink)) {
          setActivationCode(getActivationCodeFromQrLink(qrLink))
          activationCodeRef.current = getActivationCodeFromQrLink(qrLink)
        } else {
          // if invalid link, then show error and open qr scanner again
          setIosLinkError(true)
          setTimeout(() => postMessageToIos(openQrAction), 2000)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      if (activationCode) {
        await handleApiAuth({
          apiId: ApiIds.PWA_SESSION_INFO_FROM_ACTIVATION_CODE,
          onAuthSuccess: onVerifyCodeAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to verify activation code",
          mpinReason: "to verify activation code",
          toast,
          navigate,
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode])

  const onVerifyCodeAuthSuccess = async apiToken => {
    try {
      const response = await CardService.verifyActivationCode(
        apiToken,
        user.cards[0]?.id,
        activationCode,
      )
      const result = response?.data

      if (result?.success && result?.data?.accountId === user.account.id) {
        if (!user.enablePciWidget) {
          localStorage.setItem("activationCode", activationCode)
          navigate("/ResetPin", {
            state: { from: "Activation" },
            replace: true,
          })
        } else {
          await handleApiAuth({
            apiId: ApiIds.PCI_SET_PIN_URL,
            onAuthSuccess: onPciSetPinAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to reset card pin",
            mpinReason: "to reset card pin",
            toast,
            navigate,
          })
        }
      } else {
        consoleError(result.errors)
        toast("Please use your verification QR code for activation")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while activating")
      navigate("/", { replace: true })
    }
  }

  const onPciSetPinAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getResetPinWidget(
        apiToken,
        user.cards[0]?.id,
        {
          callbackUrl:
            (config.version === PwaVersions.V1 &&
              [ProgramTypes.PAY_LATER, ProgramTypes.PREPAID].includes(
                user.programType,
              )) ||
            (config.version === PwaVersions.V2 &&
              featureFlags.showCardControlsWithActivation)
              ? process.env.REACT_APP_URL + Paths.ACTIVATION_CARD_CONTROLS
              : process.env.REACT_APP_URL + Paths.HOME_WITH_ACTIVATE,
        },
      )
      const result = response.data

      if (result?.success) {
        localStorage.setItem("activationCode", activationCode)
        window.location.href = result.data
      } else {
        consoleError(result?.errors)
        toast("An error occurred while setting pin")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while setting pin")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const isQrLinkValid = link =>
    link &&
    link.startsWith(
      `${process.env.REACT_APP_URL}/${PHYSICAL_CARD_ACTIVATION_PATH}?activationCode=`,
    )

  const getActivationCodeFromQrLink = link => {
    let qrCode = link.split("?")[1]
    qrCode = qrCode.split("=")[1]
    return qrCode
  }

  const handldeScan = result => {
    if (isQrLinkValid(result)) {
      setActivationCode(getActivationCodeFromQrLink(result))
      activationCodeRef.current = result
    } else if (result && activationCodeRef.current !== result) {
      toast("Invalid QR Code. Please try again.")
      activationCodeRef.current = result
    }
  }

  const handleError = async error => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const queries = devices
      .filter(({ kind }) => kind === "videoinput")
      .map(({ deviceId }) => ({ name: "camera", deviceId }))

    if (queries.length === 0) {
      setError(true)
      setErrorMessage("Please connect a camera to use QR Scanner")
      return
    }

    const promises = queries.map(queryObj =>
      navigator.permissions.query(queryObj),
    )
    try {
      const results = await Promise.all(promises)
      // log the state of each camera
      results.forEach(({ state }, i) => {
        if (state === "granted") {
          cameraGranted.current = cameraGranted.current + 1
        }
      })
      if (cameraGranted.current === 0) {
        setError(true)
        setErrorMessage("Please grant access to camera")
        return
      }
    } catch (error) {
      setError(true)
      setErrorMessage("Something went wrong, our team is working on it.")
      consoleError(error)
      return
    }
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        {error ? (
          <ErrorScreen
            retry={true}
            updateQrPageErrorScreen={updateQrPageErrorScreen}
            message={errorMessage}
          />
        ) : iosLinkError ? (
          <ScrollView>
            <View w='100%' h={windowDimensions.height} minHeight='750px'>
              <View alignItems='center' justifyContent='center' mt='30%'>
                <View w='80%'>
                  <View mt='6px'>
                    <Text
                      fontFamily={theme.fontFamily}
                      fontWeight='400'
                      fontSize='16px'
                      lineHeight='20px'
                      color='#7B7B7B'
                      textAlign='center'
                    >
                      Invalid activation code. Opening scanner again ...
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        ) : isIosDevice() ? (
          <View>
            <View mt='30%' ml='50%'>
              Loading...
            </View>
          </View>
        ) : (
          <View>
            <View {...styles.mainContainer} height={windowDimensions.height}>
              {config.version === "v2" ? (
                <Header
                  text='&nbsp;'
                  onBack={async () => {
                    window.history.go(-1)
                  }}
                />
              ) : (
                <View {...styles.header}>
                  <ChevronLeftIcon
                    {...styles.back}
                    onClick={() => navigate("/")}
                  />
                </View>
              )}

              <View {...styles.QrReader} borderColor={theme.color1}>
                <QrReader
                  ref={qrCode}
                  delay={300}
                  style={{ width: "100%", borderRadius: 16, borderWidth: 2 }}
                  onError={handleError}
                  onScan={handldeScan}
                  showViewFinder={false}
                />
              </View>
              <View alignItems='center'>
                <Text fontFamily={theme.fontFamily} {...styles.infoText}>
                  Scan the QR code which came along with your card to activate
                  it
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

export default QRscanner
