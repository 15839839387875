import React from "react"
import { View, Text, Image, ScrollView } from "native-base"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { ColorButton, WhiteButton } from "../../core/buttons"
import ErrorImage from "../../../assets/images/ServerError.svg"
import { goToRedirectUrl } from "../../../utils/functions"
import styles from "../../messages/styles"
import { getHeaderText, showCancelButton } from "../../../utils/auth"
import { AuthChallenges, HEADER_HEIGHT_IN_PX } from "../../../utils/constants"
import { Header } from "../../core"
import { PwaVersions } from "../../../utils/enums"

const MpinBlocked = ({ onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const [theme, config] = useSelector(state => [state.theme, state.config])

  const showHeader = config?.version === PwaVersions.V2
  const headerText = getHeaderText(AuthChallenges.MPIN)

  return (
    <View>
      <ScrollView>
        {showHeader ? (
          <Header
            text={headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          {...styles.expand}
          px='16px'
          minHeight={
            windowDimensions.height -
            (showHeader ? HEADER_HEIGHT_IN_PX : 0) -
            20
          }
          minH={
            config?.version === PwaVersions.V2 ? windowDimensions.height : null
          }
          bgColor={
            config?.version === PwaVersions.V2 ? theme.backgroundColor : null
          }
        >
          <View {...styles.section1} mt={"20%"}>
            <View {...styles.imageContainer} bgColor={"#F8F8F8"}>
              <Image {...styles.image} source={{ uri: ErrorImage }} />
            </View>
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}>
                <Text {...styles.text1} fontFamily={theme.fontFamily}>
                  Access Blocked!
                </Text>
              </View>
              <Text
                fontSize='12px'
                color='#272727'
                textAlign='center'
                font-family='Inter'
                my='10px'
              >
                Your access is blocked due to multiple incorrect attempts. Reset
                your PIN now to unblock the access.
              </Text>
            </View>
          </View>
          <View {...styles.expand}></View>
          <View {...styles.button} mt='20px'>
            {/* <View {...styles.messageContainer}>
              <View {...styles.infoSvgContainer}>
                <Info iconProps={{ viewBox: "0 0 30 30" }} />
              </View>
            </View> */}
            <ColorButton
              text='Reset MPIN'
              onPress={() => onSubmit()}
              mb='10px'
            />
            {!config?.version == PwaVersions.V2 ? (
              <WhiteButton text='Go Back' onPress={() => goToRedirectUrl()} />
            ) : null}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default MpinBlocked
