import moment from "moment"
import { FlatList, Image, Text, useToast, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import rewardsEmptyImage from "../../../assets/images/rewardsEmptyImage.png"
import rewardSummaryBanner from "../../../assets/images/rewardSummaryBanner.svg"
import AccountService from "../../../services/AccountService"
import { DisplayConfig } from "../../../utils/constants"
import { formatAmount } from "../../../utils/functions"
import { Header } from "../../core"
import CustomLoader from "../../core/loader"
import { showToast1 } from "../../core/toast"
import Transactions from "./Transactions"

const initStartDate = moment().subtract(12, "months").format("YYYY-MM-DD")
const initCurrentDate = moment().format("YYYY-MM-DD")
const initTransactions = [
  { type: "TITLE", date: moment().format("YYYY-MM-DD") },
]

const initOffset = 0
const transactionCount = 10

const Rewards_v2 = () => {
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const isTransactionsLoadingRef = useRef(false)
  const [apiError, setApiError] = useState(0)

  const [transactions, setTransactions] = useState(initTransactions)
  const offset = useRef(initOffset)
  // date till which txns should be loaded
  const startDate = useRef(initStartDate)
  // pointer to current month being fetched
  const currentMonth = useRef(initCurrentDate)

  const navigate = useNavigate()
  const nativeToast = useToast()
  const windowDimensions = useWindowDimensions()
  const [theme, user, preferences, screen] = useSelector(state => [
    state.theme,
    state.user,
    state.preferences,
    state.screen,
  ])

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    if (apiError >= 3) {
      toast("Error fetching Transactions")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])
  const getTransactions = async () => {
    try {
      // get txns for current month with the set offset
      let response
      if (screen?.home?.reward?.redirectionType === "HF_CASHBACK") {
        response = await AccountService.getCashbacks(user.account.id, {
          count: transactionCount,
          offset: offset.current,
          from: moment(currentMonth.current)
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment(currentMonth.current).endOf("month").format("YYYY-MM-DD"),
        })
      } else {
        response = await AccountService.getRewards(user.account.id, {
          count: transactionCount,
          offset: offset.current,
          from: moment(currentMonth.current)
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment(currentMonth.current).endOf("month").format("YYYY-MM-DD"),
        })
      }

      const result = response.data

      // if api call successful
      if (result?.success) {
        if (result.data.totalCount !== 0) {
          setTransactions(transactions => [...transactions, ...result.data])
        }
        // if current month has more txns pending, stay in this month
        if (result.data.hasMore) {
          // increment offset, next call happens for current month with this offset
          offset.current += transactionCount
        } else {
          // else move current month pointer to next month (chronologically previous month)

          // get next month's value
          const nextMonthToFetch = moment(currentMonth.current)
            .subtract(1, "month")
            .format("YYYY-MM-DD")

          // move pointer to next month
          currentMonth.current = nextMonthToFetch
          // reset offset
          offset.current = 0
        }
      } else {
        setApiError(apiError + 1)
      }
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)
    if (
      moment(currentMonth.current).format("YYYY-MM") >=
        moment(startDate.current).format("YYYY-MM") &&
      !isTransactionsLoadingRef.current &&
      apiError < 3
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns
      await getTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    } else if (
      moment(currentMonth.current).format("YYYY-MM") <
        moment(startDate.current).format("YYYY-MM") &&
      transactions.length === 1
    ) {
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <View w='100%' alignItems='center' justifyContent='center'>
        <CustomLoader color={theme.color1} width={20} height={20} />
      </View>
    ) : null
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <>
            <View mb='14px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='700'
                fontSize='18px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                  ? "Cashbacks History"
                  : "Rewards History"}
              </Text>
            </View>
          </>
        )

      case "DATE":
        return <View marginTop={30} marginLeft={6}></View>

      case "EMPTY":
        return (
          <>
            <View mt='32px' alignSelf={"center"}>
              <Image
                source={{
                  uri: rewardsEmptyImage,
                }}
                width='192px'
                height='183px'
                alt='Alternate Text '
              />
            </View>
            <View alignSelf={"center"} mt='16px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                Start shopping to earn rewards!
              </Text>
            </View>
          </>
        )

      default:
        return (
          <View>
            <Transactions
              transaction={item}
              theme={theme}
              type={screen?.home?.reward?.redirectionType}
              dateFormat={preferences.dateFormat}
              timeFormat={preferences.timeFormat}
              isLast={index === transactions.length - 1}
            />
          </View>
        )
    }
  }

  console.log(transactions)

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
    startDate.current = initStartDate
    currentMonth.current = initCurrentDate
  }
  return (
    <View>
      <View m='0'>
        <Header text={"My Card - Rewards"} onBack={() => navigate("/")} />
      </View>
      <View
        alignItems={"center"}
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        minH={windowDimensions.height}
        bgColor={theme.backgroundColor}
      >
        <View
          borderRadius={"8px"}
          background={theme.widgetBackgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          shadow={2}
          w='90%'
          padding='24px'
          mt='24px'
        >
          <View
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <View justifyContent={"center"}>
              <View>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='16px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                    ? "Total Available Cashbacks"
                    : "Total Available Rewards"}
                </Text>
              </View>
              <View mt='12px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='32px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                    ? formatAmount(560)
                    : "560"}
                </Text>
              </View>
            </View>
            <View>
              <Image
                source={{
                  uri: rewardSummaryBanner,
                }}
                width='59px'
                height='59px'
                alt='Alternate Text '
              />
            </View>
          </View>
          {/* <View mt={"24px"} alignItems='center'>
            <ColorButton
              text={"Redeem Rewards"}
              onPress={() => (window.location.href = "https://www.google.com")}
            />
          </View> */}
        </View>
        {screen?.home?.reward?.redirectionType !== "HF_CASHBACK" && (
          <View
            borderRadius={"8px"}
            background={theme.widgetBackgroundColor}
            borderWidth={"1px"}
            borderColor='#ECECEC'
            shadow={2}
            w='90%'
            mt='12px'
          >
            <View
              background={theme.color1}
              p='2px'
              alignSelf={"flex-end"}
              textAlign='center'
              w='14%'
              mr='10%'
              borderBottomRadius={"4px"}
            >
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.color2}
              >
                Note
              </Text>
            </View>
            <View w='75%' mx='12px' pb='8px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='500'
                fontSize='12px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                Reward processing is not instantaneous and the points displayed
                on the Rewards Portal reflect the current status
              </Text>
            </View>
          </View>
        )}
        <View
          borderRadius={"8px"}
          background={theme.widgetBackgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          shadow={2}
          w='90%'
          px='16px'
          py='24px'
          mt='24px'
        >
          {transactions.length >= 1 ? (
            <FlatList
              data={transactions}
              renderItem={renderItem}
              keyExtractor={(_transaction, index) => index}
              onEndReached={loadMoreTransaction}
              ListFooterComponent={renderLoader}
              onEndReachedThreshold={0.2}
              refreshing={false}
              onRefresh={handleRefresh}
            />
          ) : (
            <View>
              <View>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='700'
                  fontSize='18px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  Rewards History
                </Text>
              </View>
              <View mt='32px' alignSelf={"center"}>
                <Image
                  source={{
                    uri: rewardsEmptyImage,
                  }}
                  width='192px'
                  height='183px'
                  alt='Alternate Text '
                />
              </View>
              <View alignSelf={"center"} mt='16px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='14px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  Start shopping to earn rewards!
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default Rewards_v2
