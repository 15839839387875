import { ChevronRightIcon, Text, View } from "native-base"
import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import OffersCard_v2 from "./OffersCard_v2"

const Offers_v2 = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [screen, theme] = useSelector(state => [state.screen, state.theme])
  const [offerList, setOfferList] = useState([])
  const navigate = useNavigate()
  useEffect(() => {

    setOfferList(
      props.offerList?.slice(
        0,
        screen?.home?.offersListLength ? screen?.home?.offersListLength : 5,
      ) || [],
    )
  }, [])
  return (
    <View
      mt='24px'
      w='90%'
      mx='16px'
      bgColor={theme.widgetBackgroundColor}
      borderRadius={"12px"}
      borderWidth={"1px"}
      borderColor='#ECECEC'
      shadow={2}
      padding='12px'
    >
      <View
        display={"flex"}
        alignItems='center'
        flexDir={"row"}
        justifyContent={"space-between"}
      >
        <View>
          <Text
            fontFamily={theme.fontFamily}
            fontWeight='600'
            fontSize='16px'
            lineHeight='22px'
            color={theme.appTextColor}
          >
            Offers & Promotions
          </Text>
        </View>
        <View
          display={"flex"}
          alignItems='center'
          flexDir={"row"}
          cursor={"pointer"}
          onClick={() => {
            navigate("/Offers")
          }}
        >
          <Text
            fontFamily={theme.fontFamily}
            fontWeight='600'
            fontSize='12px'
            lineHeight='22px'
            color={theme.color1}
          >
            View All
          </Text>
          <ChevronRightIcon color={theme.color1} size={"12px"} />
        </View>
      </View>
      <View w='100%' mt={"12px"}>
        <CarouselProvider
          naturalSlideWidth={200}
          naturalSlideHeight={50}
          totalSlides={offerList.length}
          visibleSlides={1.1}
          currentSlide={activeIndex}
        >
          <Slider>
            {offerList.map((item, index) => (
              <Slide index={index}>
                <View
                  onClick={() => setActiveIndex(index)}
                  cursor='pointer'
                  mr={index === offerList.length ? "5%" : 0}
                  ml={index === 0 ? "2px" : "6px"}
                >
                  <OffersCard_v2
                    data={item}
                    index={index}
                    length={offerList.length}
                  />
                </View>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </View>
    </View>
  )
}

export default Offers_v2
