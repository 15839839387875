import { ChevronLeftIcon, Image, Text, View } from "native-base"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ErrorImage from "../../../../assets/images/ServerError.svg"
import onlineTransactionActivation from "../../../../assets/images/onlineTransactionActivation.svg"
import HorizontalCard from "../../../../assets/images/sampleHorizontalCard.svg"
import VerticalCard from "../../../../assets/images/sampleVerticalCard.svg"
import { CardOrientation, ProgramTypes } from "../../../../utils/constants"
import CardDetails_v1 from "../../../MockSwitch/CardDetails/CardDetails_v1"
import ActivatePhysicalCardStrip from "../../../core/ActivatePhysicalCardStrip/ActivatePhysicalCardStrip"
import styles from "./styles"

const CardDetailsWidget_v1 = props => {
  const navigate = useNavigate()
  const [user] = useSelector(state => [state.user])
  return (
    <View
      {...styles.mainView}
      minHeight='100%'
      bgColor={
        props.user.programType === ProgramTypes.PREPAID ? "#FFFFFF" : "#F5F4F5"
      }
    >
      <View {...styles.header}>
        <ChevronLeftIcon
          cursor='pointer'
          size='24px'
          // onClick={() => navigate(-1)}
          onClick={() => navigate("/")}
        />
        {/* <Text {...styles.title} fontFamily={props.theme.fontFamily}>
          {props.theme.title}
        </Text> */}
      </View>
      {props.user.programType !== ProgramTypes.PREPAID && (
        <View
          {...styles.cardContainer}
          w={
            props.theme.cardOrientation === CardOrientation.HORIZONTAL
              ? "100%"
              : "87%"
          }
        >
          {props.theme.cardOrientation === CardOrientation.HORIZONTAL ? (
            <View>
              <img
                src={props.theme.cardDesignUrl || HorizontalCard}
                width='100%%'
                height='100%'
                alt='Alternate Text '
              />
            </View>
          ) : (
            <View>
              {" "}
              <Image
                source={{
                  uri: props.theme.cardDesignUrl || VerticalCard,
                }}
                width='216px'
                height='343px'
                alt='Alternate Text '
              />
            </View>
          )}
        </View>
      )}
      {props.error ? (
        <View>
          <Image {...styles.image} source={{ uri: ErrorImage }} />
          <View {...styles.widgetErrorTextContainer}>
            <Text {...styles.widgetErrorText}>
              An error occurred while loading card details
            </Text>
          </View>
        </View>
      ) : !props.user.enablePciWidget ? (
        <View
          {...styles.widget}
          alignSelf='center'
          w={props.user.programType !== ProgramTypes.PREPAID ? "100%" : "82%"}
        >
          <CardDetails_v1
            theme={props.theme}
            widgetDetails={props.widgetDetails}
            user={props.user}
          />
        </View>
      ) : (
        <View {...styles.widget}>
          <iframe
            height='500'
            title='Card Details Widget'
            allow='clipboard-write'
            src={props.url}
          />
        </View>
      )}
      {!props.error &&
        props.user.programType === ProgramTypes.PREPAID &&
        !props.isOnlineActive &&
        (user.programType === ProgramTypes.PREPAID
          ? props.user.cards?.[0]?.cardType === "Virtual"
            ? !(props.user.cards?.[0]?.isVirtualCardActivated)
            : !(props.user.cards?.[0]?.isPhysicalCardActivated)
          : !props.user.cards?.[0]?.isPhysicalCardActivated) && (
          <View mt='24px'>
            <ActivatePhysicalCardStrip
              width='100%'
              to='/ManageCard/CardSettings'
              image={onlineTransactionActivation}
              text='Activate Online Transaction'
              subText='Enable online usage of your card'
            />
          </View>
        )}
    </View>
  )
}

export default CardDetailsWidget_v1
