import { View, Text, ChevronRightIcon } from "native-base"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styles from "../AllTransactions/v2/styles"
import { AU_BlockCodes, Federal_BlockCodes } from "../../utils/enums"
import { EmiStatus, TRANSACTION_LOGO_HEIGHT, TRANSACTION_LOGO_WIDTH } from "../../utils/constants"
import {
  capitalizeFirsLetter,
  formatAmount,
  getTransactionDate,
  truncateString,
} from "../../utils/functions"

import { getEmiLabel } from "../AllTransactions/v2/transactions"
import TransactionLogo from "./transactionLogo"
export const Transaction = ({ item }) => {
  const navigate = useNavigate()
  const [theme, session] = useSelector(state => [state.theme, state.session])
  const [isDescFull, setIsDescFull] = useState(false)

  const handleConvertToEmiClick = transaction => {
    navigate("/ConvertToEMI", {
      state: {
        txnId: transaction.id,
        amount: transaction.amount,
        description: capitalizeFirsLetter(transaction.description),
        logo: transaction.identifiedMerchantLogo,
        transactionDate: transaction.transactionDate,
        postingDate: transaction.postingDate,
        txnType: transaction.txnType,
        prevPath: "/",
      },
    })
  }
  return (
    <>
    <View mb='16px'>
              {item.identifiedMerchantLogo ? (
                <img
                  {...styles.image}
                  // height="52px"
                  src={`data:image/jpg;base64,${item.identifiedMerchantLogo}`}
                  alt='logo'
                  width={TRANSACTION_LOGO_WIDTH + "px"}
                  height={TRANSACTION_LOGO_HEIGHT + "px"}
                />
              ) : (
                <View h="51px">
                  <TransactionLogo transactionType={item.txnType} />
                </View>
                
              )}
            </View>
    <View  mb='18px' ml='15px' style={{ width: "80%", flex: "auto" }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: "5" }}>
          <View>
            <Text
              {...styles.transactee}
              fontFamily={theme.fontFamily}
              onClick={() => setIsDescFull(!isDescFull)}
              color={theme.appTextColor}
            >
              {isDescFull
                ? capitalizeFirsLetter(item.description)
                : truncateString(
                    capitalizeFirsLetter(item.description) || "",
                    12,
                  )}
            </Text>
          </View>
        </View>
        <View
          // justifyContent= "right"
          flex='4'
          {...styles.transactionAmountContainer}
          mb={"5px"}
          justifyContent='flex-end'
          mr='5px'
        >
          <Text {...styles.transactionType} fontFamily={theme.fontFamily}>
            {item.txnNature === "DEBIT" ? "-" : "+"}
          </Text>
          <Text
            {...styles.transactionAmount}
            fontFamily={theme.fontFamily}
            color={theme.appTextColor}
            fontSize={"14px"}
            fontWeight={"400"}
          >
            {formatAmount(item.amount)}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 5 }}>
          <Text
            {...styles.transactionTime}
            fontFamily={theme.fontFamily}
            color={theme.appTextColor}
          >
            {getTransactionDate(item.transactionDate, item.postingDate)}
          </Text>
        </View>

        <View
          styles={{
            flexDirection: "column",
            flex: "4",
          }}
          justifySelf='end'
          justifyContent={"end"}
          alignSelf={"center"}
        >
          {(session.disabledFunctionsByBlockCode.includes(
            AU_BlockCodes.EMI_CONVERSION_DISABLE,
          ) ||
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.EMI_CONVERSION_DISABLE,
            )) === false &&
            item.emiAllowed &&
            item.emiStatus === EmiStatus.NOT_CREATED && (
              <View
                {...styles.transactionConvertToEMI}
                justifyContent='end'
                mt='1px'
                cursor={"pointer"}
              >
                <Text
                  {...styles.transactionConvertToEMIText}
                  color={theme.color1}
                  textAlign={"end"}
                  fontSize={"13px"}
                  onClick={() =>
                    session.disabledFunctionsByBlockCode.includes(
                      AU_BlockCodes.EMI_CONVERSION_DISABLE,
                    ) ||
                    session.disabledFunctionsByBlockCode.includes(
                      Federal_BlockCodes.EMI_CONVERSION_DISABLE,
                    )
                      ? null
                      : handleConvertToEmiClick(item)
                  }
                >
                  Convert to EMI
                </Text>
                <ChevronRightIcon
                  color={theme.color1}
                  size='20px'
                  width='fit-content'
                />
              </View>
            )}
          {getEmiLabel(item.emiStatus) && (
            <View
              {...styles.transactionConvertedToEMI}
              alignSelf='end'
              justifySelf={"end"}
              justifyContent='end'
            >
              <Text {...styles.transactionConvertedToEMIText}>
                {getEmiLabel(item.emiStatus)}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
    </>
  )
}

export default Transaction;
