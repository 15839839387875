import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { View, Text, ChevronRightIcon } from "native-base"
import { ColorButton } from "../../../core/buttons"
import { useSelector } from "react-redux"
import styles from "./styles"
import {
  truncateString,
  getTransactionDate,
  formatAmount,
  capitalizeFirsLetter,
} from "../../../../utils/functions"
import {
  TRANSACTION_LOGO_WIDTH,
  TRANSACTION_LOGO_HEIGHT,
  EmiStatus,
} from "../../../../utils/constants"
import TransactionLogo from "../../../core/transactionLogo"
import {
  getEmiLabel,
  getEmiLabelColor,
} from "../../../AllTransactions/v1/transactions"

const TransactionSection_v1 = props => {
  const navigate = useNavigate()

  const theme = useSelector(state => state.theme)
  const [isDescFull, setIsDescFull] = useState(false)
  const ViewTransaction = () => {
    navigate("/Transactions", {
      state: {
        data: props.recentTransactions,
      },
    })
  }
  const handleConvertToEmiClick = transaction => {
    navigate("/ConvertToEMI", {
      state: {
        txnId: transaction.id,
        amount: transaction.amount,
        description: capitalizeFirsLetter(transaction.description),
        logo: transaction.identifiedMerchantLogo,
        transactionDate: transaction.transactionDate,
        postingDate: transaction.postingDate,
        txnType: transaction.txnType,
        prevPath: "/",
      },
    })
  }
  return (
    <>
      <View w='90%'>
        <Text
          fontFamily={theme.fontFamily}
          fontWeight='400'
          fontSize='18px'
          lineHeight='24px'
          color='#303030'
          mt='24px'
        >
          Recent Transactions
        </Text>
      </View>
      <View
        mt='16px'
        p='16px'
        w='90%'
        borderRadius={"8px"}
        borderWidth={"1px"}
        borderColor='#ECECEC'
      >
        {props.recentTransactions.map((transaction, index) => (
          <View {...styles.transactionContainer} key={index}>
            <View mb='16px' width='20%'>
              {transaction.identifiedMerchantLogo ? (
                // <Image
                //   source={transaction.identifiedMerchantLogo}
                //   {...styles.image}
                // />
                <img
                  {...styles.image}
                  src={`data:image/jpg;base64,${transaction.identifiedMerchantLogo}`}
                  alt='logo'
                  width={TRANSACTION_LOGO_WIDTH + "px"}
                  height={TRANSACTION_LOGO_HEIGHT + "px"}
                />
              ) : (
                <TransactionLogo transactionType={transaction.txnType} />
              )}
            </View>
            <View w='50%' mb='16px'>
              <View>
                <Text
                  {...styles.transactee}
                  fontFamily={theme.fontFamily}
                  onClick={() => setIsDescFull(!isDescFull)}
                >
                  {isDescFull
                    ? capitalizeFirsLetter(transaction.description)
                    : truncateString(
                        capitalizeFirsLetter(transaction.description) || "",
                        25,
                      )}
                </Text>
              </View>
              <View>
                <Text {...styles.transactionTime} fontFamily={theme.fontFamily}>
                  {getTransactionDate(
                    transaction.transactionDate,
                    transaction.postingDate,
                  )}
                </Text>
              </View>
              {transaction.emiAllowed &&
                transaction.emiStatus === EmiStatus.NOT_CREATED && (
                  <View {...styles.transactionConvertToEMI} cursor='pointer'>
                    <Text
                      {...styles.transactionConvertToEMIText}
                      color={theme.color1}
                      onClick={() => handleConvertToEmiClick(transaction)}
                    >
                      Convert to EMI
                    </Text>
                    <ChevronRightIcon
                      color={theme.color1}
                      size='20px'
                      width='fit-content'
                    />
                  </View>
                )}
            </View>
            <View
              styles={{
                display: "flex",
                flexDirection: "column",
              }}
              justifySelf='end'
              justifyContent={"end"}
              alignSelf={"center"}
              width='30%'
            >
              <View {...styles.transactionAmountContainer}>
                <Text {...styles.transactionType} fontFamily={theme.fontFamily}>
                  {transaction.txnNature === "DEBIT" ? "-" : "+"}
                </Text>
                <Text
                  {...styles.transactionAmount}
                  fontFamily={theme.fontFamily}
                >
                  {formatAmount(transaction.amount)}
                </Text>
              </View>
              {getEmiLabel(transaction.emiStatus) && (
                <View
                  {...styles.transactionConvertedToEMI}
                  background={getEmiLabelColor(transaction.emiStatus)}
                >
                  <Text {...styles.transactionConvertedToEMIText}>
                    {getEmiLabel(transaction.emiStatus)}
                  </Text>
                </View>
              )}
            </View>
          </View>
        ))}
      </View>
      <View width='100%' alignItems='center' my='16px'>
        <ColorButton text='View All' onPress={ViewTransaction} />
      </View>
    </>
  )
}

export default TransactionSection_v1
