import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  Image,
  Text,
  View
} from "native-base"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import aboutProgramReward2 from "../../../../../assets/images/aboutProgramReward2.svg"
import { Header } from "../../../../core"
import FaqAccordian from "./FaqAccordian"

const AboutProgram_v2 = () => {
  const [theme,screen] = useSelector(state => [
    state.theme,
    state.screen,
  ])
  const windowDimensions = useWindowDimensions()

  const [isOpen, setIsOpen] = useState(true)
  return (
    <View minH={windowDimensions.height} bgColor={theme.backgroundColor}>
      <Header
        text='About Card Program'
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      {/* <View
        w="100%"
        py="12px"
        px="16px"
        bgColor={theme.headerBackgroundColor}
        shadow="3"
        flexDir={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <View onClick={() => navigate("/ManageCard")}>
          <ChevronLeftIcon size="24px" color={theme.titleColor} />
        </View>
        <View>
          <Text
            fontSize="16px"
            lineHeight="22px"
            fontWeight="600"
            color={theme.titleColor}
          >
            About Card Program
          </Text>
        </View>
        <View></View>
      </View> */}
      {screen?.aboutProgram?.description && (
        <View
          mt='32px'
          mx='16px'
          borderRadius={"12px"}
          bgColor={theme.widgetBackgroundColor}
          shadow='1'
          borderColor={"#ECECEC"}
          borderWidth='1px'
          p='16px'
        >
          <Text
            fontSize='14px'
            lineHeight='22px'
            fontWeight='500'
            color={theme.appTextColor}
          >
            {screen?.aboutProgram?.description}
          </Text>
        </View>
      )}

      <View
        mt='32px'
        mx='16px'
        borderRadius={"12px"}
        bgColor={theme.widgetBackgroundColor}
        shadow='1'
        borderColor={"#ECECEC"}
        borderWidth='1px'
      >
        {screen?.aboutProgram?.cvp.map(item => (
          <View
            p='24px'
            borderColor={"#ECECEC"}
            flexDir={"row"}
            alignItems='center'
            justifyContent={"center"}
          >
            <View w='28%' mr='2%'>
              {item?.imageUrl && (
                <Image
                  source={{
                    uri: item?.imageUrl || aboutProgramReward2,
                  }}
                  width='72px'
                  height='42px'
                  alt='Alternate Text '
                />
              )}
            </View>
            <View w='70%' bgColor={theme.widgetBackgroundColor}>
              <Text
                fontSize='16px'
                lineHeight='22px'
                fontWeight='600'
                color={theme.appTextColor}
              >
                {item?.title}
              </Text>
              <Text
                fontSize='12px'
                lineHeight='16px'
                fontWeight='400'
                color={theme.appTextColor}
              >
                {item?.desc}
              </Text>
            </View>
          </View>
        ))}
      </View>
      {screen?.aboutProgram?.faq?.length > 0 && (
        <View
          mt='32px'
          mx='16px'
          p='16px'
          borderRadius={"12px"}
          bgColor={theme.widgetBackgroundColor}
          shadow='1'
          borderColor={"#ECECEC"}
          borderWidth='1px'
        >
          <View
            flexDirection={"row"}
            alignItems='row'
            justifyContent={"space-between"}
          >
            <View>
              <Text
                fontSize='16px'
                lineHeight='22px'
                fontWeight='700'
                color={theme.appTextColor}
              >
                FAQ
              </Text>
            </View>
            <View onClick={() => setIsOpen(!isOpen)} cursor='pointer'>
              {isOpen ? (
                <ChevronUpIcon size='6' color={theme.appTextColor} />
              ) : (
                <ChevronDownIcon size='6' color={theme.appTextColor} />
              )}
            </View>
          </View>
          {isOpen && (
            <View>
              {screen?.aboutProgram?.faq.map((item, index) => (
                <FaqAccordian item={item} />
              ))}
            </View>
          )}
        </View>
      )}
      {screen?.aboutProgram?.knowMoreUrl && (
        <View
          flexDir={"row"}
          alignItems='center'
          mx='16px'
          mt='12px'
          cursor={"pointer"}
          onClick={() =>
            (window.location.href =
              screen?.aboutProgram?.knowMoreUrl || "https://www.google.com")
          }
        >
          <View flexDir={"row"} alignItems='center'>
            <Text color={theme.appTextColor}>
              {`${screen?.aboutProgram?.knowMoreText} ` ||
                "Want to know more? "}
            </Text>
            <Text color={theme.color1}>Click Here</Text>
          </View>
          <View ml='6px'>
            <ChevronRightIcon size='16px' color={theme.color1} />
          </View>
        </View>
      )}
    </View>
  )
}

export default AboutProgram_v2
