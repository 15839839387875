import { View, Text, ChevronRightIcon, Image } from "native-base"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import EMI from "../../../../../assets/images/EmiLogo.svg"
import moment from "moment"
import { setSelectedEmi } from "../../../../../store/actions/Session"
import { useNavigate } from "react-router-dom"
import { formatAmount, truncateString } from "../../../../../utils/functions"
import EmptyTransactionEmi from "../../../../svg/emptyTransactionEmi"
import { PwaVersions } from "../../../../../utils/enums"

const TransactionEmi = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }
  const [paidCounts, setPaidCounts] = useState([])
  useEffect(() => {
    const paid = props.data.map(item => {
      let temp = 0
      for (let schedule of item.schedules) {
        if (
          schedule.interest === 0 &&
          schedule.principal === 0
        ) {
          temp = temp + 1
        }
      }
      return temp
    })
    setPaidCounts(paid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View p='16px'>
      {props.data.length > 0 ? (
        props.data.map((item, index) => {
          return (
            <View
              borderRadius='8px'
              borderWidth={"1px"}
              borderColor='#ECECEC'
              bgColor={
                getVersionedDisplay() ? `${theme.appTextColor}:alpha.20` : null
              }
              mt='24px'
            >
              <View
                flexDirection={"row"}
                alignItems='center'
                justifyContent={"space-between"}
                px='14px'
                py='12px'
                borderTopRadius='8px'
                bgColor={
                  getVersionedDisplay()
                    ? `${theme.appTextColor}:alpha.10`
                    : null
                }
              >
                <View w='15%' alignItems={"center"} borderRadius='40px'>
                  {item.merchantLogo ? (
                    <Image
                      src={`data:image/jpg;base64,${item.merchantLogo}`}
                      width='32px'
                      height='32px'
                    />
                  ) : (
                    <Image
                      source={{ uri: EMI }}
                      alt='Card Control Icon'
                      width='32px'
                      height='32px'
                    />
                  )}
                </View>
                <View w='65%'>
                  <View>
                    <Text
                      fontSize='12px'
                      lineHeight='16px'
                      fontWeight='600'
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {item.txnDescription
                        ? truncateString(item.txnDescription, 40)
                        : ""}
                    </Text>
                  </View>
                  <View mt='4px'>
                    <Text
                      fontSize='12px'
                      lineHeight='16px'
                      fontWeight='400'
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {`${moment(item.createdOn).format("MMM 'YY")} EMIs`}
                    </Text>
                  </View>
                </View>
                <View w='20%' alignItems={"center"}>
                  <View>
                    <Text
                      fontSize='12px'
                      lineHeight='16px'
                      fontWeight='600'
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {formatAmount(item.principal)}
                    </Text>
                  </View>
                  <View
                    mt='4px'
                    borderWidth='1px'
                    px='4px'
                    py='1px'
                    borderRadius='4px'
                    borderColor={
                      getVersionedDisplay() ? theme.appTextColor : null
                    }
                  >
                    <Text
                      fontSize='11px'
                      lineHeight='16px'
                      fontWeight='500'
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {`${paidCounts[index]}/${item.tenureInMonths} Paid`}
                    </Text>
                  </View>
                </View>
              </View>
              <View>
                {item.schedules.map((schedule, index) => (
                  <View
                    px='12px'
                    pt='12px'
                    bgColor={
                      getVersionedDisplay() ? theme.widgetBackgroundColor : null
                    }
                    alignItems='center'
                  >
                    <View
                      pb={item.schedules.length !== index + 1 ? "16px" : "12px"}
                      w='100%'
                      borderBottomWidth={
                        item.schedules.length !== index + 1 ? "1px" : "0px"
                      }
                      borderBottomColor={"#F8F8F8"}
                      alignItems='center'
                      justifyContent={"space-between"}
                      flexDirection='row'
                    >
                      <View>
                        <View>
                          <Text
                            fontSize='16px'
                            lineHeight='20px'
                            fontWeight='400'
                            color={
                              getVersionedDisplay() ? theme.appTextColor : null
                            }
                          >
                            {`EMI ${index + 1}/${item.schedules.length}`}
                          </Text>
                        </View>
                        <View>
                          <Text
                            fontSize='11px'
                            lineHeight='16px'
                            fontWeight='400'
                            color='#036F29'
                          >
                            {`Due in ${moment(schedule.paymentDueDate).format(
                              "MMM Do 'YY",
                            )}`}
                          </Text>
                        </View>
                      </View>
                      <View>
                        <Text
                          fontSize='16px'
                          lineHeight='20px'
                          fontWeight='400'
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          {formatAmount(item.monthlyEmi)}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
              <View
                p='12px'
                w='100%'
                borderTopWidth={"1px"}
                borderTopColor={"#F8F8F8"}
              >
                <View
                  alignItems={"center"}
                  flexDir='row'
                  cursor='pointer'
                  onClick={() => {
                    dispatch(setSelectedEmi({ currentSelectedEmi: item }))
                    navigate("/ManageCard/ForecloseEmi")
                  }}
                >
                  <Text
                    fontSize='16px'
                    lineHeight='20px'
                    fontWeight='500'
                    color={theme.color1}
                  >
                    Preclose
                  </Text>
                  <ChevronRightIcon
                    mb='-2px'
                    ml='4px'
                    size='20px'
                    color={theme.color1}
                  />
                </View>
              </View>
            </View>
          )
        })
      ) : (
        <View w='100%' mt='50%' alignItems='center' justifyContent={"center"}>
          <View>
            <EmptyTransactionEmi />
          </View>
          <View mt='12px'>
            <Text
              fontSize='16px'
              lineHeight='20px'
              fontWeight='600'
              color={getVersionedDisplay() ? theme.appTextColor : null}
            >
              No Transaction EMIs
            </Text>
          </View>
          <View w='80%' mt='16px'>
            <Text
              textAlign={"center"}
              fontSize='12px'
              lineHeight='16px'
              fontWeight='400'
              color={getVersionedDisplay() ? theme.appTextColor : null}
            >
              You can convert your transactions to EMI once the transaction has
              happened
            </Text>
          </View>
        </View>
      )}
    </View>
  )
}

export default TransactionEmi
