import { useToast, View } from "native-base"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native-web"
import PublicService from "../../../services/PublicService"
import { PwaVersions } from "../../../utils/enums"
import CustomLoader from "../../core/loader"
import { showToast1 } from "../../core/toast"
import Login_v2 from "./Login_v2"

const Login = () => {
  const loading = false
  const [accountId, setAccountId] = useState()
  const [clientId, setClientId] = useState()
  const nativeToast = useToast()

  const toast = (theme, message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }
  const login = async () => {
    try {
      const response = await PublicService.login(
        accountId,
        clientId.split("_").length > 1 ? clientId : "secret_" + clientId,
      )
      if (response.data.success) {
        window.location.href = `${process.env.REACT_APP_URL}?sessionToken=${response.data.data.token}`
      } else {
        toast("please check the details entered")
        toast(response.data.data.errors)
      }
    } catch (err) {
      console.log(err)
      toast(err)
    }
  }

  const windowDimensions = useWindowDimensions()
  const getVersioned = item => {
    switch (item) {
      case PwaVersions.V1:
        return <></>
      case PwaVersions.V2:
        return (
          <Login_v2
            login={login}
            accountId={accountId}
            setAccountId={setAccountId}
            clientId={clientId}
            setClientId={setClientId}
          />
        )
      default:
        return <></>
    }
  }
  return loading ? (
    <View
      w='100%'
      alignItems='center'
      justifyContent='center'
      height={windowDimensions.height}
    >
      <CustomLoader
        // color={theme.color1}
        color='#A8A8A8'
        width={120}
        height={120}
      />
    </View>
  ) : (
    getVersioned(PwaVersions.V2)
  )
}
export default Login
