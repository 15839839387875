import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { setCards } from "../../../../../store/actions/User"
import styles from "../styles"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { View, Text, Radio, useToast, ScrollView } from "native-base"
import { ColorButton, WhiteButton } from "../../../../core/buttons"
import CardService from "../../../../../services/CardService"
import { consoleError } from "../../../../../utils/functions"
import { showToast1 } from "../../../../core/toast"
import ApiIds from "../../../../../auth/ApiIds"
import { handleApiAuth } from "../../../../../utils/auth"
import { DisplayConfig } from "../../../../../utils/constants"

const defaultReasons = [
  { value: "CARDSTOLEN", text: "My Card Is Stolen" },
  { value: "CARDLOST", text: "Lost My Card" },
  { value: "DAMAGED", text: "My Card Is Damaged" },
  { value: "FRAUD", text: "Fraud" },
]

const BlockCardReason_v1 = () => {
  const [selectedValue, setSelectedValue] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const initCard = useRef(null)

  const [theme, user, featureFlags] = useSelector(state => [
    state.theme,
    state.user,
    state.featureFlags,
  ])

  const reasons = featureFlags?.blockCardOptions || defaultReasons

  const dispatch = useDispatch()
  const window = useWindowDimensions()
  const navigate = useNavigate()
  const nativeToast = useToast()

  useEffect(() => {
    // store and check init card state as this screen changes hotlist status
    initCard.current = initCard.current || user.cards?.[0]

    if (
      user.account.status !== "ACTIVE" ||
      !initCard.current ||
      initCard.current.isHotlisted
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards])

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const handleSubmitClick = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.HOTLIST_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to block card",
      mpinReason: "to block card",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.hotlist(apiToken, user.cards[0]?.id, {
        reason: selectedValue,
      })
      const result = response?.data
      if (result?.success) {
        const tempCard = { ...user.cards[0] }
        tempCard.isHotlisted = true
        dispatch(setCards({ cards: [tempCard] }))
        navigate("/ManageCard/BlockCard/CardBlocked", {
          state: { from: "blockCard" },
          replace: true,
        })
      } else {
        consoleError(result?.errors)
        toast("Sorry an error occurred")
        // navigate on failure also as success callback may be called from challenge screen
        // this action can be made more specific by checking current path in url and acting accordingly
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <ScrollView>
          <View
            {...styles.mainContainer}
            height={window.height}
            minHeight='560px'
          >
            <View my='24px' mx='16px'>
              {/* body content start */}
              <View justifyContent='center'>
                <Text {...styles.reasonsHeader} fontFamily={theme.fontFamily}>
                  Block Card
                </Text>
                <Text
                  {...styles.reasonsSubHeader}
                  fontFamily={theme.fontFamily}
                >
                  Please state your reason to block the card
                </Text>
              </View>
              {/* body title end */}

              {/* content box start */}
              <View mt='36px' borderRadius='8px'>
                <Radio.Group
                  name='myRadioGroup'
                  accessibilityLabel='card blocking reason'
                  colorScheme='blue'
                  value={selectedValue}
                  onChange={nextValue => {
                    setSelectedValue(nextValue)
                  }}
                >
                  {reasons.map(item => (
                    <View
                      key={item.value}
                      borderColor={
                        selectedValue === item.value ? theme.color1 : "#383838"
                      }
                      {...styles.reasonsOptionContainer}
                    >
                      <Radio value={item.value} my={1}>
                        <Text
                          {...styles.reasonsText}
                          fontFamily={theme.fontFamily}
                          color={
                            selectedValue === item.value
                              ? theme.color1
                              : "#383838"
                          }
                        >
                          {item.text}
                        </Text>
                      </Radio>
                    </View>
                  ))}
                </Radio.Group>
              </View>
              {/* content box start */}

              {/* body content end */}
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              <ColorButton
                text='Block Card'
                isDisabled={!selectedValue}
                onPress={handleSubmitClick}
                isLoading={submitLoading}
              />
              <WhiteButton
                text='Cancel'
                onPress={() => navigate("/ManageCard")}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default BlockCardReason_v1
