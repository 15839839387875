import {
  Image,
  Text,
  View,
} from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import call from "../../../../assets/images/call.svg"
import mail from "../../../../assets/images/mail.svg"
import { DisplayConfig } from "../../../../utils/constants"
import { PwaVersions } from "../../../../utils/enums"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"

const CustomerCare_v2 = () => {
  const [theme,screen, config] = useSelector(state => [
    state.theme,
    state.screen,
    state.config,
  ])
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()
  return (
    <View minH={windowDim.height} bgColor={theme.backgroundColor}>
      <Header
        text='Customer Care'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <View
        mt='15%'
        mx='16px'
        p='24px'
        bgColor={theme.widgetBackgroundColor}
        borderRadius='8px'
        borderWidth='1px'
        shadow='2'
        borderColor='#F8F8F8'
      >
        {screen?.customerCare?.descText && (
          <View mt='16px'>
            <Text
              fontSize='16px'
              lineHeight='22px'
              fontWeight='500'
              color={theme.appTextColor}
            >
              {screen?.customerCare?.descText}
            </Text>
          </View>
        )}
        {screen?.customerCare?.moblieNo && (
          <View mt='20px'>
            <View>
              <Text
                fontSize='16px'
                lineHeight='22px'
                fontWeight='400'
                color={theme.appTextColor}
              >
                {"You can call us on"}
              </Text>
            </View>
            <View
              mt='2px'
              cursor={"pointer"}
              display='flex'
              flexDir={"row"}
              alignItems='center'
              onClick={() =>
                (window.location.href = `tel:${screen?.customerCare?.moblieNo}`)
              }
            >
              <View mr='12px'>
                <Image
                  source={{ uri: call }}
                  width='20px'
                  height='20px'
                  alt='Alternate Text '
                />
              </View>
              <View>
                <Text
                  fontSize='16px'
                  lineHeight='22px'
                  fontWeight='400'
                  color={theme.appTextColor}
                >
                  {screen?.customerCare?.moblieNo}
                </Text>
              </View>
            </View>
          </View>
        )}
        {screen?.customerCare?.email && (
          <View mt='20px'>
            <View>
              <Text
                fontSize='16px'
                lineHeight='22px'
                fontWeight='400'
                color={theme.appTextColor}
              >
                {"or Email us at"}
              </Text>
            </View>
            <View
              mt='2px'
              cursor={"pointer"}
              display='flex'
              flexDir={"row"}
              alignItems='center'
              onClick={() =>
                (window.location.href = `mailto:no-${screen?.customerCare?.email}`)
              }
            >
              <View mr='12px'>
                <Image
                  source={{ uri: mail }}
                  width='20px'
                  height='20px'
                  alt='Alternate Text '
                />
              </View>
              <View>
                <Text
                  fontSize='16px'
                  lineHeight='22px'
                  fontWeight='400'
                  color={theme.appTextColor}
                >
                  {screen?.customerCare?.email}
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
      {!config?.version == PwaVersions.V2 ? (
        <View alignItems='center' justifyContent='center'>
          <View
            alignItems='center'
            justifyContent='center'
            position='fixed'
            width='100%'
            bottom='24px'
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          >
            <ColorButton
              text='Go Back'
              onPress={() => navigate("/ManageCard")}
            />
          </View>
        </View>
      ) : null}
    </View>
  )
}

export default CustomerCare_v2
