import { Image, ScrollView, Text, View } from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import ErrorImage from "../../assets/images/ServerError.svg"
import { PwaVersions } from "../../utils/enums"
import { goToRedirectUrl, isIosDevice, postMessageToIos } from "../../utils/functions"
import { ColorButton, WhiteButton } from "../core/buttons"
import styles from "./styles"

import { DisplayConfig } from "../../utils/constants"
import { Header } from "../core"

const ErrorScreen = ({
  showButtons = true,
  updateQrPageErrorScreen,
  message = "Something went wrong, our team is working on it.",
  retry = false,
}) => {
  const windowDimensions = useWindowDimensions()

  const [session, theme, config] = useSelector(state => [
    state.session,
    state.theme,
    state.config,
  ])

  const backHome = () => {
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  const handleRetryClick = () => {
    if (updateQrPageErrorScreen) {
      updateQrPageErrorScreen()
    }
  }
  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }
  return (
    <View
      {...styles.mainView}
      minH={getVersionedDisplay() ? windowDimensions.height : null}
      bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
    >
      <ScrollView width='100%'>
        <Header
          text='&nbsp;'
          onBack={async () => {
            goToRedirectUrl()
          }}
        />

        <View
          _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          {...styles.innerView}
          h={windowDimensions.height}
        >
          <View {...styles.section1} mt='30%'>
            <View
              {...styles.imageContainer}
              bgColor={
                getVersionedDisplay() ? theme.widgetBackgroundColor : null
              }
            >
              <Image {...styles.image} source={{ uri: ErrorImage }} />
            </View>
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}>
                <Text
                  {...styles.text1}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Oops!
                </Text>
              </View>
              <View {...styles.text2Container}>
                <Text
                  {...styles.text2}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {message}
                </Text>
              </View>
            </View>
          </View>
          <View
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            {...styles.button}
            mt='20%'
            position={"fixed"}
            bottom={"24px"}
          >
            {showButtons &&
              (retry ? (
                <>
                  <ColorButton text='Retry' onPress={handleRetryClick} />
                  {(config?.version !== PwaVersions.V2) ? (
                    <WhiteButton text='Go Back' onPress={backHome} />
                  ) : null}
                </>
              ) : (config?.version !== PwaVersions.V2) ? (
                <ColorButton text='Go Back' onPress={backHome} />
              ) : null)}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default ErrorScreen
