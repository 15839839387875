import { Image, ScrollView, Text, View } from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import ExpiredImage from "../../assets/images/sessionExpired.svg"
import { PwaVersions } from "../../utils/enums"
import { goToRedirectUrl, isIosDevice, postMessageToIos } from "../../utils/functions"
import { Header } from "../core"
import { ColorButton } from "../core/buttons"
import styles from "./styles"

import { DisplayConfig } from "../../utils/constants"

const SessionExpired = () => {
  const windowDimensions = useWindowDimensions()

  const [session, theme, config] = useSelector(state => [
    state.session,
    state.theme,
    state.config,
  ])

  const backHome = () => {
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  const getVersionedDisplay = () => {
    return config?.version === PwaVersions.V2
  }

  return (
    <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
      <View>
        <ScrollView>
          <Header
            marginBottom='0px'
            text='Session Expired'
            onBack={async () => {
              goToRedirectUrl()
            }}
          />
          <View
            {...styles.innerView}
            h={windowDimensions.height}
            minH={windowDimensions.height}
            bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
          >
            <View {...styles.section1}>
              <View
                {...styles.imageContainer}
                bgColor={
                  getVersionedDisplay() ? theme.widgetBackgroundColor : null
                }
              >
                <Image {...styles.image} source={{ uri: ExpiredImage }} />
              </View>
              <View {...styles.text1Container1}>
                <View {...styles.text1Container2}>
                  <Text
                    {...styles.text1}
                    fontFamily={theme.fontFamily}
                    color={getVersionedDisplay() ? theme.appTextColor : null}
                  >
                    Session Expired
                  </Text>
                </View>
                <View {...styles.text2Container}>
                  <Text
                    {...styles.text2}
                    fontFamily={theme.fontFamily}
                    color={getVersionedDisplay() ? theme.appTextColor : null}
                  >
                    Your session has expired, please go back and try again.
                  </Text>
                </View>
              </View>
            </View>
            {!config?.version == PwaVersions.V2 ? (
              <View {...styles.button} mt='20%'>
                <ColorButton text='Go Back' onPress={backHome} />
              </View>
            ) : null}
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default SessionExpired
