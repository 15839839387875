import moment from "moment"
import { ChevronDownIcon, ChevronUpIcon, Image, Text, View } from "native-base"
import React, { useState } from "react"
import rewardsTransactionLogo from "../../../assets/images/rewardsTransactionLogo.svg"
import { formatAmount } from "../../../utils/functions"

const Transactions = props => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <View py='12px' borderBottomWidth={"1px"} borderColor='#ECECEC'>
      <View flexDir={"row"} justifyContent='center' alignItems={"center"}>
        <View w='15%'>
          <Image
            source={{
              uri: rewardsTransactionLogo,
            }}
            width='48px'
            height='48px'
            alt='Alternate Text '
          />
        </View>
        <View w='40%'>
          <View>
            <Text
              fontFamily={props.theme.fontFamily}
              fontWeight='600'
              fontSize='14px'
              lineHeight='18px'
              letterSpacing='0.02em'
              color={props.theme.appTextColor}
            >
              {props.transaction.description}
            </Text>
          </View>
          <View>
            <Text
              fontFamily={props.theme.fontFamily}
              fontWeight='400'
              fontSize='12px'
              lineHeight='18px'
              letterSpacing='0.02em'
              color={props.theme.appTextColor}
            >
              {moment(props.transaction.outcomePostingDate).format(
                "DD MMM YYYY",
              )}
            </Text>
          </View>
        </View>
        <View w='38%' alignItems={"end"}>
          <View>
            <Text
              fontFamily={props.theme.fontFamily}
              fontWeight='600'
              fontSize='14px'
              lineHeight='18px'
              letterSpacing='0.02em'
              color={props.theme.appTextColor}
            >
              {`${props.transaction.outcomeValue} Points`}
            </Text>
          </View>
          {props.transaction.status !== "POSTED" && (
            <View p='4px' borderRadius={"4px"} backgroundColor={"#FEFF86"}>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                Pending
              </Text>
            </View>
          )}
        </View>
        <View w='7%' onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <ChevronUpIcon color={props.theme.color1} size='20px' />
          ) : (
            <ChevronDownIcon color={props.theme.color1} size='20px' />
          )}
        </View>
      </View>
      {isOpen && (
        <View
          borderRadius={"8px"}
          background={props.theme.backgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          mt='16px'
          p='12px'
        >
          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDir={"row"}
            mt='12px'
          >
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                Transaction Amount
              </Text>
            </View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {formatAmount(props.transaction.originalTransactionAmount)}
              </Text>
            </View>
          </View>

          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDir={"row"}
            mt='12px'
          >
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                Transaction Date
              </Text>
            </View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {moment(props.transaction.originalTransactionDate).format(
                  "DD MMM YYYY",
                )}
              </Text>
            </View>
          </View>

          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDir={"row"}
            mt='12px'
          >
            <View w='60%'>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                Benefit Applied
              </Text>
            </View>
            <View w='40%'>
              <Text
                textAlign={"right"}
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {props.transaction.benefitName}
              </Text>
            </View>
          </View>
        </View>
      )}
    </View>
  )
}

export default Transactions
