import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const EyeHide = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon
      viewBox='0 0 24 20'
      size='30px'
      alignItems={"center"}
      justifyContent={"center"}
    >
      <svg
        width='24'
        aria-hidden='true'
        height='20'
        viewBox='0 0 24 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.83156 0.384817C2.81093 0.388567 2.79031 0.394191 2.77156 0.399817C2.59155 0.431691 2.44718 0.562942 2.39655 0.737317C2.34593 0.913567 2.39843 1.10107 2.53156 1.22482L20.7716 19.4648C20.9628 19.6561 21.2703 19.6561 21.4616 19.4648C21.6528 19.2736 21.6528 18.9661 21.4616 18.7748L17.3966 14.7098C20.5522 13.0073 22.7797 10.4742 22.9166 10.3148C23.0703 10.1348 23.0703 9.86482 22.9166 9.68482C22.7084 9.44294 17.7603 3.75982 11.9966 3.75982C10.4084 3.75982 8.89343 4.19482 7.51156 4.82482L3.22155 0.534817C3.12218 0.427942 2.97781 0.373567 2.83156 0.384817ZM11.9966 4.71982C12.1841 4.71982 12.3659 4.73669 12.5516 4.74982C15.2009 5.03107 17.2766 7.27732 17.2766 9.99982C17.2766 11.2804 16.8228 12.4598 16.0616 13.3748L13.6466 10.9598C13.8134 10.6767 13.9166 10.3523 13.9166 9.99982C13.9166 8.93857 13.0578 8.07982 11.9966 8.07982C11.6441 8.07982 11.3197 8.18294 11.0366 8.34982L8.62156 5.93482C9.53656 5.17357 10.7159 4.71982 11.9966 4.71982ZM5.75656 5.75482C3.04343 7.41982 1.20218 9.53857 1.07655 9.68482C0.922805 9.86482 0.922805 10.1348 1.07655 10.3148C1.28468 10.5567 6.23281 16.2398 11.9966 16.2398C12.1897 16.2398 12.3772 16.2267 12.5666 16.2098C13.6034 16.1423 14.6159 15.9154 15.5666 15.5648L14.5916 14.5898C13.8228 15.0267 12.9434 15.2798 11.9966 15.2798C11.8091 15.2798 11.6272 15.2629 11.4416 15.2498C8.79218 14.9686 6.71656 12.7223 6.71656 9.99982C6.71656 9.05294 6.96968 8.17357 7.40656 7.40482L5.75656 5.75482ZM10.0916 10.0748C10.1309 11.0723 10.9222 11.8654 11.9216 11.9048L10.0916 10.0748Z'
          fill={theme.appTextColor}
        />
      </svg>
    </Icon>
  )
}

export default EyeHide
