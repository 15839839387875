import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import StatementData_v1 from "./StatementData_v1"
import StatementData_v2_new from "./StatementData_v2_new"

const StatementData = props => {
  const [config] = useSelector(state => [state.config])
  const getVersionedComponent = version => { 
    switch (version) {
      case PwaVersions.V1:
        return <StatementData_v1 statement={props.statement} />
      case PwaVersions.V2:
        return <StatementData_v2_new statement={props.statement} />
      default:
        return <StatementData_v1 statement={props.statement} />
    }
  }
  return getVersionedComponent(config?.version)
}

export default StatementData
