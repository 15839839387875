import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const PosIcon = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 30 30' size='24px'>
     <svg width="24" height="24" viewBox="0 0 24 24" fill={theme.appTextColor} xmlns="http://www.w3.org/2000/svg">
<path d="M19.8311 7.64316H16.1274V5.38643C16.1274 4.80668 15.642 4.33489 15.0455 4.33489L13.9816 4.33471V2.61339C13.9816 2.27463 13.699 2 13.3504 2H5.777C5.42844 2 5.14587 2.27462 5.14587 2.61339V4.33471H4.08196C3.48545 4.33471 3 4.80651 3 5.38625V20.9485C3 21.5282 3.48545 22 4.08196 22H15.0456C15.6421 22 16.1275 21.5282 16.1275 20.9485V18.4385H19.8312C20.4755 18.4385 20.9998 17.9289 20.9998 17.3027L21 8.77887C21 8.15252 20.4756 7.64326 19.8313 7.64326L19.8311 7.64316ZM12.3407 17.6996V16.4728H13.603V17.6996H12.3407ZM13.603 18.8773V20.1041H12.3407V18.8773H13.603ZM12.3407 15.2951V14.0683H13.603V15.2951H12.3407ZM5.52436 12.4274V8.74715H13.6028V12.4274H5.52436ZM8.93245 17.6995V16.4727H10.1947V17.6995H8.93245ZM10.1947 18.8772V20.104H8.93245V18.8772H10.1947ZM8.93245 15.295V14.0682H10.1947V15.295H8.93245ZM5.52436 17.6995V16.4727H6.78663V17.6995H5.52436ZM6.78663 18.8772V20.104H5.52436V18.8772H6.78663ZM5.52436 15.295V14.0682H6.78663V15.295H5.52436ZM6.40795 3.22642H12.7193V5.9253H13.8553V6.66135H5.27185V5.9253H6.40786L6.40795 3.22642ZM19.7374 17.2116H16.1274V8.8695H17.5009V15.3713H18.7631V8.8695H19.7374V17.2116Z" fill={theme.appTextColor}/>
</svg>

    </Icon>
  )
}

export default PosIcon
