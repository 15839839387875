import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import Statement from "./Main"
import StatementNew from "./MainNew"


const StatementData = props => {
  const [config] = useSelector(state => [state.config])
  

  const getVersionedComponent = version => { 
    switch (version) {
      case PwaVersions.V1:
        return < Statement />
      case PwaVersions.V2:
        return < StatementNew/>
      default:
        return < Statement />
    }
  }
  return getVersionedComponent(config?.version)
}

export default StatementData
