import { View } from "native-base"
import React from "react"
import {
  EmiStatus
} from "../../../utils/constants"
import Transaction from "../../core/Transaction"
import styles from "./styles"

export const getEmiLabel = emiStatus => {
  if (emiStatus === EmiStatus.NOT_CREATED) {
    return null
  } else if (emiStatus === EmiStatus.PENDING) {
    return "EMI Pending"
  } else if (emiStatus === EmiStatus.ACTIVE) {
    return "EMI Converted"
  } else if (emiStatus === EmiStatus.CANCELLED) {
    return "EMI Cancelled"
  } else if (emiStatus === EmiStatus.FORECLOSED) {
    return "EMI Foreclosed"
  } else if (emiStatus === EmiStatus.CLOSED) {
    return "EMI Closed"
  } else {
    return null
  }
}

export const getEmiLabelColor = emiStatus => {
  if (emiStatus === EmiStatus.NOT_CREATED) {
    return null
  } else if (emiStatus === EmiStatus.PENDING) {
    return "#fcf55f"
  } else if (emiStatus === EmiStatus.ACTIVE) {
    return "#d5f0aa"
  } else if (emiStatus === EmiStatus.CANCELLED) {
    return "#ff7575"
  } else if (emiStatus === EmiStatus.FORECLOSED) {
    return "#ff7575"
  } else if (emiStatus === EmiStatus.CLOSED) {
    return "#ff7575"
  } else {
    return null
  }
}

const Transactions = props => {
  return (
    <>
      {/* Transactions Section */}
      <View>
        <View mt="16px" ml='16px'>
          <View>
            <View
              {...styles.transactionContainer}
              borderBottomWidth={props.isLast ? null : "1px"}
            >
              <Transaction item={props.transaction}/>
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default Transactions
