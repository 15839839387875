import { Checkbox, Input, Text, useToast, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import Loader from "../../../components/core/loader"
import CardService from "../../../services/CardService"
import CommonService from "../../../services/CommonService"
import {
  setDeviceId,
  setIsMpinSet,
  setVerifiedChallenges,
} from "../../../store/actions/Auth"
import {
  clearSetMpinState,
  setSetMpinApiToken,
} from "../../../store/actions/SetMpin"
import { setCards } from "../../../store/actions/User"
import {
  getHeaderText,
  handleApiAuth,
  showCancelButton,
} from "../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
} from "../../../utils/constants"
import {
  Federal_BlockCodes,
  PwaVersions,
  SetMpinTncActions,
} from "../../../utils/enums"
import { consoleError, goToRedirectUrl } from "../../../utils/functions"
import { EmbeddedLink, Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import SetMpinIntro from "./SetMpinIntro"
import SetMpinSuccess from "./SetMpinSuccess"
import styles from "./styles"
import AuthLoader from "../../core/SkeletonLoader/authLoader"

// As of now, set mpin screen can be reached from -
// 1. App component
// 2. From middle of an auth by clicking forgot mpin option or mpin gets blocked due to multiple incorrect attempts
// 3. Change mpin option in manage card screen
// Success and failure actions will differ based on the screen the user is coming from and they need to be set in set mpin store

const SetMpin = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()
  const location = useLocation()

  const [theme, setMpinStore, user, authStore, screen, config, session] =
    useSelector(state => [
      state.theme,
      state.setMpin,
      state.user,
      state.auth,
      state.screen,
      state.config,
      state.session,
    ])

  const setMpinConfig = screen.mpin?.setMpin || {}

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [showSetMpinSuccess, setShowSetMpinSuccess] = useState(false)
  const [isIntroDone, setIsIntroDone] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(
    Boolean(
      authStore.afaDetails.config?.isMpinEverSet?.result
        ? setMpinConfig?.tncIsCheckedByDefaultSubsequent
        : setMpinConfig?.tncIsCheckedByDefault,
    ),
  )
  const [pin, setPin] = useState(["", "", "", "", "", "", ""])
  const [confirmPin, setConfirmPin] = useState(["", "", "", "", "", "", ""])

  const mpin0 = useRef(null)
  const mpin1 = useRef(null)
  const mpin2 = useRef(null)
  const mpin3 = useRef(null)
  const mpin4 = useRef(null)
  const mpin5 = useRef(null)
  const mpin6 = useRef(null)

  const confirmMpin0 = useRef(null)
  const confirmMpin1 = useRef(null)
  const confirmMpin2 = useRef(null)
  const confirmMpin3 = useRef(null)
  const confirmMpin4 = useRef(null)
  const confirmMpin5 = useRef(null)
  const confirmMpin6 = useRef(null)

  // check version
  const isV2 = config?.version === PwaVersions.V2

  const showHeader = isV2
  const headerText = getHeaderText(AuthChallenges.VERIFY_CUSTOMER)
  const mpinSize =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE

  // isLogin is set if coming from app component
  const isLogin = Boolean(location.state?.isLogin)

  // isChangeMpin is set if coming from change mpin option in manage card
  const isChangeMpin = Boolean(location.state?.isChangeMpin)

  const showIntro = isV2 && isLogin && !isIntroDone

  const isMpinSet = authStore.afaDetails.config.isMpinSet.result

  // if tnc action is to activate card, ensure card is not already activated
  const showTnc =
    isV2 &&
    (authStore.afaDetails.config?.isMpinEverSet?.result
      ? setMpinConfig.tncEnabledSubsequent
      : setMpinConfig.tncEnabled)
  const showCancelBtn = isMpinSet

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  useEffect(() => {
    ;(async () => {
      if (!isV2 || (isV2 && !isLogin) || (isV2 && isLogin && isIntroDone)) {
        // api auth for set mpin is to be done before showing the set mpin screen
        // if api token is already set it means api auth is done
        if (setMpinStore.apiToken) return

        if (
          isV2 &&
          session.disabledFunctionsByBlockCode.includes(
            Federal_BlockCodes.RESET_MPIN_DISABLE,
          )
        ) {
          navigate("/")
          toast("Access has been blocked to change MPIN")
          return
        }

        setLoading(true)

        await handleApiAuth({
          apiId: ApiIds.SET_MPIN,
          onAuthSuccess: onSetMpinAuthSuccess,
          onAuthFailure: onSetMpinAuthFailure,
          onAuthCancel: onSetMpinAuthCancel,
          otpReason: "to set mpin",
          mpinReason: "to set mpin",
          toast,
          navigate,
        })

        setLoading(false)
      }
    })()
  }, [isIntroDone])

  const setMpin = async () => {
    if (pin.join("").length < mpinSize) {
      setError(true)
      toast("The MPIN entered is incorrect")
      return
    }

    if (pin.join("") !== confirmPin.join("")) {
      setError(true)
      toast("Confirm MPIN doesn't match")
      setSubmitLoading(false)
      return
    }

    const finalPin = pin.join("")
    let sameValidation = false
    let consecutiveValidation = false
    const maxSameAllowed = mpinSize
    const maxConsecutiveAllowed = mpinSize
    let sameCount = 1
    let consecutiveCount = 1

    for (let ind = 1; ind < finalPin.length; ind++) {
      if (finalPin.charAt(ind) === finalPin.charAt(ind - 1)) sameCount++
      else sameCount = 1
      if (finalPin.charAt(ind) == +finalPin.charAt(ind - 1) + 1)
        consecutiveCount++
      else consecutiveCount = 1
      if (sameCount >= maxSameAllowed) sameValidation = true
      if (consecutiveCount >= maxConsecutiveAllowed)
        consecutiveValidation = true
    }

    const continuousValidation =
      setMpinStore?.mpinMetadata?.hasNonContiguousValidation
    const nonRepeatValidation =
      setMpinStore?.mpinMetadata?.hasNonRepetitiveValidation

    if (sameValidation && nonRepeatValidation) {
      toast(`MPIN cannot have ${maxSameAllowed} repetitive digits`)
      return
    }
    if (consecutiveValidation && continuousValidation) {
      toast(`MPIN cannot have ${maxConsecutiveAllowed} consecutive digits`)
      return
    }

    setSubmitLoading(true)

    try {
      const mpin = pin.join("")

      const response = await CommonService.setMpin(setMpinStore.apiToken, {
        customerId: user.customer.id,
        programId: user.account.programId,
        mpin,
      })
      const result = response.data

      if (result?.success) {
        // update isMpinSet flag
        dispatch(setIsMpinSet({ isMpinSet: { result: true } }))

        // if coming from change mpin then get an mpin verification id since user is not prompted to enter the new mpin
        if (isChangeMpin) {
          const verifyChallengeMpinResponse =
            await CommonService.verifyChallengeMpin({
              customerId: user.customer?.id,
              programId: user.account?.programId,
              mpin,
            })
          const verifyChallengeMpinResult = verifyChallengeMpinResponse.data

          if (verifyChallengeMpinResult?.success) {
            const verifiedChallenges = {
              ...authStore.verifiedChallenges,
              mpinRefId: verifyChallengeMpinResult.data.mpinRefId,
            }

            dispatch(setVerifiedChallenges({ verifiedChallenges }))
          }
        }

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        if (isV2) {
          setShowSetMpinSuccess(true)
        } else {
          await onCompletion()
        }
      } else {
        consoleError(result?.errors)
        await setMpinStore.onSetMpinFailure(result?.errors)
      }
    } catch (error) {
      consoleError(error)
      await setMpinStore.onSetMpinFailure()
    }

    setSubmitLoading(false)
  }

  const onSetMpinAuthSuccess = async apiToken => {
    // set api token to be used in set mpin api
    dispatch(setSetMpinApiToken({ apiToken }))
    // navigate back to set mpin screen after auth
    navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin } })
  }

  const onSetMpinAuthFailure = async (error, message) => {
    // first call failure method from store
    await setMpinStore.onSetMpinFailure(error, message)
    // then clear the store
    dispatch(clearSetMpinState())
  }

  const onSetMpinAuthCancel = async message => {
    await setMpinStore.onSetMpinCancel(message)
    dispatch(clearSetMpinState())
  }

  const onCompletion = async () => {
    await setMpinStore.onSetMpinSuccess()
    dispatch(clearSetMpinState())
  }

  const onCancel = async () => {
    await setMpinStore.onSetMpinCancel()
    dispatch(clearSetMpinState())
  }

  const onSetMpinSuccessSubmit = async () => {
    if (isTncChecked) {
      if (
        authStore.afaDetails.config?.isMpinEverSet?.result
          ? setMpinConfig.tncActionSubsequent ===
            SetMpinTncActions.ENABLE_DOMESTIC_ONLINE
          : setMpinConfig.tncAction === SetMpinTncActions.ENABLE_DOMESTIC_ONLINE
      ) {
        await handleApiAuth({
          apiId: ApiIds.UPDATE_CARD_CONTROLS,
          onAuthSuccess: onEnableDomesticOnlineSuccess,
          onAuthFailure: onCompletion,
          onAuthCancel: onCompletion,
          otpReason: "to enable domestic online transactions",
          mpinReason: "to enable domestic online transactions",
          toast,
          navigate,
        })
      } else if (
        authStore.afaDetails.config?.isMpinEverSet?.result
          ? setMpinConfig.tncActionSubsequent ===
            SetMpinTncActions.ACTIVATE_CARD
          : setMpinConfig.tncAction === SetMpinTncActions.ACTIVATE_CARD
      ) {
        await handleApiAuth({
          apiId: ApiIds.ACTIVATE_CARD,
          onAuthSuccess: onActivateCardSuccess,
          onAuthFailure: onCompletion,
          onAuthCancel: onCompletion,
          otpReason: "to activate card",
          mpinReason: "to activate card",
          toast,
          navigate,
        })
      } else {
        await onCompletion()
      }
    } else {
      await onCompletion()
    }
  }

  const onEnableDomesticOnlineSuccess = async apiToken => {
    try {
      const response = await CardService.updateCardControls(
        apiToken,
        user.cards[0]?.id,
        {
          updateCardControls: [
            {
              txnTags: {
                location: "DOMESTIC",
                txnType: "RETAIL",
                txnChannel: "ECOMMERCE",
              },
              isEnabled: true,
            },
          ],
        },
      )
      const result = response?.data

      if (result?.success) {
        dispatch(setCards({ cards: [result.data] }))
      } else {
        consoleError(
          "Failed to enable domestic online transaction after set mpin. Error: " +
            result.errors,
        )
      }
    } catch (error) {
      consoleError(
        "An exception occurred while enabling domestic online transaction after set mpin. Error: " +
          error,
      )
    }

    await onCompletion()
  }

  const onActivateCardSuccess = async apiToken => {
    try {
      // when activating card from tnc action, as of now activation code is not needed
      const response = await CardService.activateCard(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...user.cards?.[0] }
        tempCard.isPhysicalCardActivated = true
        dispatch(setCards({ cards: [tempCard] }))
      } else {
        consoleError(
          "Failed to activate card after set mpin. Error: " + result.errors,
        )
      }
    } catch (error) {
      consoleError(
        "An exception occurred while activating card after set mpin. Error: " +
          error,
      )
    }

    await onCompletion()
  }

  return loading ? (
    config.version === PwaVersions.V1 ?
    <View
      w='100%'
      alignItems='center'
      justifyContent='center'
      height={windowDimensions.height}
      style={{
        backgroundColor: `${
          config.version === PwaVersions.V2 ? theme.backgroundColor : "white"
        }`,
      }}
    >
      <Loader color={theme.color1} width={120} height={120} />
    </View> :(
      <View h={windowDimensions.height} backgroundColor={theme.backgroundColor}>
        <AuthLoader/>
      </View>
    )
  ) : showIntro ? (
    <SetMpinIntro
      setMpinConfig={setMpinConfig}
      onSubmit={() => setIsIntroDone(true)}
    />
  ) : showSetMpinSuccess ? (
    <SetMpinSuccess
      successImageUrl={setMpinConfig.successImageUrl}
      onSubmit={onSetMpinSuccessSubmit}
    />
  ) : (
    <View
      alignItems='center'
      bgColor={
        config?.version === PwaVersions.V2 ? theme.backgroundColor : null
      }
    >
      <View w='100%'>
        {showHeader ? (
          <Header
            text={headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          px='5'
          minHeight={
            windowDimensions.height -
            (showHeader ? HEADER_HEIGHT_IN_PX : 0) -
            20
          }
        >
          <View
            backgroundColor={
              config?.version === PwaVersions.V2
                ? theme.widgetBackgroundColor
                : "#FFFFFF"
            }
            padding='24px'
            borderRadius={"12px"}
            shadow={2}
          >
            <View>
              <Text
                {...styles.mpinVerificationText}
                fontFamily={theme.fontFamily}
                color={theme.appTextColor}
              >
                Setup a new MPIN
              </Text>
              <Text
                {...styles.saveSettingText}
                mt='7'
                color={theme.appTextColor}
              >
                Enter new MPIN
              </Text>
            </View>
            <View {...styles.pinMainView} px={0}>
              <View {...styles.pinMainView1}>
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin0}
                  value={pin[0]}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      text,
                      pin[1],
                      pin[2],
                      pin[3],
                      pin[4],
                      pin[5],
                      pin[6],
                    ])
                    if (text !== "") {
                      mpin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin1}
                  value={pin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[1] === "") {
                      mpin0.current.focus()
                      setPin(["", "", pin[2], pin[3], pin[4], pin[5], pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      pin[0],
                      text,
                      pin[2],
                      pin[3],
                      pin[4],
                      pin[5],
                      pin[6],
                    ])
                    if (text !== "") {
                      mpin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin2}
                  value={pin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[2] === "") {
                      mpin1.current.focus()
                      setPin([pin[0], "", "", pin[3], pin[4], pin[5], pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      pin[0],
                      pin[1],
                      text,
                      pin[3],
                      pin[4],
                      pin[5],
                      pin[6],
                    ])
                    if (text !== "") {
                      mpin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={mpin3}
                  value={pin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[3] === "") {
                      mpin2.current.focus()
                      setPin([pin[0], pin[1], "", "", pin[4], pin[5], pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      text,
                      pin[4],
                      pin[5],
                      pin[6],
                    ])
                    if (text !== "") {
                      mpinSize === 4
                        ? confirmMpin0.current.focus()
                        : mpin4.current.focus()
                    }
                  }}
                />
                {mpinSize > 4 && (
                  <Input
                    {...styles.textInput}
                    borderColor={error ? "#C2181B" : "fff"}
                    ref={mpin4}
                    value={pin[4]}
                    onKeyPress={({ nativeEvent }) => {
                      if (nativeEvent.key === "Backspace" && pin[4] === "") {
                        mpin3.current.focus()
                        setPin([pin[0], pin[1], pin[2], "", "", pin[5], pin[6]])
                      }
                    }}
                    onChangeText={text => {
                      if ((text < "0" || text > "9") && text !== "")
                        return false
                      setPin([
                        pin[0],
                        pin[1],
                        pin[2],
                        pin[3],
                        text,
                        pin[5],
                        pin[6],
                      ])
                      if (text !== "") {
                        mpinSize === 5
                          ? confirmMpin0.current.focus()
                          : mpin5.current.focus()
                      }
                    }}
                  />
                )}
                {mpinSize > 5 && (
                  <Input
                    {...styles.textInput}
                    borderColor={error ? "#C2181B" : "fff"}
                    ref={mpin5}
                    value={pin[5]}
                    onKeyPress={({ nativeEvent }) => {
                      if (nativeEvent.key === "Backspace" && pin[5] === "") {
                        mpin4.current.focus()
                        setPin([pin[0], pin[1], pin[2], pin[3], "", "", pin[6]])
                      }
                    }}
                    onChangeText={text => {
                      if ((text < "0" || text > "9") && text !== "")
                        return false
                      setPin([
                        pin[0],
                        pin[1],
                        pin[2],
                        pin[3],
                        pin[4],
                        text,
                        pin[6],
                      ])
                      if (text !== "") {
                        mpinSize === 6
                          ? confirmMpin0.current.focus()
                          : mpin6.current.focus()
                      }
                    }}
                  />
                )}
                {mpinSize > 6 && (
                  <Input
                    {...styles.textInput}
                    borderColor={error ? "#C2181B" : "fff"}
                    ref={mpin6}
                    value={pin[6]}
                    onKeyPress={({ nativeEvent }) => {
                      if (nativeEvent.key === "Backspace" && pin[6] === "") {
                        mpin5.current.focus()
                        setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                      }
                    }}
                    onChangeText={text => {
                      if ((text < "0" || text > "9") && text !== "")
                        return false
                      setPin([
                        pin[0],
                        pin[1],
                        pin[2],
                        pin[3],
                        pin[4],
                        pin[5],
                        text,
                      ])
                      if (text !== "") {
                        confirmMpin0.current.focus()
                      }
                    }}
                  />
                )}
              </View>
            </View>
            {/* <View mt="12px" display={error ? "flex" : "None"}>
              <Text
                fontFamily={theme.fontFamily}
                font-weight="700"
                font-size="14px"
                line-height="18px"
                letter-spacing="0.02em"
                color="#C2181B"
              >
                {error}
              </Text>
            </View> */}
            <Text
              {...styles.saveSettingText}
              mt={12}
              color={theme.appTextColor}
            >
              Confirm new MPIN
            </Text>
            <View {...styles.pinMainView} px={0}>
              <View {...styles.pinMainView1}>
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={confirmMpin0}
                  value={confirmPin[0]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      confirmPin[0] === ""
                    ) {
                      if (mpinSize === 4) {
                        mpin3.current.focus()
                        setPin([pin[0], pin[1], pin[2], "", "", "", ""])
                      } else if (mpinSize === 5) {
                        mpin4.current.focus()
                        setPin([pin[0], pin[1], pin[2], pin[3], "", "", ""])
                      } else if (mpinSize === 6) {
                        mpin5.current.focus()
                        setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                      } else if (mpinSize === 7) {
                        mpin6.current.focus()
                        setPin([
                          pin[0],
                          pin[1],
                          pin[2],
                          pin[3],
                          pin[4],
                          pin[5],
                          "",
                        ])
                      }

                      setConfirmPin([
                        "",
                        confirmPin[1],
                        confirmPin[2],
                        confirmPin[3],
                        confirmPin[4],
                        confirmPin[5],
                        confirmPin[6],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setConfirmPin([
                      text,
                      confirmPin[1],
                      confirmPin[2],
                      confirmPin[3],
                      confirmPin[4],
                      confirmPin[5],
                      confirmPin[6],
                    ])
                    if (text !== "") {
                      confirmMpin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={confirmMpin1}
                  value={confirmPin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      confirmPin[1] === ""
                    ) {
                      confirmMpin0.current.focus()
                      setConfirmPin([
                        "",
                        "",
                        confirmPin[2],
                        confirmPin[3],
                        confirmPin[4],
                        confirmPin[5],
                        confirmPin[6],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setConfirmPin([
                      confirmPin[0],
                      text,
                      confirmPin[2],
                      confirmPin[3],
                      confirmPin[4],
                      confirmPin[5],
                      confirmPin[6],
                    ])
                    if (text !== "") {
                      confirmMpin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={confirmMpin2}
                  value={confirmPin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      confirmPin[2] === ""
                    ) {
                      confirmMpin1.current.focus()
                      setConfirmPin([
                        confirmPin[0],
                        "",
                        "",
                        confirmPin[3],
                        confirmPin[4],
                        confirmPin[5],
                        confirmPin[6],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setConfirmPin([
                      confirmPin[0],
                      confirmPin[1],
                      text,
                      confirmPin[3],
                      confirmPin[4],
                      confirmPin[5],
                      confirmPin[6],
                    ])
                    if (text !== "") {
                      confirmMpin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  borderColor={error ? "#C2181B" : "fff"}
                  ref={confirmMpin3}
                  value={confirmPin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      confirmPin[3] === ""
                    ) {
                      confirmMpin2.current.focus()
                      setConfirmPin([
                        confirmPin[0],
                        confirmPin[1],
                        "",
                        "",
                        confirmPin[4],
                        confirmPin[5],
                        confirmPin[6],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    setConfirmPin([
                      confirmPin[0],
                      confirmPin[1],
                      confirmPin[2],
                      text,
                      confirmPin[4],
                      confirmPin[5],
                      confirmPin[6],
                    ])
                    if (text !== "") {
                      mpinSize === 4
                        ? confirmMpin3.current.blur()
                        : confirmMpin4.current.focus()
                    }
                  }}
                />
                {mpinSize > 4 && (
                  <Input
                    {...styles.textInput}
                    borderColor={error ? "#C2181B" : "fff"}
                    ref={confirmMpin4}
                    value={confirmPin[4]}
                    onKeyPress={({ nativeEvent }) => {
                      if (
                        nativeEvent.key === "Backspace" &&
                        confirmPin[4] === ""
                      ) {
                        confirmMpin3.current.focus()
                        setConfirmPin([
                          confirmPin[0],
                          confirmPin[1],
                          confirmPin[2],
                          "",
                          "",
                          confirmPin[5],
                          confirmPin[6],
                        ])
                      }
                    }}
                    onChangeText={text => {
                      if ((text < "0" || text > "9") && text !== "")
                        return false
                      setConfirmPin([
                        confirmPin[0],
                        confirmPin[1],
                        confirmPin[2],
                        confirmPin[3],
                        text,
                        confirmPin[5],
                        confirmPin[6],
                      ])
                      if (text !== "") {
                        mpinSize === 5
                          ? confirmMpin4.current.blur()
                          : confirmMpin5.current.focus()
                      }
                    }}
                  />
                )}
                {mpinSize > 5 && (
                  <Input
                    {...styles.textInput}
                    borderColor={error ? "#C2181B" : "fff"}
                    ref={confirmMpin5}
                    value={confirmPin[5]}
                    onKeyPress={({ nativeEvent }) => {
                      if (
                        nativeEvent.key === "Backspace" &&
                        confirmPin[5] === ""
                      ) {
                        confirmMpin4.current.focus()
                        setConfirmPin([
                          confirmPin[0],
                          confirmPin[1],
                          confirmPin[2],
                          confirmPin[3],
                          "",
                          "",
                          confirmPin[6],
                        ])
                      }
                    }}
                    onChangeText={text => {
                      if ((text < "0" || text > "9") && text !== "")
                        return false
                      setConfirmPin([
                        confirmPin[0],
                        confirmPin[1],
                        confirmPin[2],
                        confirmPin[3],
                        confirmPin[4],
                        text,
                        confirmPin[6],
                      ])
                      if (text !== "") {
                        mpinSize === 6
                          ? confirmMpin5.current.blur()
                          : confirmMpin6.current.focus()
                      }
                    }}
                  />
                )}
                {mpinSize > 6 && (
                  <Input
                    {...styles.textInput}
                    borderColor={error ? "#C2181B" : "fff"}
                    ref={confirmMpin6}
                    value={confirmPin[6]}
                    onKeyPress={({ nativeEvent }) => {
                      if (
                        nativeEvent.key === "Backspace" &&
                        confirmPin[6] === ""
                      ) {
                        confirmMpin5.current.focus()
                        setConfirmPin([
                          confirmPin[0],
                          confirmPin[1],
                          confirmPin[2],
                          confirmPin[3],
                          confirmPin[4],
                          "",
                          "",
                        ])
                      }
                    }}
                    onChangeText={text => {
                      if ((text < "0" || text > "9") && text !== "")
                        return false
                      setConfirmPin([
                        confirmPin[0],
                        confirmPin[1],
                        confirmPin[2],
                        confirmPin[3],
                        confirmPin[4],
                        confirmPin[5],
                        text,
                      ])
                      if (text !== "") {
                        confirmMpin6.current.blur()
                      }
                    }}
                  />
                )}
              </View>
            </View>
          </View>
          <View {...styles.expand}></View>
          <View alignItems='center' justifyContent='center' w='100%'>
            <View
              {...styles.button}
              _web={{ maxW: DisplayConfig.MAX_WIDTH }}
              mb='12px'
            >
              {showTnc && (
                <View
                  {...styles.button}
                  _web={{ maxW: DisplayConfig.MAX_WIDTH }}
                >
                  <View
                    {...styles.messageContainer}
                    backgroundColor={
                      config?.version === PwaVersions.V2
                        ? theme.widgetBackgroundColor
                        : "#FFFFFF"
                    }
                  >
                    <View {...styles.infoSvgContainer}>
                      <Checkbox
                        marginLeft='-3px'
                        {...styles.tncCheckBox}
                        isChecked={isTncChecked}
                        backgroundColor={isTncChecked ? theme.color1 : null}
                        onChange={state => setIsTncChecked(state)}
                      />
                    </View>
                    <Text
                      marginTop='auto'
                      {...styles.messageText}
                      color={theme.appTextColor}
                    >
                      <EmbeddedLink
                        text={
                          authStore.afaDetails.config?.isMpinEverSet?.result
                            ? setMpinConfig.tncTextSubsequent
                            : setMpinConfig.tncText
                        }
                      />
                    </Text>
                  </View>
                </View>
              )}
              <ColorButton
                text={"Submit"}
                isDisabled={
                  (showTnc &&
                    (authStore.afaDetails.config?.isMpinEverSet?.result
                      ? setMpinConfig.tncIsMandatorySubsequent
                      : setMpinConfig.tncIsMandatory) &&
                    !isTncChecked) ||
                  pin.join("").length !== mpinSize ||
                  confirmPin.join("").length !== mpinSize
                }
                onPress={setMpin}
                isLoading={submitLoading}
              />
              {showCancelBtn && config?.version != PwaVersions.V2 && (
                <WhiteButton text='Cancel' onPress={onCancel} />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SetMpin
