import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import CustomerCare_v2 from "./CustomerCare_v2"

const CustomerCare = () => {
  const [config] = useSelector(state => [state.config])
  const versionedCustomerCare = programType => {
    switch (programType) {
      case PwaVersions.V1:
        return <></>
      case PwaVersions.V2:
        return <CustomerCare_v2 />
      default:
        return <></>
    }
  }
  return versionedCustomerCare(config?.version)
}

export default CustomerCare
