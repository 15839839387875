import moment from "moment"
import {
  Button,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  Image,
  Text,
  View,
  useToast,
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { Animated } from "react-native"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import emptyRewardsDefault from "../../../../assets/images/emptyRewardsDefault.svg"
import HorizontalCard from "../../../../assets/images/sampleHorizontalCard.svg"
import VerticalCard from "../../../../assets/images/sampleVerticalCard.svg"
import CommonService from "../../../../services/CommonService"
import { CardOrientation, ProgramTypes } from "../../../../utils/constants"
import { AU_BlockCodes, Federal_BlockCodes } from "../../../../utils/enums"
import { formatAmount } from "../../../../utils/functions"
import CustomLoader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import Offers_v2 from "../OffersSection/v2/Offers_v2"
import styles from "./styles"
import "./styles.css"

const animationName = ["CARD_FLASH", "TILT_SHAKING", "GROW", "VIEW_CARD"]
const overlayPosition = {
  BOTTOM: "BOTTOM",
  RIGHT: "RIGHT",
  NONE: "NONE",
}

const CardAndBalance_v2 = props => {
  const navigate = useNavigate()
  const nativeToast = useToast()
  const viewCard = true
  const [redirectionLoading, setRedirectionLoading] = useState(false)
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)
  const animatedController = useRef(new Animated.Value(0)).current
  const [isOpen, setIsOpen] = useState(
    props?.showReminder?.toDate &&
      moment(props.showReminder.toDate).format("YYYY-MM-DD") <=
        moment().format("YYYY-MM-DD") &&
      moment(props.showReminder.dueDate).format("YYYY-MM-DD") >=
        moment().format("YYYY-MM-DD"),
  )
  animatedController.interpolate({
    inputRange: [-1, 1],
    outputRange: ["-0.8rad", "0.8rad"],
  })

  const [theme, user, session, preferences, screen, featureFlags] = useSelector(
    state => [
      state.theme,
      state.user,
      state.session,
      state.preferences,
      state.screen,
      state.featureFlags,
    ],
  )
  useEffect(() => {
    if (user.programType === ProgramTypes.PREPAID) setIsOpen(true)

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      setViewStatementByBlockCode(false)
    }
  }, [])
  const lrRedirection = async () => {
    setRedirectionLoading(true)
    if (screen?.home?.reward?.redirectionType === "SSO") {
      const response = await CommonService.getLrSsoUrl()
      if (response.data.success) {
        window.location.href = response.data.data.ssoUrl
      } else {
        toast("redirection to rewards failed")
        setRedirectionLoading(false)
      }
    } else if (screen?.home?.reward?.redirectionType === "URL") {
      window.location.href = screen?.home?.reward?.lrUrl
    } else {
      navigate("/rewards")
    }
    setRedirectionLoading(false)
  }

  const repayUrlCall = () => {
    window.location.href = session.repaymentUrl
  }

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const seeCardDetails = () => {
    if (!stripMsg) {
      navigate("/CardDetailsWidget")
    }
  }

  const seePrepaidCardDetails = () => {
    if (card?.isLocked) {
      toast(
        "Unlock your card from the settings \n to access virtual card details",
      )
    } else {
      navigate("/CardDetailsWidget")
    }
  }

  const account = user.account
  const card = user.cards?.[0]

  const hideVirtualCard = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIRTUAL_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      return true
    }
    return (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIRTUAL_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.TEMP_CARD_DISABLE,
      )
    )
  }

  const getStripMsg = () => {
    let msg = ""
    if (
      session.disabledFunctionsByBlockCode.includes(AU_BlockCodes.CARD_LOCKED)
    ) {
      msg ="Card Locked"
    } else if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIRTUAL_CARD_DISABLE,
      )
    ) {
      msg = "Card Blocked"
    } else if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.CARD_BLOCKED,
      )
    ) {
      msg = "Card Blocked"
    } else if (account.status === "SUSPENDED") {
      msg = "Account is temporarily suspended"
    } else if (account.status === "FORCED_SUSPENDED") {
      msg = "Account is suspended"
    } else if (account.status === "CLOSED") {
      msg = "Account is closed"
    } else if (!card) {
      msg = "Card is not issued"
    } else if (card.isHotlisted) {
      msg = "Card is permanently blocked"
    } else if (card.isLocked) {
      msg = "Card Locked"
    }
    return msg
  }
  const stripMsg = getStripMsg()
  const overlayImageUrl = theme?.iconOverlayImageUrl || ""
  const overlay = !!theme?.iconOverlayImageUrl ? theme?.iconOverlay : overlayPosition.NONE
  const animation = !stripMsg ? theme?.virtualCardAnimation : "NONE"

  const renderHorizontal = () => {
    switch (animation) {
      case animationName[0]:
        return (
          <div
            class={`container-vertical ${
              viewCard ? "container-vertical-viewcard" : ""
            }`}
          >
            <div className='strip'></div>
            <div className='rectangle-vertical'>
              <View
                borderRadius='12px'
                shadow={4}
                onClick={() =>
                  user.programType === ProgramTypes.PREPAID
                    ? seePrepaidCardDetails()
                    : seeCardDetails()
                }
              >
                <img
                  src={props.cardDetails.image || HorizontalCard}
                  // class="zoom-horizontal"
                  height='165'
                  width='261'
                  alt='Alternate Text '
                  style={{borderRadius:"12px"}}
                />
                {overlay === overlayPosition.RIGHT ? (
                  <div>
                    <Image
                      source={{ uri: overlayImageUrl }}
                      width='14px'
                      height='70px'
                      alt='Alternate Text '
                      borderRadius='12px'
                      position={"absolute"}
                      right={"0px"}
                      top={"42px"}
                    />
                  </div>
                ) : null}
                {overlay === overlayPosition.BOTTOM ? (
                  <div style={{ position: "relative" }}>
                    <Image
                      source={{ uri: overlayImageUrl }}
                      width='70px'
                      height='14px'
                      alt='Alternate Text '
                      borderRadius='12px'
                      position={"absolute"}
                      bottom={"0px"}
                      right={"88px"}
                    />
                  </div>
                ) : null}
              </View>
            </div>
          </div>
        )
      case animationName[1]:
        return (
          <div class='tilt-shaking'>
            <View
              borderRadius={"12px"}
              shadow={4}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || HorizontalCard}
                // class="zoom-horizontal"
                height='165'
                width='261'
                alt='Alternate Text'
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    right={"0px"}
                    top={"42px"}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    bottom={"0px"}
                    right={"88px"}
                  />
                </div>
              ) : null}
            </View>
          </div>
        )
      case animationName[2]:
        return (
          <View alignItems={"center"} justifyContent='center'>
            <div
              class='grow-horizontal'
              style={{ backgroundColor: theme?.color1 }}
            ></div>
            {/* <div class='grow-outline'></div> */}
            <View
              zIndex={1}
              borderRadius='12px'
              alignSelf='center'
              justifySelf={"center"}
              shadow={4}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || HorizontalCard}
                // class="zoom-vertical"
                // class="flip"
                height='165'
                width='261'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    right={"0px"}
                    top={"42px"}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    bottom={"0px"}
                    right={"88px"}
                  />
                </div>
              ) : null}
            </View>
          </View>
        )
      case animationName[3]:
        return (
          <View alignItems={"center"} justifyContent='center'>
            <div style={{ backgroundColor: theme?.color1 }}></div>
            <View
              zIndex={1}
              borderRadius={"12px"}
              justifyContent="center"
              alignItems={"center"}
              mb="0"
              pt="9px"
              shadow={4}
              height='165'
              width='261'
              cursor={"pointer"}
              backgroundColor={theme.widgetBackgroundColor}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || HorizontalCard}
                height='130'
                width='222'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              <View>
                <Text
                  fontWeight={"bold"}
                  marginTop='0px'
                  paddingBottom={"4px"}
                  textAlign={"center"}
                  color={theme.color1}
                >
                  View Card
                  <ChevronRightIcon size='4' mb='2px' color={theme.color1} />
                </Text>
              </View>
            </View>
          </View>
        )
      default:
        return (
          <View
            borderRadius='12px'
            shadow={4}
            opacity={hideVirtualCard() ? "0.6" : "1"}
            onClick={() =>
              user.programType === ProgramTypes.PREPAID
                ? seePrepaidCardDetails()
                : seeCardDetails()
            }
          >
            <img
              src={props.cardDetails.image || HorizontalCard}
              class='zoom-horizontal'
              height='165'
              width='261'
              alt='Alternate Text '
              style={{borderRadius:"12px"}}
            />
            {overlay === overlayPosition.RIGHT ? (
              <div>
                <Image
                  source={{ uri: overlayImageUrl }}
                  width='14px'
                  height='70px'
                  alt='Alternate Text '
                  borderRadius='12px'
                  position={"absolute"}
                  right={"0px"}
                  top={"42px"}
                />
              </div>
            ) : null}
            {overlay === overlayPosition.BOTTOM ? (
              <div>
                <Image
                  source={{ uri: overlayImageUrl }}
                  width='70px'
                  height='14px'
                  alt='Alternate Text '
                  borderRadius='12px'
                  position={"absolute"}
                  bottom={"152px"}
                  right={"88px"}
                />
              </div>
            ) : null}
          </View>
        )
    }
  }
  const renderVertical = () => {
    switch (animation) {
      case animationName[0]:
        return (
          <div class='container-horizontal'>
            <div className='strip'></div>
            <div className='rectangle'>
              <View
                zIndex={1}
                borderRadius='12px'
                shadow={4}
                onClick={() =>
                  user.programType === ProgramTypes.PREPAID
                    ? seePrepaidCardDetails()
                    : seeCardDetails()
                }
              >
                <img
                  src={props.cardDetails.image || VerticalCard}
                  // class="zoom-vertical"
                  // class="flip"
                  height='261'
                  width='165'
                  alt='Alternate Text '
                  style={{borderRadius:"12px"}}
                />
                {overlay === overlayPosition.RIGHT ? (
                  <div>
                    <Image
                      source={{ uri: overlayImageUrl }}
                      width='14px'
                      height='70px'
                      alt='Alternate Text '
                      borderRadius='12px'
                      position={"absolute"}
                      right={"0px"}
                      top={"95px"}
                    />
                  </div>
                ) : null}
                {overlay === overlayPosition.BOTTOM ? (
                  <div>
                    <Image
                      source={{ uri: overlayImageUrl }}
                      width='70px'
                      height='14px'
                      alt='Alternate Text '
                      borderRadius='12px'
                      position={"absolute"}
                      bottom={"0px"}
                      right={"50px"}
                    />
                  </div>
                ) : null}
              </View>
            </div>
          </div>
        )
      case animationName[1]:
        return (
          <div class='tilt-shaking'>
            <View
              zIndex={1}
              borderRadius='12px'
              shadow={4}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || VerticalCard}
                // class="zoom-vertical"
                // class="flip"
                height='261'
                width='165'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    right={"0px"}
                    top={"95px"}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    bottom={"0px"}
                    right={"50px"}
                  />
                </div>
              ) : null}
            </View>
          </div>
        )
      case animationName[2]:
        return (
          <View alignItems={"center"} justifyContent='center'>
            <div class='grow' style={{ backgroundColor: theme?.color1 }}></div>
            {/* <div class='grow-outline'></div> */}
            <View
              zIndex={1}
              borderRadius='12px'
              alignSelf='center'
              justifySelf={"center"}
              shadow={4}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || VerticalCard}
                // class="zoom-vertical"
                // class="flip"
                height='261'
                width='165'
                alt='Alternate Text '
                style={{borderRadius:"12px"}}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='14px'
                    height='70px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    right={"0px"}
                    top={"95px"}
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <Image
                    source={{ uri: overlayImageUrl }}
                    width='70px'
                    height='14px'
                    alt='Alternate Text '
                    borderRadius='12px'
                    position={"absolute"}
                    bottom={"0px"}
                    right={"50px"}
                  />
                </div>
              ) : null}
            </View>
          </View>
        )

      case animationName[3]:
        return (
          <View alignItems={"center"} justifyContent='center'>
            <div style={{ backgroundColor: theme?.color1 }}></div>
            <View
              zIndex={1}
              borderRadius='12px'
              alignSelf='center'
              justifySelf={"center"}
              shadow={4}
              height='261'
              width='165'
              cursor={"pointer"}
              backgroundColor={theme.widgetBackgroundColor}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <Image
                margin={"auto"}
                src={props.cardDetails.image || VerticalCard}
                height='212'
                width='135'
                alt='Alternate Text '
                borderRadius='12px'
              />
              <View>
                <Text
                  fontWeight={"bold"}
                  mt='-10px'
                  pb='6px'
                  textAlign={"center"}
                  color={theme.color1}
                >
                  View Card
                  <ChevronRightIcon size='4' mb='2px' color={theme.color1} />
                </Text>
              </View>
            </View>
          </View>
        )

      default:
        return (
          <View
            opacity={hideVirtualCard() ? "0.6" : "1"}
            borderRadius='12px'
            shadow={4}
            onClick={() =>
              user.programType === ProgramTypes.PREPAID
                ? seePrepaidCardDetails()
                : seeCardDetails()
            }
          >
            <img
              src={props.cardDetails.image || VerticalCard}
              // class="zoom-vertical"
              width='165'
              height='261'
              alt='Alternate Text '
              style={{borderRadius:"12px"}}
            />
            {overlay === overlayPosition.RIGHT ? (
              <div>
                <Image
                  source={{ uri: overlayImageUrl }}
                  width='14px'
                  height='70px'
                  alt='Alternate Text '
                  borderRadius='12px'
                  position={"absolute"}
                  right={"0px"}
                  top={"95px"}
                />
              </div>
            ) : null}
            {overlay === overlayPosition.BOTTOM ? (
              <div>
                <Image
                  source={{ uri: overlayImageUrl }}
                  width='70px'
                  height='14px'
                  alt='Alternate Text '
                  borderRadius='12px'
                  position={"absolute"}
                  bottom={"0px"}
                  right={"50px"}
                />
              </div>
            ) : null}
          </View>
        )
    }
  }

  return (
    <View {...styles.horizontalContainer} mt="0px">
      {/** Card Section */}
      {props.cardDetails.orientation === CardOrientation.HORIZONTAL ? (
        <View>
          <View alignItems={"center"} justifyContent='center'>
            {renderHorizontal()}
            {screen?.home?.reward?.isEnabled && (
              <View
                mt='12px'
                padding={""}
                width={"261px"}
                flexDir='row'
                bgColor={theme.widgetBackgroundColor}
                alignItems={"center"}
                justifyContent='space-between'
                borderRadius='12px'
                borderWidth={"1px"}
                borderColor='#ECECEC'
                shadow={2}
              >
                {Number(user.account?.rewardsSummary?.accrued) === 0 ? (
                  <View w='55%' mx='16px' mt='12px'>
                    <Text
                      fontFamily={theme.fontFamily}
                      fontWeight='600'
                      fontSize='12px'
                      lineHeight='16px'
                      color={theme.appTextColor}
                    >
                      {screen?.home?.reward?.rewardEmptyText ||
                        "Start shopping to earn points!"}
                    </Text>
                  </View>
                ) : (
                  <View w='55%' mx='16px' mt='12px'>
                    {user.account?.rewardsSummary && (
                      <View>
                        <Text
                          fontFamily={theme.fontFamily}
                          fontWeight='600'
                          fontSize='12px'
                          lineHeight='16px'
                          color={theme.appTextColor}
                        >
                          {screen?.home?.reward?.rewardDescText ||
                            "Lifetime Reward Points!"}
                        </Text>
                      </View>
                    )}
                    <View
                      flexDir={"row"}
                      justifyContent='space-between'
                      alignItems={"center"}
                    >
                      <Text
                        fontFamily={theme.fontFamily}
                        fontWeight='600'
                        fontSize='14px'
                        lineHeight='28px'
                        color={theme.appTextColor}
                      >
                        {user.account?.rewardsSummary?.accrued || 
                          "View rewards"}
                      </Text>
                      {!redirectionLoading ? (
                        <ChevronRightIcon
                          size='5'
                          color={theme.appTextColor}
                          onClick={() => lrRedirection()}
                        />
                      ) : (
                        <CustomLoader
                          height='16px'
                          width='16px'
                          color={theme.appTextColor}
                        />
                      )}
                    </View>
                  </View>
                )}
                <View w='36%' ml="-15px" >
                  <Image
                    source={{
                      uri:
                        screen?.home?.reward?.rewardBannerImageUrl ||
                        emptyRewardsDefault,
                    }}
                    width='96px'
                    height='96px'
                    alt='Alternate Text '
                  />
                </View>
              </View>
            )}
          </View>
          {Boolean(stripMsg) && (
            <View
              width='261px'
              bgColor={theme.widgetBackgroundColor}
              alignItems='center'
              flexDirection='row'
              px='24px'
              py='12px'
              position='absolute'
              top='100px'
            >
              <View m="auto">
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='700'
                  fontSize='14px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                  margin="auto"
                >
                  {stripMsg}
                </Text>
              </View>
            </View>
          )}
        </View>
      ) : (
        <View w='90%'>
          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDirection={"row"}
            width='100%'
          >
            <View
              alignItems={"center"}
              justifyContent='center'
              width={!screen?.home?.reward?.isEnabled ? "100%" : null}
            >
              {renderVertical()}
            </View>

            {screen?.home?.reward?.isEnabled && (
              <View
                w='45%'
                ml='12px'
                height={"261px"}
                alignItems={"center"}
                justifyContent='center'
                bgColor={theme.widgetBackgroundColor}
                borderRadius='12px'
                borderWidth={"1px"}
                borderColor='#ECECEC'
                shadow={2}
              >
                <View w='90%'>
                  <Image
                    source={{
                      uri:
                        screen?.home?.reward?.rewardBannerImageUrl ||
                        emptyRewardsDefault,
                    }}
                    width='170px'
                    height='170px'
                    mt="-15px"
                    ml="-5px"
                    alt='Alternate Text '
                  />
                </View>
                {Number(user.account?.rewardsSummary?.accrued) === 0? (
                  <View
                    borderRadius={"4px"}
                    w='85%'
                    bg={theme.backgroundColor}
                    p={3}
                    mt="15px"
                  >
                    <Text
                      fontFamily={theme.fontFamily}
                      fontWeight='600'
                      fontSize='12px'
                      lineHeight='16px'
                      color={theme.appTextColor}
                    >
                      {screen?.home?.reward?.rewardEmptyText ||
                        "Start shopping to earn points!"}
                    </Text>
                  </View>
                ) : (
                  <View
                    borderRadius={"4px"}
                    w='85%'
                    bg={theme.backgroundColor}
                    p={3}
                  
                  >
                    {user.account?.rewardsSummary  && (
                      <View>
                        <Text
                          fontFamily={theme.fontFamily}
                          fontWeight='600'
                          fontSize='12px'
                          lineHeight='16px'
                          color={theme.appTextColor}
                        >
                          {screen?.home?.reward?.rewardDescText ||
                            "Lifetime Reward Points!"}
                        </Text>
                      </View>
                    )}
                    <View
                      flexDir={"row"}
                      justifyContent='space-between'
                      alignItems={"center"}
                    >
                      <Text
                        fontFamily={theme.fontFamily}
                        fontWeight='600'
                        fontSize='14px'
                        lineHeight='28px'
                        color={theme.appTextColor}
                      >
                        {user.account?.rewardsSummary?.accrued || 
                          "View rewards"}
                      </Text>
                      {!redirectionLoading ? (
                        <ChevronRightIcon
                          size='5'
                          color={theme.appTextColor}
                          onClick={() => lrRedirection()}
                        />
                      ) : (
                        <CustomLoader
                          height='16px'
                          width='16px'
                          color={theme.appTextColor}
                        />
                      )}
                    </View>
                  </View>
                )}
              </View>
            )}
          </View>
          {Boolean(stripMsg) && (
            <View
              alignItems={!screen?.home?.reward?.isEnabled ? "center" : null}
              justifyContent={
                !screen?.home?.reward?.isEnabled ? "center" : null
              }
              width={!screen?.home?.reward?.isEnabled ? "100%" : null}
            >
              <View
                width={"165px"}
                bgColor={theme.widgetBackgroundColor}
                alignItems='center'
                flexDirection='row'
                px='6px'
                py='12px'
                position='absolute'
                bottom={"108px"}
              >
                
                <View m='auto' w='88%'>
                  <Text
                    fontFamily={theme.fontFamily}
                    fontWeight='600'
                    fontSize='12px'
                    lineHeight='18px'
                    letterSpacing='0.02em'
                    color={theme.appTextColor}
                    margin="auto"
                  >
                    {stripMsg}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>
      )}

      {featureFlags?.showOffers && <Offers_v2 offerList={props.offersList} />}
      <View
        {...styles.horizontalCreditContainer}
        borderWidth={"0px"}
        borderRadius={"12px"}
        bgColor={theme.widgetBackgroundColor}
      >
        <View
          flexDirection={"row"}
          alignItems='center'
          justifyContent={"space-between"}
          m='16px 16px 0px 16px'
        >
          <View>
            <Text
              fontFamily={theme.fontFamily}
              fontWeight='600'
              fontSize='16px'
              lineHeight='22px'
              color={theme.appTextColor}
            >
              {user.programType === ProgramTypes.PREPAID
                ? "Balance Details"
                : "Card Summary"}
            </Text>
          </View>
          <View onClick={() => setIsOpen(!isOpen)} cursor='pointer'>
            {isOpen ? (
              <ChevronUpIcon size='6' color={theme.appTextColor} />
            ) : (
              <ChevronDownIcon size='6' color={theme.appTextColor} />
            )}
          </View>
        </View>
        {user.programType === ProgramTypes.PREPAID
          ? isOpen && (
              <View>
                <View {...styles.horizontalCreditBalanceContainer}>
                  <View>
                    <View>
                      <Text
                        {...styles.CreditBalanceHeading}
                        fontFamily={theme.fontFamily}
                        color={theme.appTextColor}
                      >
                        CURRENT BALANCE
                      </Text>
                    </View>
                    <View>
                      <Text
                        {...styles.CreditBalanceText}
                        fontFamily={theme.fontFamily}
                        color={theme.appTextColor}
                      >
                        {formatAmount(props.creditDetails.currentBalance)}
                      </Text>
                    </View>
                  </View>
                  {session.repaymentFlow && (
                    <View w='48%'>
                      <Button
                        onPress={() => repayUrlCall()}
                        mt='8px'
                        backgroundColor={theme.color1}
                        borderColor={theme.color1}
                        {...styles.btnStyles}
                      >
                        <Text
                          color={theme.color2}
                          fontFamily={theme.fontFamily}
                          fontSize='16px'
                          fontWeight='600'
                        >
                          Manage Balance
                        </Text>
                      </Button>
                    </View>
                  )}
                </View>
              </View>
            )
          : isOpen && (
              <View {...styles.horizontalCreditBalanceContainer}>
                <View>
                  <View>
                    <Text
                      {...styles.CreditBalanceHeading}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                    >
                      Available Credit
                    </Text>
                  </View>
                  <View>
                    <Text
                      {...styles.CreditBalanceText}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                    >
                      {formatAmount(props.creditDetails.availableCredit)}
                    </Text>
                  </View>
                </View>
                <View width='1px' height='100%' bgColor='#ECECEC' />
                <View>
                  <View>
                    <Text
                      {...styles.CreditBalanceHeading}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                    >
                      {user.programType === ProgramTypes.CREDIT_CARD
                        ? "Available Cash"
                        : "Used Credit"}
                    </Text>
                  </View>
                  <View>
                    <Text
                      {...styles.CreditBalanceText}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                    >
                      {formatAmount(
                        user.programType !== ProgramTypes.PAY_LATER
                          ? props.creditDetails.availableCash
                          : Number(props.creditDetails.totalCredit) -
                              Number(props.creditDetails.availableCredit),
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            )}
        {/**Balance Amount Ends  */}

        {/**Pay Reminder Setio Starts  */}

        {isOpen && session.repaymentFlow ? (
          <View
            display={props.showReminder.show ? "flex" : "none"}
            {...styles.horizontalPayReminder}
            borderTopRadius={card && !card.isHotlisted ? "0px" : "8px"}
            bgColor={theme.widgetBackgroundColor}
            borderTopWidth='2px'
            borderTopColor='#ECECEC'
            flexDirection='column'
          >
            {moment(props.showReminder.toDate).format("YYYY-MM-DD") <=
              moment().format("YYYY-MM-DD") &&
              moment(props.showReminder.dueDate).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") && (
                <View
                  mt='4px'
                  flexDir={"row"}
                  display='flex'
                  alignItems={"center"}
                >
                  <View>
                    <Text
                      {...styles.repaymentReminderText}
                      fontFamily={theme.fontFamily}
                      color={theme.appTextColor}
                    >
                      {formatAmount(props.showReminder.amount)}
                    </Text>
                  </View>
                  <Text
                    ml='4px'
                    {...styles.repaymentReminderTextLight}
                    color={`${theme.appTextColor}:alpha.80`}
                  >
                    {`Due on ${moment(props.showReminder.dueDate).format(
                      "DD MMM YYYY",
                    )}`}
                  </Text>
                </View>
              )}

            <View
              w='100%'
              flexDirection={"row"}
              alignItems='center'
              justifyContent={"space-between"}
            >
              <View
                cursor='pointer'
                {...styles.PayNowContainer}
                w='49%'
                opacity={!viewStatementByBlockCode ? "0.4" : "1"}
                bgColor={theme.color2}
                borderColor={theme.color1}
                borderWidth='1'
                color={theme.color1}
                onClick={() =>
                  !viewStatementByBlockCode ? <></> : navigate("/Statement")
                }
              >
                <Text {...styles.PayNowContainerText} color={theme.color1}>
                  View Statement
                </Text>
              </View>
              <View
                cursor='pointer'
                {...styles.PayNowContainer}
                ml='12px'
                w='48%'
                opacity={
                  session?.disabledFunctionsByBlockCode?.includes(
                    AU_BlockCodes.PAY_BILL_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode?.includes(
                    Federal_BlockCodes.PAY_BILL_DISABLE,
                  )
                    ? "0.4"
                    : "1"
                }
                bgColor={theme.color1}
                onClick={() =>
                  session.disabledFunctionsByBlockCode?.includes(
                    Federal_BlockCodes.PAY_BILL_DISABLE,
                  ) ||
                  session?.disabledFunctionsByBlockCode?.includes(
                    AU_BlockCodes.PAY_BILL_DISABLE,
                  ) ? (
                    <></>
                  ) : (
                    repayUrlCall()
                  )
                }
              >
                <Text {...styles.PayNowContainerText} color={theme.color2}>
                  Pay Bill
                </Text>
              </View>
            </View>
          </View>
        ) : (
          isOpen && (
            <View
              display={props.showReminder.show ? "flex" : "none"}
              {...styles.horizontalPayReminder}
              borderTopRadius={card && !card.isHotlisted ? "0px" : "8px"}
              bgColor={theme.color1}
              onClick={() => navigate("/Statement")}
            >
              <View flexDirection='row' alignItems='center'>
                <View>
                  <Text
                    {...styles.PayReminderText}
                    fontFamily={theme.fontFamily}
                    color={theme.appTextColor}
                    mr='4px'
                  >
                    {formatAmount(props.showReminder.amount)}
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.PayReminderTextLight}
                    fontFamily={theme.fontFamily}
                    color={theme.appTextColor}
                  >
                    due on
                  </Text>
                </View>
                <View>
                  <Text
                    {...styles.PayReminderText}
                    fontFamily={theme.fontFamily}
                    color={theme.appTextColor}
                    ml='4px'
                  >
                    {moment(props.showReminder.dueDate).format(
                      preferences.dateFormat,
                    )}
                  </Text>
                </View>
                <View>
                  <ChevronRightIcon color={theme.appTextColor} size='6' />
                </View>
              </View>
            </View>
          )
        )}

        {/**Pay Reminder Setio ends  */}
      </View>
    </View>
  )
}

export default CardAndBalance_v2
