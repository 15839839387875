import React from "react"
import { useSelector } from "react-redux"
import { View, Text, Image, ScrollView } from "native-base"
import { useWindowDimensions } from "react-native"
import { ColorButton } from "../../core/buttons"
import { CardOrientation, HEADER_HEIGHT_IN_PX } from "../../../utils/constants"
import { Header } from "../../core"
import { goToRedirectUrl } from "../../../utils/functions"
import { showCancelButton } from "../../../utils/auth"
import { PwaVersions } from "../../../utils/enums"

const SetMpinIntro = ({ setMpinConfig, onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const [theme, config] = useSelector(state => [state.theme, state.config])

  const isV2 = config?.version === PwaVersions.V2

  return (
    <View
      minH={isV2 ? windowDimensions.height : null}
      bgColor={isV2 ? theme.backgroundColor : null}
    >
      <ScrollView>
        {isV2 ? (
          <Header
            text='Setup MPIN'
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          display='flex'
          flex='1'
          flexDirection='column'
          px='16px'
          minHeight={
            windowDimensions.height - (isV2 ? HEADER_HEIGHT_IN_PX : 0) - 20
          }
        >
          <View px='20px'>
            {setMpinConfig.introTitle1 && (
              <Text
                fontWeight='700'
                fontSize='24px'
                textAlign='center'
                mt='10px'
                color={isV2 ? theme.appTextColor : null}
              >
                {setMpinConfig.introTitle1}
              </Text>
            )}
            {setMpinConfig.introTitle2 && (
              <Text
                fontWeight='500'
                fontSize='16px'
                color={isV2 ? theme.appTextColor : null}
                textAlign='center'
                mt='10px'
              >
                {setMpinConfig.introTitle2}
              </Text>
            )}
            {setMpinConfig.introImageUrl &&
              (theme.cardOrientation === CardOrientation.VERTICAL ? (
                <Image
                  source={{ uri: setMpinConfig.introImageUrl }}
                  w='100px'
                  h='150px'
                  mx='auto'
                  my='20px'
                />
              ) : (
                <Image
                  source={{ uri: setMpinConfig.introImageUrl }}
                  w='150px'
                  h='100px'
                  mx='auto'
                  my='20px'
                />
              ))}
            {setMpinConfig.introParagraph1 && (
              <Text
                fontWeight='600'
                fontSize='14px'
                color={isV2 ? theme.appTextColor : null}
              >
                {setMpinConfig.introParagraph1}
              </Text>
            )}
            {setMpinConfig.introParagraph2 && (
              <Text
                fontWeight='400'
                fontSize='14px'
                mt='15px'
                color={isV2 ? theme.appTextColor : null}
              >
                {setMpinConfig.introParagraph2}
              </Text>
            )}
            {setMpinConfig.introSteps && (
              <View mt='15px'>
                {setMpinConfig.introSteps?.split("\r\n").map((point, index) => (
                  <Text
                    fontWeight='600'
                    fontSize='14px'
                    color={isV2 ? theme.appTextColor : null}
                  >
                    {index + 1}. {point}
                  </Text>
                ))}
              </View>
            )}
          </View>
          <View display='flex' flex='1' flexDirection='column'></View>
          <View
            alignItems='center'
            justifyContent='center'
            width='100%'
            mt='20px'
          >
            <ColorButton
              mx='auto'
              text='Proceed'
              onPress={async () => onSubmit()}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default SetMpinIntro
