import {
  Radio,
  ScrollView,
  Text,
  View,
  useToast
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../../auth/ApiIds"
import CardService from "../../../../../services/CardService"
import { setCards } from "../../../../../store/actions/User"
import { handleApiAuth } from "../../../../../utils/auth"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "../../../../../utils/enums"
import { consoleError } from "../../../../../utils/functions"
import { ColorButton, WhiteButton } from "../../../../core/buttons"
import { showToast1 } from "../../../../core/toast"
import styles from "../styles"

import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import { DisplayConfig } from "../../../../../utils/constants"
import { Header } from "../../../../core"
import { BlockOptions } from "../constants"

const defaultReasons = [
  { value: "CARDSTOLEN", text: "My Card Is Stolen" },
  { value: "CARDLOST", text: "Lost My Card" },
  { value: "DAMAGED", text: "My Card Is Damaged" },
  { value: "FRAUD", text: "Fraud" },
]

const BlockCardReason_v2 = () => {
  const [selectedValue, setSelectedValue] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const initCard = useRef(null)

  const [theme, user, session, featureFlags, config] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
    state.featureFlags,
    state.config,
  ])

  const reasons = featureFlags?.blockCardOptions || defaultReasons


  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }

  const dispatch = useDispatch()
  const windowDim = useWindowDimensions()
  const navigate = useNavigate()
  const nativeToast = useToast()

  useEffect(() => {
    // store and check init card state as this screen changes hotlist status
    initCard.current = initCard.current || user.cards?.[0]

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      replaceOrBlock() === BlockOptions.ONLY_REPLACE ||
      replaceOrBlock() === BlockOptions.GRAY_OUT
    ) {
      toast("Access has been blocked to block card page")
      navigate("/")
      return false
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
    ) {
      toast("Access has been blocked")
      navigate("/")
      return
    }

    if (
      user.account.status !== "ACTIVE" ||
      !initCard.current ||
      initCard.current.isHotlisted
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards])

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const handleSubmitClick = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.HOTLIST_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to block card",
      mpinReason: "to block card",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const updateBlockCodeAction = () => {
    let disabledFunctions = session?.disabledFunctionsByBlockCode
    if (session?.issuer === Issuers.AU_BANK) {
      let index = disabledFunctions.indexOf(AU_BlockCodes.TEMP_CARD_DISABLE)
      if (index === -1) {
        disabledFunctions.push(AU_BlockCodes.TEMP_CARD_DISABLE)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
    if (session?.issuer === Issuers.FEDERAL_BANK) {
      let index = disabledFunctions.indexOf(Federal_BlockCodes.CARD_BLOCKED)
      if (index === -1) {
        disabledFunctions.push(Federal_BlockCodes.CARD_BLOCKED)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.hotlist(apiToken, user.cards[0]?.id, {
        reason: selectedValue,
      })
      const result = response?.data
      if (result?.success) {
        const tempCard = { ...user.cards[0] }
        tempCard.isHotlisted = true

        updateBlockCodeAction()

        dispatch(setCards({ cards: [tempCard] }))
        navigate("/ManageCard/BlockCard/CardBlocked", {
          state: { from: "blockCard" },
          replace: true,
        })
      } else {
        consoleError(result?.errors)
        toast("Sorry an error occurred")
        // navigate on failure also as success callback may be called from challenge screen
        // this action can be made more specific by checking current path in url and acting accordingly
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        bgColor={theme.backgroundColor}
      >
        <ScrollView>
          <Header
            text='Block Card'
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          <View
            {...styles.mainContainer}
            height={windowDim.height}
            minH={windowDim.height}
            bgColor={theme.backgroundColor}
          >
            <View
              my='24px'
              mx='16px'
              p='16px'
              bgColor={theme.widgetBackgroundColor}
              borderRadius='12px'
              borderWidth='1px'
              shadow='2'
              borderColor='#F8F8F8'
            >
              {/* body content start */}
              <View justifyContent='center'>
                <Text
                  {...styles.reasonsHeader}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                >
                  Block Card
                </Text>
                <Text
                  {...styles.reasonsSubHeader}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                >
                  Please state your reason to block the card
                </Text>
              </View>
              {/* body title end */}

              {/* content box start */}
              <View mt='36px' borderRadius='8px'>
                <Radio.Group
                  name='myRadioGroup'
                  accessibilityLabel='card blocking reason'
                  colorScheme='blue'
                  value={selectedValue}
                  onChange={nextValue => {
                    setSelectedValue(nextValue)
                  }}
                >
                  {reasons.map(item => (
                    <View
                      w='100%'
                      key={item.value}
                      borderColor={
                        selectedValue === item.value
                          ? theme.color1
                          : theme.appTextColor
                      }
                      {...styles.reasonsOptionContainer}
                      borderRadius='8px'
                    >
                      <Radio value={item.value} my={1}>
                        <Text
                          {...styles.reasonsText}
                          fontFamily={theme.fontFamily}
                          color={
                            selectedValue === item.value
                              ? theme.color1
                              : theme.appTextColor
                          }
                        >
                          {item.text}
                        </Text>
                      </Radio>
                    </View>
                  ))}
                </Radio.Group>
              </View>
              {/* content box start */}

              {/* body content end */}
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              <ColorButton
                text='Block Card'
                isDisabled={!selectedValue}
                onPress={handleSubmitClick}
                isLoading={submitLoading}
              />
              {!config?.version == PwaVersions.V2 ? (
                <WhiteButton
                  text='Cancel'
                  onPress={() => navigate("/ManageCard")}
                />
              ) : null}
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default BlockCardReason_v2
