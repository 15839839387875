import React from 'react'
import styled,{keyframes} from 'styled-components';

export const shimmer = keyframes`
0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const CardSkeleton = styled.div`
    display: ${props => props.display ? props.display : "block"};
    flex-direction: ${props => props.flexDirection ? props.flexDirection : "row"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "normal"};
    align-items: ${props => props.alignItems ? props.alignItems : "normal"};
    width: ${props => props.width ? props.width : "40px"};
    margin-top: ${props => props.mt ? props.mt : "margin"};
    margin-bottom: ${props => props.mb ? props.mb : "0"};
    margin-left: ${props => props.ml ? props.ml : "0"};
    margin-right: ${props => props.mr ? props.mr : "0"};
    margin: ${props => props.margin ? props.margin : "0"};
    padding: ${props => props.padding ? props.padding : "0"};
    height: ${props => props.height ? props.height : "40px"};
    border-radius: ${props => props.br ? props.br : "5px"};
    background: ${props => props.bg ? props.bg : "linear-gradient(to right, #a3a0a0 4%, #c7c3c3 25%, #a3a0a0 36%)"};
    animation : ${shimmer} 2s infinite linear;
    background-size: 1000px 100%;
`;
export const TextWrapperSkeleton = styled.div`
    display: ${props => props.display ? props.display : "block"};
    margin: ${props => props.margin ? props.margin : "0"};
    width: ${props => props.width ? props.width : "80px"};
    height: ${props => props.height ? props.height : "10px"};
    position: ${props => props.position ? props.position : "static"};
    background: ${props => props.bg ? props.bg : "linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)"};
    animation : ${shimmer} 2s infinite linear;
    background-size: 1000px 100%;
    border-radius: ${props => props.br ? props.br : "4px"};
`;

const Avatar = styled.div`
    width: ${props => props.width ? props.width : "40px"};
    height: ${props => props.height ? props.height : "40px"};
    border-radius: "50%;
    background: ${props => props.bg ? props.bg : "linear-gradient(to right, #a3a0a0 4%, #c7c3c3 25%, #a3a0a0 36%)"};

`;