import { Checkbox, ScrollView, Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../../auth/ApiIds"
import CardService from "../../../../../services/CardService"
import { setCards } from "../../../../../store/actions/User"
import { handleApiAuth } from "../../../../../utils/auth"
import {
  AU_BlockCodes,
  BlockReplaceTncActions,
  Federal_BlockCodes,
  Issuers,
} from "../../../../../utils/enums"
import { consoleError } from "../../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../../core"
import { ColorButton, WhiteButton } from "../../../../core/buttons"
import { showToast1 } from "../../../../core/toast"
import ErrorScreen from "../../../../messages/errorScreen"
import Info from "../../../../svg/info"
import styles from "../styles"

import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import { DisplayConfig } from "../../../../../utils/constants"

const NewCardRequest_v2 = () => {
  const windowDimension = useWindowDimensions()
  const nativeToast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [theme, user, screen, session] = useSelector(state => [
    state.theme,
    state.user,
    state.screen,
    state.session,
  ])

  const [address, setAddress] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(
    Boolean(screen?.blockReplaceCard?.tncIsCheckedByDefault),
  )
  let currentCard = user?.cards[0]

  useEffect(() => {
    ;(async () => {
      if (!user.cards[0].isHotlisted) {
        toast("Cannot request a new card, as card is already active")
        navigate("/")
        return
      }
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.CARD_DASHBOARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        toast("Access has been blocked to request a new card")
        navigate("/")
        return
      }

      if (
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        toast("Access has been blocked")
        navigate("/")
        return
      }
      if (
        user.account.status !== "ACTIVE" ||
        !user.cards[0] ||
        !user.cards[0].isHotlisted
      ) {
        navigate("/")
        return
      }

      const currentAddress = user.customer?.currentAddress

      if (currentAddress) {
        const line1 = currentAddress.line1 ? currentAddress.line1 : ""
        const line2 = currentAddress.line2 ? ", " + currentAddress.line2 : ""
        const line3 = currentAddress.line3 ? ", " + currentAddress.line3 : ""
        const city = currentAddress.city ? ", " + currentAddress.city : ""
        const pincode = currentAddress.pincode
          ? " - " + currentAddress.pincode
          : ""
        const state = currentAddress.state ? ", " + currentAddress.state : ""

        setAddress(`${line1}${line2}${line3}${city}${pincode}${state}`)
      } else {
        setError(true)
      }
    })()
  }, [])

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onEnableDomesticOnlineSuccess = async apiToken => {
    try {
      const response = await CardService.updateCardControls(
        apiToken,
        currentCard?.id,
        {
          updateCardControls: [
            {
              txnTags: {
                location: "DOMESTIC",
                txnType: "RETAIL",
                txnChannel: "ECOMMERCE",
              },
              isEnabled: true,
            },
          ],
        },
      )
      const result = response?.data

      if (result?.success) {
        dispatch(setCards({ cards: [result.data] }))
        toast("The blocked card has been replaced")
        navigate("/", { replace: true })
      } else {
        navigate("/", { replace: true })
        toast("The blocked card has been replaced")
        consoleError(
          "Failed to enable domestic online transaction after Replacing Card. Error: " +
            result.errors,
        )
      }
    } catch (error) {
      navigate("/", { replace: true })
      consoleError(
        "An exception occurred while enabling domestic online transaction after Replacing Card. Error: " +
          error,
      )
    }
  }

  const onActivateCardSuccess = async apiToken => {
    try {
      // when activating card from tnc action, as of now activation code is not needed
      const response = await CardService.activateCard(apiToken, currentCard?.id)
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...user.cards?.[0] }
        tempCard.isActivated = true
        dispatch(setCards({ cards: [tempCard] }))
        toast("The blocked card has been replaced")
        navigate("/", { replace: true })
      } else {
        navigate("/", { replace: true })
        toast("The blocked card has been replaced")
        consoleError(
          "Failed to activate card after set mpin. Error: " + result.errors,
        )
      }
    } catch (error) {
      navigate("/", { replace: true })
      toast("The blocked card has been replaced")
      consoleError(
        "An exception occurred while activating card after set mpin. Error: " +
          error,
      )
    }
  }

  const handleSubmitClick = async () => {
    if (screen?.blockReplaceCard?.isTncEnabled && !isTncChecked) {
      return false
    }
    setSubmitLoading(true)
    handleApiAuth({
      apiId: ApiIds.REPLACE_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to request a new card",
      mpinReason: "to request a new card",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const updateBlockCodeAction = () => {
    let disabledFunctions = session?.disabledFunctionsByBlockCode
    if (session?.issuer === Issuers.AU_BANK) {
      let index = disabledFunctions.indexOf(AU_BlockCodes.TEMP_CARD_DISABLE)
      if (index > -1) {
        disabledFunctions.splice(index, 1)
      }
      let indexOfLocked = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
      if (indexOfLocked > -1) {
        disabledFunctions.splice(indexOfLocked, 1)
      }

      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
    if (session?.issuer === Issuers.FEDERAL_BANK) {
      let index = disabledFunctions.indexOf(Federal_BlockCodes.CARD_BLOCKED)
      if (index > -1) {
        disabledFunctions.splice(index, 1)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.replace(apiToken, user.cards[0]?.id, {
        cardExpiry: user.cards[0]?.cardExpiry?.replace(/\//g, ""), // remove forward slash from expiry
      })
      const result = response?.data
      if (result?.success) {
        const tempCard = { ...result.data }
        currentCard = tempCard

        updateBlockCodeAction()

        dispatch(setCards({ cards: [tempCard] }))
        if (
          screen?.blockReplaceCard?.tncAction ===
          BlockReplaceTncActions.ENABLE_DOMESTIC_ONLINE
        ) {
          await handleApiAuth({
            apiId: ApiIds.UPDATE_CARD_CONTROLS,
            onAuthSuccess: onEnableDomesticOnlineSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to enable domestic online transactions",
            mpinReason: "to enable domestic online transactions",
            toast,
            navigate,
          })
        } else if (
          screen?.blockReplaceCard?.tncAction ===
          BlockReplaceTncActions.ACTIVATE_CARD
        ) {
          await handleApiAuth({
            apiId: ApiIds.ACTIVATE_CARD,
            onAuthSuccess: onActivateCardSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to activate card",
            mpinReason: "to activate card",
            toast,
            navigate,
          })
        } else {
          toast("The blocked card has been replaced")
          navigate("/", { replace: true })
        }
      } else {
        consoleError(result.errors)
        toast("New card request failed")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return error ? (
    <View
      width='100%'
      height={windowDimension.height}
      alignItems='center'
      justifyContent='center'
    >
      <ErrorScreen />
    </View>
  ) : (
    <View alignItems='center' justifyContent='center'>
      <View
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        bgColor={theme.backgroundColor}
        s
      >
        <ScrollView>
          <Header
            text='Block Card'
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          <View
            {...styles.mainContainer}
            height={windowDimension.height}
            minH={window.height}
            bgColor={theme.backgroundColor}
          >
            <View
              my='24px'
              mx='16px'
              p='24px'
              bgColor={theme.widgetBackgroundColor}
              borderRadius='12px'
              borderWidth='1px'
              shadow='2'
              borderColor='#F8F8F8'
            >
              {/* body content start */}
              <View justifyContent='center'>
                <Text
                  {...styles.reasonsHeader}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                >
                  Request a New Card
                </Text>
                <Text
                  {...styles.reasonsSubHeader}
                  color={theme.appTextColor}
                  fontFamily={theme.fontFamily}
                >
                  Your card will be delivered to your current billing address as
                  below
                </Text>
              </View>
              {/* body title end */}

              {/* content box start */}
              <View
                {...styles.addressContainer}
                bgColor={`${theme.appTextColor}:alpha.30`}
              >
                <Text
                  {...styles.addressText}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                >
                  {address}
                </Text>
              </View>
              {/* body content end */}
            </View>
            <View
              display={"flex"}
              alignItems='center'
              justifyContent={"center"}
            >
              <View
                {...styles.messageContainer}
                bgColor={theme.widgetBackgroundColor}
              >
                <View {...styles.infoSvgContainer}>
                  <Info
                    iconProps={{
                      size: "40px",
                      mt: "-1px",
                      fill: `${theme.appTextColor}`,
                    }}
                  />
                </View>
                <Text {...styles.messageText} color={theme.appTextColor}>
                  Please approach your nearest branch in case you wish to change
                  your delivery address before requesting for a new card
                </Text>
              </View>
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              {screen?.blockReplaceCard?.isTncEnabled && (
                <View
                  alignItems={"center"}
                  width='100%'
                  justifyContent={"center"}
                  _web={{ maxW: DisplayConfig.MAX_WIDTH }}
                >
                  <View
                    {...styles.messageContainer}
                    backgroundColor={theme.BackgroundColor}
                  >
                    <View {...styles.infoSvgContainer} marginTop='0px'>
                      <Checkbox
                        marginLeft='-3px'
                        {...styles.tncCheckBox}
                        isChecked={isTncChecked}
                        backgroundColor={isTncChecked ? theme.color1 : null}
                        onChange={state => setIsTncChecked(state)}
                      />
                    </View>
                    <Text
                      marginTop='auto'
                      {...styles.messageText}
                      color={theme.appTextColor}
                    >
                      <EmbeddedLink text={screen.blockReplaceCard?.tncUrl} />
                    </Text>
                  </View>
                </View>
              )}
              <ColorButton
                isDisabled={
                  screen?.blockReplaceCard?.isTncEnabled && !isTncChecked
                }
                text='Confirm'
                onPress={handleSubmitClick}
                isLoading={submitLoading}
              />
              <WhiteButton
                text='Return to Home Screen'
                onPress={() => navigate("/", { replace: true })}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default NewCardRequest_v2
