import {
  ChevronDownIcon,
  ChevronUpIcon,
  CircleIcon,
  Text,
  View
} from "native-base"
import React, { useState } from "react"
import { useSelector } from "react-redux"

const OfferDetailAccordian_v2 = props => {
  const [open, setOpen] = useState(props.index === 0)
  const [theme] = useSelector(state => [state.theme])
  return (
    <View>
      <View alignItems={"center"} flexDir='row'>
        <View w='85%' ml='4px'>
          <Text
            fontSize='14px'
            lineHeight='22px'
            fontWeight='600'
            color={theme.appTextColor}
          >
            {props.data.title}
          </Text>
        </View>
        <View
          onClick={() => {
            setOpen(!open)
          }}
          cursor='pointer'
          w='7%'
        >
          {open ? (
            <ChevronUpIcon size='6' color={theme.appTextColor} />
          ) : (
            <ChevronDownIcon size='6' color={theme.appTextColor} />
          )}
        </View>
      </View>
      {open &&
        props.data.points.map(item => (
          <View alignItems={"flex-start"} flexDir='row' mt='8px' ml='4px'>
            <View w='5%' mt='6px'>
              <CircleIcon size={2} color={theme.color1} />
            </View>
            <View w='90%' ml='4px'>
              <Text
                fontSize='14px'
                lineHeight='16px'
                fontWeight='400'
                color={theme.appTextColor}
              >
                {item}
              </Text>
            </View>
            <View w='5%'></View>
          </View>
        ))}
    </View>
  )
}

export default OfferDetailAccordian_v2
