import { combineReducers } from "redux"
import theme from "./Theme"
import session from "./Session"
import preferences from "./Preferences"
import user from "./User"
import otp from "./Otp"
import setMpin from "./SetMpin"
import auth from "./Auth"
import common from "./Common"
import config from "./Config"
import screen from "./Screen"
import featureFlags from "./FeatureFlags"

export default combineReducers({
  theme,
  session,
  preferences,
  user,
  otp,
  setMpin,
  auth,
  common,
  config,
  screen,
  featureFlags,
})
