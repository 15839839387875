import React, { useState, useRef, useEffect } from "react"
import YearAccordion_v1 from "./YearAccordion_v1"
import YearAccordion_v2 from "./YearAccordion_v2"
import { Animated } from "react-native"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"

const YearAccordion = ({ yearIndex, year, statements }) => {
  // since bodySectionHeight is not known beforehand, initially setting first accordion to closed and all other open, and then toggling all in useEffect
  const [isOpened, setIsOpened] = useState(yearIndex !== 0)
  const animatedController = useRef(new Animated.Value(0)).current
  const [bodySectionHeight, setBodySectionHeight] = useState(0)
  const [config] = useSelector(state => [state.config])

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  })

  useEffect(() => {
    toggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggle = () => {
    if (isOpened) {
      Animated.timing(animatedController, {
        duration: 500,
        toValue: 0,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 500,
        toValue: 1,
        useNativeDriver: false,
      }).start()
    }
    setIsOpened(!isOpened)
  }

  const getVersionedComponent = version => {
    switch (version) {
      case PwaVersions.V2:
        return (
          <YearAccordion_v2
            isOpened={isOpened}
            toggle={toggle}
            bodyHeight={bodyHeight}
            setBodySectionHeight={setBodySectionHeight}
            yearIndex={yearIndex}
            statements={statements}
            year={year}
          />
        )
      default:
        return (
          <YearAccordion_v1
            isOpened={isOpened}
            toggle={toggle}
            bodyHeight={bodyHeight}
            setBodySectionHeight={setBodySectionHeight}
            yearIndex={yearIndex}
            statements={statements}
            year={year}
          />
        )
    }
  }
  return getVersionedComponent(config?.version)
}

export default YearAccordion
