import { Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ProgramTypes } from "../../../utils/constants"
import { AU_BlockCodes } from "../../../utils/enums"
import { copyToClipboard, formatCardNumber } from "../../../utils/functions"
import { showToast1 } from "../../core/toast"
import Copy from "../../svg/copy"
import EyeHide from "../../svg/eyeHide"
import EyeShow from "../../svg/eyeShow"
import styles from "../styles"

const CardDetails_v2 = props => {
  const nativeToast = useToast()
  const [isCvvHiden, setIsCvvHiden] = useState(true)

  const navigate = useNavigate()
  const copy = () => {
    copyToClipboard(props.widgetDetails?.unmaskedCardNumber || "")
    toast("Card Number Copied")
  }

  const theme = props.theme

  const toast = message => {
    const theme = props.theme
    showToast1({ nativeToast, theme, message })
  }

  const [session,featureFlags] = useSelector(
    state => [
      state.session,
      state.featureFlags,
    ],
  )

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIRTUAL_CARD_DISABLE,
      )
    ) {
      navigate("/")
      toast("Access has been blocked to virtual card")
    }
  }, [])

  return props.user.programType === ProgramTypes.PREPAID ? (
    <View
      borderColor={"#ECECEC"}
      borderWidth='1px'
      shadow={"4"}
      borderRadius='8px'
      bgColor={theme.widgetBackgroundColor}
    >
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading} color={theme.appTextColor}>
            Name on Card
          </Text>
        </View>
        <View alignItems='center' flexDirection='row'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText} color={theme.appTextColor}>
              {props.user.customer.firstName +
                " " +
                props.user.customer.lastName}
            </Text>
          </View>
        </View>
      </View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading} color={theme.appTextColor}>
            Card Number
          </Text>
        </View>
        <View alignItems='center' flexDirection='row' w='100%'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText} color={theme.appTextColor}>
              {isCvvHiden
                ? formatCardNumber(props.widgetDetails.unmaskedCardNumber)
                : "xxxx xxxx xxxx xxxx"}
            </Text>
          </View>

          {featureFlags?.showCardCopyButton ? (
            <View
              ml='12px'
              onClick={() => {
                copyToClipboard(props.widgetDetails?.unmaskedCardNumber || "")
                toast("Card Number Copied")
              }}
            >
              <Copy />
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
      <View flexDirection={"row"}>
        <View px='20px' py='24px'>
          <View>
            <Text {...styles.widgetHeading} color={theme.appTextColor}>
              Expiry
            </Text>
          </View>
          <View mt='4px'>
            {isCvvHiden ? (
              <Text {...styles.widgetDetailText} color={theme.appTextColor}>
                {props.widgetDetails.cardExpiry}
              </Text>
            ) : (
              <Text {...styles.widgetDetailText} color={theme.appTextColor}>
                xx/xx
              </Text>
            )}
          </View>
        </View>
        <View px='20px' py='24px'>
          <View>
            <Text {...styles.widgetHeading} color={theme.appTextColor}>
              CVV
            </Text>
          </View>

          <View alignItems='center' flexDirection='row'>
            <View mt='4px'>
              {isCvvHiden ? (
                <Text {...styles.widgetDetailText} color={theme.appTextColor}>
                  ***
                </Text>
              ) : (
                <Text {...styles.widgetDetailText} color={theme.appTextColor}>
                  {props.widgetDetails.cvv}
                </Text>
              )}
            </View>
            <View ml='12px'>
              {isCvvHiden ? (
                <View
                  onClick={() => {
                    setIsCvvHiden(false)
                  }}
                >
                  <EyeShow />
                </View>
              ) : (
                <View
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => {
                    setIsCvvHiden(true)
                  }}
                >
                  <EyeHide />
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  ) : (
    <View
      borderColor={"#ECECEC"}
      borderWidth='1px'
      shadow={"4"}
      borderRadius='8px'
      bgColor={theme.widgetBackgroundColor}
    >
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading} color={theme.appTextColor}>
            Card Number
          </Text>
        </View>
        <View alignItems='center' flexDirection='row'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText} color={theme.appTextColor}>
              {isCvvHiden
                ? formatCardNumber(props.widgetDetails.unmaskedCardNumber)
                : "xxxx xxxx xxxx xxxx"}
            </Text>
          </View>
          {featureFlags?.showCardCopyButton ? (
            <View
              ml='12px'
              onClick={() => {
                isCvvHiden ? copy() : toast("Cannot copy Card Number ")
              }}
            >
              <Copy />
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading} color={theme.appTextColor}>
            Expiry
          </Text>
        </View>
        <View mt='4px'>
          {isCvvHiden ? (
            <Text {...styles.widgetDetailText} color={theme.appTextColor}>
              {props.widgetDetails.cardExpiry}
            </Text>
          ) : (
            <Text {...styles.widgetDetailText} color={theme.appTextColor}>
              xx/xx
            </Text>
          )}
        </View>
      </View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading} color={theme.appTextColor}>
            CVV
          </Text>
        </View>
        <View alignItems='center' flexDirection='row'>
          <View mt='4px'>
            {isCvvHiden ? (
              <Text {...styles.widgetDetailText} color={theme.appTextColor}>
                ***
              </Text>
            ) : (
              <Text {...styles.widgetDetailText} color={theme.appTextColor}>
                {props.widgetDetails.cvv}
              </Text>
            )}
          </View>
          <View ml='12px'>
            {isCvvHiden ? (
              <View
                onClick={() => {
                  setIsCvvHiden(false)
                }}
              >
                <EyeShow />
              </View>
            ) : (
              <View
                alignItems={"center"}
                justifyContent={"center"}
                onClick={() => {
                  setIsCvvHiden(true)
                }}
              >
                <EyeHide />
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default CardDetails_v2
