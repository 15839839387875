import {
  ChevronRightIcon, Text, useToast, View
} from "native-base"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AU_BlockCodes, Federal_BlockCodes } from "../../../../utils/enums"
import { formatAmount, formatNegativeAmount } from "../../../../utils/functions"
import { showToast1 } from "../../../core/toast"
import styles from "../styles"

const StatementData_v2_new = ({ statement }) => {
  const navigate = useNavigate()
  const data = {
    Payment: {
      amount: statement.payments,
      sign: "-",
    },
    "Refunds & Credits": {
      amount: statement.refundsAndCredits,
      sign: "-",
    },
    "Purchase & Debits": {
      amount: statement.purchasesAndDebits,
      sign: "+",
    },
    Cashback: {
      amount: statement.cashback,
      sign: "-",
    },
  }

  const nativeToast = useToast()
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const [theme, session] = useSelector(state => [
    state.theme,
    state.session,
  ])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      toast("Access has been blocked for Statement Page")
      navigate("/")
    }
  }, [])
  return (
    <View style={styles.statementDataHeader}>
      <View marginBottom={5}>
        <View px='4' style={styles.summaryHeader}>
          <Text
            fontSize='14px'
            fontFamily={theme.fontFamily}
            fontWeight={600}
            color={theme.appTextColor}
          >
            Summary
          </Text>
          <View width='18px' height='16px'></View>
        </View>
        <View py='2'>
          <View style={styles.statementDetails} px='4'>
            <Text
              width='70%'
              {...styles.statementFontCommonStyles}
              color={theme.appTextColor}
            >
              Opening Balance
            </Text>
            <Text
              width='30%'
              textAlign='right'
              {...styles.statementAmountFontCommonStyles}
              color={theme.appTextColor}
            >
              {formatNegativeAmount(
                formatAmount(
                  statement.openingBalance.totalBalance === 0
                    ? 0
                    : statement.openingBalance.totalBalance * -1,
                ),
              )}
            </Text>
          </View>
          {Object.keys(data).map((key, statement) =>
            data[key].amount === undefined ||
            data[key].amount === null ? null : (
              <View
                style={styles.statementDetails}
                key={key}
                px='4'
                mt='3'
                w='100%'
              >
                <View width='40%'>
                  <Text
                    {...styles.statementFontCommonStyles}
                    key={key}
                    color={theme.appTextColor}
                  >
                    {key}
                  </Text>
                </View>
                <View
                  width='7%'
                  p='2px'
                  borderRadius={"24px"}
                  backgroundColor={`${theme.appTextColor}:alpha.20`}
                >
                  <Text
                    textAlign='center'
                    {...styles.statementAmountFontCommonStyles}
                    color={theme.appTextColor}
                  >
                    {data[key].sign}
                  </Text>
                </View>
                <View width='30%'>
                  <Text
                    textAlign='right'
                    {...styles.statementAmountFontCommonStyles}
                    color={theme.appTextColor}
                  >
                    {formatNegativeAmount(formatAmount(data[key].amount))}
                  </Text>
                </View>
              </View>
            ),
          )}
          <View borderBottomWidth={1} mb='4' mt='3' borderColor='#ECECEC' />
          <View style={styles.statementDetails} mt='2' px='4'>
            <Text
              mb='2'
              width='50%'
              {...styles.statementFontCommonStyles}
              fontWeight={700}
              color={theme.appTextColor}
            >
              Total Amount Due
            </Text>
            <Text
              width='50%'
              textAlign='right'
              {...styles.statementAmountFontCommonStyles}
              color={theme.appTextColor}
            >
              {formatNegativeAmount(formatAmount(statement.initialTAD))}
            </Text>
          </View>

          {statement.initialMAD !== undefined &&
            statement.initialMAD !== null && (
              <View style={styles.statementDetails} px='4'>
                <Text
                  mb='2'
                  width='50%'
                  {...styles.statementFontCommonStyles}
                  color={theme.appTextColor}
                >
                  Min. Amount Due
                </Text>
                <Text
                  width='50%'
                  textAlign='right'
                  {...styles.statementAmountFontCommonStyles}
                  color={theme.appTextColor}
                >
                  {formatNegativeAmount(formatAmount(statement.initialMAD))}
                </Text>
              </View>
            )}

          <View
            style={styles.statementDetails}
            px='4'
            cursor={"pointer"}
            onClick={() =>
              navigate("/Transactions", {
                state: { from: statement.fromDate, to: statement.toDate },
              })
            }
          >
            <Text
              width='100%'
              {...styles.statementFontCommonStyles}
              color={theme.color1}
              fontWeight={"600"}
            >
              View Statement Transactions
              <ChevronRightIcon color={theme.color1} size={5} />
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default StatementData_v2_new
