import { ChevronDownIcon, ChevronUpIcon, Text, View } from "native-base"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import Pointer from "../../../../svg/pointer"

const FaqAccordian = props => {
  const [subIsOpen, setSubIsOpen] = useState(true)
  const theme = useSelector(state => state.theme)
  const ans = props.item?.answer.split("#") || []
  return (
    <View
      mt='24px'
      w='100%'
      // borderBottomWidth={"1px"}
      // borderColor={"#ECECEC"}
    >
      <View alignItems={"center"} flexDir='row'>
        <View w='85%' ml='4px'>
          <Text
            fontSize='14px'
            lineHeight='22px'
            fontWeight='600'
            color={theme.appTextColor}
          >
            {props.item?.question}
          </Text>
        </View>
        <View
          onClick={() => {
            setSubIsOpen(!subIsOpen)
          }}
          cursor='pointer'
          w='7%'
        >
          {subIsOpen ? (
            <ChevronUpIcon size='6' color={theme.appTextColor} />
          ) : (
            <ChevronDownIcon size='6' color={theme.appTextColor} />
          )}
        </View>
      </View>
      {subIsOpen &&
        ans.map(item => (
          <View alignItems={"center"} flexDir='row' mt='8px' ml='4px'>
            <View w='7%'>
              <Pointer />
            </View>
            <View w='80%' ml='4px'>
              <Text
                fontSize='14px'
                lineHeight='22px'
                fontWeight='400'
                color={theme.appTextColor}
              >
                {item}
              </Text>
            </View>
            <View w='7%'></View>
          </View>
        ))}
    </View>
  )
}

export default FaqAccordian
