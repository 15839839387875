import React from "react"
import { useSelector } from "react-redux"
import {
  AuthChallenges,
  DEFAULT_OTP_EMAIL_SIZE,
} from "../../../utils/constants"
import { PwaVersions } from "../../../utils/enums"
import Otp_v2 from "./Otp_v2"

const EmailOtp = () => {
  const [config] = useSelector(state => [state.config])
  const getVersionedOtp = version => {
    switch (version) {
      case PwaVersions.V1:
        return <></>
      case PwaVersions.V2:
        return (
          <Otp_v2
            otp={
              config?.auth?.[AuthChallenges.OTP_EMAIL]?.length ||
              DEFAULT_OTP_EMAIL_SIZE
            }
          />
        )
      default:
        return <></>
    }
  }
  return getVersionedOtp(config?.version)
}

export default EmailOtp
