import moment from "moment"
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  FlatList,
  Image,
  Text,
  Toast,
  View,
  useToast,
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import homeEmptyTransaction from "../../../../assets/images/homeEmptyTransaction.svg"
import AccountService from "../../../../services/AccountService"
import { ProgramTypes } from "../../../../utils/constants"
import { AU_BlockCodes, Federal_BlockCodes } from "../../../../utils/enums"
import Transaction from "../../../core/Transaction"
import { ColorButton } from "../../../core/buttons"
import CustomLoader from "../../../core/loader"
import styles from "./styles"

const options = {
  ALL: "ALL",
  UNBILLED: "UNBILLED",
}

const initTransactions = []

const initOffset = 0
const transactionCount = 10

const TransactionSection_v2 = props => {
  const navigate = useNavigate()
  const toast = useToast()

  const [theme, screen, user, session] = useSelector(state => [
    state.theme,
    state.screen,
    state.user,
    state.session,
  ])
  const [isOpen, setIsOpen] = useState(true)
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)
  const [transactions, setTransactions] = useState(initTransactions)
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const [apiError, setApiError] = useState(0)
  const offset = useRef(initOffset)
  const isTransactionsLoadingRef = useRef(false)

  const fetchUnbilledTransactions = async () => {
    try {
      const response = await AccountService.getUnbilledTransactions(
        user.account.id,
        {
          count: transactionCount,
          offset: offset.current,
        },
      )
      const result = response.data

      if (result?.success) {
        if (result.data.totalCount !== 0) {
          setTransactions(transactions => [
            ...transactions,
            ...result.data.transactions,
          ])
        }
        if (result.data.hasMore) {
          offset.current += transactionCount
        } else if (result.data.totalCount !== 0 && !result.data.hasMore) {
          setTransactions(transactions => [...transactions, { type: "EMPTY" }])
          offset.current = -1
        }
      } else {
        setTransactions(transactions => [...transactions, { type: "EMPTY" }])
        offset.current = -1
        setApiError(apiError + 1)
      }
    } catch (error) {
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
      offset.current = -1
      setApiError(apiError + 1)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)
    if (
      offset.current !== -1 &&
      !isTransactionsLoadingRef.current &&
      apiError < 3
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns

      await fetchUnbilledTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    }
  }
  useEffect(() => {
    fetchUnbilledTransactions()
  }, [theme?.transactionViewType])

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <>
            <View {...styles.header}>
              {/* <Link to='/'>
                <View>
                  <ChevronLeftIcon size='5' color='#7B7B7B' />
                </View>
              </Link> */}
              <View>
                <Text fontFamily={theme.fontFamily} {...styles.heading}>
                  Transactions
                </Text>
              </View>
              <View></View>
            </View>
          </>
        )

      case "DATE":
        return (
          <View marginTop={30} marginLeft={6}>
            <Text {...styles.dateText}>
              {moment(item.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? "Today"
                : moment(item.date).format("Do MMM YY")}
            </Text>
          </View>
        )

      case "EMPTY":
        return (
          <View
            flex='row'
            cursor='pointer'
            width='100%'
            alignItems='center'
            my='16px'
          >
            <ColorButton
              opacity={!viewStatementByBlockCode ? "0.4" : "1"}
              text={"View Statement Summary"}
              onPress={!viewStatementByBlockCode ? null : ViewTransaction}
            />
          </View>
        )

      default:
        return (
          <View {...styles.transactionContainer} key={index}>
            <Transaction item={item} />
          </View>
        )
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <View {...styles.loader}>
        <CustomLoader color={theme.color1} width={20} height={20} />
      </View>
    ) : null
  }
  const isAll = theme?.transactionViewType === options.ALL
  const ViewTransaction = () => {
    if (user.programType === ProgramTypes.PREPAID) navigate("/Transactions")
    else if (isAll) navigate("/ViewTransactions")
    else navigate("/Statement")
  }

  useEffect(() => {
    if (apiError >= 3) {
      toast.show({
        placement: "bottom",
        render: () => {
          return (
            <Toast>
              <View ml='8px'>
                <Text
                  color={theme.toastColor}
                  fontFamily={theme.fontFamily}
                  {...styles.toast}
                >
                  Error fetching Transactions
                </Text>
              </View>
            </Toast>
          )
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      if (user.programType !== ProgramTypes.PREPAID) {
        setViewStatementByBlockCode(false)
      }
    }
  })
  const getMsg = () => {
    if (user.programType === ProgramTypes.PREPAID)
      return "View All Transactions"
    else if (isAll) return null
    else return "View Statement Summary"
  }

  return (
    <>
      <View
        p='16px'
        w='90%'
        borderRadius='12px'
        shadow='1'
        bgColor={theme.widgetBackgroundColor}
        mb='24px'
      >
        <View
          w='100%'
          flexDirection={"row"}
          alignItems='center'
          justifyContent={"space-between"}
          mb={isOpen ? "24px" : "0"}
        >
          <View>
            <Text
              fontFamily={theme.fontFamily}
              fontWeight='600'
              fontSize='16px'
              lineHeight='22px'
              color={theme.appTextColor}
            >
              Recent Transactions
            </Text>
          </View>
          <View onClick={() => setIsOpen(!isOpen)} cursor='pointer'>
            {isOpen ? (
              <ChevronUpIcon size='6' color={theme.appTextColor} />
            ) : (
              <ChevronDownIcon size='6' color={theme.appTextColor} />
            )}
          </View>
        </View>

        {user.programType === ProgramTypes.PREPAID || isAll
          ? props.recentTransactions.length > 0
            ? isOpen &&
              props.recentTransactions.map((transaction, index) => (
                <View {...styles.transactionContainer} key={index}>
                  <Transaction item={transaction} />
                </View>
              ))
            : isOpen && (
                <View alignItems={"center"} justifyContent='center' mt='24px'>
                  <View>
                    <Image
                      source={{
                        uri:
                          screen?.home?.transaction?.transactioEmptyImageUrl ||
                          homeEmptyTransaction,
                      }}
                      width='185px'
                      height='122px'
                      alt='Alternate Text '
                    />
                  </View>
                  <View mt='24px'>
                    <Text
                      fontFamily={theme.fontFamily}
                      fontWeight='600'
                      fontSize='14px'
                      lineHeight='20px'
                      color={theme.appTextColor}
                    >
                      No Transactions Yet
                    </Text>
                  </View>
                </View>
              )
          : null}
        {user.programType !== ProgramTypes.PREPAID &&
        theme?.transactionViewType === options.UNBILLED &&
        isOpen ? (
          transactions.length > 0 ? (
            <FlatList
              data={transactions}
              renderItem={renderItem}
              keyExtractor={(_transaction, index) => index}
              onEndReached={loadMoreTransaction}
              ListFooterComponent={renderLoader}
              onEndReachedThreshold={0.2}
              refreshing={false}
              onRefresh={handleRefresh}
            />
          ) : (
            <View alignItems={"center"} justifyContent='center' mt='24px'>
              <View>
                <Image
                  source={{
                    uri:
                      screen?.home?.transaction?.transactioEmptyImageUrl ||
                      homeEmptyTransaction,
                  }}
                  width='185px'
                  height='122px'
                  alt='Alternate Text '
                />
              </View>
              <View mt='24px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='14px'
                  lineHeight='20px'
                  color={theme.appTextColor}
                >
                  No Transactions Yet
                </Text>
              </View>
              {getMsg() && (
                <View
                  alignItems={"center"}
                  justifyContent='center'
                  onClick={() => ViewTransaction()}
                  cursor={"pointer"}
                >
                  <Text color={theme.color1}>
                    {getMsg()}
                    <ChevronRightIcon mb='1px' color={theme.color1} size={4} />
                  </Text>
                </View>
              )}
            </View>
          )
        ) : null}

        {
          <View>
            {user.programType === ProgramTypes.PREPAID || isAll
              ? props.recentTransactions.length > 0
                ? isOpen && (
                    <View
                      flex='row'
                      cursor='pointer'
                      width='100%'
                      alignItems='center'
                      my='16px'
                    >
                      <ColorButton
                        opacity={!viewStatementByBlockCode ? "0.4" : "1"}
                        text={getMsg()|| "View All Transactions"}
                        onPress={
                          !viewStatementByBlockCode ? null : ViewTransaction
                        }
                      />
                    </View>
                  )
                : isOpen &&
                  (user.summary.lastStatement.toDate ||
                    props.recentTransactions.length === 0) &&
                  getMsg() && (
                    <View
                      alignItems={"center"}
                      justifyContent='center'
                      onClick={() => ViewTransaction()}
                      cursor={"pointer"}
                    >
                      <Text color={theme.color1}>
                        {getMsg()}
                        <ChevronRightIcon
                          mb='1px'
                          color={theme.color1}
                          size={4}
                        />
                      </Text>
                    </View>
                  )
              : null}
          </View>
        }
      </View>
    </>
  )
}

export default TransactionSection_v2
