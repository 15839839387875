import React from "react"
import { Route, Routes } from "react-router-dom"

import ShowTransactions from "../components/AllTransactions/index"
import ViewTransactions from "../components/AllTransactions/v2/viewTransactions"
import AuthCustomer from "../components/auth/customer/Customer"
import EmailOtp from "../components/auth/emailOtp"
import AuthMpin from "../components/auth/mpin/Mpin"
import MpinBlocked from "../components/auth/mpin/MpinBlocked"
import AuthSetMpin from "../components/auth/mpin/SetMpin"
import AuthOtp from "../components/auth/otp/index"
import ActivationLimits from "../components/core/ActivationLimits"
import Payment from "../components/core/payment"
import QRscanner from "../components/core/QRscanner/QRscanner"
import CardDetailsWidget from "../components/Home/Components/CardDetailsWidget/index"
import EMIPlans from "../components/Home/Components/ConvertToEMI/EMIPlans"
import Home from "../components/Home/home"
import ManageCard from "../components/ManageCard/manageCard"
import AboutProgram from "../components/ManageCard/screens/AboutProgram"
import BlockCard from "../components/ManageCard/screens/BlockCard/blockCard/index"
import BlockCardReason from "../components/ManageCard/screens/BlockCard/blockCardReason/index"
import CardBlocked from "../components/ManageCard/screens/BlockCard/cardBlocked/index"
import NewCardRequest from "../components/ManageCard/screens/BlockCard/newCardRequest/index"
import CardSettings from "../components/ManageCard/screens/CardSettings/index"
import CustomerCare from "../components/ManageCard/screens/CustomerCare"
import Emi from "../components/ManageCard/screens/EMI/Emi"
import ForeClose from "../components/ManageCard/screens/EMI/ForeClose"
import Blocked from "../components/messages/Blocked"
import ErrorScreen from "../components/messages/errorScreen"
import SessionExpired from "../components/messages/SessionExpired"
import ResetPin from "../components/MockSwitch/ResetPin/index"
import OfferDetails from "../components/OffersList/OfferDetails"
import OfferList from "../components/OffersList/OfferList"
import Preloader_v2 from "../components/PreLoader/preloader_v2"
import Rewards from "../components/Rewards"
import StatementFetchAllNew from "../components/Statement/fetchAll/Main"
import Paths from "./Paths"

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.HOME} element={<Home />} />
      <Route path={Paths.HOME_WITH_ACTIVATE} element={<Home />} />
      <Route path='/loader' element={<Preloader_v2 />} />
      <Route path='/Transactions' element={<ShowTransactions />} />
      <Route path='/ViewTransactions' element={<ViewTransactions />} />
      {/* <Route path="/Statement" element={<Statement />} /> */}
      <Route path='/rewards' element={<Rewards />} />
      <Route path='/Statement' element={<StatementFetchAllNew />} />

      <Route path='/payment' element={<Payment />} />
      <Route path='/ResetPin' element={<ResetPin />} />
      <Route path='/Offers' element={<OfferList />} />
      <Route path='/Offers/Details' element={<OfferDetails />} />
      <Route path='/ManageCard' element={<ManageCard />} />
      <Route path='/ManageCard/CardSettings' element={<CardSettings />} />
      <Route path='/ManageCard/BlockCard' element={<BlockCard />} />
      <Route
        path='/ManageCard/BlockCard/Reason'
        element={<BlockCardReason />}
      />
      <Route
        path='/ManageCard/BlockCard/CardBlocked'
        element={<CardBlocked />}
      />
      <Route path='/ConvertToEMI' element={<EMIPlans />} />

      <Route path='/ManageCard/NewCardRequest' element={<NewCardRequest />} />
      <Route path='/ManageCard/ListEMI' element={<Emi />} />
      <Route path='/ManageCard/AboutProgram' element={<AboutProgram />} />
      <Route path='/ManageCard/CustomerCare' element={<CustomerCare />} />
      <Route path='/ManageCard/ForecloseEmi' element={<ForeClose />} />

      <Route path='/CardDetailsWidget' element={<CardDetailsWidget />} />
      <Route
        path='/CardDetailsError'
        element={<ErrorScreen showButtons={false} />}
      />

      <Route path='/ActivateCard/Scanner' element={<QRscanner />} />
      <Route
        path={Paths.ACTIVATION_CARD_CONTROLS}
        element={<ActivationLimits />}
      />

      <Route path='/Auth/Otp' element={<AuthOtp />} />
      <Route path='/Auth/EmailOtp' element={<EmailOtp />} />
      <Route path='/Auth/Mpin' element={<AuthMpin />} />
      <Route path='/Auth/SetMpin' element={<AuthSetMpin />} />
      <Route path='/Auth/MpinBlocked' element={<MpinBlocked />} />
      <Route path='/Auth/Customer' element={<AuthCustomer />} />

      <Route path='/SessionExpired' element={<SessionExpired />} />
      <Route path='/Error' element={<ErrorScreen />} />
      <Route path='/Blocked' element={<Blocked />} />

      <Route path='*' element={<Home />} />
    </Routes>
  )
}

export default CustomRoutes
