import { Input, Link, Text, useToast, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CommonService from "../../../services/CommonService"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import * as analytics from "../../../utils/analytics"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton
} from "../../../utils/auth"
import {
  AuthChallenges,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
  OtpErrors
} from "../../../utils/constants"
import { PwaVersions } from "../../../utils/enums"
import {
  getAnalyticsProgramType, getMaskedEmail, goToRedirectUrl
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import Info from "../../svg/info"
import styles from "./styles"
import AuthLoader from "../../core/SkeletonLoader/authLoader"

const intervalTime = 60

const Otp_v2 = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const [theme, user, authStore, config] = useSelector(state => [
    state.theme,
    state.user,
    state.auth,
    state.config,
  ])

  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [resendCount, setResendCount] = useState(0)
  const [seconds, setSeconds] = useState(intervalTime)
  const [pin, setPin] = useState(["", "", "", "", "", "", ""])

  const interval = useRef(null)
  const otpId = useRef("")
  const otp0 = useRef(null)
  const otp1 = useRef(null)
  const otp2 = useRef(null)
  const otp3 = useRef(null)
  const otp4 = useRef(null)
  const otp5 = useRef(null)
  const otp6 = useRef(null)

  const maxResendCount =
    config?.auth?.[AuthChallenges.OTP_EMAIL]?.maxResendCount

  const canResend = maxResendCount && maxResendCount > resendCount
  const showHeader = config?.version === PwaVersions.V2
  const headerText = getHeaderText(AuthChallenges.OTP_EMAIL)
  const windowDimensions = useWindowDimensions()

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  useEffect(() => {
    if (seconds < 1) {
      clearInterval(interval.current)
    }
  }, [seconds])

  useEffect(() => {
    ;(async () => {
      try {
        // generate otp for token and scope
        const response = await CommonService.generateChallengeEmailOTP({
          apiToken: authStore.apiToken,
          challengeScope: authStore.currentFactor.scope,
          emailAddress: user.customer.emailAddress || "testemail@gmail.com",
        })
        const result = response.data
        if (result?.success) {
          otpId.current = result.data.referenceId
          setSeconds(intervalTime)
          interval.current = setInterval(() => {
            setSeconds(seconds => seconds - 1)
          }, 1000)
        } else {
          // if otp generation failed call failure callback
          if (
            result?.errors?.status === OtpErrors.RETRIES_EXCEEDED ||
            result?.errors?.status === OtpErrors.BLOCKED_TEMPORARILY
          ) {
            const retryTime = result?.errors?.failureReason?.match(
              /Please retry after (.*)./,
            )?.[1]
            await authStore.onAuthFailure(
              result?.errors?.status,
              `OTP retries exceeded.\nPlease retry after ${
                retryTime || "some time"
              }.`,
            )
          } else {
            await authStore.onAuthFailure(
              result?.errors?.reason,
              "An error occurred while generating otp.\nPlease try again later.",
            )
          }
        }
      } catch (error) {
        // if exception occurred call failure callback
        await authStore.onAuthFailure(
          error,
          "An error occurred while generating otp.\nPlease try again later.",
        )
      }
    })()

    if (resendCount < 1) setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendCount])

  const verifyOtp = async () => {
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - Click on Verify OTP`,
    )
    if (pin.join("")?.length !== props.otp) {
      setError(true)
      toast("The OTP entered is incorrect")
      return
    }

    setSubmitLoading(true)

    try {
      // verify otp for token and scope
      const response = await CommonService.verifyChallengeOTPEmail({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        emailAddress: user.customer.emailAddress || "testemail@gmail.com",
        referenceId: otpId.current,
        otp: pin.join(""),
      })
      const result = response.data

      if (result?.success || result.errors.verificationStatus === "VERIFIED") {
        setError(false)

        // update otp ref id in verified challenges
        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          otpRefId: otpId.current,
        }

        // set verified challenges
        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        // on successful factor completion
        await onFactorCompletion(navigate)
      } else {
        if (result?.errors?.failureReason === OtpErrors.INVALID_OTP) {
          setError(true)
          toast("The OTP entered is incorrect")
        } else if (
          result?.errors?.failureReason === OtpErrors.OTP_EXPIRED &&
          canResend
        ) {
          setError(true)
          toast("Your OTP has expired")
        } else if (
          result?.errors?.failureReason === OtpErrors.OTP_EXPIRED &&
          !canResend
        ) {
          await authStore.onAuthFailure(
            result?.errors?.failureReason,
            "Your OTP has expired",
          )
        } else {
          // if otp verification failed due to unknow error then call failure callback
          await authStore.onAuthFailure(
            result?.errors?.reason,
            "An error occurred while verifying otp. Please try again later.",
          )
        }
      }
    } catch (error) {
      // if exception occurred call failure callback
      await authStore.onAuthFailure(
        error,
        "An error occurred. Please try again later.",
      )
    }

    setSubmitLoading(false)
  }
  return loading ? (
    <View>
      <AuthLoader/>
    </View>
  ) : (
    <View
      alignItems='center'
      minH={windowDimensions.height}
      bgColor={theme.backgroundColor}
    >
      <View w='100%'>
        {showHeader ? (
          <Header
            text={headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          {...styles.expand}
          px='16px'
          minHeight={
            windowDimensions.height -
            (showHeader ? HEADER_HEIGHT_IN_PX : 0) -
            20
          }
        >
          <View
            {...styles.pinMainView}
            backgroundColor={theme.widgetBackgroundColor}
            px='16px'
            py='24px'
            borderRadius={"12px"}
            borderWidth={"1px"}
            borderColor='#ECECEC'
            shadow={"2"}
          >
            <View>
              <Text
                {...styles.otpVerificationText}
                fontFamily={theme.fontFamily}
                color={theme.appTextColor}
              >
                Enter OTP sent to your registered Email Address
                {/* authStore.otpReason */}
              </Text>
            </View>
            <View {...styles.pinMainView1} mt='32px'>
              <Input
                {...styles.textInput}
                bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                ref={otp0}
                value={pin[0]}
                onChangeText={text => {
                  setPin([text, pin[1], pin[2], pin[3], pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    otp1.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                ref={otp1}
                value={pin[1]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[1] === "") {
                    otp0.current.focus()
                    setPin(["", "", pin[2], pin[3], pin[4], pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  setPin([pin[0], text, pin[2], pin[3], pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    otp2.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                ref={otp2}
                value={pin[2]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[2] === "") {
                    otp1.current.focus()
                    setPin([pin[0], "", "", pin[3], pin[4], pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  setPin([pin[0], pin[1], text, pin[3], pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    otp3.current.focus()
                  }
                }}
              />
              <Input
                {...styles.textInput}
                bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                ref={otp3}
                value={pin[3]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[3] === "") {
                    otp2.current.focus()
                    setPin([pin[0], pin[1], "", "", pin[4], pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  setPin([pin[0], pin[1], pin[2], text, pin[4], pin[5], pin[6]])
                  if (text !== "") {
                    props.otp === 4 ? otp3.current.blur() : otp4.current.focus()
                  }
                }}
              />
              {props.otp > 4 && (
                <Input
                  {...styles.textInput}
                  bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                  ref={otp4}
                  value={pin[4]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[4] === "") {
                      otp3.current.focus()
                      setPin([pin[0], pin[1], pin[2], "", "", pin[5], pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      pin[3],
                      text,
                      pin[5],
                      pin[6],
                    ])
                    if (text !== "") {
                      props.otp === 5
                        ? otp4.current.blur()
                        : otp5.current.focus()
                    }
                  }}
                />
              )}
              {props.otp > 5 && (
                <Input
                  {...styles.textInput}
                  bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                  ref={otp5}
                  value={pin[5]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[5] === "") {
                      otp4.current.focus()
                      setPin([pin[0], pin[1], pin[2], pin[3], "", "", pin[6]])
                    }
                  }}
                  onChangeText={text => {
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      pin[3],
                      pin[4],
                      text,
                      pin[6],
                    ])
                    if (text !== "") {
                      props.otp === 6
                        ? otp5.current.blur()
                        : otp6.current.focus()
                    }
                  }}
                />
              )}
              {props.otp > 6 && (
                <Input
                  {...styles.textInput}
                  bgColor={Boolean(error) ? "#FDF6F6" : "#fff"}
                  ref={otp6}
                  value={pin[6]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[6] === "") {
                      otp5.current.focus()
                      setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                    }
                  }}
                  onChangeText={text => {
                    setPin([
                      pin[0],
                      pin[1],
                      pin[2],
                      pin[3],
                      pin[4],
                      pin[5],
                      text,
                    ])
                    if (text !== "") {
                      otp6.current.blur()
                    }
                  }}
                />
              )}
            </View>
            {canResend && (
              <View mt='3'>
                <Text {...styles.resendOtp} color={theme.appTextColor}>
                  Didn't get OTP?{" "}
                  <Link
                    _text={{
                      color: seconds === 0 ? "blue.400" : "#808080",
                    }}
                    _web={{
                      mb: 1,
                    }}
                    isUnderlined={seconds === 0}
                    onPress={() => {
                      if (seconds < 1) {
                        setResendCount(count => count + 1)
                      }
                    }}
                  >
                    <Text
                      color={seconds === 0 ? theme.color1 : null}
                      fontWeight={seconds === 0 ? "600" : null}
                    >
                      Resend
                    </Text>
                  </Link>
                  <Text
                    display={seconds === 0 ? "none" : "inline"}
                    color={theme.color1}
                  >
                    {" "}
                    in{" "}
                  </Text>
                  <Text
                    color={theme.color1}
                    bold
                    display={seconds === 0 ? "none" : "inline"}
                  >
                    00:{seconds >= 10 ? seconds : `0${seconds}`}
                  </Text>
                </Text>
              </View>
            )}
          </View>
          {/* <View mt="12px" display={error ? "flex" : "None"}>
            <Text
              fontFamily={theme.fontFamily}
              font-weight="700"
              font-size="14px"
              line-height="18px"
              letter-spacing="0.02em"
              color="#C2181B"
            >
              {error}
            </Text>
          </View> */}
          <View {...styles.expand}></View>
          <View alignItems='center' justifyContent='center' w='100%' mt='20px'>
            <View {...styles.button} _web={{ maxW: DisplayConfig.MAX_WIDTH }}>
              <View
                {...styles.messageContainer}
                backgroundColor={theme.BackgroundColor}
              >
                <View {...styles.infoSvgContainer}>
                  <Info iconProps={{size:"40px", ml:"-10px", mt:"-1px",fill:`${theme.appTextColor}`}} />
                </View>
                <Text {...styles.messageText} color={theme.appTextColor}>
                  One Time Password (OTP) has been sent to your registered email
                  address{" "}
                  {user.customer.emailAddress ||
                    ("testemail@gmail.com" &&
                      "ending with " +
                        getMaskedEmail(
                          user.customer.emailAddress || "testemail@gmail.com",
                        ))}{" "}
                  linked with the bank.
                </Text>
              </View>
              <ColorButton
                text='Verify OTP'
                isDisabled={pin.join("").length !== props.otp}
                onPress={verifyOtp}
                isLoading={submitLoading}
              />
              {showCancelButton() && config?.version != PwaVersions.V2 && (
                <WhiteButton
                  text='Cancel'
                  onPress={async () => {
                    analytics.track(
                      `${getAnalyticsProgramType(
                        user.programType,
                      )} - Click on Cancel(OTP)`,
                    )
                    await authStore.onAuthCancel()
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Otp_v2
