import {
  Image,
  Text,
  View,
  useToast
} from "native-base"
import React, { useEffect } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ErrorImage from "../../../../assets/images/ServerError.svg"
import onlineTransactionActivation from "../../../../assets/images/onlineTransactionActivation.svg"
import { ProgramTypes } from "../../../../utils/constants"
import { AU_BlockCodes, Federal_BlockCodes } from "../../../../utils/enums"
import CardDetails_v2 from "../../../MockSwitch/CardDetails/CardDetails_v2"
import { Header } from "../../../core"
import ActivatePhysicalCardStrip from "../../../core/ActivatePhysicalCardStrip/ActivatePhysicalCardStrip"
import { showToast1 } from "../../../core/toast"
import styles from "./styles"

const CardDetailsWidget_v2 = props => {
  const [config, theme, session, user] = useSelector(state => [
    state.config,
    state.theme,
    state.session,
    state.user,
  ])

  const windowDim = useWindowDimensions()
  const navigate = useNavigate()
  const nativeToast = useToast()

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIRTUAL_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.CARD_BLOCKED,
      )
    ) {
      toast("Access has been blocked to see card Details")
      navigate("/")
    }
  }, [])

  return (
    <View minH={windowDim.height} bgColor={theme.backgroundColor}>
      <Header
        text='Card Details'
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      <View px='5'>
        {props.error ? (
          <View
            borderColor={"#ECECEC"}
            borderWidth='1px'
            shadow={"4"}
            borderRadius='12px'
            bgColor={"#FFFFFF"}
          >
            <Image {...styles.image} source={{ uri: ErrorImage }} />
            <View {...styles.widgetErrorTextContainer}>
              <Text {...styles.widgetErrorText}>
                An error occurred while loading card details
              </Text>
            </View>
          </View>
        ) : !props.user.enablePciWidget ? (
          <View
            {...styles.widget}
            alignSelf='center'
            w={props.user.programType !== ProgramTypes.PREPAID ? "100%" : "82%"}
          >
            <CardDetails_v2
              theme={props.theme}
              widgetDetails={props.widgetDetails}
              user={props.user}
            />
          </View>
        ) : (
          <View {...styles.widget}>
            <iframe
              height='500'
              title='Card Details Widget'
              allow='clipboard-write'
              src={props.url}
            />
          </View>
        )}
        {config?.cardDetailsBannerImageUrl && (
          <View {...styles.cardContainer} w={"100%"} py='20px'>
            <View>
              <img
                src={config?.cardDetailsBannerImageUrl}
                width='200px'
                height='300px'
                alt='Alternate Text '
              />
            </View>
          </View>
        )}
        {!props.error &&
          props.user.programType === ProgramTypes.PREPAID &&
          !props.isOnlineActive &&
          (user.programType === ProgramTypes.PREPAID
            ? props.user.cards?.[0]?.cardType === "Virtual"
              ? !(props.user.cards?.[0]?.isVirtualCardActivated)
              : !(props.user.cards?.[0]?.isPhysicalCardActivated)
            : !props.user.cards?.[0]?.isPhysicalCardActivated) && (
            <View mt='24px'>
              <ActivatePhysicalCardStrip
                width='100%'
                to='/ManageCard/CardSettings'
                image={onlineTransactionActivation}
                text='Activate Online Transaction'
                subText='Enable online usage of your card'
              />
            </View>
          )}
      </View>
    </View>
  )
}

export default CardDetailsWidget_v2
