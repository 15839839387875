import { Image, Input, Text, View } from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import Contact from "../../../assets/images/contacts.svg"
import AuLoginBanner from "../../../assets/images/HyperfaceLogo.svg"
import { DisplayConfig } from "../../../utils/constants"

const Login_v2 = props => {
  const buttonLoading = false;
  const windowDimensions = useWindowDimensions()

  const [theme] = useSelector(state => [state.theme])

  return (
    <View
      alignItems='center'
      minH={windowDimensions.height}
      justifyContent={"center"}
    >
      <View
        maxW={DisplayConfig.MAX_WIDTH}
        height={windowDimensions.height}
        w={windowDimensions.width}
      >
        {/* <Header /> */}
        <View
          w='100%'
          p='16px 24px'
          backgroundColor={"#060a37"}
          flexDirection='row'
          alignItems={"center"}
        >
          <View>
            <Image
              source={{
                uri: AuLoginBanner,
              }}
              height='42px'
              width='150px'
              alt=''
            />
          </View>
          <View ml='20%'>
            <Text
              color='#FFF'
              fontWeight='700'
              fontFamily='Quicksand'
              fontSize='24px'
              lineHeight='26px'
            ></Text>
          </View>
          <View w='20px'></View>
        </View>
        <View w={"100%"}>
          <View alignItems={"center"} mt='40%'>
            <View
              w='90%'
              ml={windowDimensions.width > 400 ? "0px" : "28px"}
              mx='4px'
              p='16px 24px'
              shadow={"6"}
              borderRadius='16px'
              borderWidth={"0.5px"}
              borderColor='#8C8C8C'
              backgroundColor={"#FFFFFF"}
            >
              <View
                height='94px'
                width='94px'
                p='24px'
                borderRadius={"100%"}
                bgColor='#F5F5F5'
                alignItems={"center"}
                justifyContent='center'
                justifySelf={"center"}
                alignSelf='center'
                mb='16px'
              >
                <Image
                  source={{
                    uri: Contact,
                  }}
                  height='60px'
                  width='60px'
                  alt=''
                />
              </View>
              <View>
                <Text
                  color='#303030'
                  fontWeight='600'
                  fontFamily={theme.fontFamily}
                  fontSize='16px'
                  lineHeight='26px'
                >
                  Hyperface Account ID
                </Text>
              </View>
              <View mt='4px'>
                <Input
                  placeholder=''
                  borderColor='#303030'
                  borderWidth='1px'
                  padding={"12px"}
                  value={props.accountId}
                  onChangeText={contact => {
                    props.setAccountId(contact)
                  }}
                  _focus={{ borderColor: "#303030" }}
                ></Input>
              </View>
              <View mt='24px'>
                <Text
                  color='#303030'
                  fontWeight='600'
                  fontFamily={theme.fontFamily}
                  fontSize='16px'
                  lineHeight='26px'
                >
                  Client ID
                </Text>
              </View>
              <View mt='4px'>
                <Input
                  placeholder=''
                  borderColor='#303030'
                  borderWidth='1px'
                  padding={"12px"}
                  value={props.clientId}
                  onChangeText={contact => {
                    props.setClientId(contact)
                  }}
                  _focus={{ borderColor: "#303030" }}
                ></Input>
              </View>

              <View
                alignItems='center'
                justifyContent='center'
                width='100%'
                mt='24px'
              >
                <View
                  ml={windowDimensions.width > 400 ? "0px" : "28px"}
                  cursor='pointer'
                  onClick={() => {
                    buttonLoading ? console.log() : props.login()
                  }}
                  w='100%'
                  alignItems='center'
                  justifyContent='center'
                  bgColor='#3B3486'
                  p='10px 140px'
                  borderRadius='12px'
                  opacity={buttonLoading ? "0.6" : "1"}
                >
                  <Text
                    color='#FFFFFF'
                    fontWeight='700'
                    fontFamily={theme.fontFamily}
                    fontSize='18px'
                    lineHeight='22px'
                  >
                    Login
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Login_v2
