import { Switch, Text, useToast, View } from "native-base"
import React, { useState } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CardService from "../../services/CardService"
import { consoleError } from "../../utils/functions"
import { ColorButton, WhiteButton } from "./buttons"
import { showToast1 } from "./toast"
import ApiIds from "../../auth/ApiIds"
import { handleApiAuth } from "../../utils/auth"
import { PwaVersions } from "../../utils/enums"
import { DisplayConfig } from "../../utils/constants"

const ActivationLimits = () => {
  const navigate = useNavigate()
  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()
  const [saveLoading, setSaveLoading] = useState(false)
  const [theme, user, config] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
  ])
  const [controls, setControls] = useState([true, true, false])

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const navigateForCardActivation = () =>
    navigate("/", { replace: true, state: { from: "CardActivated" } })

  const handleSaveClick = async () => {
    setSaveLoading(true)

    await handleApiAuth({
      apiId: ApiIds.UPDATE_CARD_CONTROLS,
      flowId: "4",
      onAuthSuccess: onCardSettingsAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to save card settings",
      mpinReason: "to save card settings",
      toast,
      navigate,
    })

    setSaveLoading(false)
  }

  const onCardSettingsAuthSuccess = async apiToken => {
    try {
      // set api data
      const data = [
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE",
          },
          isEnabled: controls[0],
        },
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "CONTACT",
          },
          isEnabled: controls[1],
        },
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "MAGNETIC",
          },
          isEnabled: controls[1],
        },
        {
          txnTags: {
            location: "DOMESTIC",
            txnType: "RETAIL",
            txnChannel: "CONTACTLESS",
          },
          isEnabled: controls[2],
        },
      ]

      const response = await CardService.updateCardControls(
        apiToken,
        user.cards[0]?.id,
        {
          updateCardControls: data,
        },
      )
      const result = response?.data

      if (result?.success) {
        toast("Card settings updated successfully", true)
        navigateForCardActivation()
      } else {
        consoleError(
          "An error occurred while updating card settings. Error:" +
            result?.errors,
        )
        toast("Sorry an error occurred while updating card settings")
        navigateForCardActivation()
      }
    } catch (error) {
      consoleError(
        "An exception occurred while updating card settings. Error:" + error,
      )
      toast("Sorry an error occurred while updating card settings")
      navigateForCardActivation()
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigateForCardActivation()
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigateForCardActivation()
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        minH={
          config?.version === PwaVersions.V2 ? windowDimensions.height : null
        }
        bgColor={
          config?.version === PwaVersions.V2 ? theme.backgroundColor : null
        }
      >
        <View height={windowDimensions.height} width='100%' px='16px' py='24px'>
          <View>
            <Text
              fontWeight='600'
              fontSize='20px'
              lineHeight='26px'
              color='#303030'
            >
              Card Control Setup
            </Text>
            <Text
              mt='12px'
              fontWeight='400'
              fontSize='18px'
              lineHeight='24px'
              color='#7B7B7B'
            >
              Please configure you card
            </Text>
          </View>

          <View
            px='20px'
            py='16px'
            bgColor='#F8F8F8'
            mt='28px'
            mb='12px'
            borderRadius='6px'
          >
            <View
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              borderBottomWidth='1px'
              borderBottomColor='#ECECEC'
              pb='16px'
            >
              <Text
                fontWeight='500'
                fontSize='14px'
                lineHeight='20px'
                color='#303030'
              >
                Online Transactions
              </Text>
              <Switch
                onThumbColor='whitesmoke'
                offTrackColor='grey'
                isDisabled={false}
                isChecked={controls[0]}
                onTrackColor={
                  config?.version === PwaVersions.V2
                    ? theme.color1
                    : theme.color2
                }
                size='md'
                onToggle={value =>
                  setControls([value, controls[1], controls[2]])
                }
              />
            </View>
            <View
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              py='16px'
            >
              <Text
                fontWeight='500'
                fontSize='14px'
                lineHeight='20px'
                color='#303030'
              >
                Offline Transactions
              </Text>
              <Switch
                onThumbColor='whitesmoke'
                offTrackColor='grey'
                isDisabled={false}
                isChecked={controls[1]}
                onTrackColor={
                  config?.version === PwaVersions.V2
                    ? theme.color1
                    : theme.color2
                }
                size='md'
                onToggle={value =>
                  setControls([controls[0], value, controls[2]])
                }
              />
            </View>
            <View
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              pt='16px'
            >
              <Text
                fontWeight='500'
                fontSize='14px'
                lineHeight='20px'
                color='#303030'
              >
                Contactless
              </Text>
              <Switch
                onThumbColor='whitesmoke'
                offTrackColor='grey'
                isDisabled={false}
                isChecked={controls[2]}
                onTrackColor={
                  config?.version === PwaVersions.V2
                    ? theme.color1
                    : theme.color2
                }
                size='md'
                onToggle={value =>
                  setControls([controls[0], controls[1], value])
                }
              />
            </View>
          </View>
          <View alignItems='center' justifyContent='center' w='100%'>
            <View
              _web={{ maxW: DisplayConfig.MAX_WIDTH }}
              pt='8px'
              alignItems='center'
              justifyContent='center'
              mt='24px'
              width='95%'
              bottom='24px'
              position='fixed'
            >
              <ColorButton
                text='Save Changes'
                isLoading={saveLoading}
                isDisabled={false}
                onPress={handleSaveClick}
              />
              {!config?.version == PwaVersions.V2 ? (
                <WhiteButton
                  text='Cancel'
                  onPress={() => navigateForCardActivation()}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ActivationLimits
