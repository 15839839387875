import React, { useState } from "react"
import { View, Text, Image, ScrollView } from "native-base"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { ColorButton } from "../../core/buttons"
import SuccessIcon from "../../../assets/images/Success1.svg"
import styles from "../../messages/styles"
import { getHeaderText, showCancelButton } from "../../../utils/auth"
import { goToRedirectUrl } from "../../../utils/functions"
import { AuthChallenges, HEADER_HEIGHT_IN_PX } from "../../../utils/constants"
import { Header } from "../../core"
import { PwaVersions } from "../../../utils/enums"

const SetMpinSuccess = ({ successImageUrl, onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const [theme, config] = useSelector(state => [state.theme, state.config])

  const [submitLoading, setSubmitLoading] = useState(false)

  const showHeader = config?.version === PwaVersions.V2
  const headerText = getHeaderText(AuthChallenges.MPIN)

  const handleSubmitClick = async () => {
    setSubmitLoading(true)
    await onSubmit()
    setSubmitLoading(false)
  }

  return (
    <View
      {...styles.mainView}
      minH={config?.version === PwaVersions.V2 ? windowDimensions.height : null}
      bgColor={
        config?.version === PwaVersions.V2 ? theme.backgroundColor : null
      }
    >
      <ScrollView w='100%'>
        {showHeader ? (
          <Header
            text={headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <View mt='20px'></View>
        )}
        <View
          {...styles.innerView}
          h={windowDimensions.height}
          bgColor={
            config?.version === PwaVersions.V2 ? theme.backgroundColor : null
          }
          {...styles.expand}
          minHeight={
            windowDimensions.height -
            (showHeader ? HEADER_HEIGHT_IN_PX : 0) -
            20
          }
        >
          <View {...styles.section1} mt='30%'>
            {successImageUrl ? (
              <Image {...styles.image} source={{ uri: successImageUrl }} />
            ) : (
              <View
                {...styles.imageContainer}
                bgColor={theme.widgetBackgroundColor}
              >
                <Image {...styles.image} source={{ uri: SuccessIcon }} />
              </View>
            )}
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}>
                <Text
                  {...styles.text1}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                >
                  MPIN successfully set!
                </Text>
              </View>
              <View {...styles.text2Container}>
                <Text
                  {...styles.text2}
                  fontFamily={theme.fontFamily}
                  color={theme.appTextColor}
                >
                  This MPIN will be used for logging in to our platform.
                </Text>
              </View>
            </View>
          </View>
          <View {...styles.expand}></View>
          <View {...styles.button} mt='20px'>
            <ColorButton
              text='Proceed'
              isLoading={submitLoading}
              onPress={handleSubmitClick}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default SetMpinSuccess
