import {
  SET_USER,
  SET_CUSTOMER,
  SET_ACCOUNT,
  SET_CARDS,
  SET_SUMMARY,
  SET_EMI_DETAILS,
} from "../ActionTypes"

export const setUser = data => dispatch => {
  dispatch({
    type: SET_USER,
    payload: data,
  })
}

export const setCustomer = data => dispatch => {
  dispatch({
    type: SET_CUSTOMER,
    payload: data,
  })
}

export const setAccount = data => dispatch => {
  dispatch({
    type: SET_ACCOUNT,
    payload: data,
  })
}

export const setCards = data => dispatch => {
  dispatch({
    type: SET_CARDS,
    payload: data,
  })
}

export const setEmiDetails = data => dispatch => {
  dispatch({
    type: SET_EMI_DETAILS,
    payload: data,
  })
}

export const setSummary = data => dispatch => {
  dispatch({
    type: SET_SUMMARY,
    payload: data,
  })
}
